import Paper from 'components/Paper';
import cx from 'classnames';
import React from 'react';
import { StyledFormPanel, StyledTopWrap, StyledTitleDescription } from './style';

interface FormPanelProps {
  title: string;
  description?: string;
  note?: string | React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  id?: string;
}

const FormPanel = (props: FormPanelProps): JSX.Element => {
  const {
    id = '', title = '', note, children, className, description
  } = props;

  return (
    <StyledFormPanel className={cx('form-panel', className)} aria-label={`${id} FormPanel`}>
      <Paper>
        <StyledTopWrap className="StyledTopWrap">
          <div>
            <div className="title" aria-label={`${id} Title Wrap`}>
              {title}
            </div>
            {description && <StyledTitleDescription aria-label={`${id} Description`}>{description}</StyledTitleDescription>}
          </div>
          <div className="note">
            {note}
          </div>
        </StyledTopWrap>
        {children}
      </Paper>
    </StyledFormPanel>
  );
};
export default FormPanel;
