import * as React from "react";
import { SVGProps } from "react";
const SvgPartnerships = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="-1 -2 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 9l3-3 3 3 4-4M6 18l4-4 4 4M1 1h18M2 1h16v12a1 1 0 01-1 1H3a1 1 0 01-1-1V1z"
      stroke="#fff"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPartnerships;
