import PersonalWelcomeBanner from 'components/PersonalWelcomeBanner';
import CorporateWelcomeBanner from 'components/CorporateWelcomeBanner';
import JointWelcomeBanner from 'components/JointWelcomeBanner';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { StyledContainer } from './style';

const AccountRegistrationBanner = (): JSX.Element => {
  const { t } = useTranslation('selectAccountType');
  const match = useMatch('create-account/:accountType/thank-you');
  const getContent = (): JSX.Element => {
    switch (match?.params.accountType) {
      case 'corporate-account':
        return <CorporateWelcomeBanner />;
      case 'joint-account':
        return <JointWelcomeBanner />;
      default:
        return (
          <PersonalWelcomeBanner
            className="acyWelcomeBanner"
            id="ACY"
            greeting={t('hello')}
            title={(
              <div aria-label="Registration title">
                {t('welcomeTo')}
              </div>
            )}
            content={t('accountRegistrationBannerContent')}
          />
        );
    }
  };

  return (
    <StyledContainer>
      {getContent()}
    </StyledContainer>
  );
};

export default AccountRegistrationBanner;
