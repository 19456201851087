/* eslint-disable no-unused-expressions */
import * as React from 'react';
import cx from 'classnames';
import { CommonProps } from 'type/component';
import {
  StyledContainerDiv, StyledInputText, StyledWarningMsg, StyledErrMsg
} from './style';

export interface InputProps
  extends CommonProps,
    Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      'readOnly' | 'required' | 'defaultValue'
    > {
  autoComplete?: 'on' | 'off';
  ref?: React.MutableRefObject<HTMLInputElement>;
  value?: string;
  defaultValue?: string;
  readonly?: boolean;
  height?: string;
  width?: string;
  label?: string;
  require?: string;
  errorMsg?: string;
  warningMsg?: string | string[];
  markWarning?: boolean;
  addonBefore?: React.ReactNode;
  addonAfter?: string | React.ReactNode;
  id?:string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPressEnterCallback?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    label = '', require, onPressEnterCallback, errorMsg, value, warningMsg, markWarning, id = '', ...others
  } = props;
  const addonBeforeArr = [];
  addonBeforeArr.push(props.addonBefore);
  const addonAfterArr = [];
  addonAfterArr.push(props.addonAfter);

  const renderWarningMsg = ():React.ReactNode => {
    if (Array.isArray(warningMsg)) {
      return warningMsg.map((eachMsg:string) => (
        <StyledWarningMsg key={eachMsg} markWarning={markWarning} aria-label={`${label} Error Message`}>
          {eachMsg}
        </StyledWarningMsg>
      ));
    }
    return (
      <StyledWarningMsg markWarning={markWarning} aria-label={`${label} Error Message`}>
        {warningMsg}
      </StyledWarningMsg>
    );
  };

  return (
    <StyledContainerDiv
      label={props.label}
      className={cx('lp-input', props.className)}
      require={require}
    >
      {label && <label htmlFor={id} aria-label={`${id} Input Label`}>{label}</label>}
      <div className={cx('input-wrap', { disabled: props.disabled })}>
        {addonBeforeArr}
        <StyledInputText
          {...others}
          id={id}
          ref={ref}
          value={value || ''}
          className="input"
          height={props.height}
          width={props.width}
          readOnly={props.readonly}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            props.onBlur && props.onBlur(e);
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange && props.onChange(e);
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            props.onKeyDown && props.onKeyDown(e);
            e.keyCode === 13 && onPressEnterCallback && onPressEnterCallback(e);
          }}
          isErr={!!props.errorMsg}
          isWarn={!!props.warningMsg}
          markWarning={markWarning}
          aria-label={`${id} Input`}
        />
        {addonAfterArr}

        <div className="input-msg-wrap">
          {errorMsg && (
          <StyledErrMsg aria-label={`Error message for ${id} field`}>
            {errorMsg}
          </StyledErrMsg>
          )}
          { warningMsg && renderWarningMsg()}
        </div>
      </div>
    </StyledContainerDiv>
  );
});

export default Input;
