import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import Modal from '@mui/material/Modal';

export const StyledModalWrapper = styled(Modal)`
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledModal = styled.div`
  padding: 24px;
  margin: 0 16px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #1c1c1c;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  > div {
    display: flex;
    size: 16px;
    line-height: 24px;
  }

  @media ${device.mobileMaxWidth} {
    padding: 24px 16px;
    width: 344px;
  }
`;

export const StyledHeader = styled.div<{showLine?: boolean}>`
  margin-bottom: 32px;
  padding-bottom: 17px;
  display: flex;
  justify-content: space-between;
  color: #1C1C1C;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  border-bottom: ${(props) => (props.showLine ? '1px solid #F4F4F4' : 'none')};
  div {
    display: flex;
    flex-direction: row;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCloseIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  > svg {
    width: 24px;
    height: 24px;
  }
`;

export const StyledIconWrapper = styled.span<{ curLang: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px; 
  margin: ${({ curLang }) => (curLang !== 'ar' && curLang !== 'he' ? '0px 4px 0px 0px' : '0px 0px 0px 4px')};
`;
