import * as React from "react";
import { SVGProps } from "react";
const SvgAffiliates = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.319 8.5h-7.94L2.764 14a8.24 8.24 0 0 0 4.061 1.893 8.323 8.323 0 0 0 4.468-.5 8.14 8.14 0 0 0 3.52-2.742 7.898 7.898 0 0 0 1.505-4.15ZM2.043 13.294A7.932 7.932 0 0 1 .11 9.315a7.85 7.85 0 0 1 .51-4.377A8.03 8.03 0 0 1 3.42 1.49 8.275 8.275 0 0 1 7.657.016v7.778l-5.614 5.5v-.001ZM8.678.016V7.5h7.64a7.929 7.929 0 0 0-2.375-5.158A8.245 8.245 0 0 0 8.678.016Z"
      fill="#F4F4F4"
    />
  </svg>
);
export default SvgAffiliates;
