import IconComponent from 'components/Icons';
import {
  StyledModal, StyledModalWrapper, StyledHeader, StyledCloseIcon, StyledContent, StyledTitle
} from './style';

interface PopUpProps {
  id?: string;
  className?: string;
  header?: string;
  openModal: boolean;
  onClose?: () => void;
  children?: React.ReactNode
}

const PopUp = ({
  openModal, onClose, children, header, className, id
}: PopUpProps): JSX.Element => (
  <StyledModalWrapper
    aria-label={id ? `${id} Pop Up` : 'Pop Up'}
    open={openModal}
    onClose={onClose}
  >
    <StyledModal className={className}>
      {header && (
        <StyledHeader>
          <StyledTitle aria-label={`${id} header` || 'header'}>{header}</StyledTitle>
          <StyledCloseIcon onClick={onClose} aria-label={`Close ${id} Modal`}>
            <IconComponent name="DemoAccountClose" />
          </StyledCloseIcon>
        </StyledHeader>
      )}
      <StyledContent>{children}</StyledContent>
    </StyledModal>
  </StyledModalWrapper>
);

export default PopUp;
