import VerifyIdentityPanel from 'containers/VerifyIdentityPanel';
import { VerifyIdentityWrapper } from './style';

const VerifyIdentity = ():JSX.Element => (
  <VerifyIdentityWrapper>
    <VerifyIdentityPanel />
  </VerifyIdentityWrapper>
);

export default VerifyIdentity;
