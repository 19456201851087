import styled from 'styled-components';

export const StyledContainer = styled.div`
  .acyWelcomeBanner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media only screen and (min-width: 768px) {
    .acyWelcomeBanner{
      flex-direction: row;
    }
  }
`;

export const StyledHighlight = styled.span`
  color: ${(props) => props.theme.common.primary};
`;
