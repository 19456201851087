import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState
} from 'react';
import { AccountData, MemberData } from './accountListContext';

type Context = {
  memberData: MemberData;
  setMemberData: Dispatch<SetStateAction<MemberData>>;
  accountData: AccountData;
  setAccountData: Dispatch<SetStateAction<AccountData>>;
};

const StagingDataContext = createContext<Context>({} as Context);

export const StagingDataProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [memberData, setMemberData] = useState<MemberData>({} as MemberData);
  const [accountData, setAccountData] = useState<AccountData>({} as AccountData);

  const value = useMemo(
    () => ({
      memberData,
      setMemberData,
      accountData,
      setAccountData
    }),
    [memberData, accountData]
  );

  return (
    <StagingDataContext.Provider value={value}>
      {children}
    </StagingDataContext.Provider>
  );
};

export const useStagingDataState = (): Context => useContext(StagingDataContext);
