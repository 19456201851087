import { SVGProps } from 'react';

const SvgSendEail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10 6.66667L7.33333 9.33333L11.3333 13.3333L14 2.66667L2 7.33333L4.66667 8.66667L6 12.6667L8 10'
      stroke='#8C8C8C'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default SvgSendEail;
