import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledFormPanel = styled.div`
  .paper {
    height: 100%;
    padding: 0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16), 0px 1px 4px rgba(0, 0, 0, 0.1);
  }
`;

export const StyledTopWrap = styled.div`
  padding: 24px;
  display: flex;
  background: #ffffff;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px 16px 0 0;
  color: #212121;
  border-bottom: 1px solid #DDDDDD;
  .title {
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    font-size: 20px;
  }

  @media ${device.mobileMaxWidth} {
    padding: 24px 16px;
  }
`;

export const StyledTitleDescription = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #5c5c5c;
`;
