import { SVGProps } from "react";
const SvgLangIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 21C13.6569 21 15 16.9706 15 12C15 7.02944 13.6569 3 12 3M12 21C10.3431 21 9 16.9706 9 12C9 7.02944 10.3431 3 12 3M12 21C8.08134 21 4.74763 18.4956 3.51212 15M12 21C15.9187 21 19.2524 18.4956 20.4879 15M12 3C8.08134 3 4.74763 5.50442 3.51212 9M12 3C15.9187 3 19.2524 5.50442 20.4879 9M3.51212 9C3.18046 9.93834 3 10.9481 3 12C3 13.0519 3.18046 14.0617 3.51212 15M3.51212 9H20.4879M20.4879 9C20.8195 9.93834 21 10.9481 21 12C21 13.0519 20.8195 14.0617 20.4879 15M3.51212 15H20.4879"
      stroke="#3E3E3E"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgLangIcon;
