import { DefaultTheme } from 'styled-components';

const cssBreakPoints = {
  mobile: 768
};

const isRTL = (): boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  const getLocaleParam = urlParams.get('locale');
  if (getLocaleParam) return getLocaleParam === 'ar' || getLocaleParam === 'he';
  return localStorage.getItem('language') === 'ar' || localStorage.getItem('language') === 'he';
};

export const lightTheme: DefaultTheme = {
  cssBreakPoints,
  isRTL: isRTL(),
  common: {
    background: 'linear-gradient(180deg, #F4F4F4 0, #DDDDDD 100 %)',
    lightGrey: '#5C5C5C',
    primary: '#013B81'
  },
  sideMenu: {
    background: 'rgba(1, 37, 79, 1)',
    primary: '#FFFFFF',
    secondary: '#DDDDDD',
    active: '#000f20',
    avatarActive: '#0282D0',
    hover: '#003566'
  },
  notificationPanel: {
    fontColor: '#5C5C5C'
  },
  radio: {
    primary: '#5C5C5C',
    secondary: '#01254F'
  }
};

export const primaryTheme: DefaultTheme = {
  isRTL: isRTL(),
  common: {
    background: 'linear-gradient(180deg, #F4F4F4 0%, #DDDDDD 100%)',
    lightGrey: '#5C5C5C',
    primary: '#013B81',
    disabledBg: '#728FB2',
    inputBoxHeight: '48px'
  },
  sideMenu: {
    background: 'rgba(1, 37, 79, 1)',
    primary: '#FFFFFF',
    avatarMenuBg: '#003566',
    secondary: '#DDDDDD',
    active: '#0282D0',
    avatarActive: '#0282D0',
    hover: '#003566'
  },
  radio: {
    primary: '#5C5C5C',
    secondary: '#01254F'
  }
};
