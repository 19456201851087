import { forwardRef } from 'react';
import {
  StyledVideoCard,
  StyledVideoImage,
  StyledVideoTitle,
  StyledDateContainer,
  StyledContent,
  StyledText,
  StyledContentContainer
} from './style';

  interface SingleVideoProps {
    title: string;
    time: string;
    imgSrc: string;
    onClick?: () => void
  }
const VideoCard = forwardRef<HTMLDivElement, SingleVideoProps>(({
  title, time, imgSrc, onClick
}, ref) => (
  <StyledVideoCard onClick={onClick} ref={ref}>
    <StyledContentContainer>
      <StyledVideoImage src={imgSrc} alt="image" aria-label={`${title} video`} />
      <StyledContent>
        <StyledVideoTitle aria-label={`${title} video title`}>{title}</StyledVideoTitle>
      </StyledContent>
    </StyledContentContainer>
    <StyledDateContainer>
      <StyledText fontSize={12} aria-label={`${title} video sponsor`}>ACY</StyledText>
      <StyledText fontSize={10} aria-label={`${title} video time`}>{time}</StyledText>
    </StyledDateContainer>
  </StyledVideoCard>
));

export default VideoCard;
