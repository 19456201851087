/* eslint-disable no-tabs */
/* eslint-disable max-len */
export const primaryHolder = [
  {
    id: 'ID-Front-J-Pr',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'ID-Back-J-Pr',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'POA-J-Pr',
    required: true,
    title: 'proofAddress',
    fileDescription: 'proofOfAddressDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'Other-J-Pr',
    required: false,
    title: 'otherDocument',
    fileDescription: 'proofOfAddressDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  }
];

export const primaryHolderVFSC = [
  {
    id: 'ID-Front-J-Pr',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'ID-Back-J-Pr',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  }
];

export const secondaryHolder = [
  {
    id: 'ID-Front-J-Se',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'ID-Back-J-Se',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'POA-J-Se',
    required: true,
    title: 'proofAddress',
    fileDescription: 'proofOfAddressDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'Other-J-Se',
    required: false,
    title: 'otherDocument',
    fileDescription: 'proofOfAddressDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  }
];

export const secondaryHolderVFSC = [
  {
    id: 'ID-Front-J-Se',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'ID-Back-J-Se',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  }
];
