import * as React from "react";
import { SVGProps } from "react";
const SvgMacOs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.998 13.438c-.775.74-1.622.622-2.437.272-.862-.357-1.653-.373-2.563 0-1.14.483-1.74.343-2.421-.272C.716 9.527 1.286 3.57 5.669 3.352c1.068.054 1.812.575 2.437.622.933-.187 1.827-.723 2.824-.653 1.195.093 2.097.56 2.69 1.4-2.468 1.454-1.883 4.65.38 5.544-.451 1.167-1.037 2.326-2.01 3.181l.008-.008ZM8.027 3.305C7.907 1.571 9.34.14 10.986 0c.229 2.006-1.852 3.5-2.96 3.305Z"
      fill="#00B875"
    />
  </svg>
);
export default SvgMacOs;
