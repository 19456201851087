import styled from 'styled-components';

interface InputTextProps {
  height?: string;
  isWarn: boolean;
  isErr: boolean;
  markWarning?: boolean;
  disabled?: boolean;
  curLang?: string;
}

interface ContainerProps {
  width?: number;
  label?: string;
  require?: string;
  disabled?: boolean;
  curLang?: string;
  icon?: {
    position: string;
    pic: string;
  };
}

const vCenter = (): string => `
  display: flex;
  align-items: center;
`;

const handleBorder = (isErr = false, isWarn = false, markWarning = false, isFocused = false): string => {
  const commonStyle = (color: string): string => `border: solid ${color}  1px;`;
  if (isFocused) return commonStyle('#006ACC');
  if (isErr) return commonStyle('#E92C2C');
  if (!isErr && isWarn && !markWarning) {
    return commonStyle('#F98600');
  }
  return commonStyle('#BBBBBB');
};

export const StyledWrap = styled.div<ContainerProps>`
  display: ${(props) => props.label && 'flex'};
  flex-direction: column;
  border-radius: 8px;

  .form-control.phone-input {
    ${(props) => props.disabled && ' background-color: #FEFEFE; color: #BBB; opacity: 1;'};
  }

  label {
    ${vCenter};
    line-height: 1.5;
    ${(props: ContainerProps) => ({
    [`&::${props.require}`]: { content: "'*'", color: 'red' }
  })}
  }

  .country-list {
    width: 100vw;
    max-width: ${(props) => `${props.width}px`};
    border-radius: 8px;
    max-height: 232px;

    .country {
      height: 36px;
      border-radius: 8px;
      margin:8px;
      &:active, &:focus{
        background-color: #DDDDDD;
      }

       &:hover{
        background-color: #F4F4F4;
      }

      &.highlight{
       background-color: #CCE6F6;
      }
    }
  }

  .msg {
    min-height: 32px;
    color: #e92c2c;
    font-size: 14px;
  }
`;

export const StyledInputWrap = styled.div<InputTextProps>`
  .flag-dropdown.open{
    z-index: 1;

    .selected-flag {
      border-radius:${({ curLang }) => ((curLang !== 'ar' && curLang !== 'he') ? '8px 0 0 8px' : '0 8px 8px 0')};

    }
  }
  &:hover{
    .form-control {
      border: solid #5c5c5c 1px;
    }
    .flag-dropdown{
      .selected-flag {
          border: solid #5c5c5c 1px;
          border-right: ${({ curLang }) => ((curLang !== 'ar' && curLang !== 'he') ? 'none' : 'solid #5c5c5c 1px')};
          border-left: ${({ curLang }) => ((curLang !== 'ar' && curLang !== 'he') ? 'solid #5c5c5c 1px' : 'none')};
      }
    }
  }

  .form-control {
    padding: ${({ curLang }) => ((curLang !== 'ar' && curLang !== 'he') ? '1px 2px 1px 64px' : '1px 64px 1px 2px')};
    border-radius: 8px;
    width: 100%;
    height: ${(props) => props.height};
    color: #2a2a2a;
    border: 1px solid #bbbbbb;
    &:hover{
      border: solid #5c5c5c 1px;
    }
    ${({ isErr, isWarn, markWarning }) => handleBorder(isErr, isWarn, markWarning)};

    border: 1px solid #bbbbbb;
    &:hover{
      border: solid #5c5c5c 1px;
    }
    ${({ isErr, isWarn, markWarning }) => handleBorder(isErr, isWarn, markWarning)};

  };
  .flag-dropdown {
    background-color: #ffffff;
    border: none;
    border-radius: 8px;

    .selected-flag {
      padding: ${({ curLang }) => ((curLang !== 'ar' && curLang !== 'he') ? '18px 0 18px 16px' : '18px 16px 18px 16px')};
      width: 62px;
      border-radius:${({ curLang }) => ((curLang !== 'ar' && curLang !== 'he') ? '8px 0 0 8px' : '0 8px 8px 0')};

      ${({ isErr, isWarn, markWarning }) => handleBorder(isErr, isWarn, markWarning)};
      border-right: ${({ curLang }) => ((curLang !== 'ar' && curLang !== 'he') ? '0' : '1px solid #BBBBBB')};
      border-left: ${({ curLang }) => ((curLang !== 'ar' && curLang !== 'he') ? '1px solid #BBBBBB' : '0')};
      ${(props) => props.disabled && ' background: #FEFEFE;'}


      .flag {
        height: 12px;
        padding-left: 8px;
        position:${({ curLang }) => ((curLang !== 'ar' && curLang !== 'he') ? 'absolute' : 'relative')};
        .arrow {
          position:${({ curLang }) => ((curLang !== 'ar' && curLang !== 'he') ? 'relative' : 'absolute')};
          right:${({ curLang }) => ((curLang !== 'ar' && curLang !== 'he') ? '-20px' : '28px')};
        }
      }
    }
    .arrow {
      border-left: 3.33px solid transparent;
      border-right: 3.33px solid transparent;
      width: 6.7px;
    }
    .arrow.up {
      border-left: 3.33px solid transparent;
      border-right: 3.33px solid transparent;
      width: 6.7px;
    }
    .search-class{
      color: red;
      > input{
        width: 100%;
      }
      input[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
      [class*='country-name'] {
        color: red !important;
      }
    }

    .dropdown {
      font-size: 14px;
      color: #2a2a2a;

      span[class='dial-code'] {
        font-size: 14px;
        color: #2a2a2a;
        margin-right: 6px;
      }
    }
  };
`;

export const StyledErrMsg = styled.div`
    color: #E92C2C;
    font-size: 14px;
    margin: 3px 0;
    line-height: 24px;
`;

export const StyledWarningMsg = styled.div<{ markWarning?: boolean }>`
    color: ${(props) => (props.markWarning ? '#BBB' : '#F98600')};
    font-size: 14px;
    line-height: 24px;
    margin:3px 0;
    word-break: break-word;
`;
