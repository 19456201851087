import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledPageContainer = styled.div`
  margin: 28px 70px;

  @media ${device.tablet} {
    margin: 30px 34px; 
  }

  @media ${device.mobileMaxWidth} {
    margin: 30px 20px; 
  }
`;
