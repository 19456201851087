import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CreateAccountStatusType {
  type:string;
  isOnBoardingMethodSelecting:boolean;
  isModalOpen:boolean;
}

interface ApiError {
  id: number;
  code: number | string;
  message: string;
}

// Define a type for the slice state
interface PageState {
  globalPopUp: {
    isModalOpen: boolean,
    msg: string
  },
  apiErrors: ApiError[];
  accountStatus: string;
  createAccountStatus: CreateAccountStatusType
  isJumioFetched?:boolean;
  activeAccountNumber: string;
}

// Define the initial state using that type
export const initialState: PageState = {
  globalPopUp: {
    isModalOpen: false,
    msg: ''
  },
  apiErrors: [],
  accountStatus: '',
  createAccountStatus: {
    type: '',
    isOnBoardingMethodSelecting: false,
    isModalOpen: false
  },
  isJumioFetched: false,
  activeAccountNumber: ''
};

export const pageSlice = createSlice({
  name: 'page',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setGlobalPopUp: (state, action) => {
      state.globalPopUp = action.payload;
    },
    setAccountStatus: (state, action) => {
      state.accountStatus = action.payload.accountStatus;
    },
    setCreateAccountStatus: (state, action) => {
      state.createAccountStatus = {
        ...state.createAccountStatus,
        ...action.payload.createAccountStatus
      };
    },
    setIsJumioFetched: (state, action) => {
      state.isJumioFetched = action.payload.isJumioFetched;
    },
    setActiveAccountNumber: (state, action) => {
      state.activeAccountNumber = action.payload;
    },
    // API error
    addApiError: (state, action: PayloadAction<ApiError>) => {
      state.apiErrors.push(action.payload);
    },
    removeApiError: (state, action: PayloadAction<ApiError['id']>) => {
      state.apiErrors = state.apiErrors.filter((error) => error.id !== action.payload);
    },
    clearApiErrors: (state) => {
      state.apiErrors = [];
    }
  }
});

export const {
  setGlobalPopUp,
  setAccountStatus,
  setCreateAccountStatus,
  setIsJumioFetched,
  setActiveAccountNumber,
  addApiError,
  removeApiError,
  clearApiErrors
} = pageSlice.actions;

export default pageSlice.reducer;
