import { useTranslation } from 'react-i18next';
import { StyledBanner } from './style';

const CorporateWelcomeBanner = (): JSX.Element => {
  const { t } = useTranslation('registrationForm');

  return (
    <StyledBanner>
      <div>{t('corporateAccountRegistration')}</div>
      <div>{t('accountRegistrationBannerContent')}</div>
    </StyledBanner>
  );
};

export default CorporateWelcomeBanner;
