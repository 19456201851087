export const navigationConfig = [
  {
    id: 'dashboard',
    type: 'item',
    icon: 'Dashboard',
    value: 'Dashboard',
    url: '/'
  },
  {
    id: 'accountManagement',
    type: 'group',
    icon: 'Account',
    value: 'Account Management',
    items: [
      {
        id: 'createAccount',
        type: 'item',
        url: '/create-account/select-account-type',
        value: 'Create Account'
      },
      {
        id: 'demoAccount',
        type: 'item',
        url: '/demo-account',
        value: 'Demo Account'
      },
      {
        id: 'accountList',
        type: 'item',
        url: '/account-list',
        value: 'Account List'
      }
    ]
  },
  {
    id: 'training',
    type: 'group',
    icon: 'Training',
    value: 'Training',
    items: [
      {
        id: 'onlineTraining',
        type: 'item',
        url: '/training/online-training',
        value: 'Online Training'
      },
      {
        id: 'videos',
        type: 'item',
        url: '/training/videos',
        value: 'Videos'
      }
    ]
  },
  {
    id: 'cashManagement',
    type: 'group',
    icon: 'CashManagement',
    value: 'Cash Management',
    items: [
      {
        id: 'deposit',
        type: 'item',
        url: '/fund-manager/deposit',
        value: 'Deposit'
      },
      {
        id: 'withdrawal',
        type: 'item',
        url: '/fund-manager/withdrawal',
        value: 'Withdrawal'
      },
      {
        id: 'internalTransfer',
        type: 'item',
        url: '/fund-manager/internal-transfer',
        value: 'Internal Transfer'
      },
      {
        id: 'verifyPaymentMethods',
        type: 'item',
        url: '/fund-manager/bank-account',
        value: 'Verify Payment Methods'
      }
    ]
  },
  {
    id: 'partnerships',
    type: 'group',
    icon: 'Partnerships',
    value: 'Partner Ships',
    items: [
      {
        id: 'partnerDashboard',
        type: 'item',
        url: '/agent-center/agent-activity',
        value: 'Partner Dashboard'
      },
      {
        id: 'statistics',
        type: 'item',
        url: '/agent-center/statistics',
        value: 'Statistics'
      },
      {
        id: 'clientList',
        type: 'item',
        url: '/agent-center/client-list',
        value: 'Client List'
      },
      {
        id: 'marketingMaterials',
        type: 'item',
        url: '/agent-center/marketing-materials',
        value: 'Marketing Materials'
      }
    ]
  },
  {
    id: 'support',
    type: 'item',
    icon: 'Email',
    url: '/ticket/ticket-list',
    value: 'Support'
  },
  {
    id: 'download',
    type: 'item',
    icon: 'DownloadCenter',
    url: '/download-center',
    value: 'Download Center'
  }
];

export const navigationConfigWithActiveAccount = [
  {
    id: 'accountManagement',
    type: 'group',
    icon: 'Account',
    value: 'Account Management',
    items: [
      {
        id: 'accountList',
        type: 'item',
        url: '/account-list',
        value: 'Account List'
      }
    ]
  }
];
