import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledAccountListWrapper = styled.div`
  padding: 32px 40px;
  position: 'relative';
  @media ${device.tablet} {
    padding: 32px 24px;
  }

  @media ${device.mobileMaxWidth} {
    padding: 32px 16px;
  }
`;

export const StyledContent = styled.div`
  padding: 32px 0;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #f4f4f4;

  > span {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #212121;

    @media ${device.mobileMaxWidth} {
      font-size: 20px;
    }
  }
`;

export const StyledPlusIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  > span {
    margin-left: ${(props) => !props.theme.isRTL && '8px'};
    margin-right: ${(props) => props.theme.isRTL && '8px'};
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #145db5;
  }
`;
