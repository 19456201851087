import styled from 'styled-components';
import placeholder from 'styles/placeholderStyle';

enum EInputHeight {
  large = '40px',
  default = '32px',
  small = '24px',
}

interface IContainerProps {
  label?: string;
  require?: string;
  icon?: {
    position: string;
    pic: string;
  };
  disabled?: boolean;
}

interface IInputTextProps {
  width?: string;
  height?: string;
  isErr?: boolean;
  isWarn?: boolean;
  markWarning?: boolean
}

/** mixin */
const vCenter = (): string => `
    display: flex;
    align-items: center;
`;

const handleBorder = (isErr = false, isWarn = false, markWarning = false, isFocused = false): string => {
  const commonStyle = (color: string): string => `border: solid ${color}  1px;`;
  if (isFocused) return commonStyle('#006ACC');
  if (isErr) return commonStyle('#E92C2C');
  if (!isErr && isWarn && !markWarning) {
    return commonStyle('#F98600');
  }
  return commonStyle('#BBBBBB');
};

export const StyledInputText = styled.input<IInputTextProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  height: ${(props) => props.height || EInputHeight.default};
  padding: 12px 16px;
  color: ${({ disabled }) => (disabled ? '#BBBBBB' : '#2a2a2a')};
  font-size: 14px;
  line-height: 1.5;
  background-color:  ${({ disabled }) => (disabled ? '#FCFCFC' : '#fff')};
  background-image: none;
  ${({ isErr, isWarn, markWarning }) => handleBorder(isErr, isWarn, markWarning)};
  ${({ disabled }) => (disabled && 'cursor: not-allowed;')}


  border-radius: 8px;
  transition: all 0.3s;
  &:hover {
    border-color: #5c5c5c;
  }
  &:focus {
    border-color: #006acc;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const StyledContainerDiv = styled.div<IContainerProps>`
  display: ${(props) => props.label && 'flex'};
  flex-direction: column;

  label {
    ${vCenter};
    line-height: 1.5;
    margin: 0 5px;
    ${(props: IContainerProps) => ({
    [`&::${props.require}`]: { content: "'*'", color: 'red' }
  })}
  }

  .input-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    > input {
      ${placeholder({ color: '#BBBBBB' })};
    }
  }

  .icon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

export const StyledErrMsg = styled.div`
    color: #E92C2C;
    font-size: 14px;
    margin: 3px 0;
    line-height: 24px;
`;

export const StyledWarningMsg = styled.div<{ markWarning?: boolean }>`
    color: ${(props) => (props.markWarning ? '#BBB' : '#F98600')};
    font-size: 14px;
    line-height: 24px;
    margin:3px 0;
    word-break: break-word;
`;
