import { useEffect } from 'react';
import { useAuth } from 'store/context/hooks';

const LogoutRedirect = (): null => {
  const { handleLogout } = useAuth();

  useEffect(() => {
    handleLogout();
  }, []);

  return null;
};
export default LogoutRedirect;
