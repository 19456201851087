import Button from 'components/Button';
import IconComponent from 'components/Icons';
import { useTranslation } from 'react-i18next';
import {
  StyledLButtonsWrap, StyledModalContent, StyledModalWrapper, StyledPopUpContent
} from './style';

interface PopUpProps {
  content: string;
  openModal: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  children?: React.ReactNode
}

const PopUp = ({
  openModal, onConfirm, onClose, content, children
}: PopUpProps): JSX.Element => {
  const { t } = useTranslation('createAccount');
  return (
    <StyledModalWrapper
      aria-label="Pop Up"
      open={openModal}
      onClose={onClose}
    >
      <StyledModalContent>
        <div>
          <IconComponent name="Alert" />
          <StyledPopUpContent aria-label="Pop Up Content">
            {content}
          </StyledPopUpContent>
        </div>
        <StyledLButtonsWrap>
          {children || (
          <>
            <Button aria-label="Pop Up Cancel" onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button aria-label="Pop Up Confirm" onClick={onConfirm}>
              {t('confirm')}
            </Button>
          </>
          )}
        </StyledLButtonsWrap>
      </StyledModalContent>
    </StyledModalWrapper>
  );
};

export default PopUp;
