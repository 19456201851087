import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import { sideMenuCloseWidth, sideMenuOpenWidth } from 'containers/SideMenu/styled';

const INTERVAL_TO_SIDE_MENU = 64;

const paddingLeft = (isMenuOpen: boolean, isRTL: boolean): string => {
  const padding = isMenuOpen ? INTERVAL_TO_SIDE_MENU + sideMenuOpenWidth : INTERVAL_TO_SIDE_MENU + sideMenuCloseWidth;
  return isRTL ? `${INTERVAL_TO_SIDE_MENU}px` : `${padding}px`;
};

const paddingRight = (isMenuOpen: boolean, isRTL: boolean): string => {
  const padding = isMenuOpen ? sideMenuOpenWidth : sideMenuCloseWidth;
  return isRTL ? `${padding}px` : `${INTERVAL_TO_SIDE_MENU}px`;
};

export const StyledHeaderWrap = styled.div<{ isMenuOpen: boolean }>`
  width: 100%;
  position: fixed;
  z-index: 2;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0 64px;
  box-shadow: 0px 0px 9.3169px rgba(0, 0, 0, 0.12),
    0px 0px 13.4577px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  box-sizing: border-box;
  transition: width 0.5s;
  transition: padding-left 0.5s;
  right: 0;
  padding-left: ${(props) => paddingLeft(props.isMenuOpen, props.theme.isRTL)};

  > div:first-child {
    display: flex;
    flex: 1;
    padding-right: ${(props) => paddingRight(props.isMenuOpen, props.theme.isRTL)};
    transition: padding-right 0.5s;
    > svg {
      width: 137px;
      height: 40px;
    }
  }
`;

export const StyledHeaderMobileWrap = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  height: 72px;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: 0px 0px 9.3169px rgba(0, 0, 0, 0.12),
    0px 0px 13.4577px rgba(0, 0, 0, 0.2);

  > button {
    border: none;
    padding: ${(props) => (props.theme.isRTL ? '0 0 0 16px' : '0 16px 0 0')};
    cursor: pointer;
    background-color: #ffffff;

    > svg {
      font-size: 40px;
    }
  }
`;

export const StyledAcyWrapper = styled.div<{ hasBtn: boolean }>`
  display: flex;
  width: 100%;
  ${({ hasBtn }) => !hasBtn && 'padding-right: 55px;'}

  > svg {
    width: 137px;
    height: 40px;
  }

  @media ${device.mobileMaxWidth} {
    width:100%;
    > svg {
      width: 72px;
    }
  }
`;

export const StyledSettingWrap = styled.div`
  display: flex;

  > select {
    margin: 0 5px;
  }

  .dropDown {
    min-width: 0;

    .title {
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.common.lightGrey};
      > span:first-child {
        height: 24px;
        padding-right: 4px;
        svg {
          font-size: 24px;
        }
      }
      > span:nth-child(2) {
        padding-right: 4px;
        font-size: 14px;
        color: #3e3e3e;
        white-space: nowrap;
      }

      > span:nth-child(3) {
        > svg {
          font-size: 24px;
        }
      }
    }

    .menu-list {
      width: 216px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      z-index: 2;
      top: 28px;
      left: ${(props) => (props.theme.isRTL ? 0 : '-120px')};

      .item {
        padding-left: 12px;
        padding-right: 12px;
        margin: 6px 8px;
        height: 36px;
        border-radius: 8px;
        &:hover {
          background-color: ${(props) => props.theme.sideMenu.secondary};
        }

        &:active {
          background: #bbb;
        }

        > div {
          display: flex;
          align-items: center;
          justify-content: left;
          > span:nth-child(2) {
            min-width: 56px;
          }
        }
      }
    }
  }
`;

export const StyledLangIcon = styled.span`
  display: flex;
  width: 32px;
`;

export const StyledItemWrap = styled.div`
  height: 100%;
`;

export const StyledContinueBtnWrap = styled.div<{ isSmallSize?: boolean }>`
  padding: ${(props) => (props.theme.isRTL ? '0 0 0 16px' : '0 16px 0 0')};

  > button {
    height: 40px;
    font-family: 'Noto Sans';
    font-style: normal;
    background: #013b81;
    border-radius: 4px;
    padding: 8px 16px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: none;
    &:hover,
    &:active {
      background: #013b81;
    }
  }

  @media ${device.mobileMaxWidth} {
    padding: 0;
    > button {
      font-size: ${(props) => `${props.isSmallSize ? '12px' : '14px'}`};
    }
  }
`;

export const StyledMobileBtnWrap = styled.div`
  .MuiButtonBase-root {
    white-space: nowrap;
  }
`;
