import {
  TableBody, TableCell, TableHead, TableRow
} from '@mui/material';
import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledTableCell = styled(TableCell)`
  font-family: 'Noto Sans';
  color: #1c1c1c;
  ${({ theme }) => theme?.isRTL && 'text-align: right'};
  font-size: 14px;
  line-height: 24px;
  padding: 16px;
  border-bottom: none;

  @media ${device.mobileMaxWidth} {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const StyledTableHead = styled(TableHead)`
  ${StyledTableCell} {
    font-weight: 600;
    background-color: #fbfbfb;
    white-space: nowrap;
  }
`;

type StyledTableRowProps = {
  $hasStripeBackground?: boolean;
  $hasHoverBackground?: boolean;
};

export const StyledTableRow = styled(TableRow)<StyledTableRowProps>`
  ${StyledTableCell} {
    ${({ $hasStripeBackground }) => $hasStripeBackground && 'background-color: #fbfbfb'};
  }

  ${({ $hasHoverBackground }) => $hasHoverBackground
    && `:hover {
      ${StyledTableCell} {
        background-color: #f4f4f4;
      }
    }`}
`;

export const StyledTableWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid #dddddd;
`;

export const StyledInnerTableContainer = styled.div`
  padding: 0 16px;
`;

export const StyledInnerTableHead = styled(TableHead)`
  ${StyledTableCell} {
    font-weight: 600;
    border-bottom: 1px solid #dddddd;
    white-space: nowrap;
  }
`;

export const StyledInnerTableBody = styled(TableBody)`
  ${StyledTableCell} {
    padding: 28px 16px;
    border-bottom: 1px solid #dddddd;
  }
`;

export const StyledActionButtonGroup = styled.div`
  display: flex;
  gap: 16px;
`;

type StyledActionButtonProps = {
  isDisabled?: boolean;
};

export const StyledActionButton = styled.div<StyledActionButtonProps>`
  color: ${({ isDisabled }) => (isDisabled ? '#8C8C8C' : '#006ACC')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  white-space: nowrap;
`;

type StyledCollapseButtonProps = {
  isActive: boolean;
};

export const StyledCollapseButton = styled.button<StyledCollapseButtonProps>`
  padding: 0;
  border: none;
  background: transparent;
  width: 24px;
  height: 24px;
  color: #5c5c5c;
  ${({ isActive }) => isActive && 'transform: rotate(-180deg)'};
`;

export const StyledCollapseContainer = styled.div`
  padding: 16px 0;
`;

export const StyledCollapseTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #1c1c1c;
  margin: 8px 0;
`;

type StyledLabelProps = {
  isHighlighted: boolean;
};

export const StyledLabel = styled.span<StyledLabelProps>`
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  color: ${({ isHighlighted }) => (isHighlighted ? '#33c85d' : '#5c5c5c')};
  background-color: ${({ isHighlighted }) => (isHighlighted ? '#ccf1d6' : '#f4f4f4')};
  padding: 4px 16px;
  border-radius: 20px;
  white-space: nowrap;
`;

export const StyledIconButton = styled.button`
  padding: 0;
  background: none;
  border: none;
  line-height: 0;
  vertical-align: text-top;
  margin: 0 8px;
  cursor: pointer;
`;
