import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import {
  InternalTransferDT,
  InternalTransferMOB,
  InternalTransferTAB
} from 'assets/blurredPages';
import { StyledBlurredPageWrap } from 'pages/BlurredPage/style';

export const StyledInternalTransferWrap = styled(StyledBlurredPageWrap)`
 ::before {
    background-image: url(${InternalTransferDT});
      @media ${device.tablet} {
              background-image: url(${InternalTransferTAB});
        }
      @media ${device.mobileMaxWidth} {
            background-image: url(${InternalTransferMOB});
      }
  }
`;
