import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import Modal from '@mui/material/Modal';

export const StyledModalWrapper = styled(Modal)`
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledModal = styled.div`
  padding: 24px;
  margin: 0 16px;
  border-radius: 8px;
  background-color: #ffffff;
  width: 800px;
  color: #1c1c1c;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  > div {
    display: flex;
    size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  @media ${device.mobileMaxWidth} {
    padding: 24px 16px;
    width: 344px;
  }
`;

export const StyledHeader = styled.div`
  margin-bottom: 32px;
  padding-bottom: 17px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4f4;
`;

export const StyledTitle = styled.div`
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  color: #1c1c1c;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  color: #3e3e3e;
  font-size: 14px;
  line-height: 24px;
`;

export const StyledCloseIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  > svg {
    width: 24px;
    height: 24px;
  }
`;
