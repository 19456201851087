import CloseIcon from '@mui/icons-material/Close';
import {
  StyledModal, StyledModalWrapper, StyledHeader, StyledCloseIcon, StyledContent
} from './style';

interface PopUpProps {
  className?: string;
  header?: string;
  openModal: boolean;
  onClose?: () => void;
  children?: React.ReactNode
}

const PopUp = ({
  openModal, onClose, children, className, header
}: PopUpProps): JSX.Element => (
  <StyledModalWrapper
    aria-label="Result Pop Up"
    open={openModal}
    onClose={onClose}
  >
    <StyledModal className={className}>
      <StyledHeader>
        <div>{header}</div>
        <StyledCloseIcon onClick={onClose}>
          <CloseIcon />
        </StyledCloseIcon>
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
    </StyledModal>
  </StyledModalWrapper>
);

export default PopUp;
