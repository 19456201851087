import { useEffect } from 'react';

const env = process.env.NODE_ENV;

interface GTMInfo {
  gtmIframeSrc: string;
  gtmScript: string;
}

export const getGTMInfo = (): GTMInfo => {
  const gtmInfo = {
    gtmIframeSrc: '',
    gtmScript: ''
  };
  const url = new URL('https://www.googletagmanager.com/ns.html');
  const id = {
    production: 'GTM-TBGGKFH',
    development: 'GTM-PKSWFJK',
    test: ''
  }[env];
  const params = new URLSearchParams({ id });
  url.search = params.toString();

  const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');`;

  if (id) {
    gtmInfo.gtmIframeSrc = url.href;
    gtmInfo.gtmScript = gtmScript;
  }

  return gtmInfo;
};

const GTMIframe = (): React.ReactElement | null => {
  const { gtmIframeSrc, gtmScript } = getGTMInfo();

  useEffect(() => {
    const script = document.createElement('script');
    const meta = document.createElement('meta');

    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=cf04c151-2df1-4907-acac-fba7c414e126';
    script.id = 'ze-snippet';
    script.async = true;

    document.body.appendChild(script);
    const gtmScriptEle = document.createElement('script');
    gtmScriptEle.type = 'text/javascript';
    gtmScriptEle.async = true;

    if (gtmScript) {
      gtmScriptEle.innerHTML = gtmScript;
    }

    if (process.env.NODE_ENV === 'production') {
      // Just need facebook code in prod env
      meta.content = 'esot97acbzzdzxuzkaf71uhoc9awjf';
      meta.name = 'facebook-domain-verification';
      document.head.appendChild(meta);
    }

    document.head.appendChild(gtmScriptEle);

    return () => {
      document.body.removeChild(script);
      document.head.removeChild(gtmScriptEle);
      document.head.removeChild(meta);
    };
  }, [gtmScript]);

  if (!gtmIframeSrc) return null;

  return (
    <noscript>
      <iframe
        title="This is for GTM"
        src={gtmIframeSrc}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
};

export default GTMIframe;
