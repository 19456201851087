import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

interface StyledInfoProps {
  marginTop?: string;
}

export const StyledTradingEmployment = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledFieldContent = styled.div`
  display: flex;

  .lp-option-button{
    cursor: pointer;
    background-color: #F6F6F6 ;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 12px 32px;
    height: 32px;
    line-height: 11px;
  }

  @media ${device.tablet} {
    display: block;

    .form-panel + .form-panel {
      margin-top: 30px;
    }
  }

  @media ${device.mobileMaxWidth} {
    display: block;

    .form-panel + .form-panel {
      margin-top: 20px;
    }
  }

  @media ${device.desktopMinWidth} {
    display: flex;
    justify-content: space-around;
    flex: 1 1 0;
    gap: 20px;

    .form-panel {
      // make children the same width
      flex: 1 1 0;
      width: 0;
      .paper {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

export const StyledTips = styled.div<{ showTip: boolean }>`
  display: ${(props) => (props.showTip ? 'flex' : 'none')};
  margin-bottom: 20px;
  padding: 7.5px 20px;
  width: 100%;
  border-radius: 8px;
  background-color: #ccf1e3;
  align-items: center;

  > svg {
    flex-shrink: 0;
    font-size: 22px;
    path {
      fill: #004a2f;
    }
  }

  > span {
    padding-left: 16px;
    font-size: 16px;
    line-height: 24px;
    color: #004a2f;
  }

  @media ${device.tablet} {
    padding: 4px 20px;
  }

  @media ${device.mobileMaxWidth} {
    padding: 4px 20px;
    > span {
      font-size: 12px;
    }
  }
`;

export const StyledRadioGroupWrapper = styled.div`
  margin-bottom: 32px;
`;

export const StyledInfo = styled.p`
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #5C5C5C;
  margin-top: ${(props: StyledInfoProps) => (props.marginTop)};
`;
