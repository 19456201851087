import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { StyledNote, StyledPopUpWrapper } from './style';

interface AddVirtualFundsSuccessPopUpProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  success: boolean;
  content: { __html: string };
}

const AddVirtualFundsSuccessPopUp = ({
  openModal,
  setOpenModal,
  success,
  content
}: AddVirtualFundsSuccessPopUpProps): JSX.Element => {
  const { t } = useTranslation('demoAccount');

  useEffect(() => {
    if (success) {
      const timeoutId = setTimeout(() => {
        setOpenModal(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
    return undefined;
  }, [openModal]);

  return (
    <StyledPopUpWrapper
      id="delete account pop up"
      header={t(success ? 'depositSuccess' : 'unSuccess')}
      openModal={openModal}
      onClose={() => setOpenModal(false)}
      icon={success ? 'CheckCircle' : 'CrossCircle'}
    >
      {!success && <StyledNote dangerouslySetInnerHTML={content} />}
    </StyledPopUpWrapper>
  );
};

export default AddVirtualFundsSuccessPopUp;
