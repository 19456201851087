import styled, { css } from 'styled-components';
import { ButtonStyle, UbankButtonStyle } from 'containers/RegisterFormContainers/style';
import { device } from 'styles/deviceStyle';

const CenterStyle = css`
    display: flex;
    justify-content: center;
`;

const ErrorStyle = css`
    outline: 1px solid #E92C2C;
`;

export const StyledEmailIcon = styled.div`
  ${CenterStyle}
  margin: 40px;
  >svg {
    width: 172px;
    height: 144px;
  }
`;

export const StyledDescriptionWrap = styled.div`
  ${CenterStyle}
  margin-bottom: 16px;
`;

export const StyledDescription = styled.div`
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 24px;
`;

export const StyledVerificationInputWrap = styled.div<{isError: boolean}>`
  ${CenterStyle}
  width: 100%;
  >div, .verification-container{
    width: 100%;
  }
  margin-bottom: ${(props) => (props.isError ? '16' : '30')}px;
  .verification-container{
    gap: 16px;
  }
  .verification-character{
    width: 52px;
    height: 52px;
    background: #FFFFFF;
    border: 1px solid #BBBBBB;
    outline: 1px solid red;

    ${(props) => (props.isError && ErrorStyle)}
    border-radius: 4px;
    font-size: 16px;
    color: #000000;
  }
    .container {
      display: flex;
      gap: 16px;
    }

    .input {
      justify-content: space-between;
      width: calc((100% - 48px)/4);
      height: 52px;
      padding: 0;
      font-size: 24px;
      text-align: center;
      text-transform: uppercase;
      border: 1px solid;
      border-color: ${(props) => (props.isError ? '#e92c2c' : '#d6d6d6')};
      border-radius: 4px;
      background: #fff;
      background-clip: padding-box;
      border-radius: 4px;
      font-size: 16px;
    }

    .input:focus {
      appearance: none;
      border: 2px solid #0282D0;
    }

`;

export const StyledResendWrap = styled.div`
  ${CenterStyle}
  margin-bottom: 20px;
  width: 100%;
  color: #5C5C5C;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  flex-wrap: wrap;
`;

export const StyledResend = styled.span<{ubank?: boolean}>`
  color: ${(props) => (props.ubank ? '#C29738' : '145DB5')};
  &:hover {
    cursor: pointer;
  }
`;

export const StyledButtonWrap = styled.div`
  width: 100%;
  .custom-button{
    ${ButtonStyle}
    margin-top: 0;
    margin-bottom: 40px;
    max-width: 100%;
  }

  .ubank-custom-button{
    ${UbankButtonStyle}
    margin-top: 0;
    margin-bottom: 40px;
    max-width: 100%;
  }
`;

export const StyledBackWrap = styled.div`
  ${CenterStyle}
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  color: #5C5C5C;
  font-size: 14px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledBack = styled.div`
  display: flex;
  align-items: center;
  color: #5C5C5C;
  >svg{
    width: 24px;
    height: 20px;
  }
`;

export const StyledBackIcon = styled.div`
  display: flex;
  align-items: center;
  >svg{
    width: 24px;
    height: 20px;
  }
`;

export const StyledErrorMessage = styled.div`
  ${CenterStyle}
  margin-bottom: 20px;
  color: #E92C2C;
  font-size: 12px;
  align-items: center;
  >svg{
    margin: 0 4px;
  }
`;

export const StyledEmailVerificationWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  margin: auto;
  margin-top: 84px;
  @media ${device.desktopMinWidth} {
    width: 580px;
  }
  @media ${device.bigDesktopMinWidth} {
    width: 628px;
  }
`;
