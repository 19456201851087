/* eslint-disable no-tabs */
/* eslint-disable max-len */

export const personalDetail = [
  {
    type: 'input',
    name: 'First Name',
    id: 'first_name',
    placeHolder: 'Enter your first name...',
    rules: {
      required: {
        value: true, message: 'Field is mandatory'
      },
      pattern: {
        value: '^([^0-9]*)$', message: 'Please enter a valid first name'
      },
      maxLength: {
        value: 40, message: 'Maximum characters limit is 40'
      }
    }
  },
  {
    type: 'input',
    name: 'Last Name',
    id: 'last_name',
    placeHolder: 'Enter your last name...',
    rules: {
      required: {
        value: true, message: 'Field is mandatory'
      },
      pattern: {
        value: '^([^0-9]*)$', message: 'Please enter a valid last name'
      },
      maxLength: {
        value: 40, message: 'Maximum characters limit is 40'
      }
    }
  },
  {
    type: 'input',
    name: 'Middle Name (Optional)',
    id: 'middle_name',
    placeHolder: 'Enter your middle name...',
    rules: {
      pattern: {
        value: '^([^0-9]*)$', message: 'Please enter a valid middle name'
      },
      maxLength: {
        value: 40, message: 'Maximum characters limit is 40'
      }
    }
  },
  {
    type: 'select',
    name: 'Gender',
    id: 'gender',
    placeHolder: 'Please Select...',
    rules: {
      required: {
        value: true, message: 'Field is mandatory'
      }
    },
    options: [
      { value: 'Male', label: 'Male' },
      { value: 'Female', label: 'Female' },
      { value: 'Other', label: 'Other' }
    ]
  },
  {
    type: 'select',
    name: 'Nationality',
    id: 'nationality',
    placeHolder: 'Please Select...',
    rules: {
      required: {
        value: true, message: 'Field is mandatory'
      }
    },
    options: [
      { value: 'Nationality1', label: 'Nationality1' },
      { value: 'Nationality2', label: 'Nationality2' }
    ]
  },
  {
    type: 'input',
    name: 'Photo ID Number',
    id: 'photo_id_number',
    placeHolder: 'Enter your photo ID number…',
    rules: {
      required: {
        value: true, message: 'Field is mandatory'
      },
      maxLength: {
        value: 40, message: 'Maximum characters limit is 40'
      }
    }
  },
  {
    type: 'phone',
    name: 'Mobile Number',
    id: 'mobile',
    placeHolder: '',
    rules: {
      required: {
        value: true, message: 'Field is mandatory'
      }
    }
  },
  {
    type: 'date',
    name: 'Date of Birth',
    id: 'dob',
    placeHolder: '',
    rules: {
      required: {
        value: true, message: 'Field is mandatory'
      }
    }
  },
  {
    type: 'select',
    name: 'Country of Residence',
    id: 'country',
    placeHolder: 'Please Select...',
    rules: {
      required: {
        value: true, message: 'Field is mandatory'
      }
    },
    options: [
      { value: 'AUS', label: 'Australia' },
      { value: 'USA', label: 'United States of America' },
      { value: 'TW', label: 'Taiwan' },
      { value: 'DE', label: 'Germany' },
      { value: 'NL', label: 'The Netherlands' }
    ]
  },
  {
    type: 'input',
    name: 'Residential Address',
    id: 'residential_address',
    placeHolder: 'Must be consistent with ID/Passport',
    rules: {
      required: {
        value: true, message: 'Field is mandatory'
      },
      maxLength: {
        value: 150, message: 'Maximum characters limit is 150'
      }
    }
  },
  {
    type: 'input',
    name: 'City',
    id: 'city',
    placeHolder: 'Enter your city...',
    rules: {
      required: {
        value: true, message: 'Field is mandatory'
      },
      maxLength: {
        value: 40, message: 'Maximum characters limit is 40'
      }
    }
  },
  {
    type: 'select',
    name: 'State',
    id: 'state',
    placeHolder: 'Please Select...',
    rules: {
      required: {
        value: true, message: 'Field is mandatory'
      }
    },
    options: [
      { value: 'State1', label: 'State1' },
      { value: 'State2', label: 'State2' }
    ]
  },
  {
    type: 'input',
    name: 'Zip',
    id: 'zip',
    placeHolder: 'Enter your zip code...',
    rules: {
      required: {
        value: true, message: 'Field is mandatory'
      },
      maxLength: {
        value: 40, message: 'Maximum characters limit is 40'
      }
    }
  }
];

export const tradingPreference = [
  {
    id: 'total_amount_of_investment',
    type: 'select',
    name: 'Total amount of investment',
    placeHolder: 'Please Select...',
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        label: '1-29,999',
        value: 'range1'
      },
      {
        label: '30,000-79,999',
        value: 'range2'
      },
      {
        label: '80,000-149,999',
        value: 'range3'
      },
      {
        label: '>150,000',
        value: 'range4'
      }
    ]
  },
  {
    id: 'trading_platform',
    type: 'radioButton',
    name: 'Trading Platform',
    placeHolder: '',
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        label: 'MT4',
        value: 'MetaTrader4'
      },
      {
        label: 'MT5',
        value: 'MetaTrader5'
      }
    ]
  },
  {
    id: 'funding_currency',
    type: 'select',
    name: 'Funding Currency',
    placeHolder: 'Please Select...',
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        MetaTrader4: [
          {
            label: 'USD',
            value: 'usd'
          },
          {
            label: 'AUD',
            value: 'aud'
          },
          {
            label: 'NZD',
            value: 'nzd'
          }
          // {
          //   label: 'EUR',
          //   value: 'eur'
          // },
          // {
          //   label: 'GBP',
          //   value: 'gbp'
          // },
          // {
          //   label: 'CAD',
          //   value: 'cad'
          // },
          // {
          //   label: 'JPY',
          //   value: 'jpy'
          // }
        ],
        MetaTrader5: [
          // {
          //   label: 'USD',
          //   value: 'usd'
          // },
          // {
          //   label: 'AUD',
          //   value: 'aud'
          // },
          // {
          //   label: 'NZD',
          //   value: 'nzd'
          // },
          {
            label: 'EUR',
            value: 'eur'
          },
          {
            label: 'GBP',
            value: 'gbp'
          },
          {
            label: 'CAD',
            value: 'cad'
          },
          {
            label: 'JPY',
            value: 'jpy'
          }
        ]
      }
    ]
  },
  {
    id: 'leverage',
    type: 'select',
    name: 'Leverage',
    placeHolder: 'Please Select...',
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        MetaTrader4: [
          {
            label: '50',
            value: '50'
          },
          {
            label: '100',
            value: '100'
          },
          {
            label: '200',
            value: '200'
          },
          {
            label: '300',
            value: '300'
          },
          {
            label: '400',
            value: '400'
          },
          {
            label: '500',
            value: '500'
          }
        ],
        MetaTrader5: [
          {
            label: '50',
            value: '50'
          },
          {
            label: '100',
            value: '100'
          },
          {
            label: '200',
            value: '200'
          },
          {
            label: '300',
            value: '300'
          },
          {
            label: '400',
            value: '400'
          },
          {
            label: '500',
            value: '500'
          }
        ]
      }
    ]
  },
  {
    id: 'mt_account_type',
    type: 'radioButton',
    name: 'Account Types',
    placeHolder: 'Please Select...',
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        MetaTrader4: [
          {
            label: 'Standard',
            value: 'standard'
          },
          {
            label: 'Prozero',
            value: 'prozero'
          },
          {
            label: 'Bespoke',
            value: 'bespoke'
          }
        ],
        MetaTrader5: [
          {
            label: 'Standard',
            value: 'standard'
          },
          {
            label: 'Prozero',
            value: 'prozero'
          },
          {
            label: 'Bespoke',
            value: 'bespoke'
          }
        ]
      }
    ]
  }
];

export const employment = [
  {
    id: 'employment_status',
    type: 'radioButton',
    name: 'Employment Status',
    placeHolder: '',
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        label: 'Employed',
        value: 'employed'
      },
      {
        label: 'Unemployed',
        value: 'unemployed'
      },
      {
        label: 'Student',
        value: 'student'
      },
      {
        label: 'Retired',
        value: 'retired'
      }
    ]
  },
  {
    id: 'industry',
    type: 'select',
    name: 'Industry',
    placeHolder: 'Please Select...',
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        label: 'Construction',
        value: 'Construction'
      },
      {
        label: 'Other Services',
        value: 'Other Services'
      }
    ]
  },
  {
    id: 'position',
    type: 'radioButton',
    name: 'Position',
    placeHolder: '',
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        label: 'Director',
        value: 'director'
      },
      {
        label: 'Manager',
        value: 'manager'
      },
      {
        label: 'Entry Level',
        value: 'entryLevel'
      },
      {
        label: 'Other',
        value: 'other'
      }
    ]
  },
  {
    id: 'annual_income',
    type: 'select',
    name: 'Annual Income',
    placeHolder: 'Please Select...',
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        label: 'Item 1',
        value: 'item'
      },
      {
        label: 'Item 2',
        value: 'item2'
      }
    ]
  },
  {
    id: 'source_of_funds',
    type: 'radioButton',
    name: 'Source of Funds',
    placeHolder: '',
    rules: {
      required: {
        value: true,
        message: 'Field is mandatory'
      }
    },
    options: [
      {
        label: 'Income From Employment',
        value: 'incomeFromEmployment'
      },
      {
        label: 'Investment',
        value: 'investment'
      },
      {
        label: 'Savings',
        value: 'savings'
      },
      {
        label: 'Loans',
        value: 'loans'
      }
    ]
  }
];

export const tradingExperience = [{
  id: 'question_0',
  type: 'radio',
  name: '1. Which of the following scenarios regarding Leverage is correct?',
  placeHolder: '',
  required: true,
  data: [
    {
      label: 'I have $1,000 in my account, with 30:1 leverage, I can place an order for $3,000',
      value: 'a0-1'
    },
    {
      label: 'I have $1,000 in my account, with 30:1 leverage, I can place an order for $40,000',
      value: 'a0-2'
    },
    {
      label: 'I have $1,000 in my account, with 30:1 leverage, I can place an order for $300,000',
      value: 'a0-3'
    }
  ]
}, {
  id: 'question_1',
  type: 'radioButton',
  name: '2. ACY Securities MT4/MT5 charts by default only show Bid price.',
  placeHolder: '',
  required: true,
  data: [
    {
      label: 'True',
      value: 'a1-1'
    },
    {
      label: 'False',
      value: 'a1-2'
    }
  ]
}, {
  id: 'question_2',
  type: 'radioButton',
  name: '3. Trading with leverage in Forex or any OTC Derivative product, there may be a higher level of associated risk.',
  placeHolder: '',
  required: true,
  data: [
    {
      label: 'True',
      value: 'a2-1'
    },
    {
      label: 'False',
      value: 'a2-2'
    }
  ]
}, {
  id: 'question_3',
  type: 'radio',
  name: '4. You have recently begun trading in Margin FX and you do not fully understand the Risk Management concepts, the best course of action would be:',
  placeHolder: '',
  required: true,
  data: [
    {
      label: 'Book myself into training/education',
      value: 'a3-1'
    },
    {
      label: 'Download a demo/practice account in order to get some experience',
      value: 'a3-2'
    },
    {
      label: 'Seek independent professional advice',
      value: 'a3-3'
    },
    {
      label: 'All of the above',
      value: 'a3-4'
    }
  ]
}, {
  id: 'question_4',
  type: 'radioButton',
  name: '5. When I have open positions, it\'s my own responsibility to monitor my positions and ensure I have enough fund in my account to cover the margin obligation.',
  placeHolder: '',
  required: true,
  data: [
    {
      label: 'True',
      value: 'a4-1'
    },
    {
      label: 'False',
      value: 'a4-2'
    }
  ]
}, {
  id: 'question_5',
  type: 'radio',
  name: '6. When you buy a CFD on future contract of crude oil, what is the typical delivery time?',
  placeHolder: '',
  required: true,
  data: [
    {
      label: '3 Days',
      value: 'a5-1'
    },
    {
      label: '5 Days',
      value: 'a5-2'
    },
    {
      label: 'CFD’s do not entitle you to ownership rights of the underlying asset',
      value: 'a5-3'
    }
  ]
}, {
  id: 'question_6',
  type: 'radio',
  name: '7. Which of these methods is a better option to reduce the downside risk on your trade?',
  placeHolder: '',
  required: true,
  data: [
    {
      label: 'Place a stop loss',
      value: 'a6-1'
    },
    {
      label: 'Trade without a stop loss',
      value: 'a6-2'
    }
  ]
}, {
  id: 'question_7',
  type: 'radio',
  name: '8. Which of the following statements best describe high volatility?',
  placeHolder: '',
  required: true,
  data: [
    {
      label: 'When price increases greatly',
      value: 'a7-1'
    },
    {
      label: 'When price fluctuates in a very wide range within a short period of time',
      value: 'a7-2'
    },
    {
      label: 'A term used to describe a price decrease',
      value: 'a7-3'
    }
  ]
}, {
  id: 'question_8',
  type: 'radio',
  name: '9. What is your reason for trading CFDs?',
  placeHolder: '',
  required: true,
  data: [
    {
      label: 'Speculation and or Hedging',
      value: 'a8-1'
    },
    {
      label: 'CFDs are not an investment product. I decide not to continue this application.',
      value: 'a8-2'
    }
  ]
}, {
  id: 'question_9',
  type: 'radio',
  name: '10. Would losing your trading capital have a material impact on your standard of living?',
  placeHolder: '',
  required: true,
  data: [
    {
      label: 'No',
      value: 'a9-1'
    },
    {
      label: 'Yes. CFDs are not suitable for me. I decide not to continue this application.',
      value: 'a9-2'
    }
  ]
}

];

export const termsAndConditions = {
  id: 'termsAndConditions',
  panelTitle: 'Terms And Conditions',
  bannerComment: 'Please confirm you have read our Terms and Conditions before moving onto the next step.',
  termsContent: `
<h5><b>UNDERSTAND RISK</b></h5><p class="cond">Trading CFDs carries significant risk and is not suitable for all investors. You may lose more than you invest and you do not own, or have any interest in, the underlying asset. This is not an invitation to invest nor is it a recommendation to buy or sell investments.
		</p>
		<p class="cond">
            We recommend that you seek independent advice and ensure you fully understand the risks involved before trading. Please consider the Financial Service Guide, Privacy Statements and Website Terms and Conditions.
		</p>
		<h5><b>1. REGISTERING WITH ACY SECURITIES</b></h5>
		<p class="cond">
			ACY Securities Pty Ltd (ACY AU) is regulated and licensed by the Australian Securities and Investments Commission (ASIC) (AFSL: 403863). ACY Securities Pty Ltd ONLY accepts Australian residents as clients.
			<br>
			ACY Capital Australia Limited (ACY LTD) is regulated and licensed by Vanuatu Financial Services Commission (VFSC: 012868) and accepts clients who are non-Australian residents subject to local laws and regulations
			<br>
			ACY Securities is a trading name of ACY AU and ACY Ltd.
			<br>
			As a financial services provider, ACY Securities is to meet strict capital requirements and to implement strict internal procedures including risk management, staff training, accounting, and audits.
		</p>

		<h5><b>2. ACY DOES NOT PROVIDE ANY PERSONAL ADVICE</b></h5>
		<p class="cond">
            We only provide general product advice. Therefore, before you apply for an account, you must consider your investment objectives, financial situation, and needs. Trading margin foreign exchange and CFD contain high-risk. We recommend that you read the following disclosure documents below carefully, and consult your independent financial adviser, tax adviser, and other professional advisers. We cannot guarantee the results of your trading.
		</p>

		<h5><b>3. UNDERLYING ASSETS</b></h5>
		<p class="cond">
		When you trade Margin Forex and CFDs, your profits or losses depend on a rise or fall in the price of the underlying product. You need to know that you do not have any interest in the underlying forex, indices, and commodities. You are trading CFDs based on price fluctuation. There is no physical exchange or delivery of the commodity. CFDs are the financial derivative product.
		</p>
		<h5><b>4. OVER-THE-COUNTER (OTC) FINANCIAL DERIVATIVES</b></h5>
		<p class="cond">
		When you open trade on our platform, you are in the OTC derivative contract, and orders cannot be transferred. This means that you are dealing directly with us, and these trades (positions) can only be closed with us. In other words, margin foreign exchange and CFD contracts are issued by ACY Securities, not through any exchanges, such as the US Stock Exchange. ACY Securities is your product issuer.
		</p>
		<h5><b>5. LEVERAGE</b></h5>
		<p class="cond">
		When trading margin FX, you only need a small margin to open a position. For example, if you trade AUD/USD with a value of $10,000 and a margin ratio of 0.5%, then you only need $50 to open a position. However, your risk in the market is $10,000. If your position gained 10%, you would earn $1,000. If you lose 10% of your position, you lose $1,000. The profit or loss of your trading depends on the size of the positions you open. ACY Securities’ leverage is provided according to our accounts terms and conditions, ACY Securities reserves the right to adjust your account leverage at our discretion even when you have open positions.
		</p>
		<h5><b>6.MARKET VOLATILITY</b></h5>
		<p class="cond">
            Execution prices are based on the prices provided by our liquidity banks. The price of your trading products may fluctuate rapidly due to financial market news. Any changes in prices and spreads will have a direct impact on your account funds and positions. Price fluctuations can lead to a common situation called gapping, which occurred when opening price hugely differ from its closing price. Gapping caused by an unexpected economic event or a market announcement, especially when such information occurs outside the trading hours. Therefore, you may not have the opportunity to open or close positions between the two prices. The platform will execute your order at the next closest market price. You must bear the risk of a price gap, and your loss may exceed your account’s net worth, which may cause your account to go into negative balance. You have the responsibility to avoid negative account balance. ACY reserves the right to take further actions to recover the negative amount.
		</p>
		<h5><b>7.SLIPPAGE</b></h5>
		<p class="cond">
		Slippage is also a risk that traders will face during trading. It is the result of rapid price fluctuations; the actual executed price of your trade differs from your pre-set execution price. All orders are executed at a price provided by our clearing banks. In a rare situation, our quoting price or execution price may not be in line with the underlying market price. In OTC derivatives trading, slippage and price discrepancy can occur. Besides, your pending orders (including pending order for new trades or pending order to close existing trades) price is your wanting price. However, in a fast-moving market, the actual result of a pending order may differ from your pre-set execution price.
		</p>
		<h5><b>8.LIQUIDATION RISK</b></h5>
		<p class="cond">
		    At any time, the existing funds in your account must remain above the mandatory liquidation level (the margin level must be above 50%); otherwise, your open positions will be closed. However, please do not rely entirely on the system’s liquidation order. It is your responsibility to manage your positions, account balances and account equity on your trading platform. To prevent a mandatory liquidation, you should have sufficient funds in your account. Note that even if the money you deposited earlier was sufficient at that time, it could quickly become insufficient due to the fast movement in the market. Besides, in the case of rapid market fluctuations, the hedging trades (locked positions) may also trigger mandatory liquidation due to widening spreads. Traders should only open appropriate positions based on their available fund.
		</p>
		<h5><b>9.COUNTERPARTY RISK</b></h5>
		<p class="cond">
	        Counterparty risk When you open an account with us and open a margin position, you enter into a CFD contract, we are your trading counterparty. That means we may not be able to fulfil our contractual obligations under unexpected circumstances, where we or our own trading partners (such as our hedging providers) default. Under the impact of uncontrollable factors, we neither can guarantee the execution of your trading contract, nor the result of your traded contract. In addition, if your trading methods and trading strategies that are against our risk management policies, we have the right to cancel your completed trades including your trading profits and agent commissions. The inappropriate trading strategies include high frequency arbitrage trading on pricing inefficiencies from latency, price manipulation, insider trading, and any other strategies that are prohibited from regulations. We also prohibit using multiple accounts with hedging trades to speculate the gapping on market opening. We reserve the rights to close your trading account and agent account.
		</p>
		<h5><b>10.CLIENT FINANCIAL RISK</b></h5>
		<p class="cond">
		You can refer to our disclosure documents for more information about client fund procession.
		</p>
		<h5><b>11.TECHNICAL RISK AND OTHER CIRCUMSTANCES THAT AFFECT YOUR TRANSACTION</b></h5>
		<p class="cond">
		Some circumstances may prevent you from executing orders or from logging into our trading platform or instantly deposit funds into your account. This includes system errors or termination of supply, platform maintenance, network connectivity issues, or some third-party failures (such as network vendors, power companies and payment gateway providers) that you or we cannot control. We have an emergency solution on these issues, but sometimes you may still not able to enter the trading platform or deposit funds through our payment portal. These technical risks and contingencies pose a risk when you want to open or close positions or meet your margin call requirement.
		</p>

		<h5><b>12.DISPUTE JURISDICTION</b></h5>
		<p class="cond">
		    When a dispute occurs between you and ACY Securities, please contact our Dispute Resolution Team directly and work towards a solution. However, if an agreement cannot be met, you can seek external support.
		    <br>
            Australian Residents External Dispute Resolution Australian Financial Complaints Authority (AFCA) is the Australia external independent scheme to help resolve the dispute between ACY AU and the client.
            <br>
            Vanuatu Financial Services Commission(VFSC) is the external dispute resolution authority for clients of ACY Ltd.
	    </p>

        <h5><b>FINALLY, PLEASE AGREE TO THE FOLLOWING TERMS AND CONDITIONS BEFORE YOU SUBMIT THE ONLINE ACCOUNT APPLICATION FORM:<br/></b></h5>
		<p class="cond">
			1. You have read and accepted our disclosure documents in detail, including product descriptions, financial services guidelines, risk warnings, personal privacy terms, and the terms of use of this website.<br /><br />
			2. These terms and compliance documents make up ACY service contracts.<br/><br/>
			3. What you fill in the application form is real. If there is any change in your personal information, you need to notify us in writing. If we find that your information is false after opening an account, we may immediately cancel your trading account. For the loss account, the balance will be refunded by deposits. For the profit account, the principal will be refunded by the deposits, and profits will be deducted.<br /><br />
			4. Before you decide to open an account, you have fully understood your investment objectives, financial situation, and personal needs. You understand all the risks involved. <br /><br />
			5. You understand and agree that we always have the right to change or update our compliance documents. All changes will be made through our websites.<br /><br />
			6. You have sought independent legal and investment advice before opening an account. Your account is open when you have read all the risks involved.<br /><br />
			7. Your personal information will be kept strictly confidential following ACY Securities‘ Privacy Statement. You agree and grant ACY the right to acquire, hold and maintain your personal information.<br /><br />
			8. When the dispute exists, you willing to solve the problem following ACY Securities’ legal documents.<br/><br/>
			9. You understand that any activities that related open account come with terms and conditions, and agree and accept the terms and conditions of the activities.<br /><br />
			10. You acknowledge and agree that we may pay amounts to third party referrers who may have made you aware of our products and services.<br /><br />
		</p>
	`,
  checkBoxTitle: 'You have recently begun trading in Margin FX and you do not fully understand the Risk Management concepts, the best course of action would be:',
  data: [
    {
      id: 'read_key_information_statement',
      label: "I have read the <a href='https://acy.com/en/support/terms-and-conditions' target='_blank' rel='noreferrer'>Key Information Statement</a>"
    },
    {
      id: 'read_client_terms_and_conditions',
      label: "I have read the <a href='https://acy.acy.com/en/support/terms-and-conditions' target='_blank' rel='noreferrer'>Client Terms and Conditions</a>"
    },
    {
      id: 'read_privacy_policy_statement_ltd',
      label: "I have read the <a href='https://acy.com/en/support/terms-and-conditions' target='_blank' rel='noreferrer'>Privacy Policy Statement</a>"
    }
  ]
};

export const uploadIdData = [
  {
    id: 'id_front',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'id_back',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'proof_of_address',
    required: true,
    title: 'proofAddress',
    fileDescription: 'proofOfAddressDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'other_documents',
    required: false,
    title: 'otherDocument',
    fileDescription: 'proofOfAddressDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  }
];

export const VFSCTraditional = [
  {
    id: 'id_front',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.png,.pdf,.heic'
  },
  {
    id: 'id_back',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.png,.pdf,.heic'
  }
];

export const onlyPOAUpload = [{
  id: 'proof_of_address',
  required: true,
  title: 'proofAddress',
  fileDescription: 'proofOfAddressDesc',
  sizeLimit: 5,
  formatText: '',
  accept: '.jpg,.png,.pdf,.heic'
}];

export const mockIncomplete = [
  {
    form_step: 'personal_details',
    email: 'syd-wfh-ray-test-29@zerologix.com',
    id: 3139,
    total_amount_of_investment: '80,000 - 150,000',
    account_type: 'personal',
    trading_platform: 'MetaTrader4',

    funding_currency: 'AUD',
    leverage: '100',
    mt_account_type: 'PROZERO',
    employment_status: 'employed',
    industry: 'Electricity, Gas, Water and Waste Services',
    position: 'other',
    annual_income: '> 150,000',
    first_name: 'Will',
    middle_name: '',
    last_name: 'Smith',
    mobile: '61321312',
    gender: 'Male',
    dob: '1990-08-27',
    residential_address: '12312',
    city: 'Sydney',
    zip_code: '77540',
    state: 'Western Australia',
    country: 'AUS',
    nationality: 'AUS',
    photo_id_number: '12321321',
    onboarding_type: 0,
    ai_pass: 0,
    source_of_funds: 'income_from_employment'
  }
];
