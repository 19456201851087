import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import ErrorHandler from 'utils/ErrorHandler';
import { useAppDispatch } from 'store';
import { useTranslation } from 'react-i18next';
import { getDemoTradingFields, createDemoAccount } from 'api/v1/demoAccount';
import { OptionsTypes, OptionsProps, ResponseFieldsProps } from 'pages/DemoAccount/CreateDemoAccount';
import { SuccessInfoTypes } from 'pages/DemoAccount';
import Select from 'components/Select';
import BackdropSpinner from 'components/BackdropSpinner';
import RadioGroup from 'components/RadioGroup';
import {
  StyledItemTitle, StyledItemWrapper, StyledFooter, PopUpWrapper
} from './style';

interface CreateAccountPopUpProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  onSave: (arg0: SuccessInfoTypes) => void
}

const CreateAccountPopUp = ({
  openModal, setOpenModal, onSave
}: CreateAccountPopUpProps): JSX.Element => {
  const { t } = useTranslation('demoAccount');
  const [initLoading, setInitLoading] = useState(true);
  const [demoAccountFields, setDemoAccountFields] = useState<ResponseFieldsProps[]>([]);
  const {
    control, setValue, watch, resetField, handleSubmit, formState: { isValid }
  } = useForm({ mode: 'all' });
  const platform = watch('trading_platform');
  const dispatch = useAppDispatch();

  const getOptions = (options: OptionsTypes): OptionsProps[] => {
    if ('MetaTrader4' in options[0] && 'MetaTrader5' in options[0]) {
      if (platform === 'MetaTrader 4') return options[0]?.MetaTrader4;
      if (platform === 'MetaTrader 5') return options[0]?.MetaTrader5;
      if (platform === 'LogixTrader') return options[0]?.LogixTrader;
      return [];
    }
    const res: OptionsProps[] = [];
    options.forEach((el) => {
      if ('label' in el && 'value' in el) res.push(el);
    });
    return res;
  };

  useEffect(() => {
    getDemoTradingFields(localStorage.getItem('registeredCountry') || '').then((data) => {
      if (data.status === 200) {
        setDemoAccountFields(data.data);
        setInitLoading(false);
      }
    });

    setValue('trading_platform', 'LogixTrader');
  }, []);

  useEffect(() => {
    if (!openModal) {
      setValue('trading_platform', 'LogixTrader');
      resetField('base_currency');
    }
  }, [openModal]);

  return (
    <PopUpWrapper
      id="create demo account pop up"
      header={t('createDemoAccount')}
      openModal={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
    >
      <BackdropSpinner open={initLoading} />
      {demoAccountFields.map((item) => {
        switch (item.type) {
          case 'radioButton':
            return (
              <StyledItemWrapper key={item.id}>
                <StyledItemTitle aria-label="trading platform">{item.name}</StyledItemTitle>
                <Controller
                  control={control}
                  name={item.id}
                  rules={{ required: item?.rules?.required?.value }}
                  render={({
                    field: {
                      onChange,
                      value
                    }
                  }) => (
                    <RadioGroup
                      options={getOptions(item.options)}
                      groupName={item.id}
                      optionType="button"
                      onChange={(event) => {
                        const currency = watch('base_currency');

                        demoAccountFields.forEach((categoryObj) => {
                          if (categoryObj.id === 'base_currency') {
                            let reset = true;
                            const { options } = categoryObj;
                            if ('MetaTrader4' in options[0] && 'MetaTrader5' in options[0]) {
                              if (event?.target.value === 'MetaTrader 4') {
                                reset = !options[0]?.MetaTrader4.find((props) => props.value === currency);
                              }
                              if (event?.target.value === 'MetaTrader 5') {
                                reset = !options[0]?.MetaTrader5.find((props) => props.value === currency);
                              }
                            }
                            if (reset) setValue('base_currency', null);
                          }
                        });
                        onChange(event);
                      }}
                      value={value}
                    />
                  )}
                />
              </StyledItemWrapper>
            );
          case 'select':
            return (
              <StyledItemWrapper key={item.id}>
                <StyledItemTitle aria-label="base currency">{item.name}</StyledItemTitle>
                <Controller
                  control={control}
                  name={item.id}
                  rules={{ required: item?.rules?.required?.value }}
                  render={({
                    field: {
                      onChange, value
                    }
                  }) => (
                    <Select
                      className="select-custom"
                      options={getOptions(item.options)}
                      inputHeight="48px"
                      placeholder={item.placeHolder}
                      onChange={onChange}
                      currentValue={value}
                      defaultValue={value}
                      id={item.name}
                    />
                  )}
                />
              </StyledItemWrapper>
            );
          default:
            return null;
        }
      })}
      <StyledFooter>
        <Button onClick={() => setOpenModal(false)} aria-label="cancel">
          {t('createAccount:cancel')}
        </Button>
        <Button
          id="Additional_Demo_Account_Submit"
          aria-label="submit"
          disabled={!isValid || initLoading}
          onClick={handleSubmit((data) => {
            setInitLoading(true);
            ErrorHandler(createDemoAccount({ platform: data.trading_platform, currency: data.base_currency }), dispatch).then((res) => {
              if (res.status === 200) onSave(res.data);
            }).finally(() => {
              setInitLoading(false);
              setOpenModal(false);
            });
          })}
        >
          {t('registrationForm:submit')}
        </Button>
      </StyledFooter>
    </PopUpWrapper>
  );
};

export default CreateAccountPopUp;
