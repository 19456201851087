import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledContentWrap = styled.div<{ gap?: number }>`
  padding: 40px 20px;
  background: #F6F6F6;
  border-radius: 0 0 16px 16px;
  height: 100%;
  gap: ${(props) => (props.gap ? `${props.gap}px` : '0px')};

  &.flx-column {
    flex-direction: column;
  }
  &.flx-row {
    flex-direction:row;
  }

  .accountTypeContainer{
    margin-bottom: 40px;

    @media ${device.tablet} {
      margin-bottom: 16px;
    }

    @media ${device.mobileMaxWidth} {
      margin-bottom: 20px;
    }
  }
  > div > h5 > b {
    color: #1C1C1C;
    font-size: 16px;
  }

  > div > p {
    color: #5C5C5C;
    font-size: 14px;
  }
`;

export const StyledBtnWrap = styled.div`
     >button{
       &.MuiButtonBase-root {
         margin-right:0;
         text-transform: lowercase;
         font-family: 'Noto Sans';
         min-width: unset;
        }
     }
`;

export const BlurredPopUpCardWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
`;

export const StyledSelectWrap = styled.div`
  color: #585757;
  margin: 25px 0;

  > select {
    width: 100%;
    height: 52px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: none;
  }
`;

export const StyledBannerDesp = styled.div`
  padding: 20px 18px;
  background-color: #E5F2FA;;
  color: #1C1C1C;
  font-size: 16px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    padding: 30px 50px;
    font-size: 20px;
  }

`;

export const StyledLoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

export const StyledCreatAccountFrom = styled.div`
    margin: 24px 16px;
    position:relative;
    .lp-input {
      label {
        color:#585757;
      }
      .inputWrap{
        .input{
          color: #000000;
          font-size: 16px;
        }
      }
    }

    .custom-progress{
      position: absolute;
      left: calc(50% - 40px);
      top: calc(50% - 40px);
    }

  @media only screen and (min-width: 768px) {
    padding: 20px 70px;
  }
`;
