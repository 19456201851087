import { getAccounts, getAccountPortalLoginURL } from 'api/v1/account';
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { setActiveAccountNumber } from 'containers/Pages/slices';
import AccountStatusHandler from 'utils/AccountStatusHandler';
import { AppDispatch } from 'store';
import { DOMAIN_CONFIG } from 'constant/domainConfig';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let stompClient: Stomp.Client | null = null;
const uid: string | null = localStorage.getItem('uid');

export const connect = (dispatch: AppDispatch): void => {
  if (!uid) return;

  const socket = new SockJS(`${DOMAIN_CONFIG.socket}/logixpanel-zl-websocket`);

  stompClient = Stomp.over(socket);

  stompClient.debug = () => {
    // Add this line to disable debug logs
  };

  stompClient.connect(
    { 'X-Authorization': `Bearer ${localStorage.getItem('accessToken')}` },
    () => {
      stompClient?.subscribe(`/topic/user/${uid}`, (res: { body: string; }) => {
        if (res?.body) {
          const resData = JSON.parse(res.body);
          if (resData?.message?.code === 'status_change' && resData?.message?.message === 'activated') {
            getAccounts().then(({ data }: { data: { [key: string]: string }[] }) => {
              if (data && data.length > 1) return;
              const target: { [key: string]: {[key:string]:string} } = {};
              data?.forEach((el: { [key: string]: string }) => {
                target[`${el.type}_${el.status}`] = {
                  accountID: el.id,
                  formStep: el.form_step
                };
              });

              const { accountStatus } = AccountStatusHandler(target, dispatch);

              if (data.length === 1 && (accountStatus === 'Active' || accountStatus === 'Auto_Active')) {
                getAccountPortalLoginURL(data[0].id).then((response) => {
                  if (response && response.status === 200) window.location.assign(response.data.login_url);
                });
              }
            }).catch(() => {
              dispatch(setActiveAccountNumber('0'));
            });
          }
        }
      });
    },
    () => {
    // console.log(error);
    }
  );
};

export const sendMsg = (message: string): void => {
  if (stompClient && uid) {
    stompClient.send(`/app/user/${uid}`, {}, JSON.stringify({ message }));
  } else {
    console.error('STOMP client is not initialized or uid is missing');
  }
};

export const disconnect = (): void => {
  if (stompClient) {
    stompClient.disconnect(() => {
      console.info('Disconnected from STOMP');
    });
  }
};
