import * as React from "react";
import { SVGProps } from "react";
const SvgCloudUploaded = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 2.5c-1.65.005-3.243.6-4.492 1.678-.958.825-1.652 1.9-1.83 2.978C1.583 7.62 0 9.444 0 11.648c0 2.56 2.135 4.602 4.726 4.602H15.86c2.269 0 4.141-1.787 4.141-4.034 0-2.045-1.552-3.711-3.543-3.992C16.154 4.999 13.362 2.5 10 2.5Zm2.943 6.068-3.75 3.75a.625.625 0 0 1-.886 0l-1.875-1.875a.626.626 0 1 1 .885-.886l1.433 1.434 3.307-3.308a.626.626 0 0 1 .886.885Z"
      fill="#00B875"
    />
  </svg>
);
export default SvgCloudUploaded;
