/* eslint-disable no-alert */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormPanel from 'components/FormPanel';
import AccountType from 'components/AccountType';
import { accountTypeData } from 'containers/SelectAccountTypePanel/data';
import { TypeAcc } from 'type/dashboard';
import { createAccount } from 'api/v1/account';
import { StyledCreateAccountFrom } from 'containers/CreateAccountForm/style';
import PopUp from 'components/PopUp';
import Button from '@mui/material/Button';
import BackdropSpinner from 'components/BackdropSpinner';
import CountryModal from 'containers/CountryModal';
import { setAccountStatus, setCreateAccountStatus } from 'containers/Pages/slices';
import { useAppDispatch, useAppSelector } from 'store';
import { useNavigate } from 'react-router-dom';
import { AccountStatus } from 'utils/AccountStatusHandler';
import BlurredPopUpCard from 'containers/BlurredPopUpCard';

import { StyledContentWrap, StyledBtnWrap, BlurredPopUpCardWrap } from './style';

interface AccountTypeButtonsProps {
  onClick: (type: string) => void;
}

const AccountTypeButtons = (props: AccountTypeButtonsProps): JSX.Element => {
  const { onClick } = props;
  const { t } = useTranslation('selectAccountType');
  return (
    <StyledContentWrap className="StyledContentWrap">
      {accountTypeData.map((accountObj: TypeAcc) => (
        <div
          key={accountObj.type}
          className="accountTypeContainer"
          role="presentation"
          onClick={() => onClick(accountObj.type)}
        >
          <AccountType
            id={accountObj.type}
            imgSrc={accountObj.imgSrc}
            title={t(accountObj.title)}
            content={t(accountObj.content)}
          />
        </div>
      ))}
    </StyledContentWrap>
  );
};

const SelectAccountTypePanel = (): JSX.Element => {
  const [popupTxt, setPopupTxt] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const createAccountStatus = useAppSelector((state) => state.pageState.createAccountStatus);
  const accountStatus = useAppSelector((state) => state.pageState.accountStatus);
  const { t } = useTranslation('selectAccountType');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem('veriff');
    localStorage.removeItem('hasVeriffAddress');
  }, []);

  useEffect(() => {
    if (!accountStatus) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [accountStatus]);
  const createCorporateAccount = (): void => {
    createAccount({ account_type: 'corporate' }, 'corporate_contact_person_details').then((res) => {
      if (res && res.status === 201) {
        dispatch(setCreateAccountStatus({ createAccountStatus: { type: 'corporate' } }));
        localStorage.setItem('accountID', res.data.trader.resource_account_id);
        dispatch(setAccountStatus({ accountStatus: 'Incomplete' }));
        dispatch(setCreateAccountStatus({ createAccountStatus: { type: 'corporate' } }));
        navigate('/create-account/corporate-account');
      }
    }).catch((err) => {
      if (err.response.status === 422) {
        alert(err.response.data.errors);
      }
      if (err.response.status === 409) {
        setPopupTxt(err.response.data.message);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const createPersonalAccount = (): void => {
    setIsLoading(false);
    if (accountStatus === AccountStatus.Incomplete || accountStatus === AccountStatus.Reject) {
      setPopupTxt(t('restrictTxt'));
    } else {
      dispatch(setCreateAccountStatus({
        createAccountStatus: {
          type: 'personal', isModalOpen: true
        }
      }));
    }
  };

  const handleClose = ():void => {
    dispatch(setCreateAccountStatus(
      {
        createAccountStatus: { isModalOpen: false }
      }
    ));
  };

  const createJointAccount = (): void => {
    createAccount({ account_type: 'joint' }, 'joint_primary_account_holder').then((res) => {
      if (res && res.status === 201) {
        dispatch(setCreateAccountStatus({ createAccountStatus: { type: 'joint' } }));
        localStorage.setItem('accountID', res.data.trader.resource_account_id);
        dispatch(setAccountStatus({ accountStatus: 'Incomplete' }));
        dispatch(setCreateAccountStatus({ createAccountStatus: { type: 'joint' } }));
        navigate('/create-account/joint-account');
      }
    }).catch((err) => {
      if (err.response.status === 422) {
        alert(err.response.data.errors);
      }
      if (err.response.status === 409) {
        setPopupTxt(err.response.data.message);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleClick = (type: string): void => {
    setIsLoading(true);
    if (type === 'corporate') createCorporateAccount();
    if (type === 'personal') createPersonalAccount();
    if (type === 'joint') createJointAccount();
  };

  const handleWarningClose = (): void => {
    setPopupTxt('');
    setIsLoading(false);
  };

  return (
    <StyledCreateAccountFrom>

      <FormPanel id="Select Account Type" title={t('selectAccountType')}>
        {accountStatus && accountStatus !== AccountStatus.noAccount && accountStatus !== AccountStatus.Active && (
        <BlurredPopUpCardWrap>
          <BlurredPopUpCard />
        </BlurredPopUpCardWrap>
        )}
        <AccountTypeButtons onClick={handleClick} />
      </FormPanel>

      <PopUp
        content={popupTxt}
        openModal={popupTxt !== ''}
        onClose={handleWarningClose}
      >
        <StyledBtnWrap>
          <Button
            aria-label="Pop Up OK"
            onClick={handleWarningClose}
          >
            ok
          </Button>
        </StyledBtnWrap>
      </PopUp>
      <BackdropSpinner open={isLoading} />
      <CountryModal
        isModalOpen={createAccountStatus?.isModalOpen}
        handleClose={handleClose}
      />
    </StyledCreateAccountFrom>
  );
};

export default SelectAccountTypePanel;
