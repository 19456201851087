import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import { SidesIconsWrapProps } from '.';

export const StyledSidesIconsWrap = styled.div<SidesIconsWrapProps>`
    background-image: url(${(props) => `${props.bgSrc}`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position:relative;

    .icons-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: auto;
    }
    .icon-item {
        margin-bottom: 45px;
        img, p, h6 {
            margin: 0;
            font-size:16px;
        }
        h6 {
            color: #FFF;
            font-weight:600;
        }
        p {
            font-weight:400;
            color: #BFBFBF;
        }
    }
    .bottom-img{
        position: absolute;
        left: 24px;
        bottom: 0;
    }

    @media ${device.desktopMinWidth} {
        .icons-container{
            width: 430px;
            padding: 150px 44px;
            .icon-item{
                width: 46%;
            }
        }
    }

    @media ${device.bigDesktopMinWidth} {
        
        .icons-container{
            width: 627px;
            padding: 150px 76px;
            .icon-item{
                width: 50%;
            }
        }
    }
    @media ${device.tabletMaxWidth} {
        display: none;
    }
`;
