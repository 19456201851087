import { useFormContext, Controller } from 'react-hook-form';
import { get } from 'lodash';
import Select from 'components/Select';
import { StyledFieldWrap } from 'containers/ReactHookFormInputCells/style';

interface OptionsProps {
  value: string;
  label: string;
}

interface FormSelectProps {
  id: string;
  label?: string;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  options?: OptionsProps[];
  placeHolder?: string;
  warningMsg?: string | string[];
  markWarning?: boolean;
  rules?: {
    pattern?: { value: string, message: string };
    maxLength?: { value: number, message: string };
    required?: { value: boolean, message: string }
  };
  onChangeCallback?: (val: string) => void;
}

const FormSelect = ({
  options, id, label, disabled, className, placeHolder, required, warningMsg, markWarning, rules, onChangeCallback
}: FormSelectProps): JSX.Element => {
  const { control, formState: { errors } } = useFormContext();

  return (
    <StyledFieldWrap key={id}>
      <Controller
        control={control}
        name={id}
        {...rules}
        rules={{ required }}
        render={({
          field: {
            onChange,
            value
          }
        }) => (
          <Select
            label={label}
            className={className}
            options={options}
            inputHeight="48px"
            onChange={(val) => {
              onChange(val);
              if (onChangeCallback) onChangeCallback(val);
            }}
            menuPlacement="bottom"
            currentValue={value}
            defaultValue={value}
            placeholder={placeHolder}
            errorMsg={get(errors, id) && 'Error'}
            warningMsg={warningMsg}
            disabled={disabled}
            markWarning={markWarning}
            id={id}
          />
        )}
      />
    </StyledFieldWrap>
  );
};

export default FormSelect;
