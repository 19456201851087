import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Dialog from 'components/Dialog';
import { StyledDialogActionButton } from 'components/Dialog/style';
import { useLayoutControlState } from 'containers/AccountList/context/layoutControlContext';
import { addCorporateAccountMember } from 'api/v1/account';
import { useStagingDataState } from 'containers/AccountList/context/stagingDataContext';
import { useAccountListState } from 'containers/AccountList/context/accountListContext';
import { EMAIL_PATTERN, NAME_PATTERN } from 'utils/common';
import { StyledFormGroup, StyledInput } from './style';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
};

const InviteMemberDialog = (): JSX.Element => {
  const { t } = useTranslation('accountList');

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset
  } = useForm<FormValues>({
    defaultValues: { firstName: '', lastName: '', email: '' }
  });

  const {
    isInviteMemberDialogOpen,
    setIsInviteMemberDialogOpen,
    setIsResponseMessageToastOpen,
    setResponseMessage,
    setIsBackdropSpinnerOpen
  } = useLayoutControlState();

  const { accountData } = useStagingDataState();

  const { setAccountList } = useAccountListState();

  const sendInviteSubmission = (data: FormValues): void => {
    setIsInviteMemberDialogOpen(false);
    setIsBackdropSpinnerOpen(true);

    addCorporateAccountMember(accountData.id, {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName
    })
      .then((res) => {
        if (res.status === 200) {
          setResponseMessage({
            type: 'success',
            title: t('memberInvitedSuccess'),
            content: t('memberInvitedSuccessMessage')
          });

          setAccountList((prevState) => {
            const accountIndex = prevState.findIndex(
              (account) => account.id === accountData.id
            );

            const newAccountList = [...prevState];

            newAccountList[accountIndex].members = res.data[0].members;

            return newAccountList;
          });
        }
      })
      .catch((err) => {
        setResponseMessage({
          type: 'error',
          title: t('memberInvitedFailed'),
          content: err.response?.data?.message || err.message
        });
      })
      .finally(() => {
        setIsBackdropSpinnerOpen(false);
        setIsResponseMessageToastOpen(true);
      });
  };

  const handleDialogClose = (): void => {
    setIsInviteMemberDialogOpen(false);
    reset();
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  return (
    <Dialog
      isOpen={isInviteMemberDialogOpen}
      handleClose={handleDialogClose}
      title={t('inviteMemberDialogTitle')}
      contentSlot={(
        <>
          <StyledFormGroup>
            <Controller
              control={control}
              name="firstName"
              rules={{
                required: {
                  value: true,
                  message: t('requiredFieldMessage')
                },
                pattern: {
                  value: NAME_PATTERN,
                  message: t('invalidFirstName')
                },
                maxLength: {
                  value: 40,
                  message: t('reachMaximumCharactersLimit')
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <StyledInput
                  id="invite-member-first-name-field"
                  label={t('firstName')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMsg={errors.firstName?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              rules={{
                required: {
                  value: true,
                  message: t('requiredFieldMessage')
                },
                pattern: {
                  value: NAME_PATTERN,
                  message: t('invalidLastName')
                },
                maxLength: {
                  value: 40,
                  message: t('reachMaximumCharactersLimit')
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <StyledInput
                  id="invite-member-last-name-field"
                  label={t('lastName')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMsg={errors.lastName?.message}
                />
              )}
            />
          </StyledFormGroup>
          <StyledFormGroup style={{ marginBottom: 0 }}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: {
                  value: true,
                  message: t('requiredFieldMessage')
                },
                pattern: {
                  value: EMAIL_PATTERN,
                  message: t('invalidEmailAddress')
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <StyledInput
                  id="invite-member-email-field"
                  label={t('email')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMsg={errors.email?.message}
                />
              )}
            />
          </StyledFormGroup>
        </>
      )}
      actionSlot={(
        <>
          <StyledDialogActionButton
            aria-label="Send Invite Member"
            variant="filled"
            onClick={handleSubmit((data) => sendInviteSubmission(data))}
          >
            {t('sendInvite')}
          </StyledDialogActionButton>
          <StyledDialogActionButton
            aria-label="Cancel Invite Member"
            variant="text"
            onClick={() => handleDialogClose()}
          >
            {t('cancel')}
          </StyledDialogActionButton>
        </>
      )}
    />
  );
};

export default InviteMemberDialog;
