import { useContext } from 'react';
import { AuthContext } from './authContext';
import {
  AuthContextType, CreateAccountContextType, CreateCorporateAccountContextType, CreateJointAccountContextType
} from '../types';
import { CreateAccountContext } from './createAccountContext';
import { CreateCorporateAccountContext } from './createCorporateAccountContext';
import { CreateJointAccountContext } from './createJointAccountContext';

export const useAuth = ():AuthContextType => useContext(AuthContext);

export const useCreateAccountFlow = ():CreateAccountContextType => useContext(CreateAccountContext);

export const useCreateCorporateAccountFlow = ():CreateCorporateAccountContextType => useContext(CreateCorporateAccountContext);

export const useCreateJointAccountFlow = ():CreateJointAccountContextType => useContext(CreateJointAccountContext);
