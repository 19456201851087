import styled from 'styled-components';
import placeholder from 'styles/placeholderStyle';

interface ContainerProps {
  label?: string;
  require?: string;
  icon?: {
    position: string;
    pic: string;
  };
  isWarn: boolean;
  isErr: boolean;
  markWarning?: boolean;
}

const vCenter = (): string => `
    display: flex;
    align-items: center;
`;

const handleBorder = (isErr = false, isWarn = false, markWarning = false, isFocused = false): string => {
  const commonStyle = (color: string): string => `border: solid ${color}  1px;`;
  if (isFocused) return commonStyle('#006ACC');
  if (isErr) return commonStyle('#E92C2C');
  if (!isErr && isWarn && !markWarning) {
    return commonStyle('#F98600');
  }
  return commonStyle('#BBBBBB');
};

export const StyledWrap = styled.div<ContainerProps>`
  display: ${(props) => props.label && 'flex'};
  flex-direction: column;
  border-radius: 8px;
  position: relative;

  label {
    ${vCenter};
    line-height: 1.5;
    ${(props: ContainerProps) => ({
    [`&::${props.require}`]: { content: "'*'", color: 'red' }
  })}
  }

  .msg {
    min-height: 32px;
    color: #e92c2c;
    font-size: 14px;
  }

  .desktop-date-picker {
    >div {
      height: 48px;
      border-radius: 8px;
      border: 1px solid #BBBBBB;
      padding: ${(props) => (props.theme.isRTL ? '0' : '0 14px 0 0')};
      ${({ isErr, isWarn, markWarning }) => handleBorder(isErr, isWarn, markWarning)};


      > input {
        height: 100%;
        padding: 0 16px;
        font-size: 14px;
        color: hsl(0, 0%, 20%);
        caret-color: transparent;
        ${placeholder({ color: '#BBBBBB' })};
      }

      &:hover {
        border: 1px solid #5C5C5C
      }
    }
    .Mui-focused {
        border: 1px solid #006ACC;
        &:hover {
          border: 1px solid #006ACC;
        }
      }
    fieldset {
      display: none;
    }
  }
`;

export const StyledErrMsg = styled.div`
    color: #E92C2C;
    font-size: 14px;
    margin: 3px 0;
    line-height: 24px;
`;

export const StyledWarningMsg = styled.div<{ markWarning?: boolean }>`
    color: ${(props) => (props.markWarning ? '#BBB' : '#F98600')};
    font-size: 14px;
    line-height: 24px;
    margin:3px 0;
    word-break: break-word;
`;
