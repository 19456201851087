import { useTranslation } from 'react-i18next';
import { deleteCorporateAccountMember } from 'api/v1/account';
import Dialog from 'components/Dialog';
import { StyledDialogActionButton } from 'components/Dialog/style';
import { useAccountListState } from 'containers/AccountList/context/accountListContext';
import { useLayoutControlState } from 'containers/AccountList/context/layoutControlContext';
import { useStagingDataState } from 'containers/AccountList/context/stagingDataContext';

const RemoveMemberDialog = (): JSX.Element => {
  const { t } = useTranslation('accountList');

  const {
    isRemoveMemberDialogOpen,
    setIsRemoveMemberDialogOpen,
    setIsResponseMessageToastOpen,
    setResponseMessage,
    setIsBackdropSpinnerOpen
  } = useLayoutControlState();

  const { accountData, memberData } = useStagingDataState();

  const { setAccountList } = useAccountListState();

  const confirmRemoveMemberSubmission = (): void => {
    setIsRemoveMemberDialogOpen(false);
    setIsBackdropSpinnerOpen(true);

    deleteCorporateAccountMember(accountData.id, memberData.id)
      .then((res) => {
        if (res.status === 204) {
          setResponseMessage({
            type: 'success',
            title: t('memberRemovedSuccess'),
            content: t('memberRemovedSuccessMessage')
          });

          setAccountList((prevState) => {
            const accountIndex = prevState.findIndex(
              (account) => account.id === accountData.id
            );
            const updatedAccountMembers = prevState[
              accountIndex
            ].members?.filter((member) => member.id !== memberData.id);

            const newAccountList = [...prevState];

            newAccountList[accountIndex].members = updatedAccountMembers;

            return newAccountList;
          });
        }
      })
      .catch((err) => {
        setResponseMessage({
          type: 'error',
          title: t('memberRemovedFailed'),
          content: err.response?.data?.message || err.message
        });
      })
      .finally(() => {
        setIsBackdropSpinnerOpen(false);
        setIsResponseMessageToastOpen(true);
      });
  };

  return (
    <Dialog
      isOpen={isRemoveMemberDialogOpen}
      handleClose={() => setIsRemoveMemberDialogOpen(false)}
      title={t('removeMemberDialogTitle')}
      contentSlot={(
        <>
          {t('removeMemberDialogContent1')}
          <b>{memberData?.name}</b>
          {t('removeMemberDialogContent2')}
        </>
      )}
      actionSlot={(
        <>
          <StyledDialogActionButton
            aria-label="Confirm Remove Member"
            variant="filled"
            onClick={() => confirmRemoveMemberSubmission()}
          >
            {t('remove')}
          </StyledDialogActionButton>
          <StyledDialogActionButton
            aria-label="Cancel Remove Member"
            variant="text"
            onClick={() => setIsRemoveMemberDialogOpen(false)}
          >
            {t('cancel')}
          </StyledDialogActionButton>
        </>
      )}
    />
  );
};

export default RemoveMemberDialog;
