/* eslint-disable no-tabs */
/* eslint-disable max-len */
export const contactPerson = [
  {
    id: 'ID-Front-C',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'ID-Back-C',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'POA-C',
    required: true,
    title: 'proofAddress',
    fileDescription: 'proofOfAddressDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  }
];

export const shareholderData = [
  {
    id: 'ID-Front-S',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'ID-Back-S',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'POA-S',
    required: true,
    title: 'proofAddress',
    fileDescription: 'proofOfAddressDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  }
];

export const directorData = [
  {
    id: 'ID-Front-D',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'ID-Back-D',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'POA-D',
    required: true,
    title: 'proofAddress',
    fileDescription: 'proofOfAddressDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  }
];

export const contactPersonVFSC = [
  {
    id: 'ID-Front-C',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'ID-Back-C',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  }
];

export const shareholderDataVFSC = [
  {
    id: 'ID-Front-S',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'ID-Back-S',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  }
];

export const directorDataVFSC = [
  {
    id: 'ID-Front-D',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'ID-Back-D',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  }
];

export const companyDocuments = [
  {
    id: 'CI',
    required: true,
    title: 'certificateOfIncorporation',
    fileDescription: 'certificateOfIncorporationDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'POA-Cor',
    required: true,
    title: 'companyProofOfAddress',
    fileDescription: 'proofOfAddressDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'RC',
    required: true,
    title: 'regulationCertificates',
    fileDescription: 'regulationCertificatesDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  },
  {
    id: 'SC',
    required: false,
    title: 'shareExtract',
    fileDescription: 'shareExtractDesc',
    sizeLimit: 5,
    formatText: '',
    accept: '.jpg,.jpeg,.png,.pdf,.heic'
  }
];
