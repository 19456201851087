import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledDownloadContainer = styled.div`
  width: calc((100% - 32px)/2);
  padding: 20px 24px;
  background: #FFFFFF;
  border-radius: 4px;
  border-left: 2px solid #145DB5;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16), 0px 6px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  @media ${device.tablet} { 
    padding: 20px 24px;
  }
  @media ${device.mobileMaxWidth} {
    width: 100%;
    padding: 20px 24px;
  }
`;

export const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80px;
  height: 80px;
  border-radius: 300px;
  transition: all 0.7s;
  justify-content: center;
  align-items: center;
  svg {
    width: 42px;
    height: 42px;
   }
  &:hover {
    background-color: #145DB5;
    }
  svg:hover path {
    fill: #FFFFFF;
  }
`;

export const StyledContent = styled.div`
  line-height: 24px;
  font-weight: 400;
  margin: 8px 0 0 32px;
  @media ${device.tablet} {
    margin: 8px 0 0 22px;
  }
  @media ${device.mobileMaxWidth} {
    margin: 8px 0 0 14px;
  }
`;
export const StyledTitle = styled.p`
  font-size: 16px;
  color: #1C1C1C;
  margin: 0;
`;

export const StyledSubTitle = styled.p`
  font-size: 14px;
  color: #5C5C5C;
`;
