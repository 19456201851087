import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Lang from 'utils/language.util';

export const useLanguage = (): string => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [lang] = useState(() => Lang.getDefaultLanguage());

  const { i18n } = useTranslation();

  useEffect(() => {
    localStorage.setItem('language', lang);
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  useEffect(() => {
    if (searchParams.get('locale')) {
      searchParams.delete('locale');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return lang;
};
