import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledLoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledContentWrap = styled.div<{ gap?: number }>`
  padding: 40px 40px 8px;
  background: #ffffff;
  border-radius: 0 0 16px 16px;
  height: 100%;
  gap: ${(props) => (props.gap ? `${props.gap}px` : '0px')};

  @media ${device.desktopMinWidth} {
    display: flex;
  }

  @media ${device.tablet} {
    padding: 40px 24px 8px;
  }

  @media ${device.mobileMaxWidth} {
    padding: 40px 16px 8px;
  }
`;

export const StyledHalfContentWrap = styled.div`
  flex: 1;
`;

export const StyledMoreButton = styled.div`
  font-size: 12px;
  color: #006acc;
  cursor: pointer;
  margin-bottom: 4px;
  &:hover {
    color: #339dff;
  }
`;

export const StyledRadioGroupWrapper = styled.div`
  margin-bottom: 32px;
`;

export const StyledFieldWrap = styled.div`
  width: 100%;
  margin-bottom: 24px;

  label {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #5c5c5c;
    margin: 0 0 4px;
  }

  &:last-of-type {
    margin-right:  ${(props) => (!props.theme.isRTL && 0)};
    margin-left:  ${(props) => (props.theme.isRTL && 0)};
  }

  &.zip-code, &.state {
    display: inline-flex;
    width: calc(50% - 8px);
    > div {
      width: 100%;
    }
  }

  &.state {
    margin-right: 16px;
  }

  &.share-percentage {
    & .input-wrap {
      display: block;
      > input {
        text-align: center;
        width: 100px;
      }
    }
  }

  @media ${device.mobileMaxWidth} {
    width: 100%;
    display: inline-block;
  }
`;
