import React from 'react';
import StepTest from 'components/Steps/Step';
import Icon from 'components/Icons';
import { useSearchParams } from 'react-router-dom';
import {
  StyledCircle, StyledSteps, StyledStep, StyledProgressTick,
  StyledStepItem, StyledDescription, StyledMobileDescription
} from './style';

interface StepsProps {
  children: React.ReactElement<typeof StepTest> | React.ReactElement<typeof StepTest>[];
  direction?: 'horizontal' | 'vertical'
  current: number;
  className?: string;
}

const Steps = (props: StepsProps):JSX.Element => {
  const {
    children, direction = 'horizontal', current, className
  } = props;
  const [searchParams] = useSearchParams();

  const childrenArray = React.Children.toArray(children);
  const renderPreviousSteps = (status: 'valid' | 'warning' | 'unfinished', index: number):React.ReactNode => {
    switch (status) {
      case 'valid':
        return (
          <StyledProgressTick aria-label={`Step ${index + 1}`}>
            <Icon name="ProgressTick" />
          </StyledProgressTick>
        );
      case 'warning':
        return (
          <StyledProgressTick aria-label={`Step ${index + 1}`}>
            <Icon name="ProgressWarning" />
          </StyledProgressTick>
        );
      default:
        return (
          <StyledProgressTick aria-label={`Step ${index + 1}`}>
            <Icon name="ProgressTick" />
          </StyledProgressTick>
        );
    }
  };
  const renderNextSteps = (status: 'valid' | 'warning' | 'unfinished', index: number):React.ReactNode => {
    switch (status) {
      case 'valid':
        return (
          <StyledProgressTick aria-label={`Step ${index + 1}`}>
            <Icon name="ProgressTick" />
          </StyledProgressTick>
        );
      case 'warning':
        return (
          <StyledProgressTick aria-label={`Step ${index + 1}`}>
            <Icon name="ProgressWarning" />
          </StyledProgressTick>
        );
      case 'unfinished':
        return (
          <StyledCircle
            isActive={false}
            isLastItem={index + 1 === childrenArray.length}
            aria-label={`Step ${index + 1}`}
          >
            {index + 1}
          </StyledCircle>
        );
      default:
        return (
          <StyledCircle
            isActive={false}
            isLastItem={index + 1 === childrenArray.length}
            aria-label={`Step ${index + 1}`}
          >
            {index + 1}
          </StyledCircle>
        );
    }
  };
  const steps = childrenArray.map((step, index) => {
    if (React.isValidElement(step)) {
      return (
        <StyledStep direction={direction} className={className} key={step.key}>
          <StyledStepItem>
            {current - 1 > index && renderPreviousSteps(step.props.status, index)}
            {current - 1 < index && renderNextSteps(step.props.status, index)}
            {current - 1 === index && (
              <StyledCircle
                isActive
                hasWarning={step.props.status === 'warning'}
                isLastItem={index + 1 === childrenArray.length}
                aria-label={`Step ${index + 1}`}
              >
                {index + 1}
              </StyledCircle>
            )}
            <StyledDescription
              isActive={index <= current - 1}
              hasWarning={step.props.status === 'warning'}
              isRejectMode={searchParams.get('status') === 'Reject'}
              aria-label={step.props?.ariaLabel}
            >
              {step.props.description}
            </StyledDescription>
          </StyledStepItem>
          <StyledMobileDescription hasWarning={step.props.status === 'warning'} isRejectMode={searchParams.get('status') === 'Reject'}>
            {current === index + 1 && step.props.description}
          </StyledMobileDescription>
        </StyledStep>
      );
    }
    return <div />;
  });

  return (
    <StyledSteps direction={direction} aria-label="Steps wrapper">
      {steps}
    </StyledSteps>
  );
};

export default Steps;
