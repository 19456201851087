import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import {
  TicketDT,
  TicketMOB,
  TicketTAB
} from 'assets/blurredPages';
import { StyledBlurredPageWrap } from 'pages/BlurredPage/style';

export const StyledSupportWrap = styled(StyledBlurredPageWrap)`
  ::before {
    background-image: url(${TicketDT});
    @media ${device.tablet} {
      background-image: url(${TicketTAB});
    }
    @media ${device.mobileMaxWidth} {
      background-image: url(${TicketMOB});
    }
  }
`;
