import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import {
  AgentActivityDT,
  AgentActivityMOB,
  AgentActivityTAB
} from 'assets/blurredPages';
import { StyledBlurredPageWrap } from 'pages/BlurredPage/style';

export const StyledPartnerDashboardWrap = styled(StyledBlurredPageWrap)`
  ::before {
    background-image: url(${AgentActivityDT});
    @media ${device.tablet} {
      background-image: url(${AgentActivityTAB});
    }
    @media ${device.mobileMaxWidth} {
      background-image: url(${AgentActivityMOB});
    }
  }
`;
