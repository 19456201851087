import * as React from "react";
import { SVGProps } from "react";
const SvgProgressWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#fff" d="M.5 0h32v32H.5z" />
    <rect x={1.3} y={0.8} width={30.4} height={30.4} rx={15.2} fill="#F98600" />
    <path
      d="M17.26 19.54h-1.92l-.5-9.82h2.92l-.5 9.82Zm-2.5 3.18c0-.56.147-.953.44-1.18.293-.24.66-.36 1.1-.36.413 0 .767.113 1.06.34.307.227.46.627.46 1.2 0 .547-.153.94-.46 1.18a1.62 1.62 0 0 1-1.06.36c-.44 0-.807-.12-1.1-.36-.293-.24-.44-.633-.44-1.18Z"
      fill="#fff"
    />
    <rect
      x={1.3}
      y={0.8}
      width={30.4}
      height={30.4}
      rx={15.2}
      stroke="#F98600"
      strokeWidth={1.6}
    />
  </svg>
);
export default SvgProgressWarning;
