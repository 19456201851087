import styled from 'styled-components';

export const StyledConfirmedId = styled.div`



`;

export const StyledUploadWrap = styled.div`
  display:grid;
  column-gap: 20px;
  row-gap: 30px;
  @media screen and (min-width:768px) {
    grid-template-columns: 1fr 1fr;

    }
`;

export const StyledPopupWrap = styled.div<{ gap?: number }>`
  padding: 0 38px;
  flex-direction: column;
  height: 100%;

`;

export const StyledPopupContent = styled.div<{ gap?: number }>`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 16px;

  span{
    font-weight: 600;
    color: #145DB5;
  }
`;
