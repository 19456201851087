import { useEffect, useState, useMemo } from 'react';
import { useAppDispatch } from 'store';
import FormPanel from 'components/FormPanel';
import ItemLabel from 'components/ItemLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import {
  getCompanyDetails, getTradingPreferencesFields, updateCountry, getCountryState
} from 'api/v1/account';
import { ResponseFieldsProps, OptionsProps } from 'type/form';
import { useFormContext, Controller } from 'react-hook-form';
import { useCreateCorporateAccountFlow } from 'store/context/hooks';
import {
  CreateCorporateAccountSteps, CORPORATE_ACCOUNT_STEPS, Countries, TRADING_PLATFORM_ICON
} from 'constant/createAccount';
import FormRadioButton from 'containers/ReactHookFormInputCells/components/FormRadioButton';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import PhoneInput from 'components/FormCells/PhoneInput';
import BackdropSpinner from 'components/BackdropSpinner';
import PopUp from 'components/PopUp';
import { useSearchParams } from 'react-router-dom';
import { isArray } from 'lodash';
import ErrorHandler from 'utils/ErrorHandler';
import FormSelect from 'containers/ReactHookFormInputCells/components/FormSelect';
import langData from 'i18n/langs.json';
import {
  getFieldWarningMessage, checkFieldIsWarning, checkFieldIsCorrecting, fetchNestedProperty,
  setTradingPlatformOptions
} from 'containers/CreateAccountForm/utils';
import { StyledLoadingWrap } from 'containers/CreateCorporateAccountForm/components/style';
import { TradingPlatform } from 'type/common';
import { Link } from '@mui/material';
import Info from '../../../CreateAccountForm/components/TradingEmployment/Info';
import {
  StyledFormWrapper, StyledContentWrap, StyledHalfContentWrap, StyledFieldWrap
} from './style';

interface InputInfoProps {
  type: string;
  name: string;
  id: string;
  placeHolder: string;
  options?: {
    value: string;
    label: string;
  }[];
  rules?: {
    pattern?: { value: string, message: string };
    maxLength?: { value: number, message: string };
    required?: { value: boolean, message: string }
  };
  errMsg?: {
    required?: string;
    pattern?: string;
  };
  moreButton: {
    name: string;
    url: string;
  } | null;
}

type OptionsTypes = OptionsProps[] | {
  MetaTrader4: OptionsProps[];
  MetaTrader5: OptionsProps[];
  LogixTrader: OptionsProps[];
}[]

interface WarningField {
  warning_value?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface SelectHandlerProps {
  onChange: ((...event: unknown[]) => void) | (() => void);
  prev: string;
  next: string;
}

const CompanyDetails = (): JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const [isCompanyDetailsLoading, setIsCompanyDetailsLoading] = useState(true);
  const [isTradingPreferencesLoading, setIsTradingPreferencesLoading] = useState(true);
  const [companyDetailsFields, setCompanyDetailsFields] = useState<ResponseFieldsProps[]>([]);
  const [tradingPreferencesFields, setTradingPreferencesFields] = useState<ResponseFieldsProps[]>([]);
  const [searchParams] = useSearchParams();
  const [phoneValue, setPhoneValue] = useState('');
  const { i18n } = useTranslation();
  const [isBackdropSpinnerOpen, setIsBackdropSpinnerOpen] = useState<boolean>(false);
  const [openSanctionedCountriesModal, setOpenSanctionedCountriesModal] = useState<boolean>(false);
  const [livingState, setLivingState] = useState<OptionsProps[] | null>(null);
  const {
    rejectWarningFields, rejectStepStatus, updateRejectStepWarningStatus, sanctionedCountriesInfo, updateSteps, updateFlow
  } = useCreateCorporateAccountFlow();
  const num = useMemo(
    () => (companyDetailsFields.length > 0 ? companyDetailsFields.length / 2 : 0),
    [companyDetailsFields]
  );
  const tradingPreferencesNum = useMemo(
    () => (tradingPreferencesFields.length > 0 ? tradingPreferencesFields.length / 2 : 0),
    [tradingPreferencesFields]
  );
  const {
    control, formState: { errors }, setValue, getValues, watch, resetField
  } = useFormContext();
  const currentStep = CreateCorporateAccountSteps.CorporateShareholdersAndDirectors;
  const currentPlatform = watch('trading_platform');
  const platformDependencies = ['funding_currency', 'leverage', 'mt_account_type'];
  const dispatch = useAppDispatch();

  const updateCountryState = (country: string): void => {
    setLivingState([{ value: 'N/A', label: 'N/A' }]);
    if (!country) return;
    getCountryState(country).then((res) => {
      if (res.status === 200) {
        setLivingState(res.data.options.length === 0 ? [{ value: 'N/A', label: 'N/A' }] : res.data.options);
      }
    });
  };
  const getOptions = (options: OptionsTypes): OptionsProps[] => {
    if ('MetaTrader4' in options[0] && 'MetaTrader5' in options[0]) {
      const platform = getValues('trading_platform');
      if (platform === 'MetaTrader4') return options[0]?.MetaTrader4;
      if (platform === 'MetaTrader5') return options[0]?.MetaTrader5;
      if (platform === 'LogixTrader') return options[0]?.LogixTrader;
      return [];
    }
    const res: OptionsProps[] = [];
    options.forEach((el) => {
      if ('label' in el && 'value' in el) res.push(el);
    });
    return res;
  };

  const regulateDisable = (id: string):boolean => {
    if (id !== 'company.regulated_country' && id !== 'company.regulated_number') {
      return false;
    }
    const regulateState = getValues('company.regulate_state');
    if (regulateState === 'No' || !regulateState) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (watch('company.regulate_state') === 'No' || !watch('company.regulate_state')) {
      setValue('company.regulated_country', null);
      setValue('company.regulated_number', null);
    }
  }, [watch('company.regulate_state')]);

  const renderInput = (item: InputInfoProps): JSX.Element => {
    if (item.type === 'radioButton') {
      return (
        <ItemLabel
          id={item.id}
          key={item.name}
          title={item.name}
          remarkText={item?.moreButton?.name}
          remarkLink={item?.moreButton?.url}
        >
          <FormRadioButton
            key={item.id}
            id={item.id}
            required={item?.rules?.required?.value}
            options={getOptions(item.options || [])}
            disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
          />
          <Info type={item.id} value={watch(item.id)} marginTop="-26px" />
        </ItemLabel>
      );
    }
    return (
      <ItemLabel
        id={item.id}
        key={`${item.name}`}
        title={item.name}
        remarkText={item?.moreButton?.name}
        remarkLink={item?.moreButton?.url}
      >
        <FormSelect
          key={item.id}
          id={item.id}
          required={item?.rules?.required?.value}
          options={getOptions(item.options || [])}
          placeHolder={item.placeHolder}
          warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id)}
          disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
          markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, item.id, getValues(item.id))}
        />
      </ItemLabel>
    );
  };

  const checkFields = (fields: WarningField, path = ''): boolean => {
    for (const key in fields) {
      if (fields[key] && typeof fields[key] === 'object') {
        if ('warning_value' in fields[key]) {
          if (fields[key].warning_value === getValues(`${path}${key}`)) {
            if (!rejectStepStatus[currentStep].isWarning) {
              updateRejectStepWarningStatus(currentStep, true);
            }
            return true; // return true when a match is found
          }
        } else {
          const isWarning = checkFields(fields[key] as WarningField, `${path}${key}.`);
          if (isWarning) return true; // propagate the true result up
        }
      }
    }
    return false; // default return value if no match is found
  };

  const inputChangeHandler = (inputId: string, inputIndex: number): void => {
    if (searchParams.get('status') !== 'Reject') return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let value = '' as any;
    if (rejectWarningFields[currentStep] && isArray(rejectWarningFields[currentStep])) {
      value = fetchNestedProperty(rejectWarningFields[currentStep][inputIndex], inputId);
    }
    if (rejectWarningFields[currentStep] && isArray(rejectWarningFields[currentStep]) && value) {
      if (getValues(inputId) === value.warning_value) {
        if (!rejectStepStatus[currentStep].isWarning) {
          updateRejectStepWarningStatus(currentStep, true);
        }
      } else {
        let isWarning = false;
        for (let j = 0; j < rejectWarningFields[currentStep].length; j += 1) {
          isWarning = checkFields(rejectWarningFields[currentStep][j]);
          if (isWarning) break;
        }
        if (!isWarning && rejectStepStatus[currentStep].isWarning) {
          updateRejectStepWarningStatus(currentStep, false);
        }
      }
    }
  };

  const selectHandler = ({ onChange, prev, next }: SelectHandlerProps): void => {
    if (prev === next) return;
    const isSanctionedCountry = sanctionedCountriesInfo.countries.includes(next);
    if (isSanctionedCountry) {
      setOpenSanctionedCountriesModal(true);
      return;
    }

    if (prev === Countries.AUS || next === Countries.AUS) {
      setIsBackdropSpinnerOpen(true);
      if (Object.keys(errors).length > 0) {
        Object.keys(errors).forEach((fieldKey) => {
          resetField(fieldKey);
        });
      }

      ['total_amount_of_investment', '"mt_account_type"', 'leverage', 'funding_currency'].forEach((el: string) => setValue(el, null));
      setValue('company.company_state_province', null);
      setValue('trading_platform', 'LogixTrader');
      tradingPreferencesFields.forEach((categoryObj) => {
        if (platformDependencies.includes(categoryObj?.id)) {
          const value = getValues(categoryObj.id);
          let reset = true;
          const options = getOptions(categoryObj.options);

          options.forEach((item) => {
            if (item.value === value) reset = false;
          });

          if (reset) setValue(categoryObj.id, null);
        }
      });

      onChange(next);
      ErrorHandler(updateCountry({
        accountID: localStorage.getItem('accountID')
          ? localStorage.getItem('accountID') : null,
        form: {
          ...getValues(),
          original_country: prev,
          form_step: CreateCorporateAccountSteps.CorporateCompanyDetails
        }
      }), dispatch).then((res) => {
        if (res && res.status === 200) {
          if (next === Countries.AUS) {
            updateFlow('ASIC');
            updateSteps(CORPORATE_ACCOUNT_STEPS.ASIC);
          } else {
            updateFlow('VFSC');
            updateSteps(CORPORATE_ACCOUNT_STEPS.VFSC);
          }
          Object.keys(res.data).forEach((eachKey) => {
            if (['_method'].some((el: string) => el !== eachKey)) {
              // The api does not return other information about the company, so it cannot directly cover the entire company.
              if (eachKey === 'company') {
                setValue('company.company_id', res.data[eachKey].company_id);
              } else {
                setValue(eachKey, res.data[eachKey]);
              }
            }
            if (eachKey === 'terms_and_conditions') {
              const tncObj = JSON.parse(String(res.data.terms_and_conditions));
              if (tncObj !== undefined && tncObj !== null) {
                Object.keys(tncObj).forEach((eachTerm) => {
                  setValue(eachTerm, tncObj[eachTerm]);
                });
              }
            }
          });
          const countryOfCompany = getValues('company.country_of_company');
          getTradingPreferencesFields(countryOfCompany).then((tradingRes) => {
            if (tradingRes) {
              const tradingPreferenceRes = setTradingPlatformOptions(countryOfCompany, tradingRes, t);
              setTradingPreferencesFields(tradingPreferenceRes);
            }
          }).finally(() => {
            setIsTradingPreferencesLoading(false);
          });
          updateCountryState(next);
        }
      }).catch(() => {
        onChange(prev);
      }).finally(() => setIsBackdropSpinnerOpen(false));
    } else {
      onChange(next);
      setValue('company.company_state_province', null);
      updateCountryState(next);
    }
  };

  const renderInputCell = (item: InputInfoProps, index: number): JSX.Element => {
    const id = `company.${item.id}`;
    switch (item.type) {
      case 'input':
        return (
          <StyledFieldWrap key={id}>
            <Controller
              control={control}
              name={id}
              {...item.rules}
              rules={{
                ...item.rules,
                required: regulateDisable(id) ? !regulateDisable(id) : item.rules?.required,
                pattern: item.rules?.pattern && {
                  value: new RegExp(item.rules?.pattern.value),
                  message: item.rules?.pattern.message
                }
              }}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                <Input
                  label={item.name}
                  height="48px"
                  placeholder={item.placeHolder}
                  onChange={(val) => {
                    onChange(val);
                    inputChangeHandler(id, index);
                  }}
                  value={value || ''}
                  errorMsg={errors[id] && (errors[id]?.message as unknown as string)}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, id)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, id) || regulateDisable(id)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, id, value)}
                  id={id}
                />
              )}
            />
          </StyledFieldWrap>
        );

      case 'select':
        return (
          <StyledFieldWrap key={id}>
            <Controller
              control={control}
              name={id}
              {...item.rules}
              rules={{ required: regulateDisable(id) ? !regulateDisable(id) : item.rules?.required }}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                <Select
                  label={item.name}
                  options={id === 'company.company_state_province' && livingState ? livingState : item.options}
                  inputHeight="48px"
                  onChange={(val) => {
                    if (id === 'company.country_of_company') {
                      selectHandler({ onChange, next: val, prev: value });
                    } else {
                      onChange(val);
                    }
                    inputChangeHandler(id, index);
                  }}
                  menuPlacement={id === 'state' || id === 'nationality' ? 'top' : 'bottom'}
                  currentValue={value}
                  defaultValue={value}
                  placeholder={item.placeHolder}
                  errorMsg={errors[id] && 'Error'}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, id)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, id) || regulateDisable(id)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, id, value)}
                  id={id}
                />
              )}
            />
          </StyledFieldWrap>
        );
      case 'phone':
        return (
          <StyledFieldWrap key={id}>
            <Controller
              control={control}
              name={id}
              {...item.rules}
              rules={{ required: item.rules?.required?.value }}
              render={({
                field: {
                  onChange, value
                }
              }) => (
                <PhoneInput
                  id={id}
                  label={item.name}
                  height="48px"
                  onChange={(phoneVal: string, info: { [key: string]: string }) => {
                    onChange(phoneVal);
                    if (phoneVal === info.dialCode) {
                      setValue('mobile', null);
                      setPhoneValue(info.dialCode);
                    }
                    inputChangeHandler(id, index);
                  }}
                  defaultValue={value || phoneValue}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, id)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, id)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, id, value)}
                  localization={langData[i18n.language as keyof typeof langData]}
                />
              )}
            />
          </StyledFieldWrap>
        );

      case 'date':
        return (
          <StyledFieldWrap key={id}>
            <Controller
              control={control}
              name={id}
              {...item.rules}
              rules={{ required: item.rules?.required?.value }}
              render={({
                field: {
                  onChange,
                  value
                }
              }) => (
                <DatePicker
                  id={id}
                  label={item.name}
                  onChange={(val) => {
                    onChange(val);
                    inputChangeHandler(id, index);
                  }}
                  defaultValue={value}
                  warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, id)}
                  disabled={checkFieldIsWarning(rejectWarningFields, currentStep, id)}
                  markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, id, value)}
                />
              )}
            />
          </StyledFieldWrap>
        );
      default:
        return (
          <StyledFieldWrap key={id}>
            <Input
              id={id}
              label={item.name}
              height="48px"
              placeholder={item.placeHolder}
              warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, id)}
              disabled={checkFieldIsWarning(rejectWarningFields, currentStep, id)}
            />
          </StyledFieldWrap>
        );
    }
  };
  useEffect(() => {
    tradingPreferencesFields.forEach((categoryObj) => {
      if (platformDependencies.includes(categoryObj?.id)) {
        const value = getValues(categoryObj.id);
        let reset = true;
        const options = getOptions(categoryObj.options);
        options.forEach((item) => {
          if (item.value === value) reset = false;
        });

        if (reset) setValue(categoryObj.id, null);
      }
    });
  }, [currentPlatform]);

  useEffect(() => {
    getCompanyDetails().then((res) => {
      if (res) {
        setCompanyDetailsFields(res?.data);
      }
    }).finally(() => {
      setIsCompanyDetailsLoading(false);
    });
  }, []);

  useEffect(() => {
    const countryOfCompany = getValues('company.country_of_company');
    getTradingPreferencesFields(countryOfCompany).then((res) => {
      if (res) {
        const tradingPreferenceRes = res.data?.map((item: ResponseFieldsProps) => {
          if (item.id === 'trading_platform') {
            item.options.forEach((option) => {
              option.label = option.value;
              option.icon = TRADING_PLATFORM_ICON[option.value as TradingPlatform];
              if (option.value === TradingPlatform.LogixTrader) {
                const leverage = countryOfCompany === Countries.AUS ? 30 : 5000;
                option.tooltip = (
                  <>
                    <div>{t('logixTraderTooltip', { leverage })}</div>
                    <Link color="inherit" href={`${item.moreButton?.url}/logixtrader`} target="_blank">{item.moreButton?.name}</Link>
                  </>
                );
              }
            });
          }
          return item;
        }) ?? [];
        setTradingPreferencesFields(tradingPreferenceRes);
      }
    }).finally(() => {
      setIsTradingPreferencesLoading(false);
    });
  }, []);

  useEffect(() => {
    const country = getValues('company.country_of_company');
    updateCountryState(country);
  }, []);

  return (
    <StyledFormWrapper>

      <FormPanel title={t('companyDetails')} id="Company Details">
        <StyledContentWrap className="StyledContentWrap flx-row" gap={24}>
          {!isCompanyDetailsLoading ? (
            <>
              <StyledHalfContentWrap>
                {
                  companyDetailsFields.map((item: InputInfoProps, itemIndex: number) => ((num > itemIndex)
                    ? renderInputCell(item, 0) : null))
                }
              </StyledHalfContentWrap>
              <StyledHalfContentWrap>
                {
                  companyDetailsFields.map((item: InputInfoProps, itemIndex: number) => ((num <= itemIndex)
                    ? renderInputCell(item, 0) : null))
                }
              </StyledHalfContentWrap>
            </>
          ) : (
            <StyledLoadingWrap>
              <CircularProgress />
            </StyledLoadingWrap>
          )}
        </StyledContentWrap>
      </FormPanel>

      <FormPanel title={t('tradingPreferences')}>
        <StyledContentWrap className="StyledContentWrap flx-row" gap={24}>
          {!isTradingPreferencesLoading ? (
            <>
              <StyledHalfContentWrap>
                {
                  tradingPreferencesFields.map((item: InputInfoProps, itemIndex: number) => {
                    if (tradingPreferencesNum > itemIndex) {
                      return renderInput(item);
                    }
                    return null;
                  })
                }
              </StyledHalfContentWrap>
              <StyledHalfContentWrap>
                {
                  tradingPreferencesFields.map((item: InputInfoProps, itemIndex: number) => {
                    if (tradingPreferencesNum <= itemIndex) {
                      return (
                        renderInput(item)
                      );
                    }
                    return null;
                  })
                }
              </StyledHalfContentWrap>
            </>
          ) : (
            <StyledLoadingWrap>
              <CircularProgress />
            </StyledLoadingWrap>
          )}
        </StyledContentWrap>
      </FormPanel>
      <PopUp
        content={sanctionedCountriesInfo.message}
        openModal={openSanctionedCountriesModal}
      >
        <Button aria-label="Pop Up OK" onClick={() => setOpenSanctionedCountriesModal(false)}>
          {t('ok')}
        </Button>
      </PopUp>
      <BackdropSpinner open={isBackdropSpinnerOpen} />
    </StyledFormWrapper>
  );
};

export default CompanyDetails;
