import { SVGProps } from 'react';

const SvgCrossCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.8001 3C30.6001 3.3 35.1001 5.7 38.4001 9C42.3001 13.2 44.4001 18.3 44.4001 24.3C44.4001 29.1 42.6001 33.6 39.6001 37.5C36.6001 41.1 32.4001 43.8 27.6001 44.7C22.8001 45.6 18.0001 45 13.8001 42.6C9.60011 40.2 6.30011 36.6 4.50011 32.1C2.70011 27.6 2.40011 22.5 3.90011 18C5.40011 13.2 8.10011 9.3 12.3001 6.6C16.2001 3.9 21.0001 2.7 25.8001 3ZM27.3001 41.7C31.2001 40.8 34.8001 38.7 37.5001 35.4C39.9001 32.1 41.4001 28.2 41.1001 24C41.1001 19.2 39.3001 14.4 36.0001 11.1C33.0001 8.1 29.4001 6.3 25.2001 6C21.3001 5.7 17.1001 6.6 13.8001 9C10.5001 11.4 8.10011 14.7 6.90011 18.9C5.70011 22.8 5.70011 27 7.50011 30.9C9.30012 34.8 12.0001 37.8 15.6001 39.9C19.2001 42 23.4001 42.6 27.3001 41.7ZM23.7001 22.5L30.9001 15L33.0001 17.1L25.8001 24.6L33.0001 32.1L30.9001 34.2L23.7001 26.7L16.5001 34.2L14.4001 32.1L21.6001 24.6L14.4001 17.1L16.5001 15L23.7001 22.5Z'
      fill='#E92C2C'
    />
  </svg>
);
export default SvgCrossCircle;
