import Button from 'components/Button';
import { useAppSelector, useAppDispatch } from 'store';
import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { backStep } from 'containers/CreateAccountForm/slices';
import { useCreateJointAccountFlow } from 'store/context/hooks';
import { useNavigate } from 'react-router';
import { updateFormStep } from 'api/v1/account';
import ErrorHandler from 'utils/ErrorHandler';
import cx from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ResubmitFooter from 'containers/CreateJointAccountForm/components/ResubmitFooter';
import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { StyledLButtonsWrap, StyledNeedHelpSentence } from './style';

interface StepButtonsProps {
  isSubmitting?: boolean
}

const StepButtons = (props: StepButtonsProps):JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const currentStep = useAppSelector((state) => state.createAccountState.step);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { isSubmitting = false } = props;
  const { formState: { isValid }, getValues } = useFormContext();
  const {
    steps, formIsLoading, updateRejectStepValidStatus
  } = useCreateJointAccountFlow();
  const navigator = useNavigate();

  const renderNextBtn = (showSubmit: boolean):string => {
    let btnText = `${t('next')}`;
    if (showSubmit) btnText = `${t('submit')}`;
    return btnText;
  };

  const handleBack = ():void => {
    const accountID = localStorage.getItem('accountID');
    if (currentStep === 0) {
      navigator('/create-account/select-account-type');
    } else {
      if (accountID) {
        ErrorHandler(updateFormStep(
          accountID,
          steps[currentStep - 1],
          getValues('country')
        ), dispatch);
      }
      dispatch(backStep());
    }
  };

  useEffect(() => {
    if (searchParams.get('status') !== 'Reject') return;
    updateRejectStepValidStatus(steps[currentStep], isValid);
  }, [isValid, currentStep, searchParams]);

  return (
    <StyledLButtonsWrap>
      <div>
        <Button
          className="back-button"
          onClick={() => handleBack()}
        >
          {t('back')}
        </Button>
        <Button
          id={steps[currentStep]}
          className={cx('next-button', { hidden: searchParams.get('status') === 'Reject' && currentStep === steps.length - 1 })}
          htmlType="submit"
          disabled={!isValid || formIsLoading || isSubmitting}
        >
          {renderNextBtn(currentStep === steps.length - 1)}
        </Button>

        {searchParams.get('status') === 'Reject' && (<ResubmitFooter />)}

      </div>
      { steps[currentStep]?.includes('experience') && (
        <StyledNeedHelpSentence>
          <span aria-label="Need Help For Practice Questions">{t('needHelp')}</span>
          <a
            target="_blank"
            href={`${DOMAIN_CONFIG.origin}/rg227-practice`}
            rel="noreferrer"
            aria-label="Go To Practice Questions"
          >
            {' '}
            {t('clickHere')}
          </a>
        </StyledNeedHelpSentence>
      )}
    </StyledLButtonsWrap>
  );
};

export default StepButtons;
