import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledSecondaryAccountHolderWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledContentWrap = styled.div<{ gap?: number }>`
  padding: 40px 40px 8px;
  background: #ffffff;
  border-radius: 0 0 16px 16px;
  height: 100%;
  gap: ${(props) => (props.gap ? `${props.gap}px` : '0px')};

  @media ${device.desktopMinWidth} {
    display: flex;
  }

  @media ${device.tablet} {
    padding: 40px 24px 8px;
  }

  @media ${device.mobileMaxWidth} {
    padding: 40px 16px 8px;
  }
`;

export const StyledHalfContentWrap = styled.div`
  flex: 1;
`;

export const StyledInputWrap = styled.div<{ isHalfWidth?: boolean, hasMargin?: boolean }>`
  ${(props) => props.isHalfWidth && 'display:inline-block;'};
  width:  ${(props) => (props.isHalfWidth ? 'calc(50% - 10px)' : '100%')};
  margin-right:  ${(props) => (props.isHalfWidth && props.hasMargin && !props.theme.isRTL && '20px')};
  margin-left:  ${(props) => (props.isHalfWidth && props.hasMargin && props.theme.isRTL && '20px')};
  margin-bottom:24px;

  label {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #5c5c5c;
    margin: 0 0 4px;
  }

  &:last-of-type {
    margin-right:  ${(props) => (!props.theme.isRTL && 0)};
    margin-left:  ${(props) => (props.theme.isRTL && 0)};
  }

  @media ${device.mobileMaxWidth} {
    width: 100%;
    display: inline-block;
  }
`;

export const StyledRadioGroupWrapper = styled.div`
  margin-bottom: 32px;
`;
