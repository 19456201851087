import BlurredPopUpCard from 'containers/BlurredPopUpCard';
import { useAppSelector } from 'store';
import { Navigate } from 'react-router-dom';
import BackdropSpinner from 'components/BackdropSpinner';
import { StyledDashboardWrap } from './style';

const Dashboard = (): JSX.Element | null => {
  const activeAccountNumber = useAppSelector((state) => state.pageState.activeAccountNumber);

  if (activeAccountNumber === '') return <BackdropSpinner open />;
  if (parseInt(activeAccountNumber, 10) > 0) {
    return <Navigate to="/account-list" />;
  }

  return (
    <StyledDashboardWrap>
      <BlurredPopUpCard />
    </StyledDashboardWrap>

  );
};

export default Dashboard;
