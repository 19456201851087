import * as React from "react";
import { SVGProps } from "react";
const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#Plus_svg__a)">
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2Z" fill="#013B81" />
    </g>
    <defs>
      <clipPath id="Plus_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPlus;
