import { useTranslation } from 'react-i18next';
import { StyledBanner } from './style';

const JointWelcomeBanner = (): JSX.Element => {
  const { t } = useTranslation('registrationForm');

  return (
    <StyledBanner>
      <div>{t('jointAccountRegistration')}</div>
      <div>{t('accountRegistrationBannerContent')}</div>
    </StyledBanner>
  );
};

export default JointWelcomeBanner;
