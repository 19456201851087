import * as React from "react";
import { SVGProps } from "react";
const SvgError = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#Error_svg__a)">
      <path
        d="M16 8A8 8 0 1 1-.001 8 8 8 0 0 1 16 8ZM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4Zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
        fill="#E92C2C"
      />
    </g>
    <defs>
      <clipPath id="Error_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgError;
