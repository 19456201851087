/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppSelector, useAppDispatch } from 'store';
import { nextStep, assignStep } from 'containers/CreateAccountForm/slices';
import StepButtons from 'containers/CreateCorporateAccountForm/components/StepButtons';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import ErrorHandler from 'utils/ErrorHandler';
import { getSanctionedCountryList } from 'api/v1/user';
import {
  getAccountByID,
  getRejectMessages,
  updateCorporateAccount
} from 'api/v1/account';
import ContactPersonDetails from 'containers/CreateCorporateAccountForm/components/ContactPersonDetails';
import PreferenceExperience from 'containers/CreateCorporateAccountForm/components/PreferenceExperience';
import ShareholdersAndDirectors from 'containers/CreateCorporateAccountForm/components/ShareholdersAndDirectors';
import CompanyDetails from 'containers/CreateCorporateAccountForm/components/CompanyDetails';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCreateCorporateAccountFlow } from 'store/context/hooks';
import {
  CORPORATE_ACCOUNT_STEPS,
  CreateCorporateAccountSteps,
  Countries
} from 'constant/createAccount';
import BackdropSpinner from 'components/BackdropSpinner';
import CircularProgress from '@mui/material/CircularProgress';
import PopUp from 'components/PopUp';
import Button from '@mui/material/Button';
import { StyledBtnWrap } from 'containers/SelectAccountTypePanel/style';
import { isEmpty, get } from 'lodash';
import {
  RejectWarningUploadOneByOne
} from 'containers/CreateAccountForm/utils';
import {
  setAccountStatus
} from 'containers/Pages/slices';
import { DOMAIN_CONFIG } from 'constant/domainConfig';
import TermsAndConditions from './components/TermsAndConditions';
import ConfirmId from './components/ConfirmId';
import { StyledCreateAccountFrom, StyledLoadingContainer } from './style';

interface FormInfo {
  [key: string]: string | number;
}

interface IncompleteData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface RejectDataProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface RejectMessageProps {
  [key: string]: {
    warning_message: string;
    warning_value: string | number | boolean;
  };
}
interface RejectStatusProps {
  [key: string]: {
    valid: boolean;
    isWarning: boolean;
  };
}

interface FieldResult {valid: boolean; isWarning: boolean}

const CreateCorporateAccountForm = (): JSX.Element => {
  const [initLoading, setInitLoading] = useState(true);
  const currentStep = useAppSelector((state) => state.createAccountState.step);
  const dispatch = useAppDispatch();
  const methods = useForm({ mode: 'all' });
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [popupErr, setPopUpErr] = useState<string>('');
  const [searchParams] = useSearchParams();
  const selectedCountry = localStorage.getItem('registeredCountry');
  const {
    steps,
    rejectStepStatus,
    rejectWarningFields,
    updateSteps,
    updateFlow,
    updateRejectStepStatus,
    updateRejectWarningFields,
    updateSanctionedCountriesInfo
  } = useCreateCorporateAccountFlow();

  const navigator = useNavigate();
  const accountID = String(localStorage.getItem('accountID'));

  const initIncompleteFlow = (incompleteData: IncompleteData): void => {
    const dataObj = { ...incompleteData };
    const curFlow = dataObj?.company?.country_of_company === Countries.AUS ? 'ASIC' : 'VFSC';
    const assignedStep = dataObj.form_step;
    let tmpStep;
    updateFlow(curFlow);

    if (curFlow === 'ASIC') {
      updateSteps(CORPORATE_ACCOUNT_STEPS.ASIC);
      tmpStep = CORPORATE_ACCOUNT_STEPS.ASIC;
    } else {
      updateSteps(CORPORATE_ACCOUNT_STEPS.VFSC);
      tmpStep = CORPORATE_ACCOUNT_STEPS.VFSC;
    }

    Object.entries(dataObj).forEach(([key, value]) => {
      switch (key) {
        case 'form_step':
        case 'id':
        case 'account_id':
        case 'workflow_execution_id':
          break;
        case 'trading_platform': {
          const isInitial = !dataObj.funding_currency && !dataObj.leverage;
          methods.setValue(key, isInitial ? null : value);
          break;
        }
        case 'terms_and_conditions': {
          const tncObj = JSON.parse(String(value));
          if (tncObj !== undefined && tncObj !== null) {
            Object.entries(tncObj).forEach(([tncKey, tncVal]) => methods.setValue(tncKey, tncVal));
          }
          break;
        }
        default: {
          methods.setValue(key, value);
          break;
        }
      }
    });
    const stepNum = tmpStep?.indexOf(String(assignedStep));

    dispatch(assignStep({ step: stepNum }));
  };

  const initRejectFlow = (
    RejectData: RejectDataProps,
    RejectMessage: RejectMessageProps
  ): void => {
    const curFlow = RejectData?.company?.country_of_company === Countries.AUS ? 'ASIC' : 'VFSC';
    let updateCurrentStep = false;
    let stepNum = 0;
    let tmpStep;
    updateFlow(curFlow);

    if (curFlow === 'ASIC') {
      updateSteps(CORPORATE_ACCOUNT_STEPS.ASIC);
      tmpStep = CORPORATE_ACCOUNT_STEPS.ASIC;
    } else {
      updateSteps(CORPORATE_ACCOUNT_STEPS.VFSC);
      tmpStep = CORPORATE_ACCOUNT_STEPS.VFSC;
    }

    Object.keys(RejectData).forEach((eachKey) => {
      if (
        eachKey !== 'form_step'
        && eachKey !== 'id'
        && eachKey !== 'email'
        && eachKey !== 'terms_and_conditions'
      ) {
        methods.setValue(eachKey, RejectData[eachKey]);
      }
      if (eachKey === 'terms_and_conditions') {
        const tncObj = JSON.parse(String(RejectData.terms_and_conditions));
        if (tncObj !== undefined && tncObj !== null) {
          Object.keys(tncObj).forEach((eachTerm) => {
            methods.setValue(eachTerm, tncObj[eachTerm]);
          });
        }
      }
    });
    tmpStep?.forEach((item, index) => {
      if (
        RejectMessage[item]
        && !updateCurrentStep
        && !isEmpty(RejectMessage[item])
      ) {
        updateCurrentStep = true;
        stepNum = index;
      }
    });
    dispatch(assignStep({ step: stepNum }));
  };

  // Common useEffect
  useEffect(() => {
    dispatch(assignStep({ step: 0 }));
    methods.setValue('account_type', 'corporate');
    methods.setValue('country', selectedCountry);
    methods.setValue('company.country_of_company', selectedCountry);

    const currentFlow = selectedCountry === Countries.AUS ? 'ASIC' : 'VFSC';
    updateFlow(currentFlow);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  // Reject flow
  useEffect(() => {
    if (searchParams.get('status') !== 'Reject') return;
    if (accountID) {
      const requestOne = getAccountByID(accountID);
      const requestTwo = getRejectMessages(accountID);
      Promise.all([requestOne, requestTwo])
        .then((res) => {
          initRejectFlow(res[0].data, res[1].data);
          updateRejectWarningFields(res[1].data);
        })
        .finally(() => {
          setInitLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    const getNewPath = (path: string, key: string): string => {
      if (path) {
        if (key === 'warning_value') return path;
        return `${path}.${key}`;
      }
      return key;
    };

    const processFields = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fields: { [key: string]: any },
      step: string,
      obj: RejectStatusProps,
      path = ''
    ): FieldResult | undefined => {
      const newObj = { ...obj };

      const keys = Object.keys(fields);
      let result;
      for (let k = 0; k < keys.length; k += 1) {
        const key = keys[k];
        const value = fields[key];
        const newPath = getNewPath(path, key);
        if (Array.isArray(value) && key !== 'warning_value' && key !== 'warning_message') {
          for (let index = 0; index < value.length; index += 1) {
            result = processFields(value[index], step, newObj, `${newPath}[${index}]`);
            if (result) return result;
          }
        } else if (typeof value === 'object' && value !== null && key !== 'warning_value' && key !== 'warning_message') {
          if (value.warning_value) {
            if (get(methods.getValues(), newPath) === value.warning_value) {
              if (rejectStepStatus[step] && !rejectStepStatus[step].isWarning) {
                newObj[step] = { valid: rejectStepStatus[step].valid, isWarning: true };
                return newObj[step];
              }
              if (!rejectStepStatus[step]) {
                newObj[step] = { valid: true, isWarning: true };
                return newObj[step];
              }
            }
          } else {
            result = processFields(value, step, newObj, newPath);
            if (result) return result;
          }
        }
        if (result) break;
      }
      return result;
    };

    const processFiles = (rejection:RejectWarningUploadOneByOne):boolean => {
      let warning = false;
      Object.keys(rejection).forEach((key) => {
        if (key === 'company') {
          const rejectArray = rejection[key]?.documents;
          const currentfiles = methods.getValues(key);
          if (!isEmpty(rejectArray) && !isEmpty(currentfiles)) {
            rejectArray?.forEach((doc: { warning_value: string; type: string}) => {
              const filterData = currentfiles?.documents?.filter((x : { type: string }) => x.type === doc.type);
              if (!isEmpty(filterData) && doc.warning_value === filterData[filterData.length - 1].file_path) {
                warning = true;
              }
            });
          }
        } else if (key === 'shareholders') {
          const rejectArray = rejection[key];
          const currentfiles = methods.getValues(key);
          if (!isEmpty(rejectArray) && !isEmpty(currentfiles)) {
            rejectArray?.forEach((user) => {
              const currentUser = currentfiles.filter((x: { shareholder_id: number; }) => x.shareholder_id === user?.shareholder_id);
              user?.documents.forEach((doc: { warning_value: string; type: string}) => {
                const filterData = currentUser[0]?.documents?.filter((x : { type: string }) => x.type === doc.type);
                if (!isEmpty(filterData) && doc.warning_value === filterData[filterData.length - 1].file_path) {
                  warning = true;
                }
              });
            });
          }
        } else if (key === 'directors') {
          const rejectArray = rejection[key];
          const currentfiles = methods.getValues(key);
          if (!isEmpty(rejectArray) && !isEmpty(currentfiles)) {
            rejectArray?.forEach((user) => {
              const currentUser = currentfiles.filter((x: { director_id: number; }) => x.director_id === user?.director_id);
              user?.documents.forEach((doc: { warning_value: string; type: string}) => {
                const filterData = currentUser[0]?.documents?.filter((x : { type: string }) => x.type === doc.type);
                if (!isEmpty(filterData) && doc.warning_value === filterData[filterData.length - 1].file_path) {
                  warning = true;
                }
              });
            });
          }
        } else if (key === 'contactPerson') {
          const rejectArray = rejection[key]?.documents;
          const currentfiles = methods.getValues('documents');
          if (!isEmpty(rejectArray) && !isEmpty(currentfiles)) {
            rejectArray?.forEach((doc: { warning_value: string; type: string}) => {
              const filterData = currentfiles?.filter((x : { type: string }) => x.type === doc.type);
              if (!isEmpty(filterData) && doc.warning_value === filterData[filterData.length - 1].file_path) {
                warning = true;
              }
            });
          }
        }
      });
      return warning;
    };

    if (searchParams.get('status') !== 'Reject') return;
    const obj = {} as RejectStatusProps;
    steps.forEach((step) => {
      if (rejectWarningFields[step] && !isEmpty(rejectWarningFields[step])) {
        if (step === 'corporate_confirm_id') {
          const reject = rejectWarningFields.corporate_confirm_id[0] as RejectWarningUploadOneByOne;
          obj[step] = { valid: true, isWarning: processFiles(reject) };
        } else {
          for (let j = 0; j < rejectWarningFields[step].length; j += 1) {
            obj[step] = processFields(rejectWarningFields[step][j], step, obj) || { valid: true, isWarning: false };
          }
        }
      } else {
        obj[step] = rejectStepStatus[step]
          ? { valid: rejectStepStatus[step].valid, isWarning: false }
          : { valid: true, isWarning: false };
      }
    });
    updateRejectStepStatus(obj);
  }, [steps, rejectWarningFields]);

  // Incomplete/Normal flow
  useEffect(() => {
    // setInitLoading(false);
    if (searchParams.get('status') === 'Reject') return;
    getAccountByID(accountID)
      .then((res) => {
        const resData = res.data;
        // console.log('🚀 ~ file: index.tsx:260 ~ .then ~ resData:', resData);
        if (res.status === 200) {
          initIncompleteFlow(resData);
        }
        return resData;
      })
      .then((resData) => {
        let currentFlow;
        if (resData?.company?.country_of_company) {
          currentFlow = resData?.company?.country_of_company === Countries.AUS ? 'ASIC' : 'VFSC';
        } else {
          currentFlow = selectedCountry === Countries.AUS ? 'ASIC' : 'VFSC';
        }

        if (currentFlow === 'ASIC') {
          updateSteps(CORPORATE_ACCOUNT_STEPS.ASIC);
        }
        if (currentFlow === 'VFSC') {
          updateSteps(CORPORATE_ACCOUNT_STEPS.VFSC);
        }

        getSanctionedCountryList().then((res) => {
          if (res.data) updateSanctionedCountriesInfo(res.data);
        }).finally(() => {
          setInitLoading(false);
        });
      });
  }, []);

  const renderForm = (): JSX.Element => {
    switch (steps[currentStep]) {
      case CreateCorporateAccountSteps.CorporateContactPersonDetails:
        return <ContactPersonDetails />;
      case CreateCorporateAccountSteps.CorporateCompanyDetails:
        return <CompanyDetails />;
      case CreateCorporateAccountSteps.CorporateShareholdersAndDirectors:
        return <ShareholdersAndDirectors />;
      case CreateCorporateAccountSteps.CorporatePreferenceAndExperience:
        return <PreferenceExperience />;
      case CreateCorporateAccountSteps.CorporateTermsAndConditions:
        return <TermsAndConditions />;
      case CreateCorporateAccountSteps.CorporateConfirmID:
        return <ConfirmId />;
      default:
        return <div />;
    }
  };

  const updatePersonalAccount = (
    personalData: FormInfo,
    accountId: string,
    formStep: string,
    curStep: string
  ): void => {
    setSubmitLoading(true);

    ErrorHandler(updateCorporateAccount(personalData, accountId, formStep, curStep), dispatch).then((res) => {
      if (res && res.status === 200) {
        if (currentStep === steps.length - 1) {
          navigator('/create-account/corporate-account/thank-you', { replace: true });
          localStorage.removeItem('accountID');
          dispatch(setAccountStatus({ setAccountStatus: 'Pending' }));
          return;
        }
        dispatch(nextStep());
      }
    }).finally(() => {
      setSubmitLoading(false);
    });
  };

  const onSubmit: SubmitHandler<React.SyntheticEvent> = (e) => {
    e.preventDefault();
    const data = methods.getValues();

    // const data = methods.getValues();
    if (searchParams.get('status') === 'Reject') {
      ['id_front', 'id_back', 'proof_of_address', 'other_documents'].forEach(
        (key) => {
          if (typeof data[key] === 'string') delete data[key];
        }
      );
    }

    const submitData = {
      ...data,
      entry_url: `${DOMAIN_CONFIG.origin}/rg227/${
        localStorage.getItem('accountID') || ''
      }`
    };

    const theNextStep = currentStep === steps.length - 1
      ? steps[currentStep]
      : steps[currentStep + 1];
    if (accountID) {
      // PUT
      updatePersonalAccount(
        submitData,
        accountID,
        theNextStep,
        steps[currentStep]
      );
    }
  };

  return (
    <StyledCreateAccountFrom
      className="StyledCreateAccountFrom"
    >
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          {initLoading ? (
            <StyledLoadingContainer>
              <CircularProgress className="custom-progress" />
            </StyledLoadingContainer>
          ) : (
            <>
              {renderForm()}
              <StepButtons isSubmitting={submitLoading} />
            </>
          )}
        </form>
      </FormProvider>

      <PopUp
        content={popupErr}
        openModal={popupErr !== ''}
        onClose={() => setPopUpErr('')}
      >
        <StyledBtnWrap>
          <Button aria-label="Pop Up OK" onClick={() => setPopUpErr('')}>
            ok
          </Button>
        </StyledBtnWrap>
      </PopUp>
      <BackdropSpinner open={submitLoading} />
    </StyledCreateAccountFrom>
  );
};

export default CreateCorporateAccountForm;
