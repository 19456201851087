import IconComponent from 'components/Icons';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogCloseButton,
  StyledDialogContent,
  StyledDialogHeader
} from './style';

type DialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  contentSlot: JSX.Element;
  actionSlot?: JSX.Element;
};

const Dialog = ({
  isOpen,
  handleClose,
  title,
  contentSlot,
  actionSlot
}: DialogProps): JSX.Element => (
  <StyledDialog open={isOpen} onClose={handleClose}>
    <StyledDialogHeader>
      <h5 aria-label="Dialog Title">{title}</h5>
      <StyledDialogCloseButton onClick={handleClose}>
        <IconComponent name="Close" aria-label="Dialog Close Icon" />
      </StyledDialogCloseButton>
    </StyledDialogHeader>
    <StyledDialogContent aria-label="Dialog Content">{contentSlot}</StyledDialogContent>
    {actionSlot && <StyledDialogActions>{actionSlot}</StyledDialogActions>}
  </StyledDialog>
);

export default Dialog;
