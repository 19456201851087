/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  ThemeProvider as MaterialThemeProvider,
  createTheme as materialCreateTheme,
  THEME_ID
} from '@mui/material/styles';
import { GlobalStyle } from 'styles/global_css';
// import { io } from 'socket.io-client';
import { connect } from 'stomp';
import { useAppDispatch, useAppSelector } from 'store';
import MuiButton from '@mui/material/Button';
import Button from 'components/Button';
import { clearApiErrors, setGlobalPopUp } from 'containers/Pages/slices';
import PopUp from 'components/PopUp';
import { useLanguage } from 'hooks/useLanguage';
import Lang from 'utils/language.util';
import { useTranslation } from 'react-i18next';
import GTMIframe from 'components/GTMIframe';
import pJson from '../package.json';
import { lightTheme, primaryTheme } from './styles/theme';
import Router from './Router';

const materialTheme = materialCreateTheme();

const StyledAppContainer = styled.main`
  height: 100vh;
`;

const theme = 'primary';

const StyledContainer = styled.div`
  height: 100vh;
  display: flex;
`;

export const StyledBtnWrap = styled.div`
  > button {
    &.MuiButtonBase-root {
      margin-right: 0;
      text-transform: lowercase;
      font-family: 'Noto Sans';
      min-width: unset;
    }
  }
`;

const App = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const globalPopUp = useAppSelector((state) => state.pageState.globalPopUp);
  const apiErrors = useAppSelector((state) => state.pageState.apiErrors);
  const { t } = useTranslation('registrationForm');
  useLanguage();

  useEffect(() => {
    connect(dispatch);
  }, [dispatch]);

  useEffect(() => {
    console.info(
      `%c Current Version: ${pJson.version}`,
      'background: #222; color: #bada55'
    );
  }, []);

  // useEffect(() => {
  //   const socket = io(ENDPOINT);
  //   socket.on('connect', () => {
  //     console.log('connected');
  //   });
  //   socket.on('disconnect', () => {
  //     console.log('disconnected');
  //   });
  //   // handle other events here
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  return (
    <StyledAppContainer>
      <ThemeProvider theme={theme === 'primary' ? primaryTheme : lightTheme}>
        <MaterialThemeProvider theme={{ [THEME_ID]: materialTheme }}>
          <GlobalStyle
            isRTL={Lang.isRTL()}
          />
          <StyledContainer>
            <Router />
          </StyledContainer>
          <PopUp
            content={globalPopUp.msg}
            openModal={globalPopUp.isModalOpen}
            onClose={() => dispatch(setGlobalPopUp({ isModalOpen: false, msg: '' }))}
          >
            <StyledBtnWrap>
              <MuiButton
                aria-label="Pop Up OK"
                onClick={() => dispatch(setGlobalPopUp({ isModalOpen: false, msg: '' }))}
              >
                {t('ok')}
              </MuiButton>
            </StyledBtnWrap>
          </PopUp>
          {/* [TODO]: temp popup for api error */}
          <PopUp
            content={apiErrors[apiErrors.length - 1]?.message ?? ''}
            openModal={apiErrors.length > 0}
            onClose={() => dispatch(clearApiErrors())}
          >
            <Button
              aria-label="API Error OK"
              onClick={() => dispatch(clearApiErrors())}
            >
              {t('ok')}
            </Button>
          </PopUp>
        </MaterialThemeProvider>
      </ThemeProvider>
      <GTMIframe />
    </StyledAppContainer>
  );
};
export default App;
