import { SVGProps } from 'react';

const SvgWarningCircleFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='64'
    height='64'
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M32 4C16.6 4 4 16.6 4 32C4 47.4 16.6 60 32 60C47.4 60 60 47.4 60 32C60 16.6 47.4 4 32 4ZM29.8 16H34.2V38H29.8V16ZM32 50C30.4 50 29 48.6 29 47C29 45.4 30.4 44 32 44C33.6 44 35 45.4 35 47C35 48.6 33.6 50 32 50Z'
      fill='currentColor'
    />
  </svg>
);

export default SvgWarningCircleFilled;
