import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledFieldWrap = styled.div`
  width: 100%;
  margin-bottom: 24px;

  label {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #5c5c5c;
    margin: 0 0 4px;
  }

  &:last-of-type {
    margin-right:  ${(props) => (!props.theme.isRTL && 0)};
    margin-left:  ${(props) => (props.theme.isRTL && 0)};
  }

  &.zip-code, &.state {
    display: inline-flex;
    width: calc(50% - 8px);
    > div {
      width: 100%;
    }
  }

  &.state {
    margin-right: 16px;
  }

  &.share-percentage {
    & .input-wrap {
      display: block;
      > input {
        width: 100px;
      }
    }
  }

  @media ${device.mobileMaxWidth} {
    width: 100%;
    display: inline-block;
  }
`;

export const StyledSharePercentage = styled.div`
  display: inline-flex;
  align-items: center;

  > span {
    margin: 0 4px;
    color: #BBBBBB;
  }
`;

export const StyledSharePercentageTitle = styled.div`
  display: flex;
  align-items: center;
`;
