import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import {
  PaymentDT,
  PaymentMOB,
  PaymentTAB
} from 'assets/blurredPages';
import { StyledBlurredPageWrap } from 'pages/BlurredPage/style';

export const StyledVerifyPaymentMethodWrap = styled(StyledBlurredPageWrap)`
  ::before {
   background-image: url(${PaymentDT});
   @media ${device.tablet} {
     background-image: url(${PaymentTAB});
    }
   @media ${device.mobileMaxWidth} {
     background-image: url(${PaymentMOB});
   }
  }
`;
