import { SVGProps } from 'react';

const SvgCheckCircleFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='64'
    height='64'
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_1044_28583)'>
      <path
        d='M32.0002 5.33331C17.2802 5.33331 5.3335 17.28 5.3335 32C5.3335 46.72 17.2802 58.6666 32.0002 58.6666C46.7202 58.6666 58.6668 46.72 58.6668 32C58.6668 17.28 46.7202 5.33331 32.0002 5.33331ZM26.6668 45.3333L13.3335 32L17.0935 28.24L26.6668 37.7866L46.9068 17.5466L50.6668 21.3333L26.6668 45.3333Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1044_28583'>
        <rect width='64' height='64' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCheckCircleFilled;
