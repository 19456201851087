import cx from 'classnames';
import React from 'react';
import { CommonProps } from 'type/component';
import {
  StyledWelcomeBanner, StyledContent, StyledWelcomeTitle, StyledChildrenWrap, StyledMainWrap
} from './style';

interface WelcomeBannerProps extends CommonProps {
  content?:string;
  greeting?:string;
  title?:string | JSX.Element;
  id?:string;
}

const PersonalWelcomeBanner = (props:WelcomeBannerProps):JSX.Element => {
  const {
    className, content, children, title, greeting, id = ''
  } = props;

  return (
    <StyledWelcomeBanner
      className={cx('lp-welcome-banner', className)}
      aria-label={`${id} Welcome Banner`}
    >

      <StyledMainWrap className="StyledMainWrap">
        <div
          aria-label={`${id} Welcome Banner helloTxt`}
          className="helloTxt"
        >
          {greeting}
        </div>
        <StyledWelcomeTitle aria-label={`${id} Welcome Banner Title`}>
          {title}
        </StyledWelcomeTitle>

        <StyledContent aria-label={`${id} Welcome Banner Content`}>
          {content}
        </StyledContent>
      </StyledMainWrap>
      <StyledChildrenWrap aria-label={`${id} Welcome Banner Children`}>
        {children}
      </StyledChildrenWrap>

    </StyledWelcomeBanner>
  );
};

export default PersonalWelcomeBanner;
