import * as React from "react";
import { SVGProps } from "react";
const SvgDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.47 4.47a2.517 2.517 0 0 1 1.78-.737h1.917a2.517 2.517 0 0 1 2.516 2.517v1.917a2.517 2.517 0 0 1-2.516 2.516H6.25a2.517 2.517 0 0 1-2.517-2.516V6.25c0-.668.266-1.308.738-1.78Zm1.78.463A1.317 1.317 0 0 0 4.933 6.25v1.917A1.317 1.317 0 0 0 6.25 9.483h1.917a1.317 1.317 0 0 0 1.316-1.316V6.25a1.317 1.317 0 0 0-1.316-1.317H6.25Zm7.804-.463a2.517 2.517 0 0 1 1.78-.737h1.916a2.517 2.517 0 0 1 2.517 2.517v1.917a2.516 2.516 0 0 1-2.517 2.516h-1.917a2.517 2.517 0 0 1-2.516-2.516V6.25c0-.668.265-1.308.737-1.78Zm1.78.463a1.317 1.317 0 0 0-1.317 1.317v1.917a1.317 1.317 0 0 0 1.316 1.316h1.917a1.317 1.317 0 0 0 1.317-1.316V6.25a1.317 1.317 0 0 0-1.317-1.317h-1.917ZM4.47 14.053a2.517 2.517 0 0 1 1.78-.736h1.916a2.517 2.517 0 0 1 2.516 2.516v1.917a2.517 2.517 0 0 1-2.516 2.517H6.25a2.517 2.517 0 0 1-2.517-2.517v-1.917c0-.667.266-1.307.738-1.78Zm1.78.464a1.316 1.316 0 0 0-1.318 1.316v1.917a1.317 1.317 0 0 0 1.317 1.317h1.917a1.317 1.317 0 0 0 1.316-1.317v-1.917a1.317 1.317 0 0 0-1.316-1.316H6.25Zm10.54-1.2a.6.6 0 0 1 .6.6v2.275h2.276a.6.6 0 1 1 0 1.2h-2.275v2.275a.6.6 0 1 1-1.2 0v-2.275h-2.275a.6.6 0 0 1 0-1.2h2.275v-2.275a.6.6 0 0 1 .6-.6Z"
      fill="#fff"
    />
  </svg>
);
export default SvgDashboard;
