/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { navigationConfig, navigationConfigWithActiveAccount } from 'mock/sideMenu/navigationConfigs';
import { setActiveAccountNumber } from 'containers/Pages/slices';
import IconComponent from 'components/Icons';
import DropDown from 'components/DropDown';
import { useAppSelector, useAppDispatch } from 'store';
import { useTranslation } from 'react-i18next';
import Drawer from '@mui/material/Drawer';
import { useMediaQuery } from 'react-responsive';
import { device } from 'styles/deviceStyle';
import { getAccounts } from 'api/v1/account';
import { useAuth } from 'store/context/hooks';
import { useTheme } from 'styled-components';
import { LANGUAGE_LIST, Languages } from 'constant/language';
import LanguageIcon from '@mui/icons-material/Language';
import AccountStatusHandler from 'utils/AccountStatusHandler';
import useSWR from 'swr';
import SideMenuItem from './components/SideMenuItem';
import SideMenuGroup from './components/SideMenuGroup';
import {
  StyledMenuHeader,
  StyledContent,
  StyledMenuContainer,
  StyledMenuIconWrap,
  StyledAvatarContainer,
  StyledBorderWrap,
  StyledAvatarNameWrap,
  StyledMenuBlock,
  StyleMobileLangWrap,
  StyledAvatar
} from './styled';

import { toggleSideMenu, assignSideMenu } from './slices';

const SideMenu = (): JSX.Element => {
  const { t } = useTranslation('sidebar');
  const { i18n } = useTranslation();
  const navigator = useNavigate(); // use to change SPA page
  const location = useLocation();
  const isMenuOpen = useAppSelector((state) => state.sideMenuState.isOpen);
  const activeAccountNumber = useAppSelector((state) => state.pageState.activeAccountNumber);
  const isDesktop = useMediaQuery({ query: device.desktopMinWidth });
  const isMobile = useMediaQuery({ query: device.mobileMaxWidth });
  const [isListOpen, setIsListOpen] = useState(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { handleLogout } = useAuth();
  const {
    data, error, isLoading
  } = useSWR(['/accounts', 'get'], getAccounts, { dedupingInterval: 2000, revalidateOnFocus: false });

  const clickLogoutHandler = (): void => {
    handleLogout();
  };

  const handleCloseMenu = (): void => {
    if (isDesktop) {
      dispatch(assignSideMenu(!isMenuOpen));
      return;
    }

    if (isLangMenuOpen) {
      setIsLangMenuOpen(false);
    } else {
      dispatch(toggleSideMenu());
    }
  };

  const toggleAvatarIcon = (): void => {
    if (!isMenuOpen) {
      handleCloseMenu();
    }
  };

  useEffect(() => {
    if (data && !isLoading) {
      dispatch(
        setActiveAccountNumber(
          data.data.filter((el: { [key: string]: string }) => el?.status === 'Active' || el?.status === 'Auto_Active').length.toString()
        )
      );

      const target: { [key: string]: { [key: string]: string } } = {};
      data?.data?.forEach((el: { [key: string]: string }) => {
        target[`${el.type}_${el.status}`] = {
          accountID: el.id,
          formStep: el.form_step
        };
      });
      AccountStatusHandler(target, dispatch);
    }
    if (error) dispatch(setActiveAccountNumber('0'));
  }, [isLoading, data, error]);

  const customMenu = [
    <button type="button" onClick={clickLogoutHandler}>
      <IconComponent name="LogOut" aria-label="Logout icon" />
      <span aria-label="Logout">{t('logout')}</span>
    </button>
  ];

  const changeLangHandler = (newLang: string): void => {
    localStorage.setItem('language', newLang);
    navigator(0);
  };

  const renderLanguageItems = (): React.ReactNode => Object.entries(LANGUAGE_LIST).map(([code, name]) => (
    <StyledMenuBlock key={code} aria-label="side menu language" className="lang-menu">
      <SideMenuItem
        handleClick={() => changeLangHandler(code)}
        active={false}
      >
        <StyleMobileLangWrap>
          {name}
        </StyleMobileLangWrap>
      </SideMenuItem>
    </StyledMenuBlock>
  ));

  const handleDesktopMenuIcon = (): string => {
    if (theme.isRTL) return isMenuOpen ? 'MenuExpand' : 'MenuShrink';
    return isMenuOpen ? 'MenuShrink' : 'MenuExpand';
  };

  const renderConfigItems = (config: any): JSX.Element => config.map((item: any) => {
    switch (item.type) {
      case 'group':
        return (
          <StyledMenuBlock key={item.id} aria-label="side menu group">
            <SideMenuGroup
              groupInfo={item}
              handleCloseMenu={handleCloseMenu}
            />
          </StyledMenuBlock>
        );

      default:
        // item
        return (
          <StyledMenuBlock key={item.id} aria-label="side menu item">
            <SideMenuItem
              handleClick={() => {
                if (!isDesktop) dispatch(toggleSideMenu());
                navigator(`${item.url}`);
              }}
              active={location.pathname === item.url}
              icon={item.icon}
              titleAriaLabel={item.id}
            >
              {t(item.id)}
            </SideMenuItem>
          </StyledMenuBlock>
        );
    }
  });

  const renderSideMenuItems = (): JSX.Element | null => {
    if (activeAccountNumber === '') return null;
    return renderConfigItems(parseInt(activeAccountNumber, 10) > 0 ? navigationConfigWithActiveAccount : navigationConfig);
  };

  const dropDownHandler = (e: boolean): void => {
    setIsListOpen(e);
  };

  return (
    <StyledMenuContainer
      aria-label="side menu container"
      open={isMenuOpen}
      as={isDesktop ? '' : Drawer}
      anchor={theme.isRTL ? 'right' : 'left'}
    >
      <section>
        <StyledBorderWrap>
          <StyledMenuHeader open={isMenuOpen}>
            <StyledMenuIconWrap
              aria-label="click side menu icon button"
              isMenuOpen={isMenuOpen}
              onClick={handleCloseMenu}
            >
              <div>
                {isDesktop
                  ? <IconComponent name={handleDesktopMenuIcon()} aria-label="Hamburger icon" />
                  : (
                    <IconComponent
                      name={isMenuOpen && !isDesktop ? 'Close' : 'Menu'}
                      aria-label={isMenuOpen && !isDesktop ? 'Close icon' : 'Hamburger icon'}
                    />
                  )}
              </div>
            </StyledMenuIconWrap>
          </StyledMenuHeader>

          <StyledContent>
            {isLangMenuOpen
              ? renderLanguageItems()
              : renderSideMenuItems()}
            {isMobile && !isLangMenuOpen && (
              <StyledMenuBlock key="language" aria-label="side menu language" className="lang-menu">
                <SideMenuItem
                  handleClick={() => {
                    setIsLangMenuOpen(true);
                  }}
                  active={false}
                  icon={<LanguageIcon aria-label="language icon" />}
                >
                  <StyleMobileLangWrap>
                    {LANGUAGE_LIST[i18n.language as Languages]}
                  </StyleMobileLangWrap>
                  <IconComponent name="TriangleDown" />
                </SideMenuItem>
              </StyledMenuBlock>
            )}
          </StyledContent>

          <StyledAvatarContainer isMenuOpen={isMenuOpen} isListOpen={isListOpen} onClick={toggleAvatarIcon}>
            <DropDown
              customMenu={customMenu}
              handleChangeState={dropDownHandler}
              ifOpenMenu={isListOpen && isMenuOpen}
            >
              <StyledAvatar isMenuShow={isMenuOpen}>
                <IconComponent name="Avatar" aria-label="User icon" />
              </StyledAvatar>
              {isMenuOpen && (
                <StyledAvatarNameWrap>
                  <span aria-label="User first name">{localStorage.getItem('firstName')}</span>
                  <IconComponent name="TriangleDown" aria-label="User arrow down" />
                </StyledAvatarNameWrap>
              )}
            </DropDown>
          </StyledAvatarContainer>
        </StyledBorderWrap>
      </section>
    </StyledMenuContainer>
  );
};

export default SideMenu;
