import { Button } from '@mui/material';
import React, {
  useState, useEffect, useRef, useCallback
} from 'react';
import { getCategoryVideo } from 'api/v1/video';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import VideoCard from 'components/VideoCard';
import BackdropSpinner from 'components/BackdropSpinner';
import { categories } from '../enumVideoData';
import { VideoItemType } from '../AllVideos';
import {
  StyledSectionContainer,
  StyledCategoryContainer,
  StyledCategoryTitle,
  StyledButton,
  StyledContent,
  StyledHeader,
  StyledHeaderBox,
  StyledVideoContainer,
  StyledLoad
} from './style';

const CategoryVideo = (): JSX.Element => {
  const { t } = useTranslation('videos');
  const navigate = useNavigate();
  const { id } = useParams();
  const [videos, setVideos] = useState<VideoItemType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observe = useRef<IntersectionObserver | null>(null);

  const loadMoreRef = useCallback((node: HTMLParagraphElement) => {
    let updated = false;
    if (observe.current) observe.current.disconnect();
    observe.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (hasMore && !updated) {
          updated = true;
          setCurrentPage((prev) => prev + 1);
        }
      }
    });
    if (node) observe.current.observe(node);
  }, [hasMore]);

  useEffect(() => {
    getCategoryVideo({
      page: currentPage,
      pageSize: 16,
      category: Number(id)
    }).then((res) => {
      if (res.status === 200) {
        if (currentPage === res.data.data[Number(id)].last_page) {
          setHasMore(false);
        }
        setVideos([
          ...videos,
          ...res.data.data[Number(id)].data
        ]);
      }
    });
  }, [currentPage]);

  return (
    <div>
      {isEmpty(videos) ? (
        <BackdropSpinner open />
      ) : (
        <StyledSectionContainer>
          <StyledCategoryContainer>
            <StyledHeader>
              <StyledHeaderBox>
                <h2 aria-label="videos header">{t('videos')}</h2>
              </StyledHeaderBox>
            </StyledHeader>
            <StyledContent>
              <StyledCategoryTitle aria-label={`${t(categories[Number(id)])} title`}>{t(categories[Number(id)])}</StyledCategoryTitle>
              <StyledButton>
                <Button onClick={() => navigate('/training/videos')} aria-label="all videos button">
                  {t('allVideos')}
                </Button>
              </StyledButton>
            </StyledContent>
            <StyledVideoContainer id="videoContainer">
              {videos.map((item: VideoItemType, index: number) => {
                if (videos.length === index + 1) {
                  return (
                    <VideoCard
                      onClick={() => navigate(`/training/videos/category/${item.category_id}/${item.id}`)}
                      key={`${item.id} + ${item.created_at}`}
                      title={item.title}
                      time={item.created_at}
                      imgSrc={item.image_link ? item.image_link : item.imagepath}
                      ref={loadMoreRef}
                    />
                  );
                }
                return (
                  <VideoCard
                    onClick={() => navigate(`/training/videos/category/${item.category_id}/${item.id}`)}
                    key={`${item.id} + ${item.created_at}`}
                    title={item.title}
                    time={item.created_at}
                    imgSrc={item.image_link ? item.image_link : item.imagepath}
                  />
                );
              })}
              { hasMore && <StyledLoad>{t('pullLoadmore')}</StyledLoad>}
            </StyledVideoContainer>
          </StyledCategoryContainer>
        </StyledSectionContainer>
      )}
    </div>
  );
};

export default CategoryVideo;
