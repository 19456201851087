import IconComponent from 'components/Icons';
import { useAppSelector } from 'store';
import {
  StyledSideMenuButton, StyledIconWrap, StyledSideMenuRow, StyledTitleWrap
} from '../styled';

type Props = {
  children: React.ReactNode | string;
  handleClick: () => void;
  active: boolean;
  icon?: string | JSX.Element;
  titleAriaLabel?: string;
}

interface ITrackID {
  [key: string]: string
}

const TrackID:ITrackID = {
  demoAccount: 'Open_Demo_Account'
};

const SideMenuItem = ({
  children,
  handleClick,
  active,
  icon,
  titleAriaLabel
}: Props):JSX.Element => {
  const isMenuOpen = useAppSelector((state) => state.sideMenuState.isOpen);

  return (
    <StyledSideMenuRow isMenuOpen={isMenuOpen}>
      <StyledSideMenuButton
        className="side-menu-btn"
        onClick={handleClick}
        isActive={active}
        hasIcon={!!icon}
        isMenuOpen={isMenuOpen}
        id={TrackID[titleAriaLabel || '']}
      >
        {typeof icon === 'string' && icon ? (
          <StyledIconWrap isMenuOpen={isMenuOpen}>
            <IconComponent name={icon} aria-label={`${titleAriaLabel} icon`} />
          </StyledIconWrap>
        ) : icon}
        <StyledTitleWrap isMenuOpen={isMenuOpen}><span aria-label={titleAriaLabel}>{children}</span></StyledTitleWrap>
      </StyledSideMenuButton>
    </StyledSideMenuRow>
  );
};

export default SideMenuItem;
