import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router';
import ExperienceQuestionForm from 'containers/ExperienceQuestionForm/index';
import { StyledCreateAccountFrom } from 'containers/CreateAccountForm/style';
import Banner from 'containers/Banner';
import { getAccounts } from 'api/v1/account';
import useSWR from 'swr';

const ExperienceQuestion = (): JSX.Element => {
  const location = useLocation();
  const [exceedRG227FailedAttempts, setExceedRG227FailedAttempts] = useState<
    boolean | undefined
  >(undefined);
  const {
    data, isLoading
  } = useSWR(['/accounts', 'get'], getAccounts, { dedupingInterval: 2000, revalidateOnFocus: false });

  useEffect(() => {
    if (data && !isLoading) {
      const pathNames = location.pathname.split('/');
      const accountId = pathNames[2];
      const account = data.data.find(
        (_account: { [key: string]: string }) => _account.id === accountId
      );
      setExceedRG227FailedAttempts(account.is_blocked_by_rg227);
    }
  }, [isLoading, data]);

  // If api call is pending, need to display the skeleton of the page
  return (
    <>
      {exceedRG227FailedAttempts && <Navigate replace to="/account-list" />}
      {!exceedRG227FailedAttempts && (
        <>
          <Banner name="AccountRegistrationBanner" />
          <StyledCreateAccountFrom>
            <ExperienceQuestionForm />
          </StyledCreateAccountFrom>
        </>
      )}
    </>
  );
};

export default ExperienceQuestion;
