import styled from 'styled-components';

export const StyledConfirmedCompany = styled.div`
`;

export const StyledConfirmedId = styled.div`
  margin-top: 24px;
`;

export const StyledUploadWrap = styled.div`
  display:grid;
  column-gap: 20px;
  row-gap: 30px;
  @media screen and (min-width:768px) {
    grid-template-columns: 1fr 1fr;

    }
`;

export const StyledSectionTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1C1C1C;
  margin-bottom: 24px;
`;

export const StyledIdWrap = styled.div<{ lastItem: boolean }>`
  margin-bottom: ${(props) => (props.lastItem ? '0px' : '48px')};

`;
