import { useState, useEffect } from 'react';
import VideoCard from 'components/VideoCard';
import { getVideos } from 'api/v1/video';
import { isEmpty } from 'lodash';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import rightArrow from 'assets/VideoPage/ButtonIcon.svg';
import { useTranslation } from 'react-i18next';
import BackdropSpinner from 'components/BackdropSpinner';
import { categories } from '../enumVideoData';
import {
  StyledHeader,
  StyledHeaderBox,
  StyledContent,
  StyledCategoryContainer,
  StyledCategoryHeader,
  StyledButton,
  StyledCategoryPreview,
  StyledSectionContainer
} from './style';

export interface VideoItemType {
  id: number;
  title: string;
  url?: string;
  description?: string | undefined;
  category_id: number;
  image_link: string;
  created_at: string;
  imagepath: string;
}

export const order = [
  'Trade Ideas',
  'Education',
  'ACY Partnerships',
  'ACY Advantages',
  'Trading Cup',
  'Market News',
  'Platforms'
];

const sortedKeys = Object.keys(categories)
  .sort((a, b) => order.indexOf(categories[a as unknown as number]) - order.indexOf(categories[b as unknown as number]));

const AllVideos = (): JSX.Element => {
  const { t } = useTranslation('videos');
  const navigate = useNavigate();
  const [videos, setVideos] = useState<{[key: string]: VideoItemType[]}>({});

  useEffect(() => {
    getVideos().then((res) => {
      if (res.status === 200) {
        setVideos(res.data.data.categorized);
      }
    });
  }, []);

  return (
    <div>
      {isEmpty(videos) ? (
        <BackdropSpinner open />
      ) : (
        <StyledSectionContainer>
          <StyledHeader>
            <StyledHeaderBox>
              <h2 aria-label="videos header">{t('videos')}</h2>
            </StyledHeaderBox>
          </StyledHeader>
          <StyledContent>
            {sortedKeys.map((myKey: string) => (
              videos[myKey as unknown as number] !== undefined && videos[myKey as unknown as number].length !== 0 && (
              <StyledCategoryContainer key={myKey}>
                <StyledCategoryHeader>
                  <h4 aria-label={`${t(categories[myKey as unknown as number])} title`}>
                    {t(categories[myKey as unknown as number])}
                  </h4>
                  <StyledButton>
                    <Button
                      aria-label={`${t(categories[myKey as unknown as number])} view more button`}
                      onClick={() => navigate(`category/${myKey}`)}
                    >
                      {t('viewMore')}
                      <img src={rightArrow} alt="icon" />
                    </Button>
                  </StyledButton>
                </StyledCategoryHeader>
                <StyledCategoryPreview>
                  {videos[myKey as unknown as number].map((i: VideoItemType) => (
                    <VideoCard
                      onClick={() => navigate(`/training/videos/category/${i.category_id}/${i.id}`)}
                      key={i.id}
                      title={i.title}
                      time={i.created_at}
                      imgSrc={i.image_link ? i.image_link : i.imagepath}
                    />
                  ))}
                </StyledCategoryPreview>
              </StyledCategoryContainer>
              )
            ))}
          </StyledContent>
        </StyledSectionContainer>
      )}
    </div>
  );
};

export default AllVideos;
