export enum TradingPlatform {
  LogixTrader = 'LogixTrader',
  MetaTrader4 = 'MetaTrader4',
  MetaTrader5 = 'MetaTrader5',
}

export enum WHITE_LABELS {
  ubank = 'ubank',
}

export type WhiteLabelConfig = {
  prod: string;
  dev: string;
  realm: string;
};

export type DomainConfigType = {
  realm: string;
  keycloak: string;
  api: string;
  socket: string;
  origin: string;
}
