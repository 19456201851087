import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import {
  ClientListDT,
  ClientListMOB,
  ClientListTAB
} from 'assets/blurredPages';
import { StyledBlurredPageWrap } from 'pages/BlurredPage/style';

export const StyledClientListWrap = styled(StyledBlurredPageWrap)`
  ::before {
    background-image: url(${ClientListDT});
    @media ${device.tablet} {
      background-image: url(${ClientListTAB});
    }
    @media ${device.mobileMaxWidth} {
      background-image: url(${ClientListMOB});
    }
  }
`;
