import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledDemoAccountWrapper = styled.div`
  padding: 40px 64px;

  .form-panel {
    padding-top: 32px;
  }
  
  @media ${device.tablet} {
    padding: 32px 24px 40px;

    .form-panel {
      padding-top: 24px;
    }
  }

  @media ${device.mobileMaxWidth} {
    padding: 32px 16px 40px;

    .form-panel {
      padding-top: 24px;
    }
  }
`;

export const StyledPlusIcon = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  > svg {
    path {
      fill: ${(props) => (props.disabled ? '#5C5C5C' : 'init')};
    }
  }
  > span {
    margin-left: 8px;
    font-weight: 600;
    font-size: 14px;
    color: ${(props) => (props.disabled ? '#5C5C5C' : '#145DB5')};

  }
`;

export const StyledDemoTitle = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 16px;

  > span {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    color: #212121;
  }

  @media ${device.tabletMaxWidth} {
    padding-bottom: 8px;
  }
`;

export const StyledActiveWrapper = styled.div`
  margin-top: 32px;

  @media ${device.tabletMaxWidth} {
    margin-top: 24px; 
  }
`;

export const StyledTitle = styled.div<{expired? : boolean}>`
  display: flex;
  align-items: center;

  div {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${(props) => (props.expired ? '#BBB' : 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #00BA34')}
  }

  span {
    padding: ${(props) => (props.theme.isRTL ? '0 4px 0 0' : '0 0 0 4px')};
    color: #212121;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
`;

export const StyledCardWrapper = styled.div`
  margin-top: 16px;
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.mobileMaxWidth} {
    grid-template-columns: 1fr;
  }
`;
