import { SVGProps } from 'react';

const SvgWarningCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='21'
    height='20'
    viewBox='0 0 21 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.333 1.875C8.72604 1.875 7.15515 2.35152 5.819 3.24431C4.48285 4.1371 3.44145 5.40605 2.82649 6.8907C2.21153 8.37535 2.05063 10.009 2.36413 11.5851C2.67764 13.1612 3.45147 14.6089 4.58777 15.7452C5.72407 16.8815 7.17181 17.6554 8.7479 17.9689C10.324 18.2824 11.9577 18.1215 13.4423 17.5065C14.927 16.8916 16.1959 15.8502 17.0887 14.514C17.9815 13.1779 18.458 11.607 18.458 10C18.4557 7.84581 17.599 5.78051 16.0757 4.25727C14.5525 2.73403 12.4872 1.87727 10.333 1.875ZM10.333 16.875C8.97326 16.875 7.64405 16.4718 6.51347 15.7164C5.38288 14.9609 4.50169 13.8872 3.98134 12.6309C3.46099 11.3747 3.32484 9.99237 3.59011 8.65875C3.85539 7.32513 4.51017 6.10013 5.47165 5.13864C6.43314 4.17716 7.65815 3.52237 8.99177 3.2571C10.3254 2.99183 11.7077 3.12798 12.964 3.64833C14.2202 4.16868 15.2939 5.04987 16.0494 6.18045C16.8048 7.31104 17.208 8.64025 17.208 10C17.2059 11.8227 16.481 13.5702 15.1921 14.8591C13.9032 16.1479 12.1557 16.8729 10.333 16.875ZM9.70801 10.625V6.25C9.70801 6.08424 9.77386 5.92527 9.89107 5.80806C10.0083 5.69085 10.1673 5.625 10.333 5.625C10.4988 5.625 10.6577 5.69085 10.775 5.80806C10.8922 5.92527 10.958 6.08424 10.958 6.25V10.625C10.958 10.7908 10.8922 10.9497 10.775 11.0669C10.6577 11.1842 10.4988 11.25 10.333 11.25C10.1673 11.25 10.0083 11.1842 9.89107 11.0669C9.77386 10.9497 9.70801 10.7908 9.70801 10.625ZM11.2705 13.4375C11.2705 13.6229 11.2155 13.8042 11.1125 13.9583C11.0095 14.1125 10.8631 14.2327 10.6918 14.3036C10.5205 14.3746 10.332 14.3932 10.1501 14.357C9.96826 14.3208 9.80121 14.2315 9.6701 14.1004C9.53899 13.9693 9.4497 13.8023 9.41353 13.6204C9.37735 13.4385 9.39592 13.25 9.46687 13.0787C9.53783 12.9074 9.65799 12.761 9.81216 12.658C9.96634 12.555 10.1476 12.5 10.333 12.5C10.5817 12.5 10.8201 12.5988 10.9959 12.7746C11.1717 12.9504 11.2705 13.1889 11.2705 13.4375Z'
      fill='currentColor'
    />
  </svg>
);

export default SvgWarningCircle;
