import styled from 'styled-components';
import FormPanel from 'components/FormPanel';
import { device } from 'styles/deviceStyle';

export const StyledCreateDemoAccount = styled(FormPanel)`
  .StyledTopWrap {
    color: #1C1C1C;
  }
`;

export const StyledDemoTitle = styled.div`
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 16px;

  > span {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    color: #212121;
  }

  @media ${device.tabletMaxWidth} {
    padding-bottom: 8px;
  }
`;

export const StyledContent = styled.div`
  padding: 24px 40px;

  @media ${device.tablet} {
    padding: 24px;
  }
  @media ${device.mobileMaxWidth} {
    padding: 40px 16px;
  }  
`;

export const StyledItemTitle = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #5C5C5C;
  + div {
    margin-top: 4px;
  }
`;

export const StyledItemWrapper = styled.div`
  margin-bottom: 32px;

  .select-custom {
    width: 480px;

    @media ${device.mobileMaxWidth} {
      width: 100%;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: end;
  padding: 16px 40px;
  border-top: 1px solid #DDDDDD;

  > button {
    padding: 8px 24px;
    font-family: 'Noto Sans';
    background: #145DB5;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    min-height: 40px;
    height: 40px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: none;

    &:hover, &:active {
      background: #145DB5;
    }

    &:disabled {
      color: #fff;
      opacity: 0.6;
    }
  }
`;
