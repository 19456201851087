import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledCreateAccountFrom = styled.div<{ showTip?: boolean }>`
  padding: ${(props) => (props.showTip ? '20px 64px' : '24px 64px')};
  position: relative;
  background: #f6f6f6;
  flex: 1;

  .custom-progress {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media ${device.tablet} {
    padding: ${(props) => (props.showTip ? '20px 24px' : '24px 24px')};
  }

  @media ${device.mobileMaxWidth} {
    padding: 24px 16px;
  }

`;

export const StyledLoadingContainer = styled.div`
  height: 480px;

  @media ${device.tablet} {
    height: 360px;
  }

  @media ${device.mobileMaxWidth} {
    height: 240px;
  }
`;

export const SubmitLoadingWrap = styled.div`
  width: 519px;
  height: auto;
  padding: 24px 16px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.mobileMaxWidth} {
    width: calc( 100% - 32px );
  }

  .content-wrap{
    margin-top: 16px;
  }
`;

export const SubmitLoadingContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #1C1C1C;
  text-align: center;
`;
