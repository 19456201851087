import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import bannerBg from 'assets/components/m-banner-bg.png';

export const StyledWelcomeBanner = styled.div`
  background: url(${bannerBg});
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .helloTxt{
    font-weight: 600;
    color: #1c1c1c;
    font-size: 16px;

    @media ${device.tablet} {
      font-size: 20px;
    }   
  }

  @media ${device.tablet} {
    padding: 36px 35px 48px 34px;
  }

  @media ${device.mobileMaxWidth} {
    padding: 20px;
  }
`;

export const StyledMainWrap = styled.div`
  position:relative;
`;

export const StyledGreeting = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

export const StyledWelcomeTitle = styled.div`
  font-size: 28px;
  margin: 20px 0;
  font-weight: 600;

  > span {
    color: #013B81;
  }

  @media ${device.tablet} {
    font-size: 24px;
    margin: 10px 0;
  }

  @media ${device.mobileMaxWidth} {
    font-size: 20px;
    margin: 16px 0;
  }
`;

export const StyledContent = styled.div`
  line-height: 22px;
  font-size: 16px;
  color: #5c5c5c;

  @media ${device.mobileMaxWidth} {
    margin-bottom: 16px;
  }
`;

export const StyledChildrenWrap = styled.div``;
