import { AppDispatch } from 'store';
import { setAccountStatus, setCreateAccountStatus } from 'containers/Pages/slices';
import { CreateAccountSteps } from 'constant/createAccount';

export enum AccountStatus {
  'Reject' = 'Reject',
  'Incomplete' = 'Incomplete',
  'Pending' = 'Pending',
  'Resubmitted' = 'Resubmitted',
  'Approve' = 'Approve',
  'Active' = 'Active',
  'Auto_Active' = 'Auto_Active',
  'noAccount' = 'noAccount'
}

export enum AccountType {
  'personal' = 'personal',
  'corporate' = 'corporate',
  'joint' = 'joint'
}

interface AccountInfo {
  accountStatus: AccountStatus;
  type: AccountType;
}

// In principle, each user can have a maximum of one incomplete/reject/pending account at any given time.
// However, to prevent multiple incomplete/reject/pending accounts in the account list received from the backend,
// we will arrange the order of the header buttons with the following priorities:
// Personal Incomplete > Corporate Incomplete > Joint Incomplete > Personal Reject > Corporate Reject > Joint Reject
const AccountStatusHandler = (
  target: { [key: string]: { [key: string]: string } },
  dispatch:AppDispatch
): AccountInfo => {
  let accountStatus = AccountStatus.noAccount;
  let accountType = AccountType.personal;
  if (target.personal_Reject) {
    localStorage.setItem('accountID', target.personal_Reject.accountID);
    accountStatus = AccountStatus.Reject;
  } else if (target.corporate_Reject) {
    dispatch(setCreateAccountStatus({ createAccountStatus: { type: AccountType.corporate } }));
    localStorage.setItem('accountID', target.corporate_Reject.accountID);
    accountStatus = AccountStatus.Reject;
    accountType = AccountType.corporate;
  } else if (target.joint_Reject) {
    dispatch(setCreateAccountStatus({ createAccountStatus: { type: 'joint' } }));
    localStorage.setItem('accountID', target.joint_Reject.accountID);
    accountStatus = AccountStatus.Reject;
    accountType = AccountType.joint;
  } else if (target.personal_Incomplete) {
    if (target.personal_Incomplete.formStep === CreateAccountSteps.PersonalAccountCreated) {
      dispatch(setCreateAccountStatus(
        {
          createAccountStatus: { type: AccountType.personal, isOnBoardingMethodSelecting: true }
        }
      ));
    } else {
      dispatch(setCreateAccountStatus({
        createAccountStatus: { type: AccountType.personal, isOnBoardingMethodSelecting: false }
      }));
    }
    localStorage.setItem('accountID', target.personal_Incomplete.accountID);
    accountStatus = AccountStatus.Incomplete;
  } else if (target.corporate_Incomplete) {
    dispatch(setCreateAccountStatus({ createAccountStatus: { type: AccountType.corporate } }));
    localStorage.setItem('accountID', target.corporate_Incomplete.accountID);
    accountStatus = AccountStatus.Incomplete;
    accountType = AccountType.corporate;
  } else if (target.joint_Incomplete) {
    dispatch(setCreateAccountStatus({ createAccountStatus: { type: 'joint' } }));
    localStorage.setItem('accountID', target.joint_Incomplete.accountID);
    accountStatus = AccountStatus.Incomplete;
    accountType = AccountType.joint;
  } else if (target.personal_Pending || target.corporate_Pending || target.joint_Pending) {
    accountStatus = AccountStatus.Pending;
  } else if (target.personal_Resubmitted || target.corporate_Resubmitted || target.joint_Resubmitted) {
    accountStatus = AccountStatus.Resubmitted;
  } else if (target.personal_Approve || target.corporate_Approve || target.joint_Approve) {
    accountStatus = AccountStatus.Approve;
  } else if (target.personal_Active || target.personal_Auto_Active || target.corporate_Active || target.joint_Active) {
    accountStatus = AccountStatus.Active;
  } else {
    accountStatus = AccountStatus.noAccount;
  }
  dispatch(setAccountStatus({ accountStatus }));
  return { accountStatus, type: accountType };
};

export default AccountStatusHandler;
