import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import {
  StatisticsDT,
  StatisticsMOB,
  StatisticsTAB
} from 'assets/blurredPages';
import { StyledBlurredPageWrap } from 'pages/BlurredPage/style';

export const StyledStatisticsWrap = styled(StyledBlurredPageWrap)`
  ::before {
    background-image: url(${StatisticsDT});
    @media ${device.tablet} {
      background-image: url(${StatisticsTAB});
    }
    @media ${device.mobileMaxWidth} {
      background-image: url(${StatisticsMOB});
    }
  }
`;
