import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BlurredBox from 'assets/blurredPages/BlurredBox.png';
import { AccountStatus, AccountType } from 'utils/AccountStatusHandler';
import { useAccountRG227State } from 'store/context/accountRG227Context';
import {
  StyledBlurredPopUpWrap,
  StyledMessageWrap,
  StyledLine,
  StyledBoxImg,
  BoxImg,
  StyledActionGroup
} from './styled';

const accountStatusMessageMap = {
  [AccountStatus.Incomplete]: 'openALiveAccountToday',
  [AccountStatus.noAccount]: 'openALiveAccountToday',
  [AccountStatus.Pending]: 'processingApplication',
  [AccountStatus.Approve]: 'processingApplication',
  [AccountStatus.Reject]: 'rejectApplication',
  [AccountStatus.Resubmitted]: 'processingApplication'
};

const BlurredPopUpCard = (): JSX.Element => {
  const { t } = useTranslation('blurredPopUpCard');
  const { t: commonT } = useTranslation('common');
  const navigate = useNavigate();
  const accountStatus = useAppSelector(
    (state) => state.pageState.accountStatus
  );
  const createAccountStatus = useAppSelector(
    (state) => state.pageState.createAccountStatus
  );
  const { exceedFailedAttempts, hasOnceFailed, accountId } = useAccountRG227State();

  const message = accountStatusMessageMap[
      accountStatus as Exclude<AccountStatus, AccountStatus.Active | AccountStatus.Auto_Active>
  ];

  const handleIncomplete = (): void => {
    if (createAccountStatus?.isOnBoardingMethodSelecting) {
      navigate('/create-account/verify-identity');
    } else {
      if (createAccountStatus.type === AccountType.corporate) {
        navigate('/create-account/corporate-account');
        return;
      }
      if (createAccountStatus.type === 'joint') {
        navigate('/create-account/joint-account');
        return;
      }
      navigate('/create-account/personal-account');
    }
  };

  const handleReject = (): void => {
    if (createAccountStatus.type === AccountType.corporate) {
      navigate('/create-account/corporate-account?status=Reject');
      return;
    }
    if (createAccountStatus.type === 'joint') {
      navigate('/create-account/joint-account?status=Reject');
      return;
    }
    navigate('/create-account/personal-account?status=Reject');
  };

  return (
    <div className="BlurredPopUpCard" aria-label="Blurred page popup">
      <div className="BlurredPopUpInside">
        <StyledBlurredPopUpWrap>
          <StyledBoxImg>
            <BoxImg
              src={BlurredBox}
              alt="blurredBox"
              draggable="false"
              aria-label="blurred pop up card image"
            />
          </StyledBoxImg>
          <h3 aria-label="blurred pop up card title">
            {t('liveAccountRequired')}
          </h3>
          <StyledLine />
          <StyledMessageWrap>
            <span aria-label="blurred pop up card description">
              {t(message)}
            </span>
            {hasOnceFailed && !exceedFailedAttempts && (
              <StyledActionGroup>
                <Button
                  aria-label="retry assessment"
                  onClick={() => navigate(`/rg227/${accountId}`)}
                >
                  {commonT('retryAssessment')}
                </Button>
              </StyledActionGroup>
            )}
            {accountStatus === AccountStatus.Incomplete && (
              <StyledActionGroup>
                <Button
                  aria-label="continue where you left"
                  onClick={handleIncomplete}
                >
                  {commonT('continueWhereYouLeft')}
                </Button>
              </StyledActionGroup>
            )}
            {accountStatus === AccountStatus.Reject
              && !(hasOnceFailed && !exceedFailedAttempts) && (
                <StyledActionGroup>
                  <Button
                    aria-label="finalise your application"
                    onClick={handleReject}
                  >
                    {commonT('finaliseYourApplication')}
                  </Button>
                </StyledActionGroup>
            )}
            {accountStatus === AccountStatus.noAccount && (
              <StyledActionGroup>
                <Button
                  aria-label="open a live account"
                  onClick={() => navigate('/create-account/select-account-type')}
                >
                  {commonT('openALiveAccount')}
                </Button>
              </StyledActionGroup>
            )}
          </StyledMessageWrap>
        </StyledBlurredPopUpWrap>
      </div>
    </div>
  );
};

export default BlurredPopUpCard;
