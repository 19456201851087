/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, ChangeEvent } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addVirtualFunds } from 'api/v1/demoAccount';
import CircularProgress from '@mui/material/CircularProgress';
import AddVirtualFundsSuccessPopUp from 'containers/DemoAccount/AddVirtualFundsSuccessPopUp';
import {
  StyledAccountData, StyledInputContainer, StyledInput, StyledChooseAmountContainer,
  StyledButton, StyledButtonContainer, StyledFooter, PopUpWrapper, StyledError
} from './style';

export interface DemoAccountType {
  account_number: string;
  currency: string;
  id: string;
  max_balance: string;
  name: string;
  total_balance: string;
}
interface AddVirtualFundsProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  demoAccount: DemoAccountType;
  onSuccessAdd: (inputValue: string, demoAccount:DemoAccountType) => void;
}

interface AxiosErrorDetails {
  response?: {
    data?: {
      message?: string;
      statusCode?: number;
    };
  }
}

const AddVirtualFunds = ({
  openModal, setOpenModal, demoAccount, onSuccessAdd
}: AddVirtualFundsProps): JSX.Element => {
  const { t } = useTranslation('demoAccount');
  const tabValues = ['1000', '2000', '5000', '8000', '10000', '20000'];
  const [inputValue, setInputValue] = useState<string>('10000');
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<boolean | null>(null);
  const [apiMessage, setApiMessage] = useState<{ __html: string }>({ __html: '' });
  const [openAddVirtualFundsSuccessModal, setOpenAddVirtualFundsSuccessModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const MaxDepositAmount = parseFloat(demoAccount?.max_balance?.replace(/[^\d.-]/g, ''))
  - parseFloat(demoAccount?.total_balance?.replace(/[^\d.-]/g, ''));

  useEffect(() => {
    if (parseFloat('10000') > MaxDepositAmount) {
      setShowError(true);
    }
  }, [demoAccount]);

  const validateInput = (value: string): void => {
    if (/^[1-9]\d*$|^$/.test(value)) {
      setInputValue(value);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    validateInput(value);
    setShowError(parseFloat(value) > MaxDepositAmount);
  };

  const handleTabClick = (value: string): void => {
    setInputValue(value);
    validateInput(value);
    setShowError(parseFloat(value) > MaxDepositAmount);
  };

  const openZendeskChat = async (): Promise<void> => {
    const zendeskWidget = (window as any).zE;
    if (zendeskWidget) {
      zendeskWidget('messenger', 'open');
    }
  };

  (window as any).openZendeskChat = openZendeskChat;

  const handleSubmit = async (): Promise<void> => {
    if (!showError) {
      setIsLoading(true);
      try {
        const res = await addVirtualFunds({ accountNumber: demoAccount.account_number, amount: inputValue });
        if (res.status === 200) {
          setApiError(false);
          onSuccessAdd(inputValue, demoAccount);
        }
      } catch (err) {
        setApiError(true);
        if ((err as AxiosErrorDetails)?.response?.data?.message) {
          const errorMessage = (err as AxiosErrorDetails)?.response?.data?.message ?? 'Unknown error';
          setApiMessage({ __html: errorMessage });
        }
      } finally {
        setOpenModal(false);
        setOpenAddVirtualFundsSuccessModal(true);
        setInputValue('10000');
        setIsLoading(false);
      }
    }
  };

  return (
    demoAccount && (
      <>
        <PopUpWrapper
          id="create demo account pop up"
          header={t('addVirtualFunds')}
          openModal={openModal}
          onClose={() => {
            setOpenModal(false);
            setInputValue('10000');
            setShowError(false);
          }}
        >
          <StyledAccountData>
            <p>
              {t(
                'maximumDepositAmount',
                { amount: demoAccount.total_balance.slice(0, -4) }
              )}
            </p>
          </StyledAccountData>
          <StyledInputContainer>
            <p>
              {t('enterAmount')}
              {' '}
              (
              {demoAccount ? demoAccount.currency : null}
              )
            </p>
            <StyledInput
              type="text"
              id="demoAccountInput"
              value={inputValue}
              onChange={handleInputChange}
              showError={showError}
            />
            <StyledError>
              {showError && (
              <StyledError>
                  {t('maximumAccountBalance', { amount: demoAccount.max_balance })}
                <br />
                  {t(
                    'MaximumDepositAmountIs',
                    { amount: MaxDepositAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                  )}
              </StyledError>
              )}
            </StyledError>
          </StyledInputContainer>
          <StyledChooseAmountContainer>
            <p>{t('orChoose')}</p>
            <StyledButtonContainer>
              {tabValues.map((value) => (
                <StyledButton
                  key={value}
                  onClick={() => handleTabClick(value)}
                  isSelected={inputValue === value}
                >
                  {value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </StyledButton>
              ))}
            </StyledButtonContainer>

          </StyledChooseAmountContainer>

          <StyledFooter>
            <Button
              id="Additional_Demo_Account_Submit"
              aria-label="submit"
              disabled={showError || !inputValue || isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? <CircularProgress className="custom-progress" /> : t('deposit')}
            </Button>
          </StyledFooter>
        </PopUpWrapper>
        <AddVirtualFundsSuccessPopUp
          openModal={openAddVirtualFundsSuccessModal}
          setOpenModal={(value: boolean) => setOpenAddVirtualFundsSuccessModal(value)}
          success={!apiError}
          content={apiMessage}
        />
      </>
    )
  );
};

export default AddVirtualFunds;
