import { SVGProps } from 'react';

const SvgDotsTree = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='32'
    height='24'
    viewBox='0 0 32 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.5 12.5C17.5 12.7967 17.412 13.0867 17.2472 13.3334C17.0824 13.58 16.8481 13.7723 16.574 13.8858C16.2999 13.9993 15.9983 14.0291 15.7074 13.9712C15.4164 13.9133 15.1491 13.7704 14.9393 13.5607C14.7296 13.3509 14.5867 13.0836 14.5288 12.7926C14.4709 12.5017 14.5007 12.2001 14.6142 11.926C14.7277 11.6519 14.92 11.4176 15.1666 11.2528C15.4133 11.088 15.7033 11 16 11C16.3978 11 16.7794 11.158 17.0607 11.4393C17.342 11.7206 17.5 12.1022 17.5 12.5ZM24.5 11C24.2033 11 23.9133 11.088 23.6666 11.2528C23.42 11.4176 23.2277 11.6519 23.1142 11.926C23.0007 12.2001 22.9709 12.5017 23.0288 12.7926C23.0867 13.0836 23.2296 13.3509 23.4393 13.5607C23.6491 13.7704 23.9164 13.9133 24.2074 13.9712C24.4983 14.0291 24.7999 13.9993 25.074 13.8858C25.3481 13.7723 25.5824 13.58 25.7472 13.3334C25.912 13.0867 26 12.7967 26 12.5C26 12.1022 25.842 11.7206 25.5607 11.4393C25.2794 11.158 24.8978 11 24.5 11ZM7.5 11C7.20333 11 6.91332 11.088 6.66665 11.2528C6.41997 11.4176 6.22771 11.6519 6.11418 11.926C6.00065 12.2001 5.97094 12.5017 6.02882 12.7926C6.0867 13.0836 6.22956 13.3509 6.43934 13.5607C6.64912 13.7704 6.91639 13.9133 7.20737 13.9712C7.49834 14.0291 7.79994 13.9993 8.07403 13.8858C8.34811 13.7723 8.58238 13.58 8.74721 13.3334C8.91203 13.0867 9 12.7967 9 12.5C9 12.1022 8.84197 11.7206 8.56066 11.4393C8.27936 11.158 7.89783 11 7.5 11Z'
      fill='#BBBBBB'
    />
  </svg>
);
export default SvgDotsTree;
