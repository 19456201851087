interface CategoriesType {
[key: number]: string;
}

export const categories: CategoriesType = {
  2: 'analysis',
  3: 'tutorials',
  10: 'acyAdvantage',
  1: 'marketNews',
  4: 'platforms',
  5: 'marketNews',
  6: 'platforms',
  9: 'platforms',
  11: 'acyPartnerships'
};
