import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ItemLabel from 'components/ItemLabel';
import RadioGroup from 'components/RadioGroup';
import PracticeResultPopUp from 'containers/RG227PracticeForm/component/PracticeResultPopUp';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { getExperiencePracticeForm } from 'api/v1/account';
import { useTranslation } from 'react-i18next';
import { StyledRadioWrap, StyledQuestionWrap } from 'containers/CreateAccountForm/components/TradingExperience/style';
import {
  StyledRG227PracticeFormWrap,
  StyledRG227PracticeForm,
  StyledHead,
  StyledTitle,
  StyledSubtitle,
  StyledContent,
  StyledButtonWrap,
  StyledGetNewQuestionWrap,
  StyledLoadingWrap,
  StyledPopupContentWrap,
  StyledClickHere
} from './style';

interface QuestionProps {
  id: string;
  type: string;
  name: string;
  placeHolder: string;
  required: boolean;
  data: {
    label: string;
    value: string;
  }[];
}

interface IFormInput {
  [key: string]: string;
}

const RG227PracticeForm = ():JSX.Element => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState<{[key: string]: string}>({});
  const [correctAnswersNumber, setCorrectAnswersNumber] = useState(0);
  const { t } = useTranslation('registrationForm');
  const {
    handleSubmit, control, reset, formState: { isValid }
  } = useForm({ mode: 'all' });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const num = Object.keys(data).reduce((acc, id) => (data[id] === answers[id] ? acc + 1 : acc), 0);
    setCorrectAnswersNumber(num);
    setOpenModal(true);
  };

  const getQuestions = (): void => {
    setLoading(true);
    getExperiencePracticeForm().then((res) => {
      if (res.status === 200) {
        setQuestions(res?.data?.questions || []);
        setAnswers(res?.data?.answers || {});
      }
    }).finally(() => setLoading(false));
  };

  const getNewQuestions = (): void => {
    reset();
    setOpenModal(false);
    getQuestions();
  };

  const renderQuestion = (tradingQuesObj: QuestionProps, index: number): JSX.Element => {
    const isRadio = tradingQuesObj.type === 'radio';

    return (
      <StyledQuestionWrap key={tradingQuesObj.id}>
        <ItemLabel id={tradingQuesObj.id} title={`${index + 1}. ${tradingQuesObj.name}`}>
          <StyledRadioWrap>
            <Controller
              control={control}
              name={tradingQuesObj.id}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  options={tradingQuesObj.data}
                  groupName={tradingQuesObj.id}
                  optionType={isRadio ? undefined : 'button'}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </StyledRadioWrap>
        </ItemLabel>
      </StyledQuestionWrap>
    );
  };

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <StyledRG227PracticeFormWrap>
      <StyledRG227PracticeForm>
        <StyledHead>
          <StyledTitle aria-label="Practice Title">{t('practiceTitle')}</StyledTitle>
          <StyledSubtitle aria-label="Practice Subtitle">{t('practiceSubtitle')}</StyledSubtitle>
        </StyledHead>
        <StyledContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
            loading
              ? (
                <StyledLoadingWrap>
                  <CircularProgress />
                </StyledLoadingWrap>
              )
              : (
                <>
                  {questions.map(renderQuestion)}
                  <StyledButtonWrap>
                    <Button
                      disabled={!isValid}
                      className="submit-button"
                      aria-label="submit"
                      type="submit"
                    >
                      {t('finished')}
                    </Button>
                    <StyledGetNewQuestionWrap onClick={() => getNewQuestions()} aria-label="Get More Questions">
                      {t('getNewQuestions')}
                    </StyledGetNewQuestionWrap>
                  </StyledButtonWrap>
                </>
              )
          }
          </form>
        </StyledContent>
      </StyledRG227PracticeForm>
      <PracticeResultPopUp
        openModal={openModal}
        header={`${t('yourResult')} ${correctAnswersNumber}${t('result')}`}
        onClose={() => setOpenModal(false)}
      >
        <StyledPopupContentWrap>
          <span>{t('moreQuestions')}</span>
          <StyledClickHere onClick={() => getNewQuestions()}>
            {t('clickHere')}
          </StyledClickHere>
        </StyledPopupContentWrap>
      </PracticeResultPopUp>
    </StyledRG227PracticeFormWrap>
  );
};

export default RG227PracticeForm;
