import { SVGProps } from 'react';

const SvgRemove = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12 4L4 12'
      stroke='#E92C2C'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4 4L12 12'
      stroke='#E92C2C'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default SvgRemove;
