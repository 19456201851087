import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import RadioGroup from 'components/RadioGroup';
import Select from 'components/Select';
import { useAppDispatch } from 'store';
import BackdropSpinner from 'components/BackdropSpinner';
import { Button } from '@mui/material';
import { getDemoTradingFields, createDemoAccount } from 'api/v1/demoAccount';
import ErrorHandler from 'utils/ErrorHandler';
import { useTranslation } from 'react-i18next';
import { SuccessInfoTypes } from 'pages/DemoAccount';
import {
  StyledContent, StyledItemWrapper, StyledItemTitle, StyledFooter, StyledCreateDemoAccount
} from './style';

export interface OptionsProps {
  value: string;
  label: string;
}

export type OptionsTypes = OptionsProps[] | {
  MetaTrader4: OptionsProps[];
  MetaTrader5: OptionsProps[];
  LogixTrader: OptionsProps[];
}[]

export interface ResponseFieldsProps {
  id: string;
  name: string;
  options: OptionsTypes;
  placeHolder: string;
  rules: {
    [key: string]: {
      value: string | boolean;
      message: string;
    }
  };
  type: string;
}

const CreateDemoAccount = ({ onSave }: { onSave: (arg0: SuccessInfoTypes) => void}): JSX.Element => {
  const {
    control, setValue, watch, formState: { isValid }, handleSubmit
  } = useForm({ mode: 'all' });
  const dispatch = useAppDispatch();
  const [initLoading, setInitLoading] = useState(true);
  const { t } = useTranslation('registrationForm');
  const platform = watch('trading_platform');
  const [demoAccountFields, setDemoAccountFields] = useState<ResponseFieldsProps[]>([]);
  const getOptions = (options: OptionsTypes): OptionsProps[] => {
    if ('MetaTrader4' in options[0] && 'MetaTrader5' in options[0]) {
      if (platform === 'MetaTrader 4') return options[0]?.MetaTrader4;
      if (platform === 'MetaTrader 5') return options[0]?.MetaTrader5;
      if (platform === 'LogixTrader') return options[0]?.LogixTrader;
      return [];
    }
    const res: OptionsProps[] = [];
    options.forEach((el) => {
      if ('label' in el && 'value' in el) res.push(el);
    });
    return res;
  };

  useEffect(() => {
    getDemoTradingFields(localStorage.getItem('registeredCountry') || '').then((data) => {
      if (data.status === 200) {
        setDemoAccountFields(data.data);
        setInitLoading(false);
      }
    });

    setValue('trading_platform', 'LogixTrader');
  }, []);

  return (
    <StyledCreateDemoAccount title={t('tradingPreferences')} className="form-panel" id="Trading Preferences">
      <StyledContent>
        <BackdropSpinner open={initLoading} />
        {demoAccountFields.map((item) => {
          switch (item.type) {
            case 'radioButton':
              return (
                <StyledItemWrapper key={item.id}>
                  <StyledItemTitle aria-label="trading platform">{item.name}</StyledItemTitle>
                  <Controller
                    control={control}
                    name={item.id}
                    rules={{ required: item?.rules?.required?.value }}
                    render={({
                      field: {
                        onChange,
                        value
                      }
                    }) => (
                      <RadioGroup
                        options={getOptions(item.options)}
                        groupName={item.id}
                        optionType="button"
                        onChange={(event) => {
                          const currency = watch('base_currency');

                          demoAccountFields.forEach((categoryObj) => {
                            if (categoryObj.id === 'base_currency') {
                              let reset = true;
                              const { options } = categoryObj;
                              if ('MetaTrader4' in options[0] && 'MetaTrader5' in options[0]) {
                                if (event?.target.value === 'MetaTrader 4') {
                                  reset = !options[0]?.MetaTrader4.find((props) => props.value === currency);
                                }
                                if (event?.target.value === 'MetaTrader 5') {
                                  reset = !options[0]?.MetaTrader5.find((props) => props.value === currency);
                                }
                              }
                              if (reset) setValue('base_currency', null);
                            }
                          });
                          onChange(event);
                        }}
                        value={value}
                      />
                    )}
                  />
                </StyledItemWrapper>
              );
            case 'select':
              return (
                <StyledItemWrapper key={item.id}>
                  <StyledItemTitle aria-label="base currency">{item.name}</StyledItemTitle>
                  <Controller
                    control={control}
                    name={item.id}
                    rules={{ required: item?.rules?.required?.value }}
                    render={({
                      field: {
                        onChange, value
                      }
                    }) => (
                      <Select
                        className="select-custom"
                        options={getOptions(item.options)}
                        inputHeight="48px"
                        placeholder={item.placeHolder}
                        onChange={onChange}
                        currentValue={value}
                        defaultValue={value}
                        id={item.name}
                      />
                    )}
                  />
                </StyledItemWrapper>
              );
            default:
              return null;
          }
        })}
      </StyledContent>
      <StyledFooter>
        <Button
          id="Demo_Account_Submit"
          disabled={!isValid || initLoading}
          aria-label="submit"
          onClick={handleSubmit((data) => {
            setInitLoading(true);
            ErrorHandler(createDemoAccount({ platform: data.trading_platform, currency: data.base_currency }), dispatch).then((res) => {
              if (res.status === 200) onSave(res.data);
            }).finally(() => {
              setInitLoading(false);
            });
          })}
        >
          {t('submit')}
        </Button>
      </StyledFooter>
    </StyledCreateDemoAccount>
  );
};

export default CreateDemoAccount;
