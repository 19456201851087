/* eslint-disable no-restricted-syntax */
import { useEffect, useState } from 'react';
import WebinarItemCard from 'components/WebinarItem';
import { getOnlineTraining, registerOnlineTraining } from 'api/v1/training';
import BackdropSpinner from 'components/BackdropSpinner';
import ErrorHandler from 'utils/ErrorHandler';
import { useAppDispatch } from 'store';
import { useTranslation } from 'react-i18next';
import EnrolledTraining from './EnrolledTraining';
import WebinarItemType from './type';

import {
  StyledSectionContainer,
  StyledHeader,
  StyledHeaderBox,
  StyledContent,
  StyledWebinarListContainer,
  StyledWebinarListHeader,
  StyledWebinarListMain,
  StyledWebinarListMainHeader,
  StyledHeaderBlock,
  StyledSubjectBlock,
  StyledWebinarListMainBody
} from './style';

const titleList: string[] = [
  'presenter',
  'startTime',
  'endTime',
  'timeZone',
  'status'
];

const OnlineTraining = ():JSX.Element => {
  const { t } = useTranslation('onlineTraining');
  const [webinarList, setWebinarList] = useState<WebinarItemType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getOnlineTraining().then((res) => {
      setWebinarList(res.data);
      setIsLoading(false);
    });
  }, []);

  const register = (rowIndex: number): void => {
    const trainingId = webinarList[rowIndex].id;
    ErrorHandler(registerOnlineTraining(trainingId), dispatch).then(() => {
      window.location.reload();
    }).finally(() => {
      setIsLoading(false);
    });
  };

  if (isLoading) return <BackdropSpinner open />;

  return (
    <StyledSectionContainer>
      <StyledHeader aria-label="online training title">
        <StyledHeaderBox>
          <h2>{t('onlineTraining')}</h2>
        </StyledHeaderBox>
      </StyledHeader>
      <StyledContent>
        <EnrolledTraining webinarList={webinarList} />
        <StyledWebinarListHeader>
          <h4 aria-label="online training webinarList">{t('webinarList')}</h4>
          <p aria-label="online training subtitle">{t('onlineTrainingSubtitle')}</p>
        </StyledWebinarListHeader>
        <StyledWebinarListContainer>
          <StyledWebinarListMain>
            <StyledWebinarListMainHeader aria-label="webniar list header">
              <StyledSubjectBlock>
                <span aria-label="subject">{t('subject')}</span>
              </StyledSubjectBlock>
              {titleList.map((item) => (
                <StyledHeaderBlock key={item}>
                  <span aria-label={`${item} item`}>{t(item)}</span>
                </StyledHeaderBlock>
              ))}
            </StyledWebinarListMainHeader>
            <StyledWebinarListMainBody>
              {webinarList.map((webinarItem: WebinarItemType, rowIndex: number) => (
                <WebinarItemCard
                  key={webinarItem.id}
                  webinarItem={webinarItem}
                  onClick={() => register(rowIndex)}
                  rowIndex={rowIndex}
                />
              ))}
            </StyledWebinarListMainBody>
          </StyledWebinarListMain>
        </StyledWebinarListContainer>
      </StyledContent>
    </StyledSectionContainer>
  );
};

export default OnlineTraining;
