import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { setTokenInStorage, clearStorageAndKeepNecessary } from 'utils/auth';
import { AppDispatch } from 'store';
import { addApiError } from 'containers/Pages/slices';
import i18n from 'i18n';
import { DOMAIN_CONFIG } from 'constant/domainConfig';

// [TODO]: refactor types
interface ErrorResponse {
  code?: number;
  message?: string;
  errors?: string;
}

type RefreshSubscriber = (token: string) => void;

let isRefreshing = false;
let retryAPI = false;
let refreshSubscribers: RefreshSubscriber[] = [];
let dispatch: AppDispatch;

export function injectDispatch(_dispatch: AppDispatch): void {
  dispatch = _dispatch;
}

function subscribeTokenRefresh(cb: RefreshSubscriber): void {
  refreshSubscribers.push(cb);
}

function onRefreshed(token: string): void {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers = [];
}

const customInstance: AxiosInstance = axios.create({
  baseURL: DOMAIN_CONFIG.api,
  timeout: 30_000, // 30 seconds
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': i18n.language
  }
});

const token = `Bearer ${localStorage.getItem('accessToken')}`;
customInstance.defaults.headers.common.Authorization = token;

customInstance.interceptors.request.use(
  (config) => {
    config.headers = config.headers || {};
    config.headers['Accept-Language'] = i18n.language;
    return config;
  },
  (error) => {
    console.error('Rare error in request interceptor setup:', error);
    return Promise.reject(error);
  }
);

customInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError<ErrorResponse>) => {
    const requestTimeoutTitle = i18n.getResource(i18n.language, 'error', 'requestTimeout');
    const serverErrorTitle = i18n.getResource(i18n.language, 'error', 'serverError');

    if (axios.isAxiosError(error)) {
      const id = Date.now();
      const { code = '', config, response } = error;
      switch (code) {
        case AxiosError.ECONNABORTED:
        case AxiosError.ETIMEDOUT:
          dispatch(addApiError({
            id,
            code,
            message: `${requestTimeoutTitle}\n${id}\n${code}\n${error.message}`
          }));
          break;
        default:
          if (response) {
            const { status, data } = response;
            switch (status) {
              case 401:
                // [TODO]: refactor this refreshToken logic
                if (config.url === `/realm/${DOMAIN_CONFIG.realm}/clients`) {
                  return Promise.reject(error);
                }
                if (!retryAPI) {
                  if (!isRefreshing) {
                    isRefreshing = true;
                    const headers = new Headers();
                    headers.append('Content-type', 'application/x-www-form-urlencoded; charset=UTF-8');
                    headers.append('Accept', 'application/json');
                    fetch(`${DOMAIN_CONFIG.keycloak}/token`, {
                      method: 'POST',
                      headers,
                      body: `client_id=meetup-app-pkce-client&grant_type=refresh_token&refresh_token=${localStorage.getItem('refreshToken')}`,
                      mode: 'cors'
                    })
                      .then((res) => res.json())
                      .then((res) => {
                        isRefreshing = false;
                        if (res && res.access_token) {
                          setTokenInStorage(res);
                          onRefreshed(res.access_token);
                          retryAPI = true;
                          setTimeout(() => {
                            retryAPI = false;
                          }, 15000);
                        } else {
                          clearStorageAndKeepNecessary();
                          window.location.href = '/';
                        }
                      }).catch(() => {
                        clearStorageAndKeepNecessary();
                        window.location.href = '/';
                      });
                  }

                  const retryOrigReq = new Promise((resolve) => {
                    subscribeTokenRefresh((newToken: string) => {
                    // replace the expired token and retry
                      if (config.headers) {
                        config.headers.Authorization = `Bearer ${newToken}`;
                      }
                      resolve(axios(config));
                    });
                  });
                  return retryOrigReq;
                }
                if (retryAPI) {
                  if (config.headers) {
                    config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
                  }
                  return axios(config).then((res) => res).catch((err) => Promise.reject(err));
                }
                break;
              default:
                // [TODO]: Remove it, temporary only detect '/accounts' errors.
                // Once remove this condition, should remove all APIs that have error messages in the components.
                if (config.url === '/accounts') {
                  dispatch(addApiError({
                    id,
                    code,
                    message: `${serverErrorTitle}\n${id}\n${status}\n${data.message}`
                  }));
                }
                break;
            }
          } else {
            dispatch(addApiError({
              id,
              code,
              message: `${serverErrorTitle}\n${id}\n${code}\n${error.message}`
            }));
          }
          break;
      }
    }

    console.error('Error', error);
    return Promise.reject(error);
  }
);

export default customInstance;
