import { getSingleVideo } from 'api/v1/video';
import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import BackdropSpinner from 'components/BackdropSpinner';
import { categories } from '../enumVideoData';
import {
  StyledMainWrap,
  StyledHeader,
  StyledHeaderBox,
  StyledContent,
  StyledVideoWrap,
  StyledVideoImg,
  StyledVideoInfoWrap,
  StyledVideoMessageWrap
} from './style';

interface VideoProps {
  id: number;
  category: string;
  title: string;
  url: string;
  description: string;
  category_id: number;
  image_link: string;
  created_at: string;
}
const SingleVideoPage = ():JSX.Element => {
  const { t } = useTranslation('videos');
  const { id, videoID } = useParams();
  const [singleVideo, setSingleVideo] = useState<VideoProps>();

  useEffect(() => {
    getSingleVideo(videoID).then((res) => {
      if (res.status === 200) {
        setSingleVideo(res.data.data);
      }
    });
  }, []);

  return (
    <div>
      {isEmpty(singleVideo) ? (
        <BackdropSpinner open />
      ) : (
        <StyledMainWrap>
          <StyledHeader>
            <StyledHeaderBox>
              <h2 aria-label="videos header">{t('videos')}</h2>
            </StyledHeaderBox>
          </StyledHeader>
          <StyledContent>
            <StyledVideoWrap>
              <StyledVideoImg title="video" src={singleVideo.url} />
              <h4 aria-label="single video title">{singleVideo.title}</h4>
              <StyledVideoInfoWrap>
                <span aria-label="category">
                  {t('category')}
                  : &nbsp;
                  <a href="/training/videos" aria-label="video">{t('video')}</a>
                  &nbsp;
                  /
                  &nbsp;
                  <a href={`/training/videos/category/${singleVideo.category_id}`} aria-label="category nume">{t(categories[Number(id)])}</a>
                </span>
                <span aria-label="create date">
                  ACY
                  &nbsp;
                  {singleVideo.created_at}
                </span>
              </StyledVideoInfoWrap>
            </StyledVideoWrap>
            <StyledVideoMessageWrap aria-label="description" dangerouslySetInnerHTML={{ __html: singleVideo.description }} />
          </StyledContent>
        </StyledMainWrap>
      )}
    </div>
  );
};

export default SingleVideoPage;
