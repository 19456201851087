import { AccountTypeWrap, StyledAccountMobileImg } from './style';

interface AccountTypeProp {
  imgSrc:string;
  title:string;
  content:string;
  id?:string;
}

const AccountType = (props:AccountTypeProp):JSX.Element => {
  const {
    title = '', imgSrc, content, id = ''
  } = props;

  return (

    <AccountTypeWrap
      className="accountType"
      aria-label={`${id} AccountType Wrap`}
    >
      <div
        className="img-wrap"
        aria-label={`${id} Image Wrap`}
      >
        <img src={imgSrc} alt={id} />
        <StyledAccountMobileImg>
          <div>
            <img src={imgSrc} alt={id} />
          </div>
          <div className="mobile-title" aria-label={`${id} Title`}>{title}</div>
        </StyledAccountMobileImg>
      </div>

      <div className="divider" />

      <div className="content-wrap">
        <div
          className="title"
          aria-label={`${id} Title`}
        >
          {title}
        </div>
        <div
          className="content"
          aria-label={`${id} content`}
        >
          {content}
        </div>
      </div>

    </AccountTypeWrap>
  );
};

export default AccountType;
