import { LayoutControlProvider } from 'containers/AccountList/context/layoutControlContext';
import { StagingDataProvider } from 'containers/AccountList/context/stagingDataContext';
import { AccountListProvider } from 'containers/AccountList/context/accountListContext';
import AccountListContainer from 'containers/AccountList';

const AccountList = (): JSX.Element => (
  <LayoutControlProvider>
    <StagingDataProvider>
      <AccountListProvider>
        <AccountListContainer />
      </AccountListProvider>
    </StagingDataProvider>
  </LayoutControlProvider>
);

export default AccountList;
