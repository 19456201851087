import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledSectionContainer = styled.div`
  margin: 16px auto;
  padding: 0 64px;
  @media ${device.tablet} {
    width: 100%;
    padding: 0 16px;
  }
  @media ${device.mobileMaxWidth} {
    margin: 12px auto;
    padding: 0 16px;
  }
`;

export const StyledHeader = styled.div`
  height: 72px;
  border-bottom: 1px solid #DDDDDD;
  width: 100%;
  @media ${device.mobileMaxWidth} {
    height: 60px;
  }
`;

export const StyledHeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90px;
  height: 100%;
  border-bottom: 1px solid #0282D0;
  @media ${device.mobileMaxWidth} {
    width: 56px;
  }
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    text-transform: capitalize;
    color: #1C1C1C;
    margin: 16px 0;
    @media ${device.mobileMaxWidth} {
    font-size: 20px;
    line-height: 28px;
    }
  }
`;

export const StyledCategoryContainer = styled.div`
  margin-top: 16px;
  @media ${device.tablet} {
    padding: 0;
  }
  @media ${device.mobileMaxWidth} {
    padding: 0;
    margin-top: 12px;
  }
`;

export const StyledLoad = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 24px;
`;

export const StyledContent = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 16px 0;
  @media ${device.mobileMaxWidth} {
    margin: 24px 0 16px 0;
  }
`;

export const StyledCategoryTitle = styled.h4`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #1C1C1C;
  text-transform: capitalize;
  margin: 0;
`;

export const StyledButton = styled.div`
  button {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #0282D0;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    font-style: normal;
    font-family: 'Noto Sans';
    padding-right: 0;
  }
`;

export const StyledVideoContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 20px;
  overflow: scroll;
  overflow-x: hidden;
  max-height: 700px;
  margin-bottom: 40px;
  padding-right: 8px;
  /* width */
  ::-webkit-scrollbar {
  width: 8px;
  height: 2em;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
  width: 8px;
  background: #DDDDDD;
  max-height: 240px;
  border-radius: 200px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
  background: #555;
  }
`;
