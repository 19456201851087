import { useFormContext, Controller } from 'react-hook-form';
import RadioGroup from 'components/RadioGroup';
import { StyledFieldWrap } from 'containers/ReactHookFormInputCells/style';
import { StyledRadioGroupWrapper } from './style';

interface OptionsProps {
  value: string;
  label: string;
}

interface FormRadioButtonProps {
  id: string;
  label?: string;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  options?: OptionsProps[];
  labelButton?: React.ReactNode;
  onChangeCallback?: (val: string) => void;
}

const FormRadioButton = ({
  options, id, label, disabled, className, required, labelButton, onChangeCallback
}: FormRadioButtonProps): JSX.Element => {
  const { control, formState: { errors } } = useFormContext();

  return (
    <StyledFieldWrap key={id}>
      <Controller
        control={control}
        name={id}
        rules={{ required }}
        render={({
          field: {
            onChange, value
          }
        }) => (
          <StyledRadioGroupWrapper>
            <RadioGroup
              options={options || []}
              id={id}
              groupName={id}
              label={label}
              optionType="button"
              onChange={(e) => {
                onChange(e);
                if (onChangeCallback && e) onChangeCallback(e.target.value);
              }}
              value={value}
              labelButton={labelButton}
              className={className}
              disabled={disabled}
            />
            <div className="optionBtnErr">
              {errors[id] && 'Error'}
            </div>
          </StyledRadioGroupWrapper>
        )}
      />
    </StyledFieldWrap>
  );
};

export default FormRadioButton;
