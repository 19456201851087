import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { StyledNote, StyledPopUpWrapper, StyledLButtonsWrap } from './style';

interface DeleteAccountPopUpProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  onDelete: () => void;
}

const DeleteAccountPopUp = ({
  openModal, setOpenModal, onDelete
}: DeleteAccountPopUpProps): JSX.Element => {
  const { t } = useTranslation('demoAccount');

  return (
    <StyledPopUpWrapper id="delete account pop up" header={t('deleteDemoAccount')} openModal={openModal} onClose={() => setOpenModal(false)}>
      <StyledNote aria-label="description">
        {t('areYouSure')}
      </StyledNote>
      <StyledLButtonsWrap>
        <Button aria-label="Pop Up Cancel" onClick={() => setOpenModal(false)}>
          {t('cancel')}
        </Button>
        <Button aria-label="Pop Up Confirm" onClick={onDelete}>
          {t('delete')}
        </Button>
      </StyledLButtonsWrap>
    </StyledPopUpWrapper>
  );
};

export default DeleteAccountPopUp;
