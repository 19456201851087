import { useTranslation } from 'react-i18next';
import {
  Regulated,
  AccountBalence,
  AttachMoney,
  Storage,
  Update,
  Filled,
  TimCahill,
  BgImg
} from 'assets/sidesIcons';

import { StyledSidesIconsWrap } from './style';

interface IIconItem {
  title: string;
  subtitle: string;
  src: string;
}
export interface SidesIconsWrapProps {
  bgSrc: string;
}

const SidesIcons = (): JSX.Element => {
  const { t } = useTranslation('common');
  const images = [Regulated, AccountBalence, AttachMoney, Storage, Update, Filled];
  const data = t('itemsASIC', { returnObjects: true }) as Array<IIconItem>;

  return (
    <StyledSidesIconsWrap bgSrc={BgImg}>
      <div className="icons-container">
        {data.map((item, index) => (
          <div className="icon-item" key={item.title}>
            <img src={images[index]} alt={item.title} aria-label={`advantage icon ${index + 1}`} />
            <h6 aria-label={`advantage title ${index + 1}`}>{item.title}</h6>
            <p aria-label={`advantage desc ${index + 1}`}>{item.subtitle}</p>
          </div>
        ))}
      </div>
      <img src={TimCahill} alt="" className="bottom-img" />
    </StyledSidesIconsWrap>
  );
};

export default SidesIcons;
