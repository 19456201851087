import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { TableBody } from '@mui/material';
import { useAccountListState } from 'containers/AccountList/context/accountListContext';
import {
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
  StyledTableWrapper
} from './style';
import Row from './Row';

const CollapsibleTable = (): JSX.Element => {
  const { t } = useTranslation('accountList');

  const { accountList } = useAccountListState();

  return (
    <TableContainer component={StyledTableWrapper}>
      <Table>
        <StyledTableHead>
          <StyledTableRow>
            <StyledTableCell style={{ width: '5%' }} />
            <StyledTableCell aria-label="Table head name">
              {t('name')}
            </StyledTableCell>
            <StyledTableCell aria-label="Table head type">
              {t('type')}
            </StyledTableCell>
            <StyledTableCell aria-label="Table head status">
              {t('status')}
            </StyledTableCell>
            <StyledTableCell aria-label="Table head regulation">
              {t('regulation')}
            </StyledTableCell>
            <StyledTableCell
              aria-label="Table head action"
              style={{ width: '15%' }}
            >
              {t('action')}
            </StyledTableCell>
          </StyledTableRow>
        </StyledTableHead>
        <TableBody>
          {accountList.map((account, index) => (
            <Row key={account.id} rowData={account} isOdd={index % 2 !== 0} index={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollapsibleTable;
