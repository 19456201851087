import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

const handleStatusColor = (active: boolean, warning: boolean, isRejectMode?: boolean): string => {
  if (warning) return '#F98600';
  if (active || isRejectMode) return '#145dB5';
  return '#5c5c5c';
};

export const StyledCircle = styled.div<{isLastItem?: boolean, isActive: boolean, hasWarning?: boolean}>`
  width: 32px;
  height: 32px;
  border: 1px solid #013B81;
  border-color: ${(props) => handleStatusColor(props.isActive, !!props.hasWarning)};
  color: ${(props) => handleStatusColor(props.isActive, !!props.hasWarning)};
  border-radius: 24px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  > svg {
    width: 32px;
    height: 32px;
  }

  @media ${device.mobileMaxWidth} {
    width: 24px;
    height: 24px;

    > svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const StyledSteps = styled.div<{direction: 'horizontal' | 'vertical'}>`
  background: #ffffff;
  display: flex;
  flex-direction: ${(props) => (props.direction === 'horizontal' ? 'row' : 'column')};
  width: 100%;
  align-items: center;
  padding: 42px 40px;
  border-radius: 12px;
  box-shadow: 0px 1px 4px rgba(90, 114, 143, 0.1), 0px 1px 3px rgba(90, 114, 143, 0.16);
  justify-content: space-between;

  @media ${device.tablet} {
    padding: 42px 24px;
  }

  @media ${device.mobileMaxWidth} {
    padding: 24px 16px;
  }
`;

export const StyledStepItem = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
`;

export const StyledStep = styled.div<{direction: 'horizontal' | 'vertical'}>`
  flex-direction: ${(props) => (props.direction === 'horizontal' ? 'column' : 'row')};
  display: flex;
  gap: 10px;
  @media ${device.tabletMaxWidth} {
    flex-direction: ${(props) => (props.direction === 'horizontal' ? 'row' : 'column')};
    word-break: break-all;
    align-items: center;
  }
`;

export const StyledProgressTick = styled.div`
  display: flex;
  align-items: center;

  > svg {
    width: 32px;
    height: 32px;
  }

  @media ${device.mobileMaxWidth} {
    > svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const StyledDescription = styled.div<{ isActive: boolean, hasWarning: boolean, isRejectMode: boolean }>`
  font-size: 14px;
  color: ${(props) => handleStatusColor(props.isActive, props.hasWarning, props.isRejectMode)};
  @media ${device.tabletMaxWidth} {
    display: none;
  }
`;

export const StyledMobileDescription = styled.span<{ hasWarning: boolean, isRejectMode: boolean }>`
  font-size: 14px;
  color: ${(props) => handleStatusColor(true, props.hasWarning, props.isRejectMode)};

  @media ${device.desktopMinWidth} {
    display: none;
  }

  @media ${device.mobileMaxWidth} {
    font-size: 12px;
  }
`;
