import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import {
  sideMenuOpenWidth,
  sideMenuCloseWidth
} from 'containers/SideMenu/styled';

export const StyledIconWrap = styled.div<{ isMenuOpen: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => (props.theme.isRTL ? '0 0 0 10px' : '0 10px 0 0')};
  font-size: 24px;
`;

export const StyledSideMenuRow = styled.div<{ isMenuOpen: boolean }>`
  max-width: ${sideMenuOpenWidth}px;
  display: flex;
  justify-content: center;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 0 20px;
  box-sizing: border-box;
  width: ${(props) => (props.isMenuOpen ? '100%' : sideMenuCloseWidth)};

  @media ${device.tablet} {
    max-width: 460px;
  }
  @media ${device.mobileMaxWidth} {
    max-width: 100%;
  }
`;

export const menuCloseStyle = (): string => `
  font-size: 22px;
`;

export const menuRowMixIn = (bgColor: string): string => `
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 8px 12px;

  white-space: nowrap;
  border-radius: 4px;
  &:hover {
    background-color: ${bgColor}
  }
`;

export const StyledSideMenuSubheader = styled.div<{ isMenuOpen: boolean }>`
  color: ${(props) => props.theme.sideMenu.primary};
  ${(props) => menuRowMixIn(props.theme.sideMenu.hover)};
  ${(props) => !props.isMenuOpen && menuCloseStyle};
`;

export const StyledTitleWrap = styled.div<{ isMenuOpen: boolean }>`
  width: calc(100% - 40px);
  font-size: 14px;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

export const StyledTriangleWrap = styled.div<{ isListOpen: boolean }>`
  > svg {
    width: 10px;
    height: 9px;
    ${(props) => props.isListOpen && 'transform: rotate(180deg);'};
  };
`;

export const StyledNotification = styled.div<{ isListOpen: boolean }>`
  text-align: right;
  margin-right: 6px;
  background-color: #E92C2C;
  padding: 0 8px;
  border-radius: 8px;
`;

export const StyledSmallNotificaitionBadgeContainer = styled.div`position: relative;`;

export const StyledSmallNotificaitionBadge = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  left: -4px;
  bottom: -14px;
  border: 1px solid #3E3E3E;
  background-color: red;
  border-radius: 12px;
`;

export const StyledSideMenuButton = styled.div<{
  isActive: boolean
  hasIcon: boolean
  isMenuOpen: boolean
}>`
  ${(props) => menuRowMixIn(props.theme.sideMenu.hover)};
  ${(props) => !props.isMenuOpen && menuCloseStyle()};
  color: ${(props) => (props.hasIcon ? props.theme.sideMenu.primary
    : props.theme.sideMenu.secondary)};
  background-color: ${(props) => props.isActive && props.theme.sideMenu.active} !important;
  transition: 0.5s;
`;

export const StyledCollapseContainer = styled.div`
  .side-menu-btn{
    padding: ${(props) => (props.theme.isRTL ? '0 46px 0 0' : '0 0 0 46px')};
  }
`;
