import Snackbar from '@mui/material/Snackbar';
import IconComponent from 'components/Icons';
import { StyledAlert, StyledAlertContent, StyledAlertTitle } from './style';

enum SeverityIcon {
  success = 'CheckCircle',
  error = 'CrossCircle'
}

export type AlertToastProps = {
  isOpen: boolean;
  handleClose: () => void;
  severity: 'success' | 'error';
  title: string;
  content: string;
};

const AlertToast = ({
  isOpen,
  handleClose,
  severity,
  title,
  content
}: AlertToastProps): JSX.Element => (
  <Snackbar
    open={isOpen}
    autoHideDuration={3000}
    onClose={handleClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  >
    <StyledAlert action={(
      <IconComponent
        onClick={handleClose}
        aria-label="Alert Close Icon"
        name="Close"
        style={{ width: '12px', height: '12px' }}
      />
        )}
    >
      <StyledAlertTitle aria-label="Alert Toast Title">
        <IconComponent name={SeverityIcon[severity]} aria-label="Status Icon" />
        {title}
      </StyledAlertTitle>
      <StyledAlertContent aria-label="Alert Toast Content">{content}</StyledAlertContent>
    </StyledAlert>
  </Snackbar>
);

export default AlertToast;
