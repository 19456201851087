import IconComponent from 'components/Icons';
import { useTranslation } from 'react-i18next';
import {
  StyledModal, StyledModalWrapper, StyledHeader, StyledIconWrapper, StyledCloseIcon, StyledContent
} from './style';

interface PopUpProps {
  id?: string;
  className?: string;
  header?: string;
  icon?: string;
  openModal: boolean;
  onClose?: () => void;
  children?: React.ReactNode,
  showLine?: boolean;
}

const PopUp = ({
  openModal, onClose, children, header, icon, className, id, showLine
}: PopUpProps): JSX.Element => {
  const { i18n } = useTranslation('demoAccount');
  return (
    <StyledModalWrapper
      aria-label={id ? `${id} Pop Up` : 'Pop Up'}
      open={openModal}
      onClose={onClose}
    >
      <StyledModal id="Demo_Account_Success" className={className}>
        {header && (
          <StyledHeader showLine={showLine}>
            <div aria-label={`${id} header` || 'header'}>
              {icon && (
                <StyledIconWrapper curLang={i18n.language}>
                  <IconComponent name={icon} style={{ width: '24px', height: '24px' }} />
                </StyledIconWrapper>
              )}
              {header}
            </div>
            <StyledCloseIcon onClick={onClose}>
              <IconComponent name="DemoAccountClose" aria-label="close" />
            </StyledCloseIcon>
          </StyledHeader>
        )}
        <StyledContent>{children}</StyledContent>
      </StyledModal>
    </StyledModalWrapper>
  );
};

export default PopUp;
