import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledVideoCard = styled.div`
  width: calc((100% - 48px) / 4);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding-bottom: 8px;
  @media ${device.tablet} {
    width: calc((100% - 16px) / 2);
  }
  @media ${device.mobileMaxWidth} {
    width: 100%;
  }
`;

export const StyledVideoImage = styled.img`
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  background-size: center;
  background-repeat: no-repeat;
  margin: 0;
  height: 11vw;
  @media ${device.tablet} {
    height: 156px;
  } 
  @media ${device.mobileMaxWidth} {
    height: 50vw;
  } 
`;

export const StyledContent = styled.div`       
  padding: 8px;
  margin: 0 0 24px 0;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledVideoTitle = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1C1C1C;
`;

export const StyledDateContainer = styled.span`
  padding: 0 8px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 400;
  color: #BBBBBB;
`;

export const StyledText = styled.p<{ fontSize: number }>`
  margin: 0;
  font-size: ${({ fontSize }) => `${fontSize}px`}
`;
