/* eslint-disable max-len */
export const accountTypeData = [
  {
    imgSrc: '/PersonalAccount.png',
    title: 'personalAccount',
    type: 'personal',
    content: 'personalAccountContent'
  },
  {
    imgSrc: '/CorporateAccount.png',
    title: 'corporateAccount',
    type: 'corporate',
    content: 'corporateAccountContent'
  }
  // {
  //   imgSrc: '/JointAccount.png',
  //   title: 'jointAccount',
  //   type: 'joint',
  //   content: 'jointAccountContent'
  // }
];
