import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledEnrolledTrainingWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0 0 16px 0;
  flex-wrap: wrap;
  gap: 0 32px;
  width: 100%;
  @media only screen and (max-width: 1439px) {
    gap: 0 24px;
  }
`;

export const StyledEnrolledTrainingContainer = styled.div`
  width: calc((100% - 64px)/ 3);
  display: flex;
  flex-direction: column;
  padding: 16px 16px 8px;
  background-color: #FFFFFF;
  border-left: 2px solid #0282D0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  margin: 16px 0 0 0;
  justify-content: space-between;
  @media only screen and (max-width: 1439px) {
    width: calc((100% - 48px)/ 3);
  }
  @media ${device.tablet} {
    width: 100%;
  }
  @media ${device.mobileMaxWidth} {
    width: 100%;
  }
`;

export const StyledTitleContainer = styled.div`
  justify-content: space-between;
  flex-direction: column;
  display: flex;
`;

export const StyledTitle = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1C1C1C;
`;

export const StyledSubTitle = styled.div`
  margin: 8px 0 8px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #5C5C5C;
  @media ${device.tablet} {
    font-size: 12px;
    line-height: 16px;
  }
  @media ${device.mobileMaxWidth} {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const StyledZoomLink = styled.a`
  color: #0282D0;
  font-size: 14px;
  margin: 8px 0 0 0;
  text-decoration: none;
`;

export const StyledTimezone = styled.p`
  color: #0282D0;
  font-size: 14px;
  margin: 8px 0 0 0;
`;

export const StyledTimerContainer = styled.div`
  border-top: 1px solid #DDDDDD;
  width: 100%;
  margin-top: 8px;
`;

export const StyledTimer = styled.p`
  font-size: 14px;
  margin: 8px 0 0 0;
  color: rgba(28, 28, 28, 1);
  span {
    color: rgba(92, 92, 92, 1);
  }
`;

export const StyledEnrolledCoursesHeader = styled.div`
  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-transform: capitalize;
    color: #1C1C1C;
    margin: 0;
    @media ${device.tabletMaxWidth} {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
