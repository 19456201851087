/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import FormPanel from 'components/FormPanel';
import ItemLabel from 'components/ItemLabel';
import RadioGroup from 'components/RadioGroup';
import { getExperienceForm, createExperienceTest } from 'api/v1/account';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { useCreateAccountFlow } from 'store/context/hooks';
import { useLocation } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import { checkFieldIsWarning } from 'containers/CreateAccountForm/utils';
import { CreateAccountSteps } from 'constant/createAccount';
import { StyledContentWrap, StyledBannerDesp, StyledLoadingWrap } from '../style';
import { StyledTradingExperience, StyledRadioWrap, StyledQuestionWrap } from './style';

interface QuestionProps {
  id: string;
  type: string;
  name: string;
  placeHolder: string;
  required: boolean;
  data: {
    label: string;
    value: string;
  }[];
}

const TradingExperience = ():JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const { control, setValue } = useFormContext();
  const [tradingExperience, setTradingExperience] = useState([]);
  const { rejectWarningFields, updateFormIsLoading } = useCreateAccountFlow();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    updateFormIsLoading(true);
    // will change 3111 to account ID when the email link is ready
    const accountID = localStorage.getItem('accountID') || '';
    getExperienceForm(accountID).then((res) => {
      if (res && res.data.length > 0) {
        setValue('rg227_test_id', res?.data[0]?.testId);
        setTradingExperience(res?.data[0]?.questions || []);
        updateFormIsLoading(false);
        setIsLoading(false);
      } else if (location.pathname === '/create-account/personal-account') {
        // only the create account flow can create new test
        createExperienceTest(accountID).then((response) => {
          setValue('rg227_test_id', response?.data[0]?.testId);
          setTradingExperience(response?.data[0]?.questions || []);
        }).finally(() => {
          updateFormIsLoading(false);
          setIsLoading(false);
        });
      } else {
        updateFormIsLoading(false);
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <StyledTradingExperience>

      <FormPanel id="Trading Experience" title={t('tradingExperience')}>
        {
          isLoading
            ? (
              <StyledLoadingWrap>
                <CircularProgress />
              </StyledLoadingWrap>
            )
            : (
              <>
                <StyledBannerDesp aria-label="Trading Experience Description">
                  {t('answerTenQuestion')}
                </StyledBannerDesp>

                <StyledContentWrap className="StyledContentWrap">
                  {tradingExperience.map((tradingQuesObj: QuestionProps, index) => {
                    if (tradingQuesObj.type === 'radio') {
                      return (
                        <StyledQuestionWrap key={tradingQuesObj.id}>
                          <ItemLabel id={tradingQuesObj.id} title={`${index + 1}. ${tradingQuesObj.name}`}>
                            <StyledRadioWrap>
                              <Controller
                                control={control}
                                name={tradingQuesObj.id}
                                rules={{ required: true }}
                                render={({
                                  field: {
                                    onChange, value
                                  }
                                }) => (
                                  <RadioGroup
                                    options={tradingQuesObj.data}
                                    groupName={tradingQuesObj.id}
                                    onChange={onChange}
                                    value={value}
                                    disabled={checkFieldIsWarning(rejectWarningFields, CreateAccountSteps.Experience, tradingQuesObj.id)}
                                  />
                                )}
                              />
                            </StyledRadioWrap>
                          </ItemLabel>
                        </StyledQuestionWrap>
                      );
                    }

                    return (
                      <StyledQuestionWrap key={tradingQuesObj.id}>
                        <ItemLabel id={tradingQuesObj.id} title={`${index + 1}. ${tradingQuesObj.name}`}>
                          <StyledRadioWrap>
                            <Controller
                              control={control}
                              name={tradingQuesObj.id}
                              rules={{ required: true }}
                              render={({
                                field: {
                                  onChange, value
                                }
                              }) => (
                                <RadioGroup
                                  options={tradingQuesObj.data}
                                  groupName={tradingQuesObj.id}
                                  optionType="button"
                                  onChange={onChange}
                                  value={value}
                                  disabled={checkFieldIsWarning(rejectWarningFields, CreateAccountSteps.Experience, tradingQuesObj.id)}
                                />
                              )}
                            />
                          </StyledRadioWrap>
                        </ItemLabel>
                      </StyledQuestionWrap>
                    );
                  })}
                </StyledContentWrap>
              </>
            )
}
      </FormPanel>
    </StyledTradingExperience>
  );
};

export default TradingExperience;
