import Button from 'components/Button';
import IconComponent from 'components/Icons';
import { useTranslation } from 'react-i18next';
import {
  StyledLButtonsWrap, StyledModalContent, StyledModalWrapper, StyledPopUpTitle
} from './style';

interface PopUpProps {
  title: string;
  contents: JSX.Element;
  openModal: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  children?: React.ReactNode
}

const CustomPopUp = ({
  openModal, onConfirm, onClose, title, contents, children
}: PopUpProps): JSX.Element => {
  const { t } = useTranslation('createAccount');
  return (
    <StyledModalWrapper
      aria-label="Pop Up"
      open={openModal}
      onClose={onClose}
    >
      <StyledModalContent>
        <div>
          <IconComponent name="Alert" aria-label="Alert Icon" />
          <StyledPopUpTitle aria-label="Pop Up Title">
            {title}
          </StyledPopUpTitle>
        </div>
        {contents}
        <StyledLButtonsWrap>
          {children || (
          <>
            <Button aria-label="Pop Up Cancel" onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button aria-label="Pop Up Confirm" onClick={onConfirm}>
              {t('change')}
            </Button>
          </>
          )}
        </StyledLButtonsWrap>
      </StyledModalContent>
    </StyledModalWrapper>
  );
};

export default CustomPopUp;
