import * as React from "react";
import { SVGProps } from "react";
const SvgThankYouCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 63 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M63 31.5C63 48.897 48.897 63 31.5 63S0 48.897 0 31.5 14.103 0 31.5 0 63 14.103 63 31.5ZM27.856 48.179l23.371-23.371a2.032 2.032 0 0 0 0-2.874l-2.874-2.874a2.032 2.032 0 0 0-2.874 0L26.42 38.12l-8.898-8.899a2.032 2.032 0 0 0-2.874 0l-2.874 2.874a2.032 2.032 0 0 0 0 2.874l13.21 13.21c.793.794 2.08.794 2.873 0Z"
      fill="url(#ThankYouCheck_svg__a)"
    />
    <defs>
      <linearGradient
        id="ThankYouCheck_svg__a"
        x1={7.159}
        y1={11.097}
        x2={49.361}
        y2={57.479}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#06D68A" />
        <stop offset={1} stopColor="#00935E" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgThankYouCheck;
