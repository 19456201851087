import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledMainWrap = styled.div`
  padding: 0 64px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media ${device.tablet} {
    padding: 0 16px;
  }
  @media ${device.mobileMaxWidth} {
    padding: 0 16px;
    margin-top: 12px;
  }
`;

export const StyledHeader = styled.div`
  height: 72px;
  border-bottom: 1px solid #DDDDDD; 
  width: 100%;
  @media ${device.mobileMaxWidth} {
    height: 60px;
  }
`;

export const StyledHeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90px;
  height: 100%;
  border-bottom: 1px solid #0282D0;
  @media ${device.mobileMaxWidth} {
    width: 56px;
  }
  h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    text-transform: capitalize;
    color: #1C1C1C;
    margin: 16px 0;
    @media ${device.mobileMaxWidth} {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  margin-top: 40px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  a {
    color:#0282D0;
    text-decoration: none;
  }
  @media ${device.tablet} {
    padding: 16px;
  }
  @media ${device.mobileMaxWidth} {
    margin-top: 24px;
    padding: 8px;
  }
`;

export const StyledVideoWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px;
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
  h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-transform: capitalize;
    color: #1C1C1C;
    margin: 8px 0;
  }
`;

export const StyledVideoImg = styled.iframe`
  width: 100%;
  height: 558px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  @media ${device.mobileMaxWidth} {
    height: 184px;
  }
`;

export const StyledVideoInfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  width: 100%;
  height: 16px;
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5C5C5C;
  }
`;

export const StyledVideoMessageWrap = styled.div`
  font-weight: 400;
  line-height: 16px;
  font-size: 12px;
  color: #5C5C5C;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  p {
    margin: 0;
    width: 100%;
    overflow-wrap: break-word;
  }
`;
