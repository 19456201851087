import IconComponent from 'components/Icons';
import {
  StyledActionGroup,
  StyledBox,
  StyledContent,
  StyledContentDescription,
  StyledContentTitle,
  StyledHeader,
  StyledPaper,
  StyledTitle
} from './style';

enum SeverityIcon {
  success = 'CheckCircleFilled',
  warning = 'WarningCircleFilled',
}

enum SeverityColor {
  success = '#00B875',
  warning = '#F98600',
}

type ResponseMessagePaper = {
  severity: 'success' | 'warning';
  title: string;
  contentTitle: string;
  contentDescription: string | JSX.Element;
  actionButtonSlot?: JSX.Element;
};

const ResponseMessagePaper = ({
  severity,
  title,
  contentTitle,
  contentDescription,
  actionButtonSlot
}: ResponseMessagePaper): JSX.Element => (
  <StyledPaper>
    <StyledHeader>
      <StyledTitle aria-label="title">{title}</StyledTitle>
    </StyledHeader>
    <StyledContent>
      <StyledBox>
        <IconComponent aria-label="icon" name={SeverityIcon[severity]} color={SeverityColor[severity]} />
        <StyledContentTitle aria-label="content title">{contentTitle}</StyledContentTitle>
        <StyledContentDescription aria-label="content desc">
          {contentDescription}
        </StyledContentDescription>
        {actionButtonSlot && (
          <StyledActionGroup aria-label="action button">{actionButtonSlot}</StyledActionGroup>
        )}
      </StyledBox>
    </StyledContent>
  </StyledPaper>
);

export default ResponseMessagePaper;
