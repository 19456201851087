import { useState } from 'react';
import IconComponent from 'components/Icons';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { updateDemoAccount } from 'api/v1/demoAccount';
import CircularProgress from '@mui/material/CircularProgress';
import PopUp from '../PopUp';
import {
  StyledInputWrap, StyledPopUpTitle, StyledPopUpText, StyledItem, StyledPassword, StyledEyeIcon,
  StyledCriteria, StyledCriteriaBlock, StyledCorrectIcon, StyledPopUpFooter, StyledChangeButton, StyledContentWrap, StyledCircularProgressWrap
} from './style';

interface AccountSettingsPopUpProps {
  accountNumber: string;
  accountName: string;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  onSave: () => void;
  updateCurrentAccountName: (val: string) => void;
}

export interface IFormInput {
  name: string;
  password: string;
}

const AccountSettingsPopUp = ({
  openModal, setOpenModal, accountNumber, accountName, onSave, updateCurrentAccountName
}: AccountSettingsPopUpProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [settingsMode, setSettingsMode] = useState<'setting' | 'changeName' | 'changePassword'>('setting');
  const [passwordValid, setPasswordValid] = useState({
    isValidLength: false,
    isValidUpper: false,
    isValidLower: false,
    isValidNumber: false,
    isValidSpecialChar: false
  });
  const { t } = useTranslation('demoAccount');

  const passwordRules = [
    { title: `${t('charactersLimit')}`, valid: passwordValid.isValidLength, ariaLabel: 'password hint1' },
    { title: `${t('lowercaseLetter')}`, valid: passwordValid.isValidLower, ariaLabel: 'password hint2' },
    { title: `${t('uppercaseLetter')}`, valid: passwordValid.isValidUpper, ariaLabel: 'password hint3' },
    { title: `${t('1number')}`, valid: passwordValid.isValidNumber, ariaLabel: 'password hint4' },
    { title: `${t('1specialChar')}`, valid: passwordValid.isValidSpecialChar, ariaLabel: 'password hint5' }
  ];

  const {
    control, formState: { errors, isValid }, handleSubmit, reset
  } = useForm<IFormInput>({ mode: 'all' });

  const renderContent = (): JSX.Element => {
    switch (settingsMode) {
      case 'setting':
        return (
          <>
            <StyledItem>
              <StyledPopUpTitle aria-label="Account Setting">{t('accountName')}</StyledPopUpTitle>
              <StyledPopUpText aria-label="Account Name Label">{accountName}</StyledPopUpText>
              <StyledPopUpText aria-label="Account Name" />
              <StyledChangeButton onClick={() => { setSettingsMode('changeName'); }}>
                <span aria-label="Change Account Name Button">{t('changeName')}</span>
              </StyledChangeButton>
            </StyledItem>
            <StyledItem>
              <StyledPopUpTitle aria-label="Account Password Label">{t('password')}</StyledPopUpTitle>
              <StyledChangeButton onClick={() => { setSettingsMode('changePassword'); }}>
                <span aria-label="Change Account Password Button">{t('changePassword')}</span>
              </StyledChangeButton>
            </StyledItem>
          </>
        );
      case 'changeName':
        return (
          <StyledItem>
            <StyledInputWrap aria-label="account name error" hasErrMsg={!!errors?.name?.message}>
              <Controller
                control={control}
                name="name"
                defaultValue={accountName}
                rules={{
                  required: `${t('fieldMandatory')}`,
                  maxLength: {
                    value: 40,
                    message: `${t('maxChars40')}`
                  }
                }}
                render={({
                  field: {
                    onChange,
                    value
                  }
                }) => (
                  <Input
                    height="48px"
                    placeholder={t('enterAccountName')}
                    onChange={(val) => onChange(val)}
                    errorMsg={typeof errors?.name?.message === 'string' ? errors?.name?.message : ''}
                    value={value || ''}
                    id="Enter your account name"
                  />
                )}
              />
            </StyledInputWrap>
            <StyledPopUpFooter>
              <Button
                disabled={!isValid}
                aria-label="Save Button"
                onClick={handleSubmit((data) => {
                  setIsLoading(true);
                  updateDemoAccount({ accountNumber, accountName: data.name, password: '' }).then((res) => {
                    if (res.status === 200) {
                      setSettingsMode('setting');
                      updateCurrentAccountName(data.name);
                      onSave();
                    }
                  }).finally(() => setIsLoading(false));
                })}
              >
                {t('save')}
              </Button>
            </StyledPopUpFooter>
          </StyledItem>
        );
      case 'changePassword':
        return (
          <StyledItem>
            <StyledInputWrap hasErrMsg={false}>
              <Controller
                control={control}
                name="password"
                rules={{ required: true }}
                render={({
                  field: {
                    onChange,
                    value
                  }
                }) => (
                  <StyledPassword>
                    <Input
                      height="48px"
                      placeholder={t('enterPassword')}
                      type={showPassword ? 'text' : 'password'}
                      value={value || ''}
                      onChange={(val) => {
                        setPasswordValid({
                          isValidLength: val.target.value.length <= 20 && val.target.value.length >= 8,
                          isValidUpper: /[A-Z]/.test(val.target.value),
                          isValidLower: /[a-z]/.test(val.target.value),
                          isValidNumber: /\d/.test(val.target.value),
                          isValidSpecialChar: /[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/]|]/.test(val.target.value)
                        });

                        onChange(val);
                      }}
                      id="Enter your password"
                    />
                    <StyledEyeIcon
                      role="presentation"
                      onClick={() => setShowPassword((prev) => !prev)}
                      aria-label="Password Icon"
                    >
                      <IconComponent name={showPassword ? 'Show' : 'Hide'} />
                    </StyledEyeIcon>
                  </StyledPassword>
                )}
              />
              <StyledCriteriaBlock>
                {passwordRules.map((item) => (
                  <StyledCriteria isValid={item.valid} key={item.title} aria-label={item.ariaLabel}>
                    <StyledCorrectIcon>
                      {item.valid && <IconComponent name="Check" />}
                    </StyledCorrectIcon>
                    {item.title}
                  </StyledCriteria>
                ))}
              </StyledCriteriaBlock>
            </StyledInputWrap>
            <StyledPopUpFooter>
              <Button
                disabled={Object.values(passwordValid).some((el) => el === false)}
                aria-label="Save Button"
                onClick={handleSubmit((data) => {
                  setIsLoading(true);
                  updateDemoAccount({ accountNumber, accountName: '', password: data.password }).then((res) => {
                    if (res.status === 200) {
                      setSettingsMode('setting');
                      setPasswordValid({
                        isValidLength: false,
                        isValidUpper: false,
                        isValidLower: false,
                        isValidNumber: false,
                        isValidSpecialChar: false
                      });
                      onSave();
                    }
                  }).finally(() => setIsLoading(false));
                })}
              >
                {t('save')}
              </Button>
            </StyledPopUpFooter>
          </StyledItem>
        );

      default:
        return <div />;
    }
  };

  const handleClose = (): void => {
    reset();
    if (settingsMode === 'setting') {
      setOpenModal(false);
    } else {
      setSettingsMode('setting');
    }
    if (settingsMode === 'changePassword') {
      setPasswordValid({
        isValidLength: false,
        isValidUpper: false,
        isValidLower: false,
        isValidNumber: false,
        isValidSpecialChar: false
      });
    }
  };

  return (
    <PopUp
      id="Account Setting"
      header={t(settingsMode)}
      openModal={openModal}
      showLine
      onClose={() => {
        if (!isLoading) handleClose();
      }}
      className="custom-popup"
    >
      <StyledContentWrap>
        {
          isLoading
            ? (
              <StyledCircularProgressWrap>
                <CircularProgress />
              </StyledCircularProgressWrap>
            )
            : renderContent()
        }
      </StyledContentWrap>
    </PopUp>
  );
};

export default AccountSettingsPopUp;
