import * as React from "react";
import { SVGProps } from "react";
const SvgArrowBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10.525c0-.209-.079-.41-.22-.558a.731.731 0 0 0-.53-.231H8.56l3.221-3.389c.14-.148.22-.349.22-.558 0-.21-.08-.411-.22-.56A.732.732 0 0 0 11.25 5c-.2 0-.39.082-.531.23l-4.5 4.737a.825.825 0 0 0 0 1.118l4.5 4.737c.14.148.332.232.531.232.2 0 .39-.084.531-.232.14-.148.22-.35.22-.559 0-.21-.08-.41-.22-.559l-3.22-3.388h8.689c.199 0 .39-.083.53-.231a.811.811 0 0 0 .22-.559Z"
      fill="#585757"
    />
  </svg>
);
export default SvgArrowBack;
