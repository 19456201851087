import * as React from "react";
import { SVGProps } from "react";
const SvgChat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#Chat_svg__a)">
      <path
        d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2ZM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 1 1 0-1Zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 1 1 0-1Zm0 2.5h5a.5.5 0 1 1 0 1h-5a.5.5 0 1 1 0-1Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="Chat_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgChat;
