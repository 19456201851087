/* eslint-disable camelcase */
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getDemoAccounts, deleteDemoAccount } from 'api/v1/demoAccount';
import { isEmpty } from 'lodash';
import IconComponent from 'components/Icons';
import BackdropSpinner from 'components/BackdropSpinner';
import { AccountSettingsPopUp, SuccessPopUp } from 'containers/DemoAccount';
import CreateAccountPopUp from 'containers/DemoAccount/CreateAccountPopUp';
import DeleteAccountPopUp from 'containers/DemoAccount/DeleteAccountPopup';
import AddVirtualFunds, { DemoAccountType } from 'containers/DemoAccount/AddVirtualFundsPopUp ';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store';
import ErrorHandler from 'utils/ErrorHandler';
import CreateDemoAccount from './CreateDemoAccount';
import DemoAccountCard from './DemoCard';
import {
  StyledDemoTitle, StyledDemoAccountWrapper, StyledActiveWrapper, StyledTitle,
  StyledCardWrapper, StyledPlusIcon
} from './style';

interface DemoAccountTypes {
  active?: {
    [key: string]: string;
  }[];
  expired?: {
    [key: string]: string;
  }[];
}

export interface SuccessInfoTypes {
  account_server: string;
  account_number: string;
  server_name?: string;
  password: string;
  trading_platform: string;
  download_center: {
    [key: string]: string;
  }
}

export interface Account {
  [key: string]: string;
}

const MAX_ACCOUNTS = 3;

const DemoAccount = (): JSX.Element => {
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [demoAccounts, setDemoAccounts] = useState<DemoAccountTypes>({});
  const [initLoading, setInitLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openCreateAccountModal, setOpenCreateAccountModal] = useState(false);
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState(false);
  const [openAddVirtualFundsModal, setOpenAddVirtualFundsModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const successInfoRef = useRef<SuccessInfoTypes | null>();
  const demoAccountsRef = useRef<{ account_number: string, name: string, id: string,
    total_balance: string, max_balance: string, currency: string }>({
      account_number: '', total_balance: '', max_balance: '', name: '', id: '', currency: ''
    });
  const dispatch = useAppDispatch();
  const { t } = useTranslation('demoAccount');

  useEffect(() => {
    getDemoAccounts().then((res) => {
      if (res.status === 200) {
        setDemoAccounts(res.data);
        setButtonDisabled(((res.data?.active?.length || 0) + (res.data?.expired?.length || 0) >= MAX_ACCOUNTS));
        setInitLoading(false);
      }
    });
  }, []);

  const createAccountOnSave = (successInfo?: SuccessInfoTypes): void => {
    setIsLoading(true);
    setOpenSuccessModal(true);
    successInfoRef.current = successInfo;
    getDemoAccounts().then((res) => {
      if (res.status === 200) {
        setDemoAccounts(res.data);
        setButtonDisabled(((res.data?.active?.length || 0) + (res.data?.expired?.length || 0) >= MAX_ACCOUNTS));
      }
    }).finally(() => setIsLoading(false));
  };

  const updateAccountOnSave = (): void => {
    setIsLoading(true);
    getDemoAccounts().then((res) => {
      if (res.status === 200) {
        setDemoAccounts(res.data);
      }
    }).finally(() => setIsLoading(false));
  };
  const handleAddVirtualFunds = (account: Account):void => {
    demoAccountsRef.current = {
      ...demoAccountsRef.current,
      account_number: account.account_number,
      total_balance: account.total_balance,
      max_balance: account.max_balance,
      currency: account.currency
    };
    setOpenAddVirtualFundsModal(true);
  };
  const handleCardDelete = (account: Account):void => {
    demoAccountsRef.current = {
      ...demoAccountsRef.current,
      account_number: account.account_number
    };
    setOpenDeleteAccountModal(true);
  };

  const formatNumber = (value: number, currency: string): string => value.toLocaleString('en-US', {
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const updateAmount = (inputValue: string, demoAccount: DemoAccountType): void => {
    setDemoAccounts((prevDemoAccounts) => {
      const updatedAccounts = (prevDemoAccounts?.active || []).map((account) => {
        if (account.account_number === demoAccount.account_number) {
          const currency = account.total_balance.match(/[a-zA-Z]+/g)?.[0] || '';
          const totalBalanceNumeric = parseFloat(account.total_balance.replace(/[^0-9.-]+/g, ''));
          const inputValueNumeric = parseFloat(inputValue.replace(/[^0-9.-]+/g, ''));

          const newTotalBalance = formatNumber(totalBalanceNumeric + inputValueNumeric, currency);
          const fundsNumeric = parseFloat(account.funds.replace(/[^0-9.-]+/g, ''));
          const newFunds = formatNumber(fundsNumeric + inputValueNumeric, currency);

          const newTotalBalanceWithCurrency = `${newTotalBalance} ${currency}`;

          return {
            ...account,
            total_balance: newTotalBalanceWithCurrency,
            funds: newFunds
          };
        }
        return account;
      });

      return {
        ...prevDemoAccounts,
        active: updatedAccounts
      };
    });
  };

  const handleDeleteAccount = ():void => {
    setIsLoading(true);
    const updatedAccounts = Array.isArray(demoAccounts?.expired)
      ? demoAccounts.expired.filter((acc) => acc.account_number !== demoAccountsRef.current.account_number)
      : [];

    const accountNumber = demoAccountsRef.current.account_number;

    ErrorHandler(
      deleteDemoAccount(accountNumber),
      dispatch
    ).then((res) => {
      if (res.status === 200) {
        setDemoAccounts({
          ...demoAccounts,
          expired: updatedAccounts
        });
        setButtonDisabled(((demoAccounts?.active?.length || 0) + (updatedAccounts.length)) >= MAX_ACCOUNTS);
      }
    }).finally(() => setIsLoading(false));
    setOpenDeleteAccountModal(false);
  };

  if (initLoading) {
    return <BackdropSpinner open={initLoading} />;
  }

  return (
    <StyledDemoAccountWrapper>
      <BackdropSpinner open={isLoading && !(openModal || openCreateAccountModal || openSuccessModal)} />
      <StyledDemoTitle>
        <span aria-label="demo accounts">{t('demoAccounts')}</span>
        {!isEmpty(demoAccounts?.active) && (
          <StyledPlusIcon
            id="Additional_Demo_Account"
            disabled={
              buttonDisabled
            }
            onClick={() => {
              setOpenCreateAccountModal(!buttonDisabled);
            }}
          >
            <IconComponent name="DemoAccountPlus" />
            <span aria-label="add demo account button">{t('addDemoAccount')}</span>
          </StyledPlusIcon>
        )}
      </StyledDemoTitle>
      {isEmpty(demoAccounts?.active) && ((demoAccounts?.expired?.length || 0) < MAX_ACCOUNTS) ? (
        <CreateDemoAccount onSave={createAccountOnSave} />
      ) : null}

      {!isEmpty(demoAccounts?.active) && (
        <StyledActiveWrapper>
          <StyledTitle>
            <div />
            <span aria-label="active account">{t('active')}</span>
          </StyledTitle>
          <StyledCardWrapper>
            {demoAccounts?.active?.map((account) => (
              <DemoAccountCard
                handleAddVirtualFunds={() => handleAddVirtualFunds(account)}
                key={account.account_number}
                account={account}
                onCardClick={() => {
                  demoAccountsRef.current = {
                    ...demoAccountsRef.current,
                    account_number: account.account_number,
                    total_balance: account.total_balance,
                    max_balance: account.max_balance,
                    name: account.account_name,
                    currency: account.currency
                  };
                  setOpenModal(true);
                }}
              />
            ))}
          </StyledCardWrapper>
        </StyledActiveWrapper>
      )}

      {!isEmpty(demoAccounts?.expired) && (
        <StyledActiveWrapper>
          <StyledTitle expired>
            <div />
            <span aria-label="expired account">{t('expiredAccount')}</span>
          </StyledTitle>
          <StyledCardWrapper>
            {demoAccounts?.expired?.map((account) => (
              <DemoAccountCard
                handleOpenLiveAccount={() => navigate('/create-account/select-account-type')}
                expired
                key={account.account_number}
                account={account}
                onCardClick={() => {
                  demoAccountsRef.current = {
                    ...demoAccountsRef.current,
                    account_number: account.account_number
                  };
                  handleCardDelete(account);
                }}
              />
            ))}
          </StyledCardWrapper>
        </StyledActiveWrapper>
      )}
      <AccountSettingsPopUp
        openModal={openModal}
        setOpenModal={(value: boolean) => setOpenModal(value)}
        accountNumber={demoAccountsRef.current.account_number}
        accountName={demoAccountsRef.current.name}
        updateCurrentAccountName={(val:string) => { demoAccountsRef.current.name = val; }}
        onSave={updateAccountOnSave}
      />
      <SuccessPopUp
        openModal={openSuccessModal}
        setOpenModal={(value: boolean) => setOpenSuccessModal(value)}
        information={successInfoRef?.current || null}
      />
      <CreateAccountPopUp
        openModal={openCreateAccountModal}
        setOpenModal={(value: boolean) => setOpenCreateAccountModal(value)}
        onSave={createAccountOnSave}
      />
      <AddVirtualFunds
        openModal={openAddVirtualFundsModal}
        setOpenModal={(value: boolean) => setOpenAddVirtualFundsModal(value)}
        demoAccount={demoAccountsRef?.current}
        onSuccessAdd={updateAmount}
      />
      <DeleteAccountPopUp
        openModal={openDeleteAccountModal}
        setOpenModal={(value: boolean) => setOpenDeleteAccountModal(value)}
        onDelete={handleDeleteAccount}
      />
    </StyledDemoAccountWrapper>
  );
};

export default DemoAccount;
