import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledContent = styled.div`
  text-align: center;
  color: #000000;
  margin-bottom: calc(-24px - 14px);
`;

export const StyledTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin: 0 auto;
  padding: 0 30px 8px;
  width: 45%;
  border-bottom: 1px solid #dddddd;

  @media ${device.mobileMaxWidth} {
    width: 100%;
    padding: 0 0 8px;
  }
`;

export const StyledDescription = styled.div`
  padding: 10px 40px 0;

  @media ${device.mobileMaxWidth} {
    padding: 10px 0 0;
  }

  a {
    color: #145db5;
  }
`;
