import * as React from "react";
import { SVGProps } from "react";
const SvgClock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#Clock_svg__a)">
      <path
        d="M8 1.334c-3.667 0-6.667 3-6.667 6.667 0 3.666 3 6.666 6.667 6.666s6.667-3 6.667-6.666c0-3.667-3-6.667-6.667-6.667Zm0 12a5.34 5.34 0 0 1-5.333-5.333A5.34 5.34 0 0 1 8 2.667a5.34 5.34 0 0 1 5.333 5.334A5.34 5.34 0 0 1 8 13.334Zm.333-8.667h-1v4l3.467 2.134.533-.867-3-1.8V4.667Z"
        fill="#145DB5"
      />
    </g>
    <defs>
      <clipPath id="Clock_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgClock;
