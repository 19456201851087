import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledContentWrap = styled.div<{ gap?: number }>`
  padding: 40px 40px 8px;
  background: #ffffff;
  border-radius: 0 0 16px 16px;
  height: 100%;
  gap: ${(props) => (props.gap ? `${props.gap}px` : '0px')};

  @media ${device.desktopMinWidth} {
    display: flex;
  }

  @media ${device.tablet} {
    padding: 40px 24px 8px;
  }

  @media ${device.mobileMaxWidth} {
    padding: 40px 16px 8px;
  }
`;

export const StyledHalfContentWrap = styled.div`
  flex: 1;
`;

export const StyledInputWrap = styled.div<{ isHalfWidth?: boolean }>`
  ${(props) => props.isHalfWidth && 'display:inline-block;'};
  width:  ${(props) => (props.isHalfWidth ? 'calc(50% - 10px)' : '100%')};
  margin-right:  ${(props) => (props.isHalfWidth && !props.theme.isRTL && '20px')};
  margin-left:  ${(props) => (props.isHalfWidth && props.theme.isRTL && '20px')};
  margin-bottom:24px;

  label {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #5c5c5c;
    margin: 0 0 4px;
  }

  &:last-of-type {
    margin-right:  ${(props) => (!props.theme.isRTL && 0)};
    margin-left:  ${(props) => (props.theme.isRTL && 0)};
  }

  @media ${device.mobileMaxWidth} {
    width: 100%;
    display: inline-block;
  }
`;

export const StyledHintWrap = styled.div<{ gap?: number }>`
  font-size: 14px;
  font-weight: 400;
  border-top: 1px solid #DDDDDD;
  padding-top: 8px;
  margin-top: 24px;
  color: #5C5C5C;
`;

export const StyledPopupWrap = styled.div<{ gap?: number }>`
  padding: 0 38px;
  flex-direction: column;
  height: 100%;
  font-size: 14px;

`;

export const StyledPopupContent = styled.div<{ gap?: number }>`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 16px;

  span{
    font-weight: 600;
    color: #145DB5;
  }
`;

export const StyledVeriffLoadingWrap = styled.div`
  width: 405px;
  height: auto;
  padding: 24px 16px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.mobileMaxWidth} {
    width: calc( 100% - 32px );
  }
`;

export const StyledVeriffLoadingContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #1C1C1C;
  text-align: center;
  margin-top: 16px;
`;
