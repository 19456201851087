import axios from 'api/axios';
import { AxiosPromise } from 'axios';

export const getDemoTradingFields = (country: string): AxiosPromise => axios({
  method: 'GET',
  url: '/demo-account-trading-preferences',
  params: {
    country
  }
});

export const getDemoAccounts = (): AxiosPromise => axios({
  method: 'GET',
  url: '/demo-accounts'
});

export const deleteDemoAccount = (accountNumber :string): AxiosPromise => axios({
  method: 'DELETE',
  url: `/demo-accounts/${accountNumber}`
});

export const updateDemoAccount = (
  { accountNumber, accountName, password }: { accountNumber: string, accountName: string, password: string }
): AxiosPromise => axios({
  method: 'PATCH',
  url: `/demo-accounts/${accountNumber}`,
  data: {
    account_name: accountName,
    password
  }
});

export const createDemoAccount = (
  { platform, currency }: { platform: string, currency: string }
): AxiosPromise => axios({
  method: 'POST',
  url: '/demo-accounts',
  data: {
    trading_platform: platform,
    base_currency: currency
  }
});

export const addVirtualFunds = (
  { accountNumber, amount }: { accountNumber: string, amount: string}
): AxiosPromise => axios({
  method: 'POST',
  url: `/demo-accounts/${accountNumber}/fund`,
  data: {
    amount
  }
});
