/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect, useRef } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
interface CountDownProps {
  startUtcTime: Dayjs;
}

const CountDown = ({ startUtcTime }: CountDownProps): number => {
  const [time, setTime] = useState<number>(startUtcTime.diff(dayjs.utc(), 'second') || 0);
  const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTime((t) => t - 1);
    }, 1000);
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);
  useEffect(() => {
    if (time <= 0) {
      if (intervalRef.current) clearInterval(intervalRef.current);
    }
  }, [time]);
  return time;
};
export default CountDown;
