import * as React from "react";
import { SVGProps } from "react";
const SvgProgressTick = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ProgressTick_svg__a)">
      <rect x={0.5} width={32} height={32} rx={16} fill="#145DB5" />
      <path
        d="M13.546 20.866 9.01 16.164 7.5 17.731 13.546 24 26.5 10.567 24.989 9 13.545 20.866Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ProgressTick_svg__a">
        <rect x={0.5} width={32} height={32} rx={16} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgProgressTick;
