import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconComponent from 'components/Icons';
import { StyledButton, StyledMenu, StyledMenuItem } from './style';

type ActionMenuProps = {
  onSendResetPasswordEmailButtonClick: () => void;
  onRemoveButtonClick: () => void;
};

const ActionMenu = ({
  onSendResetPasswordEmailButtonClick,
  onRemoveButtonClick
}: ActionMenuProps): JSX.Element => {
  const { t } = useTranslation('accountList');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuButtonClick = (
    event: MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledButton
        id="action-menu-button"
        aria-controls={open ? 'action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuButtonClick}
      >
        <IconComponent name="DotsTree" aria-label="DotsTree Icon" />
      </StyledButton>
      <StyledMenu
        id="action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'action-menu-button'
        }}
      >
        <StyledMenuItem
          aria-label="Send Reset Password Email"
          onClick={() => {
            onSendResetPasswordEmailButtonClick();
            handleMenuClose();
          }}
        >
          <span>
            <IconComponent name="SendEmail" />
          </span>
          <span>{t('sendResetPasswordEmail')}</span>
        </StyledMenuItem>
        <StyledMenuItem
          aria-label="Remove Member"
          onClick={() => {
            onRemoveButtonClick();
            handleMenuClose();
          }}
        >
          <span>
            <IconComponent name="Remove" />
          </span>
          <span>{t('remove')}</span>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};

export default ActionMenu;
