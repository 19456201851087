import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFooter } from 'api/v1/footer';

import { StyledFooter, StyledFooterWrapper } from './style';

const Footer = ():JSX.Element => {
  const [content, setContent] = useState();
  const { i18n } = useTranslation();

  useEffect(() => {
    getFooter().then((res) => {
      setContent(res.data.footer);
    });
  }, []);

  return (
    <StyledFooterWrapper
      style={{ direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}
      aria-label="Footer wrap"
    >
      <StyledFooter aria-label="Footer content" dangerouslySetInnerHTML={{ __html: content || '' }} />
    </StyledFooterWrapper>
  );
};
export default Footer;
