import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledBanner = styled.div`
  margin-bottom: 24px;

  > div:first-child {
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #013B81;
  }

  > div:last-child {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5C5C5C;

    &::after {
      padding-bottom: 8px;
      display: block;
      width: 100%;
      content: '';
      border-bottom: 1px solid #e3e3e3;
    }
  }
`;

export const StyledTopWrapper = styled.div`
  padding: 32px 64px 2px 64px;
  background: #f6f6f6;

  @media ${device.tablet} {
    padding: 32px 24px 2px 24px;
  }

  @media ${device.mobileMaxWidth} {
    padding: 32px 16px 2px 16px;
  }
`;

export const StyledFooterBlock = styled.div`
  height: 48px;
`;

export const StyledFooter = styled.div`
  position: fixed;
  bottom: 0;
  padding-right: 64px;
  height: 48px;
  width: 100%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #ffffff;
  box-shadow: 0px -5px 10px rgba(90, 114, 143, 0.1);

  span {
    padding-right: 16px;
    color: #145DB5;;
    font-size: 14px;
    line-height: 24px;
  }

  button {
    font-family: 'Noto Sans';
    text-transform: none;
    background: #145dB5;
    color: #ffffff;
    padding: 4px 16px;

    &:hover, &:active {
      background: #013b81;
    }

    &:disabled {
      background: #145dB5;
      opacity: 0.6;
      color: #ffffff;
    }
  }

  @media ${device.tabletMaxWidth} {
    padding-right: 16px;
    width: 100%;
  }

  @media ${device.mobileMaxWidth} {
    span {
      display: none;
    }
  }
`;
