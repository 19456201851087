import * as React from "react";
import { SVGProps } from "react";
const SvgDemoAccountCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#DemoAccountCheck_svg__a)">
      <path
        d="M24 4C12.96 4 4 12.96 4 24s8.96 20 20 20 20-8.96 20-20S35.04 4 24 4Zm-4 30L10 24l2.82-2.82L20 28.34l15.18-15.18L38 16 20 34Z"
        fill="#00B875"
      />
    </g>
    <defs>
      <clipPath id="DemoAccountCheck_svg__a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDemoAccountCheck;
