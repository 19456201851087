import Steps from 'components/Steps';
import Step from 'components/Steps/Step';
import { useAppSelector } from 'store';
import CreatAccountFrom from 'containers/CreateAccountForm';
import { useSearchParams } from 'react-router-dom';
import { useCreateAccountFlow } from 'store/context/hooks';
import { CreateAccountSteps } from 'constant/createAccount';
import { useTranslation } from 'react-i18next';
import { StyledBanner, StyledTopWrapper } from './style';

interface translationProps {
  [key: string]: string
}

const progressStepTranslation: translationProps = {
  [CreateAccountSteps.TradingAndEmployment]: 'Trading & Employment',
  [CreateAccountSteps.PersonalInformation]: 'Personal Information',
  [CreateAccountSteps.VerifyIdentity]: 'Verify Identity',
  [CreateAccountSteps.Experience]: 'Experience',
  [CreateAccountSteps.TermsAndConditions]: 'Terms & Conditions',
  [CreateAccountSteps.ConfirmID]: 'uploadDocuments'
};

const CreateAccount = (): JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const currentStep = useAppSelector((state) => state.createAccountState.step);
  const [searchParams] = useSearchParams();
  const { steps, rejectStepStatus } = useCreateAccountFlow();

  const getStatus = (el: { valid: boolean, isWarning: boolean }): 'valid' | 'warning' | 'unfinished' | undefined => {
    if (searchParams.get('status') !== 'Reject') return undefined;
    if (!el) return undefined;
    return (!el.valid || el.isWarning) ? 'warning' : 'valid';
  };
  return (
    <>
      <StyledTopWrapper>
        <StyledBanner>
          <div aria-label="Registration title">{t('welcomeTo')}</div>
          <div aria-label="Registration description">{t('accountRegistrationBannerContent')}</div>
        </StyledBanner>
        <Steps current={currentStep + 1}>
          {steps.map((item) => (
            <Step
              description={t(progressStepTranslation[item])}
              key={item}
              status={getStatus(rejectStepStatus[item])}
              ariaLabel={`Step: ${progressStepTranslation[item]}`}
            />
          ))}
        </Steps>
      </StyledTopWrapper>
      <CreatAccountFrom />
    </>
  );
};

export default CreateAccount;
