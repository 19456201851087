import { useState, useEffect } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import TradingExperience from 'containers/CreateAccountForm/components/TradingExperience';
import Button from 'components/Button';
import { useCreateAccountFlow } from 'store/context/hooks';
import { useLocation, useNavigate } from 'react-router';
import { submitTestAnswer } from 'api/v1/account';
import { useTranslation } from 'react-i18next';
import {
  StyledLButtonsWrap,
  StyledNeedHelpSentence
} from 'containers/CreateAccountForm/components/StepButtons/style';
import { DOMAIN_CONFIG } from 'constant/domainConfig';

const ExperienceQuestionForm = (): JSX.Element => {
  const [init, setInit] = useState(true);
  const methods = useForm({ mode: 'all' });
  const { formIsLoading } = useCreateAccountFlow();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('registrationForm');

  useEffect(() => {
    const pathArr = location.pathname.split('/');
    localStorage.setItem('accountID', pathArr[2]);
    setInit(false);
  }, []);

  const onSubmit: SubmitHandler<object> = (data) => {
    submitTestAnswer(
      data,
      localStorage.getItem('accountID') || '',
      methods.getValues('rg227_test_id'),
      `${DOMAIN_CONFIG.origin}/rg227/${methods.getValues(
        'rg227_test_id'
      )}`
    )
      .then((res) => {
        if (res && res.status === 200) {
          navigate('thank-you');
        }
      })
      .catch((err) => {
        if (err.response.status === 423) {
          navigate('unsuccessful');
        }
      });
  };
  return (
    <FormProvider {...methods}>
      {!init && (
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <TradingExperience />
          <StyledLButtonsWrap>
            <Button
              className="next-button"
              htmlType="submit"
              disabled={!methods.formState.isValid || formIsLoading}
              aria-label="Submit Button"
            >
              {t('submit')}
            </Button>
            <StyledNeedHelpSentence>
              <span aria-label="Need Help For Practice Questions">{t('needHelp')}</span>
              <a
                target="_blank"
                href={`${DOMAIN_CONFIG.origin}/rg227-practice`}
                rel="noreferrer"
                aria-label="Go To Practice Questions"
              >
                {' '}
                {t('clickHere')}
              </a>
            </StyledNeedHelpSentence>
          </StyledLButtonsWrap>
        </form>
      )}
    </FormProvider>
  );
};

export default ExperienceQuestionForm;
