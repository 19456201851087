import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledLoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledContentWrap = styled.div<{ gap?: number }>`
  padding: 40px 40px 8px;
  background: #ffffff;
  border-radius: 0 0 16px 16px;
  height: 100%;
  gap: ${(props) => (props.gap ? `${props.gap}px` : '0px')};

  @media ${device.desktopMinWidth} {
    display: flex;
  }

  @media ${device.tablet} {
    padding: 40px 24px 8px;
  }

  @media ${device.mobileMaxWidth} {
    padding: 40px 16px 8px;
  }
`;

export const StyledFieldWrap = styled.div<{ gap?: number }>`
  display: flex;
  gap: ${(props) => (props.gap ? `${props.gap}px` : '0px')};
`;

export const StyledHalfContentWrap = styled.div`
  flex: 1;
`;

export const StyledMoreButton = styled.div`
  font-size: 12px;
  color: #006acc;
  cursor: pointer;
  margin-bottom: 4px;
  &:hover {
    color: #339dff;
  }
`;
