import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import {
  MarketingMaterialsDT,
  MarketingMaterialsMOB,
  MarketingMaterialsTAB
} from 'assets/blurredPages';
import { StyledBlurredPageWrap } from 'pages/BlurredPage/style';

export const StyledMarketingMaterialsWrap = styled(StyledBlurredPageWrap)`
  ::before {
    background-image: url(${MarketingMaterialsDT});
    @media ${device.tablet} {
      background-image: url(${MarketingMaterialsTAB});
    }
    @media ${device.mobileMaxWidth} {
      background-image: url(${MarketingMaterialsMOB});
    }
 }
`;
