import { useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Languages } from 'constant/language';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from './windowsize';
import {
  StyledWrap, StyledInputWrap, StyledErrMsg, StyledWarningMsg
} from './style';

interface FormPanelProps {
  onChange?: (countryCode:string, info:{ [key:string] :string}) => void;
  height?: string;
  label?: string;
  errorMsg?: string;
  defaultValue?: string;
  country?: string;
  className?: string;
  disabled?:boolean;
  warningMsg?: string | string[];
  markWarning?: boolean;
  localization?: LocalizationDataType;
  id?:string
}

interface LocalizationDataType{
  [key: string]: string;
}

const CustomPhoneInput = (props:FormPanelProps):JSX.Element => {
  const { t } = useTranslation('createAccount');
  const {
    onChange, height, label = '', errorMsg, defaultValue, country = 'au', className = '', disabled,
    warningMsg, markWarning, localization, id = ''
  } = props;
  const inputWrapRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowDimensions();

  const renderWarningMsg = ():React.ReactNode => {
    if (Array.isArray(warningMsg)) {
      return warningMsg.map((eachMsg:string) => (
        <StyledWarningMsg key={eachMsg} markWarning={markWarning} aria-label={`${label} Error Message`}>
          {eachMsg}
        </StyledWarningMsg>
      ));
    }
    return (
      <StyledWarningMsg markWarning={markWarning} aria-label={`${label} Error Message`}>
        {warningMsg}
      </StyledWarningMsg>
    );
  };

  return (
    <StyledWrap disabled={disabled} width={inputWrapRef.current?.offsetWidth || 700} label={label} ref={inputWrapRef} className={className}>
      {label && <label aria-label={`${id} phone input`} htmlFor={label}>{label}</label>}
      <StyledInputWrap
        curLang={localStorage.getItem('language') || Languages.EN}
        height={height}
        isErr={!!errorMsg}
        isWarn={!!warningMsg}
        markWarning={markWarning}
        disabled={disabled}
      >
        <PhoneInput
          inputClass="phone-input"
          country={country}
          onChange={onChange}
          searchClass="search-class"
          searchStyle={{ margin: '0', width: '100%', height: '36px' }}
          dropdownStyle={{ width: `${width.toString()}` }}
          dropdownClass="dropdown"
          enableSearch
          disableSearchIcon
          countryCodeEditable={false}
          value={defaultValue}
          disabled={disabled}
          aria-label={`${id} Phone Input`}
          localization={localization}
          searchPlaceholder={t('search')}
          searchNotFound={t('searchNotFound')}
          autoFormat={false}
          disableCountryGuess
        />
      </StyledInputWrap>
      <div className="phone-input-msg-wrap">
        {errorMsg && (
        <StyledErrMsg>
          {errorMsg}
        </StyledErrMsg>
        )}
        { warningMsg && renderWarningMsg()}
      </div>

    </StyledWrap>
  );
};
export default CustomPhoneInput;
