import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import PopUp from '../PopUp';

export const StyledPopUpWrapper = styled(PopUp)`
  position: relative;
  width: 477px;

  @media ${device.mobileMaxWidth} {
    width: 343px;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;

  > svg {
    width: 48px;
    height: 48px;
  }

  span {
    margin: ${(props) => (props.theme.isRTL ? '0 16px 0 0' : '0 0 0 16px')};
    line-height: 36px;
    color: #1C1C1C;
    font-size: 24px;
    font-weight: 600;
  }
`;

export const StyledNote = styled.span`
  margin: 16px 0;
  font-weight: 400;
  color: #1C1C1C;
  line-height: 24px;
  font-size: 14px;
`;

export const StyledInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 24px;
  font-size: 14px;

  span:first-child {
    color: #1C1C1C;
    font-weight: 600;
  }
  span:nth-child(2) {
    color: #5C5C5C;
  }
`;

export const StyledDownload = styled.div`
  margin: 16px 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const StyledDeviceBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 24px;
`;

export const StyledDevice = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #00B875;
  border-radius: 4px;
  padding: 2px 8px;
  color: #00B875;

  span {
    margin: ${(props) => (props.theme.isRTL ? '0 8px 0 0' : '0 0 0 8px')};
    font-size: 14px;
    line-height: 24px;
  }
`;

export const StyledCloseIcon = styled.div`
  position: absolute;
  top: 16px;
  right: ${(props) => (props.theme.isRTL ? 'none' : '24px')};
  left: ${(props) => (props.theme.isRTL ? '24px' : 'none')};
  display: flex;
  align-items: center;
  cursor: pointer;

  > svg {
    width: 24px;
    height: 24px;
  }

  @media ${device.mobileMaxWidth} {
    right: ${(props) => (props.theme.isRTL ? 'none' : '16px')};
    left: ${(props) => (props.theme.isRTL ? '16px' : 'none')};
  }
`;
