interface ParsedURLInfo extends URL {
  /** The domain part of the URL (e.g., 'example.com') */
  domain: string;
  /** The subdomain part of the URL (e.g., 'www' in 'www.example.com') */
  subdomain: string;
  /** The top-level domain (TLD) of the URL (e.g., 'com') */
  tld: string;
}

/**
 * Parses the current window location URL and extracts additional information.
 *
 * This function extends the built-in URL object with additional properties:
 * domain, subdomain, and tld (top-level domain).
 *
 * @param {string} [url=window.location.href] - The URL to parse. Defaults to the current window location.
 * @returns {ParsedURLInfo} An object containing URL information and additional parsed properties
 *
 * @example
 * // Assuming current URL is https://www.example.com/path
 * const parsedURL = parseURL();
 * console.log(parsedURL.domain); // 'example.com'
 * console.log(parsedURL.subdomain); // 'www'
 * console.log(parsedURL.tld); // 'com'
 *
 * @throws {Error} If called in an environment where window.location is not available
 */
export const parseURL = (url: string = window.location.href): ParsedURLInfo => {
  if (typeof window === 'undefined' && !url) {
    throw new Error('parseURL can only be used in a browser environment');
  }

  const urlInstance = new URL(url);
  const { origin, hostname, searchParams } = urlInstance;
  const domainParts = hostname.split('.');
  const tld = domainParts[domainParts.length - 1];
  const domain = domainParts.slice(-2).join('.');
  const subdomain = domainParts.slice(0, -2).join('.');

  return {
    ...urlInstance,
    origin,
    domain,
    subdomain,
    tld,
    searchParams
  };
};
