import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledBackdropSpinnerContainer } from './style';

interface BackdropSpinnerProps {
  open: boolean;
  content?: JSX.Element;
  className?: string;
}

const BackdropSpinner = (props: BackdropSpinnerProps):JSX.Element => {
  const { open, className, content } = props;
  return (
    <StyledBackdropSpinnerContainer className={className} aria-label="Backdrop Spinner">
      <Backdrop
        open={open}
        className="custom-backdrop"
      >
        {content || <CircularProgress className="custom-circular-progress" />}
      </Backdrop>
    </StyledBackdropSpinnerContainer>
  );
};

export default BackdropSpinner;
