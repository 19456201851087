import {
  AcySecurities,
  ACYSecuritiesGold,
  AcyIcon,
  AcyLogo,
  Avatar,
  DemoAccountClose,
  DemoAccountPlus,
  Hide,
  TriangleDown,
  LangTriangleDown,
  Show,
  Settings,
  LogOut,
  Check,
  Lang,
  Document,
  Chat,
  Menu,
  MenuExpand,
  MenuShrink,
  MacOS,
  Close,
  Clock,
  Account,
  Affiliates,
  Dashboard,
  DownloadCenter,
  Email,
  Finlogix,
  Hamburger,
  Log,
  CashManagement,
  Partnerships,
  TradingCup,
  Training,
  CloudCheck,
  CloudToCheck,
  DemoAccountCheck,
  ThankYouCheck,
  OnboardingAI,
  CloudUploaded,
  Plus,
  Alert,
  ProgressTick,
  FilledArrowDropDown,
  ProgressWarning,
  Phone,
  Windows,
  WebAsset,
  RightArrow,
  ColorfulEmail,
  ArrowDropDown,
  ArrowBack,
  Error,
  LangIcon,
  DownArrow,
  Info,
  DotsTree,
  SendEmail,
  Remove,
  CheckCircle,
  CrossCircle,
  CheckCircleFilled,
  WarningCircle,
  WarningCircleFilled,
  Android,
  UBankIcon,
  Delete,
  ArrowRight
} from 'iconComponents';
import React, { SVGProps } from 'react';

type Props = {
  name: string;
};

type Icons = {
  [key: string]: React.ComponentType;
};

const iconObj: Icons = {
  AcySecurities,
  ACYSecuritiesGold,
  AcyIcon,
  ArrowRight,
  AcyLogo,
  Alert,
  Avatar,
  DemoAccountClose,
  DemoAccountPlus,
  Hide,
  TriangleDown,
  LangTriangleDown,
  Show,
  Settings,
  Delete,
  LogOut,
  Check,
  Lang,
  Document,
  Chat,
  Clock,

  Menu,
  MenuExpand,
  MenuShrink,
  MacOS,
  Close,
  Account,
  Affiliates,
  Dashboard,
  DownloadCenter,
  Email,
  Finlogix,
  Hamburger,
  Log,
  CashManagement,
  Partnerships,
  TradingCup,
  Training,
  CloudCheck,
  DemoAccountCheck,
  ThankYouCheck,

  OnboardingAI,
  CloudUploaded,
  CloudToCheck,
  Plus,
  ProgressTick,
  FilledArrowDropDown,
  ProgressWarning,
  Phone,
  Windows,
  WebAsset,
  RightArrow,
  ColorfulEmail,
  ArrowDropDown,
  ArrowBack,
  Error,
  LangIcon,
  DownArrow,
  Info,
  DotsTree,
  SendEmail,
  Remove,
  CheckCircle,
  CrossCircle,
  CheckCircleFilled,
  WarningCircle,
  WarningCircleFilled,
  Android,
  UBankIcon
};

const IconComponent = ({ name, ...props }: Props & SVGProps<SVGSVGElement>): JSX.Element => {
  const Icon = iconObj[name] || null;
  return Icon && <Icon {...props} />;
};

export default IconComponent;
