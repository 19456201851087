import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const VerifyIdentityWrapper = styled.div`
  background: #f6f6f6;
  padding: 32px 64px 2px 64px;

  @media ${device.tabletMaxWidth} {
    padding: 32px 24px 2px 24px;

  }

  @media ${device.mobileMaxWidth} {
    padding: 32px 16px 2px 16px;
  }
`;

export const StyledBanner = styled.div`
  margin-bottom: 24px;

  > div:first-child {
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #013B81;
  }

  > div:last-child {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5C5C5C;

    &::after {
      padding-bottom: 8px;
      display: block;
      width: 100%;
      content: '';
      border-bottom: 1px solid #e3e3e3;
    }
  }
`;
