import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import { StyledContentWrap } from '../style';

export const StyledTermsAndConditions = styled.div`

    .lp-option-button{
      cursor:pointer;
      background-color: #F6F6F6 ;
      margin-bottom:10px;
      border-radius: 8px;
      padding:12px 32px;
      height:32px;
      line-height:11px;
    }

`;

export const StyledCheckboxSect = styled.div`
  margin-top: 24px;

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #1C1C1C;;
    margin-bottom: 20px;
  }

  .checkbox {
    position: relative;
    margin: 16px 0;
    .checkbox-label{
      display: flex;
      align-items: center;
      cursor:pointer;

      > div {
        display: flex;
        align-items: center;

        >input {
          cursor: pointer;
          opacity: 0;
          height: 0;
          width: 0;
          display: inline-block;

          :checked ~ .check-mark {
            display: block;
            background-color: ${(props) => props.theme.common.primary};
            &:after{
              display:block;
            }
          }

          :disabled ~ .check-mark {
            opacity: 0.6;
            cursor: not-allowed;
          }
        }
      }
    }

    .check-mark{
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: #fff;
      border: solid #424242 1px;
      border-radius:2px;

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 4px;
        top: 1px;
        width: 5px;
        height: 7px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(35deg);
        -ms-transform: rotate(35deg);
        transform: rotate(35deg);
      }
    }
  }

  @media ${device.mobileMaxWidth} {
    .checkbox {
      margin: 8px 0;
    }
  }

  @media ${device.tabletMaxWidth} {
    margin-top: 30px;
  }
`;

export const StyledCheckboxLabelWrap = styled.span`
  margin-left: 20px;
  color:#5C5C5C;
  font-size: 14px;
  >a {
    color:#5C5C5C;
  }
`;

export const StyledLoadingWrap = styled.span`
  display: flex;
  justify-content: center;
`;

export const StyledScrollWrap = styled.div`
  padding: 0 40px;
  max-height: 548px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    display: block;
  }

  ::-webkit-scrollbar-track {
    background: #FFFFFF;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #BBBBBB;
    border-radius: 40px;
    border: 2px solid #FFFFFF;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #8C8C8C;
  }

  @media ${device.tabletMaxWidth} {
    max-height: 632px;
  }
  @media ${device.mobileMaxWidth} {
    max-height: 656px;
  }
`;

export const StyledTermsAndConditionsContentWrap = styled(StyledContentWrap)`
  padding-left: 0;
  padding-right: 0;
`;
