/* eslint-disable no-alert */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import BackdropSpinner from 'components/BackdropSpinner';
import { useNavigate } from 'react-router-dom';
import { updateFormStep, getVeriffSession } from 'api/v1/account';
import ErrorHandler from 'utils/ErrorHandler';
import { setCreateAccountStatus } from 'containers/Pages/slices';
import { useAppDispatch } from 'store';
import { CreateAccountSteps } from 'constant/createAccount';
import CurrentProcessIcon from 'assets/verifyPages/VerifyProcess1.svg';
import NextProcessIcon from 'assets/verifyPages/VerifyProcess2.svg';
import VerifyAccount from 'assets/verifyPages/VerifyAccount.svg';
import DepositFunds from 'assets/verifyPages/DepositFunds.svg';
import StartTrading from 'assets/verifyPages/StartTrading.svg';
import { DOMAIN_CONFIG } from 'constant/domainConfig';
import {
  StyledBtnWrap,
  StyledBanner,
  StyledBannerHead,
  StyledContent,
  StyledContentTitle,
  StyledContentSubtitle,
  StyledOptions,
  StyledFastCard,
  StyledRegularCard,
  StyledCardTitle,
  StyledCardSubtitle,
  StyledCardTitleDesc,
  StyledCardDesc,
  StyledDivider,
  StyledProcessWrap,
  StyledStepWrap,
  StyledDashedLine,
  StyledSolidLine,
  StyledStep,
  StyledStepContent
} from './style';

enum ProcessType {
  Fast = 0,
  Regular = 1
}

const VerifyIdentityPanel = (): JSX.Element => {
  const isAcy = DOMAIN_CONFIG.realm === 'acy';
  const registerCountry = localStorage.getItem('registeredCountry') || '';
  const shouldHideAIOnboarding = ['CHN', 'HKG', 'MAC', 'TWN', 'LBY'].includes(registerCountry);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [processType, setProcessType] = useState(isAcy && !shouldHideAIOnboarding ? ProcessType.Fast : ProcessType.Regular);
  const { t } = useTranslation('verifyIdentity');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // useEffect(() => {
  //   const accountID = localStorage.getItem('accountID');
  //   if (!accountID) navigate('/');
  //   // only step === v2_personal_account_created, show this page
  //   else {
  //     setIsLoading(true);
  //     ErrorHandler(getAccountByID(accountID), dispatch).then((res) => {
  //       if (res.status === 200) {
  //         if (res.data.form_step !== 'v2_personal_account_created') {
  //           navigate('/');
  //         } else {
  //           setIsLoading(false);
  //         }
  //       }
  //     });
  //   }
  // }, []);

  const handleFinishItLater = (): void => {
    // Go to the create personal account form
    setIsLoading(true);
    ErrorHandler(updateFormStep(
      localStorage.getItem('accountID') || '',
      CreateAccountSteps.TradingAndEmployment
    ), dispatch)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCreateAccountStatus(
            {
              createAccountStatus: { isOnBoardingMethodSelecting: false, isModalOpen: false }
            }
          ));
          navigate('/create-account/personal-account');
        }
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const handleStart = (): void => {
    if (processType === ProcessType.Fast) {
      setIsLoading(true);
      getVeriffSession('/create-account/personal-account?veriff=true', String(localStorage.getItem('accountID'))).then((veriffSessionResponse) => {
        if (veriffSessionResponse && veriffSessionResponse.data && veriffSessionResponse.data.data) {
          ErrorHandler(updateFormStep(
            localStorage.getItem('accountID') || '',
            CreateAccountSteps.TradingAndEmployment
          ), dispatch)
            .then((updateFormStepResponse) => {
              if (updateFormStepResponse.status === 200) {
                dispatch(setCreateAccountStatus(
                  {
                    createAccountStatus: { isOnBoardingMethodSelecting: true }
                  }
                ));
                window.location.replace(veriffSessionResponse.data.data.url);
              }
            }).finally(() => {
              setIsLoading(false);
            });
        }
      }).catch((e) => {
        const { code } = e.response.data;
        if (code === 410) {
          handleFinishItLater();
        }
      });
    } else {
      handleFinishItLater();
    }
  };

  return (
    <div>
      <StyledBanner aria-label="get started banner area">
        <StyledBannerHead aria-label="get started title">{t('bannerTitle')}</StyledBannerHead>
        <StyledBannerHead aria-label="get started subtitle">{t('bannerSubtitle')}</StyledBannerHead>

        <StyledProcessWrap>
          <StyledStepWrap>
            <StyledStep>
              <img src={VerifyAccount} alt="icon" className="icon" aria-label="verify account image" />
              <StyledStepContent>
                <img src={CurrentProcessIcon} alt="icon" className="processIcon" aria-label="current process icon" />
                <span aria-label="verify account text">{t('verifyAccount')}</span>
              </StyledStepContent>
            </StyledStep>
          </StyledStepWrap>
          <StyledSolidLine aria-label="solid line" />
          <StyledStepWrap>
            <StyledStep>
              <img src={DepositFunds} alt="icon" className="icon" aria-label="deposit funds image" />
              <StyledStepContent>
                <img src={NextProcessIcon} alt="icon" className="processIcon" aria-label="process icon" />
                <span aria-label="deposit funds text">{t('depositFunds')}</span>
              </StyledStepContent>
            </StyledStep>
          </StyledStepWrap>
          <StyledDashedLine aria-label="dashed line" />
          <StyledStepWrap>
            <StyledStep>
              <img src={StartTrading} alt="icon" className="icon" aria-label="start trading image" />
              <StyledStepContent>
                <img src={NextProcessIcon} alt="icon" className="processIcon" aria-label="process icon" />
                <span aria-label="start trading text">{t('startTrading')}</span>
              </StyledStepContent>
            </StyledStep>
          </StyledStepWrap>
        </StyledProcessWrap>

      </StyledBanner>
      <StyledContent>
        <StyledContentTitle aria-label="lets started title">{t('contentTitle')}</StyledContentTitle>
        <StyledContentSubtitle aria-label="lets started subtitle">{t('contentSubtitle')}</StyledContentSubtitle>
      </StyledContent>
      <StyledOptions>
        {isAcy && !shouldHideAIOnboarding && (
        <StyledFastCard
          aria-label="highly recommended box"
          content={t('highlyRecommended')}
          selected={processType === ProcessType.Fast}
          onClick={() => setProcessType(ProcessType.Fast)}
        >
          <StyledCardTitle aria-label="fast process title">
            ⚡️
            {' '}
            {t('fastProcessTitle')}
          </StyledCardTitle>
          <StyledCardTitleDesc aria-label="fast process subtitle" dangerouslySetInnerHTML={{ __html: t('fastProcessSubtitle') }} />
          <StyledDivider />
          <StyledCardDesc aria-label="fast requires text">{t('inRequires')}</StyledCardDesc>
          <StyledCardSubtitle aria-label="fast gov check text">{t('governmentCheck')}</StyledCardSubtitle>
          <StyledCardDesc aria-label="fast got check desc">{t('governmentCheckDesc')}</StyledCardDesc>
          <StyledCardSubtitle aria-label="fast liveness check text">{t('livenessCheck')}</StyledCardSubtitle>
          <StyledCardDesc aria-label="fast liveness check desc">{t('livenessCheckDesc')}</StyledCardDesc>
        </StyledFastCard>
        )}
        <StyledRegularCard
          aria-label="regular process box"
          selected={processType === ProcessType.Regular}
          onClick={() => setProcessType(ProcessType.Regular)}
        >
          <StyledCardTitle aria-label="regular process title">{t('regularProcessTitle')}</StyledCardTitle>
          <StyledCardTitleDesc aria-label="regular process subtitle" dangerouslySetInnerHTML={{ __html: t('regularProcessSubtitle') }} />
          <StyledDivider />
          <StyledCardDesc aria-label="required text">{t('inRequires')}</StyledCardDesc>
          <StyledCardSubtitle aria-label="required gov check text">{t('governmentCheck')}</StyledCardSubtitle>
          <StyledCardDesc aria-label="required got check desc">{t('governmentCheckDesc')}</StyledCardDesc>
          <StyledCardSubtitle aria-label="required manual filling text">{t('manualFilling')}</StyledCardSubtitle>
          <StyledCardDesc aria-label="required manual filling desc">{t('manualFillingDesc')}</StyledCardDesc>
        </StyledRegularCard>
      </StyledOptions>
      <StyledBtnWrap>
        <Button
          aria-label="Start Verify Identity"
          variant="contained"
          className="start"
          onClick={handleStart}
        >
          {t('start')}
        </Button>
      </StyledBtnWrap>
      <BackdropSpinner open={isLoading} />

    </div>
  );
};

export default VerifyIdentityPanel;
