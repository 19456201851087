import { css, CSSProp } from 'styled-components';

const base = ({ color }: { color: string}): string => `
  color: ${color};
  opacity: 1;
`;

const Placeholder = ({ color }: { color: string}): CSSProp => css`
  ::placeholder { /* CSS 3 standard */
    ${base({ color })}
  }

  ::-webkit-input-placeholder { /* Chrome, Safari */
    ${base({ color })}
  }

  :-ms-input-placeholder { /* IE 10+ */
    ${base({ color })}
  }

  ::-moz-placeholder { /* Firefox 19+ */
    ${base({ color })}
  }
`;

export default Placeholder;
