import * as React from "react";
import { SVGProps } from "react";
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.908 4.458c.278-1.144 1.906-1.144 2.184 0L7.047 8.974l-1.125.84a2.324 2.324 0 0 0-.027-2.374c-.612-1.006.539-2.158 1.546-1.546a2.323 2.323 0 0 0 3.467-1.435ZM5.922 9.815l1.125-.84 6.045-4.517a2.324 2.324 0 0 0 3.468 1.437c1.006-.612 2.158.539 1.546 1.546a2.324 2.324 0 0 0 1.435 3.467c1.145.278 1.145 1.906 0 2.184a2.322 2.322 0 0 0-1.436 3.468c.612 1.006-.54 2.159-1.546 1.546a2.323 2.323 0 0 0-3.467 1.435c-.278 1.145-1.906 1.145-2.184 0a2.322 2.322 0 0 0-3.468-1.436c-1.006.612-2.159-.54-1.546-1.546a2.324 2.324 0 0 0-1.435-3.467c-1.145-.278-1.145-1.906 0-2.184l1.463-1.093Zm0 0-1.464 1.093a2.324 2.324 0 0 0 1.464-1.093Zm8.336-5.64c-.574-2.367-3.942-2.367-4.516 0a1.123 1.123 0 0 1-1.676.695h-.001C5.984 3.603 3.603 5.984 4.87 8.064a1.124 1.124 0 0 1-.695 1.678c-2.368.574-2.368 3.941 0 4.516a1.124 1.124 0 0 1 .693 1.677c-1.267 2.081 1.115 4.462 3.195 3.194a1.125 1.125 0 0 1 1.678.695c.574 2.368 3.941 2.368 4.516 0a1.123 1.123 0 0 1 1.677-.694c2.081 1.268 4.461-1.114 3.194-3.194a1.125 1.125 0 0 1 .695-1.678c2.368-.574 2.368-3.941 0-4.516a1.124 1.124 0 0 1-.694-1.677c1.267-2.081-1.114-4.462-3.194-3.194a1.124 1.124 0 0 1-1.678-.695Zm-3.955 6.128a2.4 2.4 0 1 1 3.394 3.395 2.4 2.4 0 0 1-3.394-3.395ZM12 8.4a3.6 3.6 0 1 0 0 7.2 3.6 3.6 0 0 0 0-7.2Z"
      fill="#fff"
    />
  </svg>
);
export default SvgSettings;
