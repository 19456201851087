import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledWebinarItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;
  background: #FFFFFF;
  border-bottom: 1px solid #DDDDDD;
  @media ${device.tablet} {
    width: 100%;
  }
  @media ${device.mobileMaxWidth} {
    padding: 8px 16px;
  }
`;

export const StyledBlock = styled.div`
  width: 126px;
  color: #3E3E3E;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #5C5C5C;
  @media ${device.tablet} {
    width: 80px;
  }
  @media ${device.mobileMaxWidth} {
    width: 80px;
  }
`;

export const StyledSubject = styled.div`
  width: 320px;
  height: 100%;
  @media ${device.tablet} {
    width: 240px;
  }
  @media ${device.mobileMaxWidth} {
    width: 240px;
  }
`;

export const StyledSubjectTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5C5C5C;
  margin-bottom: 8px;
  @media ${device.mobileMaxWidth} {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const StyledSubjectContent = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #5C5C5C;
`;

export const StyledButton = styled.div`
  color: #0282D0;
  text-transform: none;
  cursor: pointer;

   &.Started {
    color: #D22828;
    pointer-events: none;
  }

  &.Enrolled {
    color: #00B875;
    pointer-events: none;
  }
`;
