import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const AccountTypeWrap = styled.div`
  cursor: pointer;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12), 0px 0px 7px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px;
  display: flex;

  .img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 106, 204, 0.1);
    border-radius: 8px;
    align-items: center;
    padding: 18px 23px;
    min-width: 110px;
  }

  .divider {
    margin: 0 20px;
    border-right: solid #BBBBBB 1px;
  }

  .content-wrap {
    .title{
      font-size: 20px;
      color: #1c1c1c;
      margin-bottom:18px;
    }
    .content{
      color:  #1C1C1C;
      font-size: 14px;
    }
  }

  @media ${device.desktopMinWidth} {
    .img-wrap {
      width: 110px;
    }
  }

  @media ${device.tabletMaxWidth} {
    flex-direction: column;

    .divider, .title {
      display: none;
    }

    .img-wrap {
      display: flex;
      background-color: #FFFFFF;
      border-radius: 0;
      padding: 0 0 8px 0;
      border-bottom: 1px solid #DDDDDD;

      > img {
        display: none;
      }
    }

    .content-wrap {
      padding-top: 8px;
    }
  }
`;

export const StyledAccountMobileImg = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 67px;
    height: 71px;
    background-color: rgba(0, 106, 204, 0.1);
    border-radius: 5.15px;
    margin-right: 10px;

    > img {
      width: 30px;
      height: 46px;
    }
  }

  .mobile-title {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    font-size: 16px;
    color: #1c1c1c;
    font-weight: 400;
    line-height: 24px;
    background-color: #FFFFFF;
  }

  @media ${device.desktopMinWidth} {
    display: none;
  }

  @media ${device.mobileMaxWidth} {
    div {
      width: 40px;
      height: 40px;

      > img {
        width: 24px;
        height: 32px;
      }
    }

    .mobile-title {
      font-size: 20px;
    }
  }
`;
