import axios from 'api/axios';
import { AxiosPromise } from 'axios';

export const getOnlineTraining = (): AxiosPromise => axios({
  method: 'GET',
  url: '/webinars'
});

export const registerOnlineTraining = (id: number): AxiosPromise => axios({
  method: 'POST',
  url: `webinars/${id}/enroll`
});
