import { useEffect } from 'react';
import { getAccounts } from 'api/v1/account';
import { useNavigate } from 'react-router';
import { useAppDispatch } from 'store';
import BackdropSpinner from 'components/BackdropSpinner';
import AccountStatusHandler from 'utils/AccountStatusHandler';
import { setCreateAccountStatus } from 'containers/Pages/slices';
import { CreateAccountSteps } from 'constant/createAccount';

enum NavigationPath {
  Home = '/',
  PersonalAccount = '/create-account/personal-account',
  CorporateAccount = '/create-account/corporate-account',
  JointAccount = '/create-account/joint-account'
}

const IncompleteAccountRedirect = ():JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getAccounts().then((account) => {
      const target: { [key: string]: {[key:string]:string} } = {};
      account?.data?.forEach((el: { [key: string]: string }) => {
        target[`${el.type}_${el.status}`] = {
          accountID: el.id,
          formStep: el.form_step
        };
      });

      const { accountStatus, type } = AccountStatusHandler(target, dispatch);
      if (type === 'personal') {
        if (accountStatus === 'Incomplete') {
          if (target?.personal_Incomplete?.formStep === CreateAccountSteps.PersonalAccountCreated) {
            dispatch(setCreateAccountStatus(
              {
                createAccountStatus: { isModalOpen: true }
              }
            ));
            navigate(NavigationPath.Home);
          } else {
            navigate(NavigationPath.PersonalAccount);
          }
        } else {
          navigate(NavigationPath.Home);
        }
      } else if (type === 'corporate') {
        if (accountStatus === 'Incomplete') {
          navigate(NavigationPath.CorporateAccount);
        } else {
          navigate(NavigationPath.Home);
        }
      } else if (type === 'joint') {
        if (accountStatus === 'Incomplete') {
          navigate(NavigationPath.JointAccount);
        } else {
          navigate(NavigationPath.Home);
        }
      }
    });
  }, []);

  return (
    <BackdropSpinner open />
  );
};

export default IncompleteAccountRedirect;
