import * as React from "react";
import { SVGProps } from "react";
const SvgTriangleDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.414 3.055c-.39-.39-.944-.47-1.237-.176-.293.292-.214.847.177 1.237l2.828 2.828c.243.244.55.366.818.353.267.013.574-.109.818-.353l2.828-2.828c.39-.39.47-.944.177-1.237-.293-.293-.847-.214-1.237.176L5 5.641 2.414 3.055Z"
      fill="#fff"
    />
  </svg>
);
export default SvgTriangleDown;
