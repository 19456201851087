import * as React from "react";
import { SVGProps } from "react";
const SvgFilledArrowDropDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.833 8.333 10 12.5l4.167-4.167H5.833Z"
      fill="#000"
      fillOpacity={0.65}
    />
  </svg>
);
export default SvgFilledArrowDropDown;
