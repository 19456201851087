import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import PopUp from '../PopUp';

export const PopUpWrapper = styled(PopUp)`
  > div:first-child {
    font-weight: 600;
  }
`;

export const StyledItemTitle = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #5C5C5C;
  + div {
    margin-top: 16px;
  }
`;

export const StyledItemWrapper = styled.div`
  margin-bottom: 32px;

  > .StyledRadioGroup {
    .btnType {
      padding: 4px 43.5px;
    }
  }

  .select-custom {
    width: 632px;

    @media ${device.mobileMaxWidth} {
      width: 100%;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledFooter = styled.div`
  margin-top: -8px;
  display: flex;
  justify-content: end;

  button {
    padding: 8px 16px;
    font-family: 'Noto Sans';
    text-transform: none;
    border-radius: 4px;
    line-height: 24px;
    min-height: 40px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #145DB5;
    color: #145DB5;

    &:first-child {
      border-color: #145DB5;
      margin-right: ${(props) => (props.theme.isRTL ? 'none' : '16px')};
      margin-left: ${(props) => (props.theme.isRTL ? '16px' : 'none')};
      &:hover,
      &:focus {
        color: #145DB5;
        border-color: #145DB5;
      }
    }

    &:last-child {
      color: #ffffff;
      border-color: #145DB5;
      background-color: #145DB5;

      &:hover,
      &:focus,
      &:disabled {
        color: #ffffff;
        border-color: #145DB5;
        background-color: #145DB5;
      };
      &:disabled {
        opacity: 0.6;
      }
    }
  }
`;
