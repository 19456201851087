export default function switchTimezoneCity(param: string): string {
  switch (param) {
    case 'AustraliaSydney':
      return 'sydney';
    case 'AsiaJakarta':
      return 'jakarta';
    case 'AsiaShanghai':
      return 'beijing';
    case 'AsiaDubai':
      return 'dubai';
    case 'AsiaHo_Chi_Minh':
      return 'hanoi';
    case 'AsiaTokyo':
      return 'tokyo';
    default:
      return '';
  }
}
