import * as React from "react";
import { SVGProps } from "react";
const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.334 12.667H4.667V3.334h6.667m0-2.667H4.667c-.74 0-1.333.593-1.333 1.333v12a1.333 1.333 0 0 0 1.333 1.334h6.667A1.333 1.333 0 0 0 12.667 14V2A1.333 1.333 0 0 0 11.334.667Z"
      fill="#00B875"
    />
  </svg>
);
export default SvgPhone;
