import Steps from 'components/Steps';
import Step from 'components/Steps/Step';
import { useAppSelector } from 'store';
import CreateJointAccountForm from 'containers/CreateJointAccountForm';
import { useSearchParams } from 'react-router-dom';
import { useCreateJointAccountFlow } from 'store/context/hooks';
import { CreateJointAccountSteps } from 'constant/createAccount';
import { useTranslation } from 'react-i18next';
import { StyledBanner, StyledTopWrapper } from './style';

interface translationProps {
  [key: string]: string
}
// TODO: Standardize the naming conventions
const progressStepTranslation: translationProps = {
  [CreateJointAccountSteps.JointPrimaryAccountHolder]: 'primaryAccountHolder',
  [CreateJointAccountSteps.JointSecondaryAccountHolder]: 'secondaryAccountHolder',
  [CreateJointAccountSteps.JointTradingPreference]: 'Trading Preferences',
  [CreateJointAccountSteps.JointExperience]: 'Experience',
  [CreateJointAccountSteps.JointTermsAndConditions]: 'Terms & Conditions',
  [CreateJointAccountSteps.JointConfirmID]: 'Confirm ID'
};

const CreateJointAccount = (): JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const currentStep = useAppSelector((state) => state.createAccountState.step);
  const [searchParams] = useSearchParams();
  const { steps, rejectStepStatus } = useCreateJointAccountFlow();

  const getStatus = (el: { valid: boolean, isWarning: boolean }): 'valid' | 'warning' | 'unfinished' | undefined => {
    if (searchParams.get('status') !== 'Reject') return undefined;
    if (!el) return undefined;
    return (!el.valid || el.isWarning) ? 'warning' : 'valid';
  };
  return (
    <>
      <StyledTopWrapper>
        <StyledBanner>
          <div aria-label="Joint Account Registration">{t('jointAccountRegistration')}</div>
          <div aria-label="Instructions for registering a joint account">{t('accountRegistrationBannerContent')}</div>
        </StyledBanner>
        <Steps current={currentStep + 1}>
          {steps.map((item) => (
            <Step
              description={t(progressStepTranslation[item])}
              key={item}
              ariaLabel={`Step ${progressStepTranslation[item]}`}
              status={getStatus(rejectStepStatus[item])}
            />
          ))}
        </Steps>
      </StyledTopWrapper>
      <CreateJointAccountForm />
    </>
  );
};

export default CreateJointAccount;
