import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledSectionContainer = styled.div`
  margin: 16px 0 52px 0;
  padding: 0 64px;
  @media ${device.tablet} {
    margin: 16px 0 64px 0;
    width: 100%;
    padding: 0 16px;
  }
  @media ${device.mobileMaxWidth} {
    margin: 16px 0 16px 0;
    padding: 0 16px;
  }
`;

export const StyledHeader = styled.div`
  height: 72px;
  border-bottom: 1px solid #DDDDDD; 
  width: 100%;
  @media ${device.tablet} {
     height: 72px;
  }
  @media ${device.mobileMaxWidth} {
    height: 60px;
  }
`;

export const StyledHeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: max-content;
  height: 100%;
  border-bottom: 1px solid #0282D0;
  h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    text-transform: capitalize;
    color: #1C1C1C;
    margin: 16px 0;
    @media ${device.mobileMaxWidth} {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  @media ${device.mobileMaxWidth} {
    margin-top: 24px;
    margin-bottom: 32px;
  }
`;

export const StyledWebinarListContainer = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16), 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  @media ${device.mobileMaxWidth} {
    padding-bottom: 12px;
    overflow-x: scroll;
    overflow-y: hidden;
    }
`;

export const StyledWebinarListHeader = styled.div`
  padding: 16px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16), 0px 1px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-transform: capitalize;
    color: #1C1C1C;
    margin: 0;
    @media ${device.mobileMaxWidth} {
      font-size: 16px;
      line-height: 24px;
    }
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #5C5C5C;
    margin-top: 8px;
    margin-bottom: 0;
    @media ${device.mobileMaxWidth} {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const StyledWebinarListMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  @media ${device.mobileMaxWidth} {
    width: 788px;
  }
`;

export const StyledWebinarListMainHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  width: 100%;
  height: 40px;
  background-color: #F4F4F4;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #3E3E3E;
  @media ${device.tablet} {
    font-size: 12px;
    line-height: 16px;
    height: 32px;
  }
  @media ${device.mobileMaxWidth} {
    font-size: 12px;
    line-height: 24px;
  }
`;

export const StyledWebinarListMainBody = styled.div`
  width: 100%;
  padding: 0;
  > * {
      &:last-child {
        border-bottom: none;
      }
    }
`;

export const StyledHeaderBlock = styled.div`
  width: 126px;
  @media ${device.tablet} {
    height: 16px;
    width: 80px;
  }
  @media ${device.mobileMaxWidth} {
    height: 24px;
    width: 80px;
  } 
`;

export const StyledSubjectBlock = styled.div`
  width: 320px;
  height: 24px;
  @media ${device.tablet} {
    font-size: 12px;
    line-height: 16px;
    height: 16px;
    width: 240px;
  }
  @media ${device.mobileMaxWidth} {
    width: 240px;
    height: 24px;
  }
`;
