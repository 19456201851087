import * as React from "react";
import { SVGProps } from "react";
const SvgMenuExpand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m16.864 7.273 3.03 3.03-3.03 3.03"
      stroke="#fff"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5 18.363h-19M12.318 12.909H1.5M12.318 7.454H1.5M20.5 1.636h-19"
      stroke="#fff"
      strokeWidth={1.4}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgMenuExpand;
