import * as React from "react";
import { SVGProps } from "react";
const SvgAvatar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 4a11 11 0 1 0 11 11A11.013 11.013 0 0 0 15 4ZM8.9 22.549a7.118 7.118 0 0 1 12.2 0 9.684 9.684 0 0 1-12.2 0Zm2.433-8.412a3.667 3.667 0 1 1 7.333 0 3.667 3.667 0 0 1-7.333 0Zm10.732 7.51a8.362 8.362 0 0 0-4.314-3.384 4.96 4.96 0 1 0-5.5 0 8.362 8.362 0 0 0-4.314 3.384 9.706 9.706 0 1 1 14.128 0Z"
      fill="#fff"
    />
  </svg>
);
export default SvgAvatar;
