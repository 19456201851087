import { Alert } from '@mui/material';
import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledAlert = styled(Alert)`
  background-color: #ffffff;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.12), 0px 5px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 24px;
  width: 593px;

  @media ${device.mobileMaxWidth} {
    width: 90vw;
  }

  .MuiAlert-icon {
    display: none;
  }

  .MuiAlert-message {
    width: 100%;
  }

  .MuiAlert-action {
    padding: 0;
  }
`;

export const StyledAlertTitle = styled.div`
  color: #1c1c1c;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

export const StyledAlertContent = styled.div`
  color: #1c1c1c;
  font-size: 14px;
  line-height: 24px;
`;
