import { useTranslation } from 'react-i18next';
import Banner from 'containers/Banner';
import ResponseMessagePaper from 'components/ResponseMessagePaper';
import Lang from 'utils/language.util';
import { SUPPORT_EMAIL } from 'constant/supportEmail';
import { Languages } from 'constant/language';
import { StyledPageContainer } from './style';

const RG227Unsuccessful = (): JSX.Element => {
  const { i18n, t } = useTranslation('rg227Unsuccessful');
  const curLang = i18n.language as Languages;

  return (
    <>
      <Banner name="AccountRegistrationBanner" />
      <StyledPageContainer>
        <ResponseMessagePaper
          severity="warning"
          title={t('tradingExperience')}
          contentTitle={t('applicationUnsuccessful')}
          contentDescription={(
            <div style={{ maxWidth: '800px' }}>
              <p>{t('descriptionParagraph1')}</p>
              <p>
                {t('descriptionParagraph2_1')}
                <a
                  href={`https://acy.com/${Lang.getACYLocale(curLang)}/market-news/education/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('learn')}
                </a>
                {t('descriptionParagraph2_2')}
                <a href={`mailto:${SUPPORT_EMAIL[curLang]}`}>
                  {SUPPORT_EMAIL[curLang]}
                </a>
                {t('descriptionParagraph2_3')}
              </p>
            </div>
          )}
        />
      </StyledPageContainer>
    </>
  );
};

export default RG227Unsuccessful;
