import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import Modal from '@mui/material/Modal';

export const StyledModalWrapper = styled(Modal)`
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledModal = styled.div`
  padding: 24px 16px;
  margin: 0 16px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16), 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 560px;
  @media ${device.mobileMaxWidth} {
    padding: 16px 8px;
  }
`;

export const StyledHeader = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #1C1C1C;
  @media ${device.mobileMaxWidth} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCloseIcon = styled.div`
display: flex;
align-items: center;
  cursor: pointer;
  color: #5C5C5C
`;
