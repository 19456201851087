import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
interface SideMenuState {
  isOpen: boolean,
}

// Define the initial state using that type
const initialState: SideMenuState = {
  isOpen: false
};

export const sideMenuSlice = createSlice({
  name: 'sideMenu',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    assignSideMenu: (state, action) => {
      state.isOpen = action.payload;
    },
    toggleSideMenu: (state) => {
      state.isOpen = !state.isOpen;
    }
  }
});

export const { toggleSideMenu, assignSideMenu } = sideMenuSlice.actions;
export default sideMenuSlice.reducer;
