import * as React from "react";
import { SVGProps } from "react";
const SvgDocument = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM3 4h6a.5.5 0 1 1 0 1H3a.5.5 0 1 1 0-1Zm-.5 2.5A.5.5 0 0 1 3 6h6a.5.5 0 1 1 0 1H3a.5.5 0 0 1-.5-.5ZM3 8h6a.5.5 0 1 1 0 1H3a.5.5 0 1 1 0-1Zm0 2h3a.5.5 0 0 1 0 1H3a.5.5 0 0 1 0-1Z"
      fill="#013B81"
    />
  </svg>
);
export default SvgDocument;
