import styled from 'styled-components';

export const StyledTradingExperience = styled.div`


    .lp-option-button{
      cursor:pointer;
      background-color: #F6F6F6 ;
      margin-bottom:10px;
      border-radius: 8px;
      padding:12px 32px;
      height:32px;
      line-height:11px;
    }


`;

export const StyledRadioWrap = styled.div`
  margin: 20px 0 32px;
`;

export const StyledQuestionWrap = styled.div`
  .title {
    color: #1C1C1C;
  }
`;
