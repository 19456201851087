/* eslint-disable max-len */
import {
  StyledDownloadContainer,
  StyledIconContainer,
  StyledContent,
  StyledTitle,
  StyledSubTitle
} from './style';

interface DownloadCardProp {
  id?:string;
  title: string;
  path: string;
  subtitle: string;
  onClick: ()=>void;
}

const DownloadCard = ({
  path, title, subtitle, onClick, id = ''
}: DownloadCardProp): JSX.Element => (
  <StyledDownloadContainer onClick={onClick}>
    <StyledIconContainer aria-label={`${id} icon container`}>
      <svg>
        <path
          d={path}
          fill="#145DB5"
        />
      </svg>
    </StyledIconContainer>
    <StyledContent>
      <StyledTitle aria-label={`${id} title`}>{title}</StyledTitle>
      <StyledSubTitle aria-label={`${id} subtitle`}>{subtitle}</StyledSubTitle>
    </StyledContent>
  </StyledDownloadContainer>
);

export default DownloadCard;
