import styled from 'styled-components';

export const StyledResumitBtnWrap = styled.div`

  .resubmit-button {
    display: flex;
    align-items: center;
    font-family: 'Noto Sans';
    font-size: 14px;
    text-transform: none;
    background-color: #4CAF50;
    color: #ffffff;
    padding: 8px 24px;
    border:none;
    border-radius: 4px;

    >svg{
      font-size: 18px;
      margin-right: 8px;
    }

    &:hover {
        background: #4CAF50;
        color: #ffffff;
        border:none;
    }


    &:disabled {
      color: #ffffff;
      background: #4CAF50;
      opacity: 0.5;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
    }
  }


`;

export const StyledTitle = styled.div`
    background-color:#3E3E3E;
    font-size: 12px;
`;
