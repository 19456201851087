import * as React from "react";
import { SVGProps } from "react";
const SvgLang = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 21c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m0 18a9.004 9.004 0 0 1-8.488-6M12 21a9.004 9.004 0 0 0 8.488-6M12 3a9.004 9.004 0 0 0-8.488 6M12 3a9.004 9.004 0 0 1 8.488 6M3.512 9A8.985 8.985 0 0 0 3 12c0 1.052.18 2.062.512 3m0-6h16.976m0 0c.332.938.512 1.948.512 3s-.18 2.062-.512 3M3.512 15h16.976"
      stroke="#3E3E3E"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgLang;
