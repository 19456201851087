import { SVGProps } from 'react';

const SvgWindows = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.73798 8.38702V13.8642L1 13.0733V8.38702H6.73798ZM6.73798 2.13582V7.68029H1V2.92668L6.73798 2.13582ZM15 8.38702V15L7.36899 13.9483V8.38702H15ZM15 1V7.68029H7.36899V2.05168L15 1Z'
      fill='currentColor'
    />
  </svg>
);
export default SvgWindows;
