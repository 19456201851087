import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle<{ isRTL?: boolean }>`

  // Wait for designer to decide global layout


  * {
    box-sizing: border-box;
    outline:none;
    font-family: 'Noto Sans', sans-serif;
  }

  body {
    padding: 0;
    margin: 0;
    direction: ${(props) => (props.isRTL ? 'rtl' : 'ltr')};
  }



  a {
    cursor: pointer;
  }

`;
