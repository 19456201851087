import * as React from "react";
import { SVGProps } from "react";
const SvgHide = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.03 7.03 0 0 0 2.79-.588ZM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089v-.001Z"
      fill="#969696"
    />
    <path
      d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.83l-2.83-2.83Zm4.95.708-2.83-2.83a2.5 2.5 0 0 1 2.83 2.83Zm3.17 6-12-12 .709-.708 12 12-.708.708Z"
      fill="#969696"
    />
  </svg>
);
export default SvgHide;
