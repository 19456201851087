import { useMatch, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Banner from 'containers/Banner';
import ResponseMessagePaper from 'components/ResponseMessagePaper';
import { StyledActionButton } from 'components/ResponseMessagePaper/style';
import { StyledPageContainer } from './style';

const ThankYou = (): JSX.Element => {
  const { t } = useTranslation('thankYouPage');
  const match = useMatch('create-account/:accountType/thank-you');
  const { state } = useLocation();
  const navigate = useNavigate();
  const showActionButton = match?.params.accountType === 'personal-account'
                        || match?.params.accountType === 'corporate-account'
                        || match?.params.accountType === 'joint-account';
  const getContent = (): string => {
    switch (match?.params.accountType) {
      case 'corporate-account':
        return `${t('thankYouContent')} ${t('keepTrackContent')}`;
      case 'joint-account':
        return `${t('thankYouContent')} ${t('keepTrackContent')}`;
      default: {
        return state && state?.ai ? t('thankYouContent') : t('thankYouContentRegular'); }
    }
  };

  return (
    <>
      <Banner name="AccountRegistrationBanner" />
      <StyledPageContainer>
        <ResponseMessagePaper
          severity="success"
          title={t('applicationStatus')}
          contentTitle={t('thankYou')}
          contentDescription={(
            <div style={{ maxWidth: '440px' }}>
              {getContent()}
            </div>
          )}
          {...(showActionButton && {
            actionButtonSlot: (
              <StyledActionButton onClick={() => navigate('/account-list')}>
                {t('checkApplicationStatus')}
              </StyledActionButton>
            )
          })}
        />
      </StyledPageContainer>
    </>
  );
};

export default ThankYou;
