import AccountRegistrationBanner from 'containers/Banner/components/AccountRegistrationBanner';
import React from 'react';

interface BannerProps {
  name: 'AccountRegistrationBanner'
}

const Banner = (props: BannerProps): JSX.Element => {
  const { name } = props;
  switch (name) {
    case 'AccountRegistrationBanner':
      return <AccountRegistrationBanner />;
    default:
      return <>Banner</>;
  }
};

export default Banner;
