/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  useState, useEffect, useMemo, useRef
} from 'react';
import FormPanel from 'components/FormPanel';
import { useTranslation } from 'react-i18next';
import {
  getShareholdersAndDirectors, addShareholder, deleteShareholder, addDirector, deleteDirector
} from 'api/v1/account';
import { ResponseFieldsProps } from 'containers/CreateAccountForm/type';
import IconComponent from 'components/Icons';
import { useFormContext, useFieldArray } from 'react-hook-form';
import Button from '@mui/material/Button';
import { range } from 'lodash';
import InputCell from 'containers/CreateCorporateAccountForm/components/InputCell';
import BackdropSpinner from 'components/BackdropSpinner';
import { useSearchParams } from 'react-router-dom';
import PopUp from './PopUp';
import {
  StyledFormWrapper, StyledHalfContentWrap, StyledContentWrap, StyledPlusIcon,
  StyledFieldWrap, StyledPopUpFooter, StyledLoadButton, StyledButtonGroup,
  StyledClearButton,
  StyledDeleteButton
} from './style';

interface InputInfoProps {
  type: string;
  name: string;
  id: string;
  placeHolder: string;
  options?: {
    value: string;
    label: string;
  }[];
  rules?: {
    pattern?: { value: string, message: string };
    maxLength?: { value: number, message: string };
    required?: { value: boolean, message: string }
  };
  errMsg?: {
    required?: string;
    pattern?: string;
  };
}

const MAX_SHAREHOLDERS = 4;
const MAX_DIRECTORS = 6;
const SHAREHOLDER_FIELDS = ['first_name', 'last_name', 'middle_name', 'gender', 'nationality', 'photo_id_number',
  'mobile', 'dob', 'country', 'city', 'state', 'zip_code', 'residential_address', 'email', 'shareholder_id'];
const DIRECTORS_FIELDS = ['first_name', 'last_name', 'middle_name', 'gender', 'nationality', 'photo_id_number',
  'mobile', 'dob', 'country', 'city', 'state', 'zip_code', 'residential_address', 'email', 'director_id'];

const ShareholdersAndDirectors = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [baseFields, setBaseFields] = useState<ResponseFieldsProps[]>([]);
  const [shareholderNum, setShareholderNum] = useState(0);
  const [directorNum, setDirectorNum] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const {
    setValue, getValues, control, resetField
  } = useFormContext();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('registrationForm');
  const { remove: removeShareHolders } = useFieldArray({
    control,
    name: 'shareholders'
  });
  const { remove: removeDirectors } = useFieldArray({
    control,
    name: 'directors'
  });

  const deleteTargetRef = useRef({ index: -1, 'shareholder-id': '', 'director-id': '' });
  const num = useMemo(
    () => (baseFields.length > 0 ? baseFields.length / 2 - 1 : 0),
    [baseFields]
  );

  const personalFields = [
    'first_name', 'last_name', 'middle_name', 'gender', 'nationality', 'photo_id_number',
    'mobile', 'dob', 'country', 'city', 'zip_code', 'residential_address', 'email'
  ];

  const handleLoadContactPerson = (target: string): void => {
    personalFields.forEach((field: string) => {
      resetField(`${target}.0.${field}`);
      setValue(`${target}.0.${field}`, getValues(field));
    });

    const key = target.substring(0, target.length - 1);
    setValue(`${target}.0.${key}_id`, getValues(`${target}.0.${key}_id`));
    setTimeout(() => {
      setValue(`${target}.0.state`, getValues('state'));
    }, 0);
  };

  const handleClearContactPerson = (target: string): void => {
    personalFields.forEach((field: string) => {
      resetField(`${target}.0.${field}`);
      setValue(`${target}.0.${field}`, '');
    });
    setValue(`${target}.0.mobile`, '61');
  };

  useEffect(() => {
    getShareholdersAndDirectors().then((res) => {
      if (res) {
        setBaseFields(res?.data);
      }
    }).finally(() => {
      setIsLoading(false);
      setShareholderNum(getValues('shareholders').length);
      setDirectorNum(getValues('directors').length);
    });
  }, []);

  return (
    <StyledFormWrapper>
      <BackdropSpinner open={isLoading} />
      {range(shareholderNum).map((_, index) => (
        <FormPanel
          id={`Shareholder-${index + 1}`}
          title={`${t('shareholderDetails')} ${index + 1}`}
          key={`shareholder-${index}`}
          note={shareholderNum > 1 && searchParams.get('status') !== 'Reject' && (
            <StyledDeleteButton
              type="button"
              aria-label={`Delete Shareholder ${index + 1} Details`}
              onClick={() => {
                deleteTargetRef.current = {
                  index,
                  'shareholder-id': getValues(`shareholders.${index}.shareholder_id`),
                  'director-id': ''
                };

                setOpenModal(true);
              }}
            >
              {t('delete')}
            </StyledDeleteButton>
          )}
        >
          <StyledContentWrap>
            {index === 0 && (
              <StyledButtonGroup>
                <StyledLoadButton
                  onClick={() => handleLoadContactPerson('shareholders')}
                  aria-label="Load contact person details for shareholder"
                  disabled={searchParams.get('status') === 'Reject'}
                >
                  {t('loadContactPersonDetails')}
                </StyledLoadButton>
                <StyledClearButton
                  onClick={() => handleClearContactPerson('shareholders')}
                  aria-label="Clear contact person details for shareholder"
                  disabled={searchParams.get('status') === 'Reject'}
                >
                  {t('clearDetails')}
                </StyledClearButton>
              </StyledButtonGroup>
            )}

            <StyledFieldWrap gap={24} className="StyledContentWrap flx-row">
              <StyledHalfContentWrap>
                {
                  baseFields.map((item: InputInfoProps, itemIndex: number) => ((num > itemIndex)
                    ? (
                      <InputCell
                        item={{ ...item, id: `shareholders.${index}.${item.id}` }}
                        index={0}
                        key={`${getValues(`shareholders.${index}.shareholder_id`)}-${itemIndex}`}
                      />
                    ) : null))
                }
              </StyledHalfContentWrap>
              <StyledHalfContentWrap>
                {
                  baseFields.map((item: InputInfoProps, itemIndex: number) => ((num <= itemIndex)
                    ? (
                      <InputCell
                        item={{ ...item, id: `shareholders.${index}.${item.id}` }}
                        index={0}
                        key={`${getValues(`shareholders.${index}.shareholder_id`)}-${itemIndex}`}
                      />
                    ) : null))
                }
              </StyledHalfContentWrap>
            </StyledFieldWrap>
          </StyledContentWrap>
        </FormPanel>
      ))}
      <StyledPlusIcon
        disabled={shareholderNum >= MAX_SHAREHOLDERS || searchParams.get('status') === 'Reject'}
        onClick={() => {
          if (shareholderNum >= MAX_SHAREHOLDERS) return;
          if (searchParams.get('status') === 'Reject') return;
          setIsLoading(true);
          addShareholder(localStorage.getItem('accountID') || '').then((res) => {
            setShareholderNum((prev) => prev + 1);
            SHAREHOLDER_FIELDS.forEach((field: string) => {
              setValue(`shareholders.${shareholderNum}.${field}`, {
                shareholder_id: res.data[0].shareholder_id
              }[field] || '');
            });
            setIsLoading(false);
          });
        }}
      >
        <IconComponent name="DemoAccountPlus" />
        <span aria-label="add shareholder account">{t('addShareholder')}</span>
      </StyledPlusIcon>
      {range(directorNum).map((_, index) => (
        <FormPanel
          id={`Director-${index + 1}`}
          title={`${t('directorDetails')} ${index + 1}`}
          key={`director-${index}`}
          note={(
            directorNum > 1 && searchParams.get('status') !== 'Reject' && (
            <StyledDeleteButton
              type="button"
              aria-label={`Delete Director ${index + 1} Details`}
              onClick={() => {
                deleteTargetRef.current = {
                  index,
                  'shareholder-id': '',
                  'director-id': getValues(`directors.${index}.director_id`)
                };
                setOpenModal(true);
              }}
            >
              {t('delete')}
            </StyledDeleteButton>
            )
          )}
        >
          <StyledContentWrap>
            {index === 0 && (
              <StyledButtonGroup>
                <StyledLoadButton
                  onClick={() => handleLoadContactPerson('directors')}
                  aria-label="Load contact person details for director"
                  disabled={searchParams.get('status') === 'Reject'}
                >
                  {t('loadContactPersonDetails')}
                </StyledLoadButton>
                <StyledClearButton
                  onClick={() => handleClearContactPerson('directors')}
                  aria-label="Clear contact person details for director"
                  disabled={searchParams.get('status') === 'Reject'}
                >
                  {t('clearDetails')}
                </StyledClearButton>
              </StyledButtonGroup>
            )}
            <StyledFieldWrap gap={24} className="StyledContentWrap flx-row">
              <StyledHalfContentWrap>
                {
                  baseFields.slice(0, -1).map((item: InputInfoProps, itemIndex: number) => ((num > itemIndex)
                    ? (
                      <InputCell
                        item={{ ...item, id: `directors.${index}.${item.id}` }}
                        index={0}
                        key={`${getValues(`directors.${index}.director_id`)}-${itemIndex}`}
                      />
                    ) : null))
                }
              </StyledHalfContentWrap>
              <StyledHalfContentWrap>
                {
                  baseFields.slice(0, -1).map((item: InputInfoProps, itemIndex: number) => ((num <= itemIndex)
                    ? (
                      <InputCell
                        item={{ ...item, id: `directors.${index}.${item.id}` }}
                        index={0}
                        key={`${getValues(`directors.${index}.director_id`)}-${itemIndex}`}
                      />
                    ) : null))
                }
              </StyledHalfContentWrap>
            </StyledFieldWrap>
          </StyledContentWrap>
        </FormPanel>
      ))}
      <StyledPlusIcon
        disabled={directorNum >= MAX_DIRECTORS || searchParams.get('status') === 'Reject'}
        onClick={() => {
          if (directorNum >= MAX_DIRECTORS) return;
          if (searchParams.get('status') === 'Reject') return;
          setIsLoading(true);
          addDirector(localStorage.getItem('accountID') || '').then((res) => {
            setDirectorNum((prev) => prev + 1);
            DIRECTORS_FIELDS.forEach((field: string) => {
              setValue(`directors.${directorNum}.${field}`, {
                director_id: res.data[0].director_id
              }[field] || '');
            });
            setIsLoading(false);
          });
        }}
      >
        <IconComponent name="DemoAccountPlus" />
        <span aria-label="add director account">{t('addDirector')}</span>
      </StyledPlusIcon>
      <PopUp
        id="Delete Person"
        header={t('deletePerson')}
        openModal={openModal}
        onClose={() => setOpenModal(false)}
      >
        <span aria-label="Delete Shareholder Warning">{t('deleteWarning')}</span>
        <StyledPopUpFooter>
          <Button
            disabled={isLoading}
            aria-label="delete"
            onClick={() => {
              setIsLoading(true);
              if (deleteTargetRef.current['shareholder-id']) {
                deleteShareholder(localStorage.getItem('accountID') || '', deleteTargetRef.current['shareholder-id']).then(() => {
                  if (deleteTargetRef.current['shareholder-id']) removeShareHolders(deleteTargetRef.current.index);
                }).finally(() => {
                  deleteTargetRef.current = {
                    index: -1,
                    'shareholder-id': '',
                    'director-id': ''
                  };
                  setShareholderNum((prev) => prev - 1);
                  setIsLoading(false);
                  setOpenModal(false);
                });
              }

              if (deleteTargetRef.current['director-id']) {
                deleteDirector(localStorage.getItem('accountID') || '', deleteTargetRef.current['director-id']).then(() => {
                  if (deleteTargetRef.current['director-id']) removeDirectors(deleteTargetRef.current.index);
                }).finally(() => {
                  deleteTargetRef.current = {
                    index: -1,
                    'shareholder-id': '',
                    'director-id': ''
                  };
                  setDirectorNum((prev) => prev - 1);
                  setIsLoading(false);
                  setOpenModal(false);
                });
              }
            }}
          >
            {t('delete')}
          </Button>
          <Button aria-label="cancel" disabled={isLoading} onClick={() => setOpenModal(false)}>
            {t('cancel')}
          </Button>
        </StyledPopUpFooter>
      </PopUp>
    </StyledFormWrapper>
  );
};

export default ShareholdersAndDirectors;
