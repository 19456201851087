/* eslint-disable max-len */
import DownloadCard from 'components/DownloadCard';
import { getDownloadCenterLinks } from 'api/v1/downloadCenter';
import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import BackdropSpinner from 'components/BackdropSpinner';
import {
  StyledSectionContainer,
  StyledHeader,
  StyledHeaderBox,
  StyledContent,
  StyledMetaTraderContainer,
  StyledMetaTraderHeader,
  StyledPlatformContainer,
  StyledPlatformTitle,
  StyledPlatformMain
} from './style';
import IconList from './iconList';

const downloadLists = [
  {
    title: 'MetaTrader 4',
    PC: 'pcPlatform',
    web: 'webPlatform',
    mobile: 'mobilePlatform',
    pcCard: [
      {
        title: 'windowsDesktop',
        subtitle: 'pc',
        path: IconList.Windows,
        type: 'MT4Desktop'
      },
      {
        title: 'macOS',
        subtitle: 'mac',
        path: IconList.Apple,
        type: 'MT4MacOS'
      }
    ],
    websiteCard: [
      {
        title: 'webpagePlatform',
        subtitle: 'web',
        path: IconList.Website,
        type: 'MT4Browsers'
      }
    ],
    mobileCard: [
      {
        title: 'iOS',
        subtitle: 'ios',
        path: IconList.Apple,
        type: 'MT4Ios'
      },
      {
        title: 'Android',
        subtitle: 'android',
        path: IconList.Android,
        type: 'MT4Android'
      },
      {
        title: 'Android2',
        subtitle: 'android',
        path: IconList.Android,
        type: 'MT4Android2'
      }
    ]
  },
  {
    title: 'MetaTrader 5',
    PC: 'pcPlatform',
    web: 'webPlatform',
    mobile: 'mobilePlatform',
    pcCard: [
      {
        title: 'windowsDesktop',
        subtitle: 'pc',
        path: IconList.Windows,
        type: 'MT5Desktop'
      },
      {
        title: 'macOS',
        subtitle: 'mac',
        path: IconList.Apple,
        type: 'MT5MacOS'
      }
    ],
    websiteCard: [
      {
        title: 'webpagePlatform',
        subtitle: 'web',
        path: IconList.Website,
        type: 'MT5Browsers'
      }
    ],
    mobileCard: [
      {
        title: 'iOS',
        subtitle: 'ios',
        path: IconList.Apple,
        type: 'MT5Ios'
      },
      {
        title: 'Android',
        subtitle: 'android',
        path: IconList.Android,
        type: 'MT5Android'
      },
      {
        title: 'Android2',
        subtitle: 'android',
        path: IconList.Android,
        type: 'MT5Android2'
      }
    ]
  }
];
interface CardItemType {
  title: string;
  subtitle: string;
  path: string;
  type: string;
}
interface DownLoadListType {
  pcCard: CardItemType[];
  mobileCard: CardItemType[];
  websiteCard: CardItemType[];
  title: string;
  PC: string;
  web: string;
  mobile: string;
}
const DownloadCenter = ():JSX.Element => {
  const { t } = useTranslation('downloadCenter');
  const [downloadLinks, setDownloadLinks] = useState<{[key: string]: string}>({});

  useEffect(() => {
    getDownloadCenterLinks().then((res) => {
      if (res.status === 200) {
        setDownloadLinks(res.data);
      }
    });
  }, []);

  const openPage = (type: string): void => {
    window.open(downloadLinks[type], '_blank', 'noopener,noreferrer');
  };

  if (isEmpty(downloadLinks)) return <BackdropSpinner open />;
  return (
    <StyledSectionContainer>
      <StyledHeader>
        <StyledHeaderBox>
          <h2 aria-label="Download Center">{t('downloadCenter')}</h2>
        </StyledHeaderBox>
      </StyledHeader>
      <StyledContent>
        {downloadLists.map((item: DownLoadListType) => (
          <StyledMetaTraderContainer key={item.title}>
            <StyledMetaTraderHeader aria-label={`${item.title} header`}>{t(item.title)}</StyledMetaTraderHeader>
            <StyledPlatformContainer>
              <StyledPlatformTitle aria-label="PC platform">{t(item.PC)}</StyledPlatformTitle>
              <StyledPlatformMain>
                {item.pcCard.map((eachCardItem: CardItemType) => (
                  downloadLinks[eachCardItem.type]
              && (
              <DownloadCard
                id={eachCardItem.title}
                aria-label={`${eachCardItem.title} card`}
                key={t(eachCardItem.title)}
                title={t(eachCardItem.title)}
                subtitle={t(eachCardItem.subtitle)}
                path={t(eachCardItem.path)}
                onClick={() => openPage(`${eachCardItem.type}`)}
              />
              )
                ))}
              </StyledPlatformMain>
            </StyledPlatformContainer>
            <StyledPlatformContainer>
              <StyledPlatformTitle aria-label="web platform">{t(item.web)}</StyledPlatformTitle>
              <StyledPlatformMain>
                {item.websiteCard.map((websiteCardItem: CardItemType) => (
                  downloadLinks[websiteCardItem.type]
              && (
              <DownloadCard
                id={websiteCardItem.title}
                key={websiteCardItem.title}
                title={t(websiteCardItem.title)}
                subtitle={t(websiteCardItem.subtitle)}
                path={t(websiteCardItem.path)}
                onClick={() => openPage(`${websiteCardItem.type}`)}
              />
              )
                ))}
              </StyledPlatformMain>
            </StyledPlatformContainer>
            <StyledPlatformContainer>
              <StyledPlatformTitle aria-label="mobile platform">{t(item.mobile)}</StyledPlatformTitle>
              <StyledPlatformMain>
                {item.mobileCard.map((mobileCardItem: CardItemType) => (
                  downloadLinks[mobileCardItem.type]
                && (
                <DownloadCard
                  id={mobileCardItem.title}
                  key={mobileCardItem.title}
                  title={t(mobileCardItem.title)}
                  subtitle={t(mobileCardItem.subtitle)}
                  path={mobileCardItem.path}
                  onClick={() => openPage(`${mobileCardItem.type}`)}
                />
                )
                ))}
              </StyledPlatformMain>
            </StyledPlatformContainer>
          </StyledMetaTraderContainer>
        )) }
      </StyledContent>
    </StyledSectionContainer>
  );
};

export default DownloadCenter;
