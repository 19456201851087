import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import Button from '@mui/material/Button';

export const StyledContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 40px 8px;
  background: #ffffff;
  border-radius: 0 0 16px 16px;
  height: 100%;

  @media ${device.desktopMinWidth} {
    display: flex;
  }

  @media ${device.tablet} {
    padding: 40px 24px 8px;
  }

  @media ${device.mobileMaxWidth} {
    padding: 40px 16px 8px;
  }
`;

export const StyledFieldWrap = styled.div<{ gap?: number }>`
  display: flex;
  gap: ${(props) => (props.gap ? `${props.gap}px` : '0px')};

  @media ${device.tabletMaxWidth} {
    flex-direction: column;
  }
`;

export const StyledHalfContentWrap = styled.div`
  flex: 1;
`;

export const StyledLoadButton = styled(Button)`
  font-family: 'Noto Sans';
  font-style: normal;
  background: #145db5;
  border-radius: 4px;
  padding: 8px 24px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: none;

  &:hover,
  &:active {
    background: #013b81;
  }
  &:disabled {
    background: #145db5;
    color: #fff;
    opacity: 50%;
  }
`;

export const StyledPlusIcon = styled.div<{ disabled?: boolean }>`
  margin-left: 24px;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  > svg {
    path {
      fill: ${(props) => (props.disabled ? '#5c5c5c' : '#145db5')};
    }
  }

  > span {
    margin-left: ${(props) => !props.theme.isRTL && '12px'};
    margin-right: ${(props) => props.theme.isRTL && '12px'};
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: ${(props) => (props.disabled ? '#5c5c5c' : '#145db5')};
  }
`;

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledDeleteButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  color: #006acc;
  cursor: pointer;
`;

export const StyledPopUpFooter = styled.div`
  margin-top: 24px;
  display: flex;

  button {
    font-family: 'Noto Sans';
    text-transform: none;
    border-radius: 4px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 24px;

    &:first-child {
      margin-right: 8px;
      color: #ffffff;
      background-color: #145db5;

      &:disabled {
        opacity: 0.6;
      }
    }

    &:last-child {
      min-width: 62px;
      color: #145db5;
      background: transparent;
    }
  }
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
`;

export const StyledClearButton = styled(Button)`
  padding: 8px 24px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  color: #145db5;

  &:hover,
  &:active {
    background: #fff;
  }
`;
