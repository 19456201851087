import * as React from "react";
import { SVGProps } from "react";
const SvgLog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#Log_svg__a)" fill="#F4F4F4">
      <path d="M13.594 3c.124 0 .243.053.331.146.088.094.137.221.137.354v9c0 .133-.049.26-.137.354a.455.455 0 0 1-.331.146H1.406a.455.455 0 0 1-.331-.146.518.518 0 0 1-.137-.354v-9c0-.133.049-.26.137-.354A.454.454 0 0 1 1.406 3h12.188ZM1.406 2c-.373 0-.73.158-.994.44A1.552 1.552 0 0 0 0 3.5v9c0 .398.148.78.412 1.06.264.282.621.44.994.44h12.188c.373 0 .73-.158.994-.44.264-.28.412-.662.412-1.06v-9c0-.398-.148-.78-.412-1.06a1.363 1.363 0 0 0-.994-.44H1.406Z" />
      <path d="M4.688 8c0-.133.049-.26.137-.354a.454.454 0 0 1 .331-.146h6.563c.124 0 .243.053.331.146.088.094.137.221.137.354 0 .133-.049.26-.137.354a.454.454 0 0 1-.331.146H5.156a.454.454 0 0 1-.331-.146A.517.517 0 0 1 4.687 8Zm0-2.5c0-.133.049-.26.137-.354A.454.454 0 0 1 5.156 5h6.563c.124 0 .243.053.331.146.088.094.137.221.137.354 0 .133-.049.26-.137.354A.454.454 0 0 1 11.72 6H5.156a.454.454 0 0 1-.331-.146.517.517 0 0 1-.138-.354Zm0 5c0-.133.049-.26.137-.354A.455.455 0 0 1 5.156 10h6.563c.124 0 .243.053.331.146a.518.518 0 0 1 .137.354c0 .133-.049.26-.137.354a.455.455 0 0 1-.331.146H5.156a.455.455 0 0 1-.331-.146.518.518 0 0 1-.138-.354Zm-.938-5c0 .133-.05.26-.137.354A.454.454 0 0 1 3.28 6a.454.454 0 0 1-.331-.146.517.517 0 0 1-.138-.354c0-.133.05-.26.138-.354A.454.454 0 0 1 3.28 5c.125 0 .244.053.332.146.088.094.137.221.137.354Zm0 2.5c0 .133-.05.26-.137.354a.454.454 0 0 1-.332.146.454.454 0 0 1-.331-.146A.517.517 0 0 1 2.812 8c0-.133.05-.26.138-.354A.454.454 0 0 1 3.28 7.5c.125 0 .244.053.332.146.088.094.137.221.137.354Zm0 2.5c0 .133-.05.26-.137.354A.455.455 0 0 1 3.28 11a.455.455 0 0 1-.331-.146.518.518 0 0 1-.138-.354c0-.133.05-.26.138-.354A.455.455 0 0 1 3.28 10c.125 0 .244.053.332.146a.518.518 0 0 1 .137.354Z" />
    </g>
    <defs>
      <clipPath id="Log_svg__a">
        <path fill="#fff" d="M0 0h15v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLog;
