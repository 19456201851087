import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledContentWrap = styled.div<{ gap?: number }>`
  padding: 40px 20px;
  display: inline-block;
  flex-direction: column;
  border-radius: 0 0 16px 16px;
  height: 100%;
  font-size: 16px;
  color: #1C1C1C;
  p {
    margin: 0;
    margin-bottom: 24px;
  }
`;

export const StyledHintWrap = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 16px;
  border-top: 1px solid #DDDDDD;
  padding-top: 8px;
  color: #5C5C5C;
  margin-bottom: 24px;
`;

export const StyledBtnWrap = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: center;

    @media ${device.tabletMaxWidth} {
      padding: 0 48px;
    }

    @media ${device.mobileMaxWidth} {
      padding: 0;
    }
  .start{
    background: #145DB5;
    text-transform: none;
    padding: 24px;
    width: 300px;

    @media ${device.tabletMaxWidth} {
      width: 100%;
    }
  }
  .later{
    font-weight: 600;
    text-decoration: underline;
    text-transform: none;
    color: #145DB5;
    font-size: 16px;
  }

  >button{
    &.MuiButtonBase-root {
      margin-right:0;
      font-family: 'Noto Sans';
      min-width: unset;
      font-weight: 600;
      padding: 8px 24px;
      line-height: 24px;
    }
  }
`;

export const StyledPopupBtnWrap = styled.div`
  >button{
    &.MuiButtonBase-root {
      margin-right:0;
      text-transform: lowercase;
      font-family: 'Noto Sans';
      min-width: unset;
    }
  }
`;

export const StyledBanner = styled.div`
  border-radius: 12px;
  background: linear-gradient(273deg, #004599 12.34%, #8890D7 148.91%);
  box-shadow: 0px 1px 3px 0px rgba(90, 114, 143, 0.16), 0px 1px 4px 0px rgba(90, 114, 143, 0.10);
  padding: 20px ;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
`;

export const StyledBannerHead = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  margin: 0 0 0 0;

  @media ${device.mobileMaxWidth} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledProcessWrap = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 16px;
  @media ${device.mobileMaxWidth} {
    margin-top: 8px;
  }
`;

export const StyledStepWrap = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  @media ${device.mobileMaxWidth} {
    font-size: 10px;
    gap: 4px;
  }

  .icon{
    width: 120px;
    height: auto;

    @media ${device.tabletMaxWidth} {
      width: 100px;
    }

    @media ${device.mobileMaxWidth} {
      width: 56px;
    }
  }
`;

export const StyledSolidLine = styled.div`
  border-bottom: 1px solid #FFF;
  width: 120px;
  margin: 0 16px 10px 16px;
  @media ${device.tabletMaxWidth} {
    width: 64px;
  }

  @media ${device.mobileMaxWidth} {
    width: 24px;
    margin: 0 2px 10px 2px;
  }
`;

export const StyledDashedLine = styled.div`
  border-bottom: 1px dashed #FFF;
  width: 120px;
  margin: 0 16px 10px 16px;
  @media ${device.tabletMaxWidth} {
    width: 64px;
  }

  @media ${device.mobileMaxWidth} {
    width: 24px;
    margin: 0 2px 10px 2px;
  }
`;

export const StyledContent = styled.div`
  text-align: center;
  margin: 56px 0;

  @media ${device.mobileMaxWidth} {
    margin: 24px 0;
  }
`;

export const StyledContentTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  text-transform: capitalize;
  margin: 0;

  @media ${device.mobileMaxWidth} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const StyledContentSubtitle = styled.div`
  color: #1C1C1C;
  font-family: Noto Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;

  @media ${device.mobileMaxWidth} {
    font-size: 14px;
  }
`;

export const StyledOptions = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 64px;
  gap: 40px;
  @media ${device.tabletMaxWidth} {
    flex-direction: column;
    padding: 0px 48px;
    gap: 24px;
  }

  @media ${device.mobileMaxWidth} {
    padding: 0;
  }
`;

export const StyledFastCard = styled.div<{ content: string, selected: boolean}>`
  padding: 24px;
  border-radius: 16px;
  background: ${(props) => (props.selected ? 'linear-gradient(0deg, rgba(204, 230, 255, 0.20) 0%, rgba(204, 230, 255, 0.20) 100%), #FFF;' : '#FFF')};
  box-shadow: 0px 1px 4px 0px rgba(90, 114, 143, 0.10), 0px 1px 3px 0px rgba(90, 114, 143, 0.16);
  border:${(props) => (props.selected ? '2px solid #145DB5;' : '')};
  width: 50%;
  position: relative;
  cursor: pointer;
  &::after{
    padding: 2px 8px;
    content: '${(props) => props.content}' ;;
    position: absolute;
    right: 20px;
    top: -12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;
    color: #FFF;
    margin: 0;
    border-radius: 8px;
    background: #145DB5;
    @media ${device.mobileMaxWidth} {
      font-size: 14px;
    }
  }

  @media ${device.tabletMaxWidth} {
    width: 100%;
  }
`;

export const StyledRegularCard = styled.div<{selected: boolean}>`
  padding: 24px;
  border-radius: 16px;
  background: ${(props) => (props.selected ? 'linear-gradient(0deg, rgba(204, 230, 255, 0.20) 0%, rgba(204, 230, 255, 0.20) 100%), #FFF;' : '#FFF')};
  box-shadow: 0px 1px 4px 0px rgba(90, 114, 143, 0.10), 0px 1px 3px 0px rgba(90, 114, 143, 0.16);
  border:${(props) => (props.selected ? '2px solid #145DB5;' : '')};
  width: 50%;
  cursor: pointer;
  
  @media ${device.tabletMaxWidth} {
    width: 100%;
  }
`;

export const StyledCardTitle = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-transform: capitalize;
  margin-bottom: 8px;
  @media ${device.mobileMaxWidth} {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
`;

export const StyledCardSubtitle = styled.div`
  color:  #1C1C1C;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 32px;
  @media ${device.mobileMaxWidth} {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const StyledCardTitleDesc = styled.div`
  color: #3E3E3E;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  span{
    font-weight: 600;
    color: #145DB5;
  }
  @media ${device.mobileMaxWidth} {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const StyledCardDesc = styled.div`
  color: #1C1C1C;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  @media ${device.mobileMaxWidth} {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  background: #DDD;
  margin: 24px 0;
`;

export const StyledStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledStepContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 32px;
  text-transform: capitalize;
  @media ${device.mobileMaxWidth} {
    font-size: 10px;
    gap: 4px;
    margin-top: 16px;
  }

  .processIcon{
    width: 20px;
    height: 20px;

    @media ${device.mobileMaxWidth} {
      width: 8px;
      height: 8px;
    }
  }
`;
