import cx from 'classnames';
import { forwardRef } from 'react';
import { StyledPaper } from './style';

interface PaperProps {
  className?: string;
  elevation?: number;
  color?: string;
  children?: React.ReactNode;
}

const Paper = forwardRef<HTMLDivElement, PaperProps>((props, ref) => {
  const {
    children, className, elevation = 0, color = '#FFFFFF', ...other
  } = props;
  return (
    <StyledPaper
      className={cx('paper', className)}
      elevation={elevation}
      color={color}
      ref={ref}
      {...other}
    >
      {children}
    </StyledPaper>
  );
});

export default Paper;
