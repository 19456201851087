import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import PopUp from '../PopUp';

export const StyledPopUpWrapper = styled(PopUp)`
  position: relative;
  width: 477px;
  > div:first-child {
    font-weight: 600;
    margin-bottom: 16px;
  }

  @media ${device.mobileMaxWidth} {
    width: 343px;
  }
`;

export const StyledNote = styled.span`
  font-weight: 400;
  color: #1C1C1C;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 16px;
`;

export const StyledLButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 4px;
    line-height: 24px;
    min-height: 34px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    flex: 1; 


    &:first-child {
      color: #145DB5;
      border-color: #145DB5;
      margin: ${(props) => (props.theme.isRTL ? '0 0 0 20px' : '0 20px 0 0')};
      &:hover,
      &:focus {
        color: ${(props) => props.theme.common.primary};
        border-color: ${(props) => props.theme.common.primary};
      }
    }
    &:last-child {
      color: #FFF;
      border-color: #145DB5;
      background-color: #145DB5;
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

      &:hover,
      &:focus {
        color: #ffffff;
        border-color: ${(props) => props.theme.common.primary};
        background-color: ${(props) => props.theme.common.primary};
      };
    }
  }
`;
