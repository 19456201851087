import * as React from "react";
import { SVGProps } from "react";
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.336.336a1.143 1.143 0 0 1 1.618 0L8 6.385l6.047-6.05a1.143 1.143 0 1 1 1.618 1.619L9.616 8l6.049 6.047a1.143 1.143 0 0 1-.81 1.953 1.144 1.144 0 0 1-.808-.335L8 9.616l-6.046 6.049a1.143 1.143 0 1 1-1.618-1.618L6.385 8 .335 1.954a1.143 1.143 0 0 1 0-1.618Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgClose;
