import { generateState, generateCodeChallenge, generateCodeVerifier } from 'utils/auth';
import { DOMAIN_CONFIG } from 'constant/domainConfig';
import Lang from './language.util';

const redirectToKeycloakLogin = (): void => {
  const codeVerifier = generateCodeVerifier();
  localStorage.setItem('codeVerifier', codeVerifier);

  const baseUrl = `${DOMAIN_CONFIG.keycloak}/auth`;

  const queryParams = new URLSearchParams({
    client_id: 'meetup-app-pkce-client',
    response_type: 'code',
    scope: 'openid profile',
    redirect_uri: `${DOMAIN_CONFIG.origin}/login/callback${window.location.pathname}`,
    state: generateState(),
    registerUrl: `${DOMAIN_CONFIG.origin}/open-live-account`,
    code_challenge: generateCodeChallenge(codeVerifier),
    code_challenge_method: 'S256',
    // Set the language of the Keycloak login page to the same as the current language of the website.
    ui_locales: Lang.transferLangIntoKeycloak(Lang.getDefaultLanguage())
  });

  window.location.href = `${baseUrl}?${queryParams.toString()}`;
};

export default redirectToKeycloakLogin;
