/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import { device } from 'styles/deviceStyle';

export const ButtonStyle = css`
  text-transform: capitalize;
  font-size: 16px;
  width: 100%;
  height: 48px;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 26px;

  background-color: #145db5;
  border-color: #145db5;
  border-radius: 4px;
  color: #ffffff;
  font-family: inherit;
  &:hover {
    background-color: #145db5;
    border-color: #145db5;
    opacity: 0.8;
    border-radius: 4px;
    color: #ffffff;
  }
  &:disabled {
    background-color: #145db5;
    border-color: #145db5;
    opacity: 0.6;
    border-radius: 4px;
    color: #ffffff;
  }
  &:focus {
    background-color: #145db5;
    border-color: #145db5;
    border-radius: 4px;
    color: #ffffff;
  }

  @media ${device.mobileMaxWidth} {
    margin-top: 16px;
  }

  @media ${device.bigDesktopMinWidth} {
    margin-top: 36px;
  }
`;

export const UbankButtonStyle = css`
  text-transform: capitalize;
  font-size: 16px;
  width: 100%;
  height: 48px;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 26px;

  background-color: #C29738;
  border-color: #C29738;
  border-radius: 4px;
  color: #ffffff;
  font-family: inherit;
  &:hover {
    background-color: #C29738;
    border-color: #C29738;
    opacity: 0.8;
    border-radius: 4px;
    color: #ffffff;
  }
  &:disabled {
    background-color: #C29738;
    border-color: #C29738;
    opacity: 0.6;
    border-radius: 4px;
    color: #ffffff;
  }
  &:focus {
    background-color: #C29738;
    border-color: #C29738;
    border-radius: 4px;
    color: #ffffff;
  }

  @media ${device.mobileMaxWidth} {
    margin-top: 16px;
  }

  @media ${device.bigDesktopMinWidth} {
    margin-top: 36px;
  }
`;

interface InputProps {
  valid?: boolean;
}

export const StyledRegisterContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  margin: auto;

  .customPaper {
    width: 100%;
    border-radius: 0px;
  }

  .select-msg-wrap {
    display: none;
  }
`;

export const StyledContentWrapper = styled.div`
  > form {
    display: flex;
    justify-content: center;
  }
`;

export const StyledHeaderLogoLangs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  @media ${device.desktopMinWidth} {
    width: 580px;
  }
  @media ${device.bigDesktopMinWidth} {
    width: 628px;
  }
`;

export const StyledThirdPartyLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 24px;
  > svg {
    width: 49px;
    height: 44px;
    margin: 0 4px;
    color: red;
  }
`;

export const StyledTitle = styled.div`
  color: #1c1c1c;
  font-size: 24px;
  font-weight: 600;
  margin: 0 auto 32px;

  @media ${device.mobileMaxWidth} {
    margin-bottom: 20px;
    font-size: 20px;
  }
`;

export const StyledEmailVerficationTitle = styled.div`
  color: #1c1c1c;
  font-size: 24px;
  font-weight: 600;

  @media ${device.mobileMaxWidth} {
    font-size: 20px;
  }
`;

export const StyledForm = styled.div<{ curLang: string }>`
  margin: auto;
  direction: ${({ curLang }) => (curLang !== 'ar' && curLang !== 'he' ? 'ltr' : 'rtl')};
  > form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  label {
    color: #5c5c5c;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 600;
  }

  .country-select,
  .phone-input {
    .msg {
      min-height: 0;
    }
  }

  .customButton {
    ${ButtonStyle}
  }

  .customUBankButton {
    ${UbankButtonStyle}
  }
`;

export const StyledInput = styled.input<InputProps>`
  height: 48px;
  background: #ffffff;
  border-radius: 8px;
  border: ${(props) => (props.valid ? '1px solid #BBBBBB' : '1px solid #E92C2C')};
  padding: 0 16px;
  font-size: 14px;
  margin: 6px 0;
  color: #2a2a2a;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #969696;
  }
  :-ms-input-placeholder {
    color: #969696;
  }
`;

export const StyledErrorMessage = styled.div`
  font-size: 14px;
  color: #e92c2c;
`;

export const StyledCriteriaContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  flex-wrap: wrap;
  color: #585757;
`;

export const StyledCriteria = styled.div<{ valid: boolean }>`
  display: flex;
  background: ${(props) => (props.valid ? '#99E3AE' : '#F4F4F4')};
  color: ${(props) => (props.valid ? '#004A15' : '#5C5C5C')};
  border-radius: 32px;
  padding: 4px 10px;
  font-size: 10px;
  margin: 8px 8px 0 0;
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  margin-top: 15px;
  font-size: 14px;
  color: #3e3e3e;
  white-space: pre-wrap;

  > input {
    margin-right: 12px;
  }
`;

export const StyledCheckbox = styled.span`
  margin: 0;
  margin-right: 12px;

  .ubank{
    input[type="checkbox"] {
      width: 20px;
      height: 20px;
    }
    input[type="checkbox"] {
      cursor: pointer;
      position: relative;
      width: 13px;
      height: 13px;
      font-size: 12px;
    }

    input[type="checkbox"]::after {
      position: absolute;
      top: 0;
      color: #000;
      width: 13px;
      height: 13px;
      display: inline-block;
      visibility: visible;
      padding-left: 0px;
      text-align: center;
      content: " ";
      border-radius: 2px;
    }

    input[type="checkbox"]:checked::after {
      content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" fill="white" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
      color: #fff;
      font-size: 10px;
      font-weight: bold;
      background-color: #c29738;
    }
  }
`;

export const StyledPasswordContainer = styled.div<{ curLang: string }>`
  display: flex;
  position: relative;
  width: 100%;
  input {
    width: 100%;
  }
  div {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    left: ${(props) => (props.theme.isRTL ? '12px' : 'none')};
    right: ${(props) => (props.theme.isRTL ? 'none' : '12px')};
    &:hover {
      cursor: pointer;
    }
  }
`;

export const StyledDropdownWrap = styled.div`
  text-align: center;
  min-height: 25px;
  svg {
    font-size: 10px;
  }

  .menu-list {
    padding: 0;
    text-align: center;
    height: 140px;
    overflow-y: scroll;
    height: 140px;
    overflow-y: scroll;
    .item {
      color: #3e3e3e;
      padding: 0;
      > button {
        cursor: pointer;
        width: 100%;
        border: none;
        color: #3e3e3e;
        font-size: 14px;
        line-height: 24px;
        padding: 6px 12px;
        background: #fff;
        height: 36px;
        &:hover {
          background: #f0f0f0;
        }
      }
    }
  }
`;

export const StyledLangWrap = styled.div`
  display: inline-flex;
  align-items: center;
  > span {
    margin-left: 7px;
    margin-right: 10px;
    color: #3e3e3e;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const StyledCheckWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
  > svg {
    > path {
      fill: #004a15;
    }
  }
`;

export const StyledLoadingWrap = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledPopUpBtnWrap = styled.div<{ curLang: string, ubank: boolean }>`
  direction: ${({ curLang }) => (curLang !== 'ar' && curLang !== 'he' ? 'ltr' : 'rtl')};
  > button {
    font-size: 14px;
    margin-right: 0;
    text-transform: capitalize;
    font-family: "Noto Sans";
    min-width: unset;
    
    &:first-child {
      border: ${({ ubank }) => (ubank ? '1px solid #C29738' : '1px solid #013b81')};;
      color: ${({ ubank }) => (ubank ? '#C29738' : '#013b81')};;
      > span {
        border-radius: 2px;
        border-color: #013b81;
      }
    }

    &:last-child {
      background-color: ${({ ubank }) => (ubank ? '#C29738' : '#013b81')};;
    }
  }
`;

export const StyledNameWrap = styled.div<{ curLang: string }>`
  display: flex;
  margin-top: 16px;
  > div {
    flex: 1 1 0;
    > input {
      width: 100%;
    }
  }

  > div:last-child {
    margin: ${({ curLang }) => (curLang !== 'ar' && curLang !== 'he' ? '0 0 0 23px' : '0 23px 0 0')};
  }
`;

export const StyledMobileInputWrap = styled.div<{
  mobileErr?: boolean;
  curLang: string;
}>`
  > div > label {
    margin: 16px 0 0;
  }

  .phone-input-msg-wrap {
    display: ${({ mobileErr }) => (mobileErr ? 'block' : 'none')};
  }
  .react-tel-input .search {
    padding: 10px 10px 6px 10px;
  }
  .country-list {
    width: 464px;
  }
`;

export const StyledCountrySelectWrap = styled.div`
  > div {
    background-color: #fff;
    margin-top: 4px;
  }
`;

export const StyledContent = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  width: 446px;
  margin-top: 54px;

  @media ${device.tablet} {
    width: 100%;
    margin: 54px 82px 0;
  }

  @media ${device.mobileMaxWidth} {
    width: 100%;
    margin: 20px 16px 0;
  }

  @media ${device.bigDesktopMinWidth} {
    width: 493px;
  }
`;

export const StyledAlreadRegisterWrap = styled.p<{ ubank: boolean }>`
  font-size: 14px;
  font-weight: 400;
  color: #5c5c5c;
  text-align: center;
  margin-top: 6px;
  > a {
    color: ${(props) => (props.ubank ? '#C29738' : '#145db5')};
    text-decoration: none;
    margin: ${(props) => (props.theme.isRTL ? '0 10px 0 0' : '0 0 0 10px')};
  }
`;

export const StyledPolicyLink = styled.a`
  color: #145db5;
  text-decoration: none;
`;
