import common from './common.json';
import createAccount from './createAccount.json';
import demoAccount from './demoAccount.json';
import sidebar from './sidebar.json';
import selectAccountType from './selectAccountType.json';
import videos from './videos.json';
import onlineTraining from './onlineTraining.json';
import blurredPopUpCard from './blurredPopUpCard.json';
import registrationForm from './registrationForm.json';
import thankYouPage from './thankYouPage.json';
import accountList from './accountList.json';
import downloadCenter from './downloadCenter.json';
import rg227Unsuccessful from './rg227Unsuccessful.json';
import verifyIdentity from './verifyIdentity.json';
import error from './error.json';

export default {
  common,
  createAccount,
  demoAccount,
  sidebar,
  selectAccountType,
  videos,
  blurredPopUpCard,
  registrationForm,
  onlineTraining,
  thankYouPage,
  accountList,
  downloadCenter,
  rg227Unsuccessful,
  verifyIdentity,
  error
};
