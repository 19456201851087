import { AxiosPromise } from 'axios';
import { setGlobalPopUp } from 'containers/Pages/slices';
import { AppDispatch } from 'store';

// [TODO]: remove it and unify error handling into axios instance
const ErrorHandler = (api: AxiosPromise, dispatch: AppDispatch): AxiosPromise => api
  .catch((err) => {
    if (err.response.status === 500) {
      dispatch(setGlobalPopUp({ isModalOpen: true, msg: err.response?.data?.message }));
    // eslint-disable-next-line no-alert
    } else alert(err.response?.data?.message);
    return api.catch();
  });

export default ErrorHandler;
