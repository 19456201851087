import * as React from "react";
import { SVGProps } from "react";
const SvgLogOut = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4.6A2.4 2.4 0 0 0 3.6 7v10A2.4 2.4 0 0 0 6 19.4h4a2.4 2.4 0 0 0 2.4-2.4v-1a.6.6 0 1 1 1.2 0v1a3.6 3.6 0 0 1-3.6 3.6H6A3.6 3.6 0 0 1 2.4 17V7A3.6 3.6 0 0 1 6 3.4h4A3.6 3.6 0 0 1 13.6 7v1a.6.6 0 1 1-1.2 0V7A2.4 2.4 0 0 0 10 4.6H6Zm10.576 2.976a.6.6 0 0 1 .848 0l4 4a.6.6 0 0 1 0 .848l-4 4a.6.6 0 0 1-.848-.848L19.55 12.6H7a.6.6 0 1 1 0-1.2H19.55l-2.975-2.976a.6.6 0 0 1 0-.848Z"
      fill="#fff"
    />
  </svg>
);
export default SvgLogOut;
