import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BackdropSpinner from 'components/BackdropSpinner';
import { StyledCorrectionWrap } from './style';

const CorrectionRedirect = ():JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = location.pathname.split('/')[2];
    navigate(`/correction-form?status=Reject&correctionToken=${token}`);
  }, []);

  return (
    <StyledCorrectionWrap>
      <BackdropSpinner open />
    </StyledCorrectionWrap>
  );
};

export default CorrectionRedirect;
