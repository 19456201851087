import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import { sideMenuCloseWidth, sideMenuOpenWidth } from 'containers/SideMenu/styled';

const rightSidePadding = (isMenuOpen: boolean, isRTL: boolean): string => {
  const padding = isMenuOpen ? sideMenuOpenWidth : sideMenuCloseWidth;
  return isRTL ? `0 ${padding}px 0 0` : `0 0 0 ${padding}px`;
};

export const StyledContent = styled.div<{ background?: string }>`
  padding-top: 72px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props) => (props.background ? props.background : 'inherit')};
  height: 100%;
`;

export const StyledRightSideWrap = styled.div<{ isMenuOpen: boolean }>`
  width: 100%;
  height: 100%;
  padding: ${(props) => rightSidePadding(props.isMenuOpen, props.theme.isRTL)};
  transition: padding 0.5s;
  @media ${device.tabletMaxWidth} {
    padding: 0;
  }
`;
