import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useLocation } from 'react-router';
import { getAccounts } from 'api/v1/account';
import { AccountStatus } from 'utils/AccountStatusHandler';
import useSWR from 'swr';

type Context = {
  exceedFailedAttempts: boolean;
  hasOnceFailed: boolean;
  accountId: string;
};

const AccountRG227Context = createContext<Context>({} as Context);

export const AccountRG227Provider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const location = useLocation();
  const [exceedFailedAttempts, setExceedFailedAttempts] = useState<Context['exceedFailedAttempts']>(false);
  const [hasOnceFailed, setHasOnceFailed] = useState<Context['hasOnceFailed']>(false);
  const [accountId, setAccountId] = useState<Context['accountId']>('');
  const [shouldFetch, setShouldFetch] = useState<boolean>(true);
  const {
    data, isLoading
  } = useSWR(shouldFetch ? ['/accounts', 'get'] : null, getAccounts, { dedupingInterval: 2000, revalidateOnFocus: false });

  useEffect(() => {
    setShouldFetch(true);
  }, [location.pathname]);

  useEffect(() => {
    if (data && !isLoading) {
      const inProgressAccount = data.data.find(
        (_account: { [key: string]: string }) => _account.status === AccountStatus.Reject
          || _account.status === AccountStatus.Pending
          || _account.status === AccountStatus.Incomplete
      );
      if (inProgressAccount) {
        setExceedFailedAttempts(inProgressAccount.is_blocked_by_rg227);
        setHasOnceFailed(inProgressAccount.can_retry_rg227);
        setAccountId(inProgressAccount.id);
      }
      setShouldFetch(false);
    }
  }, [isLoading, data]);

  const value = useMemo(
    () => ({
      exceedFailedAttempts,
      hasOnceFailed,
      accountId
    }),
    [exceedFailedAttempts, hasOnceFailed, accountId]
  );

  return (
    <AccountRG227Context.Provider value={value}>
      {children}
    </AccountRG227Context.Provider>
  );
};

export const useAccountRG227State = (): Context => useContext(AccountRG227Context);
