import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import Modal from '@mui/material/Modal';

export const StyledModalWrapper = styled(Modal)`
  overflow: auto;
`;

export const StyleSelectedCountry = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .country-modal-container {
    padding: 24px;
    background: #f6f6f6;
    border-radius: 8px;
    width: 500px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    .MuiCard-root {
      max-width: none;
      color: #5c5c5c;
      cursor: pointer;
      padding: 24px 24px 8px;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      backdrop-filter: blur(36px);
      border-radius: 8px;
      .MuiCardActionArea-root {
        &:hover {
          background: #fff;
        }
      }
      .MuiCardContent-root {
        padding: 24px 0 8px 0;
        > h5 {
          margin-bottom: 12px;
          font-size: 20px;
          font-family: 'Noto Sans', sans-serif;
          font-family: 600;
          color: #1c1c1c;
          + div {
            height: 41px;
          }
        }
      }

      .MuiButtonBase-root {
        .MuiTouchRipple-root {
          background: none;
          display: none;
        }
        &:hover {
          background: none;
        }
        .MuiCardActionArea-focusHighlight {
          display: none;
        }
      }

      &:last-child {
        padding: 0;
      }
    }
  }

  @media ${device.tablet} {
    width: 100%;
    .country-modal-container {
      width: 460px;
      padding: 30px;
    }
  }

  @media ${device.mobileMaxWidth} {
    width: 100%;
    .country-modal-container {
      width: 335px;
      padding: 30px 20px;
    }
  }
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  button {
    border-radius: 8px;
    min-width: 78px;
    padding: 8px 16px;
    font-size: 14px;

    &.cancel-button{
      color: #145DB5;
      background: #f6f6f6;
      border-color: #145DB5;
      border-radius: 8px;
      &:hover,
      &:focus {
          color: #145DB5;
          border-color: #145DB5;
      }
    }
    &.next-button{
      color: #FFFFFF;
      border-color: #145DB5;
      background-color: #145DB5;

      &:hover,
      &:focus {
        color: #FFFFFF;
        border-color: #145DB5;
        background-color: #145DB5;
      };
      &:disabled {
        color: #FFFFFF;
        border-color:  #145DB5;
        background-color: #145DB5;
        opacity: 0.6;
      }

      &.hidden {
        display: none;
      }
    }
  }
`;
export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px 0;
  border-bottom: solid 1px #DDD;
`;

export const StyledTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  line-height: 24px;
  color: #1C1C1C;
`;

export const StyledContent = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #1C1C1C;
  margin: 0;
`;

export const StyledIconWrap = styled.div`
  display: flex;
  color: #5C5C5C;
  cursor: pointer;
`;

export const StyledBtnWrap = styled.div`
     >button{
       &.MuiButtonBase-root {
         margin-right:0;
         text-transform: lowercase;
         font-family: 'Noto Sans';
         min-width: unset;
        }
     }
`;
