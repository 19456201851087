import * as React from "react";
import { SVGProps } from "react";
const SvgOnboardingAI = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 52 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M5.017 12.699c.605-.593 1.409-.921 2.214-1.18l.295-.095c-1.1 1.076-1.987 2.651-2.28 4.166-.342 1.774.149 3.61 1.13 7.282 1.486 5.554 3.07 10.386 5.121 15.6 1.363 3.465 2.045 5.198 3.363 6.417a8.065 8.065 0 0 0 4.176 1.994c1.771.257 3.646-.342 7.394-1.54l13.354-4.266c3.962-1.266 6.051-1.934 7.394-3.128.006-.02-.129.466 0 0-.664 2.354-3.439 4.824-5.736 5.617l.117-.041c-.903.307-.928.296-2.125.679L25.22 48.745c-3.99 1.275-5.985 1.912-7.87 1.639-1.604-.233-3.252-1.02-4.445-2.124-1.403-1.297-2.129-3.143-3.58-6.833-2.183-5.55-3.87-10.694-5.452-16.607-1.045-3.91-1.568-5.864-1.203-7.753a8.567 8.567 0 0 1 2.347-4.368Z"
      fill="url(#OnboardingAI_svg__a)"
    />
    <path
      d="M7.95 22.393c-.92-3.439-1.379-5.158-1.055-6.82a7.553 7.553 0 0 1 2.077-3.847c1.212-1.182 2.992-1.77 6.554-2.945l12.473-4.115c3.896-1.286 5.843-1.928 7.581-1.66a7.322 7.322 0 0 1 4.042 2.04c1.249 1.239 1.891 3.189 3.175 7.089l4.093 12.428c1.295 3.934 1.942 5.9 1.668 7.65a7.335 7.335 0 0 1-2.069 4.06c-1.254 1.249-3.224 1.879-7.165 3.138L26.77 43.423c-3.524 1.126-5.286 1.689-6.95 1.45a7.567 7.567 0 0 1-3.922-1.864c-1.238-1.14-1.876-2.763-3.154-6.008-1.921-4.882-3.405-9.407-4.795-14.608Z"
      fill="url(#OnboardingAI_svg__b)"
    />
    <g clipPath="url(#OnboardingAI_svg__c)" filter="url(#OnboardingAI_svg__d)">
      <path
        d="M17.215 22.681a2.343 2.343 0 0 1 1.484-2.96l13.322-4.42a2.337 2.337 0 0 1 2.956 1.486l2.944 8.89a2.344 2.344 0 0 1-1.484 2.96l-13.322 4.42a2.337 2.337 0 0 1-2.956-1.486l-2.944-8.89Zm3.143.19a.585.585 0 0 0-.371.74l.368 1.111a.585.585 0 0 0 .739.372l2.22-.737a.585.585 0 0 0 .371-.74l-.368-1.11a.585.585 0 0 0-.739-.372l-2.22.736Zm1.104 3.334a.585.585 0 0 0 .368 1.111l5.55-1.841a.584.584 0 0 0-.367-1.112l-5.551 1.842Zm.736 2.223a.585.585 0 0 0 .368 1.111l1.11-.368a.585.585 0 0 0-.368-1.112l-1.11.369Zm3.33-1.105a.585.585 0 0 0 .368 1.11l1.11-.367a.585.585 0 0 0-.367-1.112l-1.11.369Zm3.33-1.105a.585.585 0 0 0 .369 1.11l1.11-.368a.585.585 0 0 0-.368-1.11l-1.11.367Zm3.331-1.106a.585.585 0 0 0 .368 1.112l1.11-.369a.585.585 0 0 0-.368-1.111l-1.11.368Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="OnboardingAI_svg__a"
        x1={35.579}
        y1={44.173}
        x2={3.441}
        y2={20.881}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0D5699" stopOpacity={0.95} />
        <stop offset={0.417} stopColor="#2BAAFF" />
      </linearGradient>
      <linearGradient
        id="OnboardingAI_svg__b"
        x1={21.778}
        y1={6.719}
        x2={33.233}
        y2={41.436}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1AC7FF" />
        <stop offset={0.661} stopColor="#2AACFF" />
        <stop offset={1} stopColor="#2BAAFF" />
      </linearGradient>
      <clipPath id="OnboardingAI_svg__c">
        <path
          fill="#fff"
          transform="rotate(-18.357 64.303 -39.598) skewX(-.035)"
          d="M0 0h18.715v18.73H0z"
        />
      </clipPath>
      <filter
        id="OnboardingAI_svg__d"
        x={15.743}
        y={12.342}
        width={23.65}
        height={24.674}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1_2878" />
      </filter>
    </defs>
  </svg>
);
export default SvgOnboardingAI;
