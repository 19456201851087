/* eslint-disable @typescript-eslint/no-unused-vars */
export interface StepProps {
  description: string,
  status?: 'valid' | 'warning' | 'unfinished',
  ariaLabel?: string
}

const Step = (props: StepProps):JSX.Element => {
  const { description, status = 'unfinished', ariaLabel } = props;
  return (
    <div>
      {description}
      {status}
    </div>
  );
};

export default Step;
