import styled from 'styled-components';

interface ContainerProps {
  label?: string;
  require?: string;
  icon?: {
    position: string;
    pic: string;
  };
  disabled?: boolean;
}

const vCenter = (): string => `
    display: flex;
    align-items: center;
`;

export const StyledWrap = styled.div<ContainerProps>`
  display: ${(props) => props.label && 'flex'};
  flex-direction: column;
  border-radius: 8px;
  ${({ disabled }) => (disabled && 'cursor: not-allowed;')}

  div[class*='placeholder'] {
    color: #BBBBBB;
  }

  label {
    ${vCenter};
    line-height: 1.5;
    ${(props: ContainerProps) => ({
    [`&::${props.require}`]: { content: "'*'", color: 'red' }
  })}
  }

`;

export const StyledErrMsg = styled.div`
    color: #E92C2C;
    font-size: 14px;
    margin: 3px 0;
    line-height: 24px;
`;

export const StyledWarningMsg = styled.div<{ markWarning?: boolean }>`
    color: ${(props) => (props.markWarning ? '#BBB' : '#F98600')};
    font-size: 14px;
    line-height: 24px;
    margin:3px 0;
    word-break: break-word;
`;

export const StyledIconWrap = styled.div`
  display: flex;
  color: #3E3E3E;
`;

export const StyledNotAllowWrap = styled.div<{ disabled?: boolean }>`
    ${({ disabled }) => (disabled && 'pointer-events: none;')}
`;
