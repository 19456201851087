import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 680px;
  @media ${device.mobileMaxWidth} {
    width: 100%;
  }
`;

export const StyledInputWrap = styled.div<{ hasErrMsg: boolean }>`
  width: 100%;
  margin-bottom: ${(props) => (props.hasErrMsg ? '2px' : '32px')};

  label {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #5c5c5c;
    margin: 0 0 4px;
  }

  &:last-of-type {
    margin-right:0;
  }

`;

export const StyledPopUpTitle = styled.div`
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #5C5C5C;
`;

export const StyledPopUpText = styled.div`
  padding: 4px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2A2A2A;
`;

export const StyledItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledCriteriaBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledCriteria = styled.div<{ isValid: boolean }>`
  margin: 8px 8px 0 0;
  width: fit-content;
  display: flex;
  line-height: 16px;
  background: ${(props) => (props.isValid ? '#99E3AE' : '#F4F4F4')};
  color: ${(props) => (props.isValid ? '#004A15' : '#5C5C5C')};
  border-radius: 32px;
  padding: 4px 10px;
  font-size: 10px;
`;

export const StyledCorrectIcon = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 4px;

  > svg{
    > path{
      fill: #004A15;
    }
  };
`;

export const StyledPopUpFooter = styled.div`
  display: flex;
  justify-content: right;

  button {
    font-family: 'Noto Sans';
    text-transform: none;
    border-radius: 4px;
    line-height: 24px;
    min-height: 40px;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #145DB5;
    color: #145DB5;

    &:first-child {
      min-width: 78px;
      border-color: #145DB5;
      &:hover,
      &:focus {
        color: #145DB5;
        border-color: #145DB5;
      }
    }
    &:last-child {
      width: 80px;
      padding: 4px 24px;
      color: #ffffff;
      border-color: #145DB5;
      background-color: #145DB5;

      &:hover,
      &:focus,
      &:disabled {
        color: #ffffff;
        border-color: #145DB5;
        background-color: #145DB5;
      };
      &:disabled {
        opacity: 0.6;
      }
    }
  }
`;

export const StyledPassword = styled.div`
  position: relative;
`;

export const StyledEyeIcon = styled.div`
  display: flex;
  position: absolute;
  right: ${(props) => (props.theme.isRTL ? 'none' : '16px')};
  left: ${(props) => (props.theme.isRTL ? '16px' : 'none')};
  top: 0;
  align-items: center;
  height: 100%;
  &:hover{
    cursor: pointer;
  }
`;

export const StyledChangeButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 4px;
  > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #145DB5;
  }
`;

export const StyledCircularProgressWrap = styled.div`
  display: flex;
  justify-content: center;
`;
