import styled from 'styled-components';

export const StyledRadioWrap = styled.div`
  margin: 20px 0 32px;
`;

export const StyledQuestionWrap = styled.div`
  .title {
    color: #2a2a2a !important;
  }
`;

export const StyledContentWrap = styled.div`
  padding: 40px 40px 24px;
  background: #ffffff;
  border-radius: 0 0 16px 16px;
  height: 100%;

  &.flx-column {
    flex-direction: column;
  }
  &.flx-row {
    flex-direction: row;
  }
`;
