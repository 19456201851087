import cx from 'classnames';
import { StyledItemLabel, StyledMoreButton, StyledTitleWrapper } from './style';

interface ItemLabelProps {
  title:string;
  children?: React.ReactNode;
  className?: string;
  remarkText?: string;
  remarkLink?: string;
  id?:string;
}

const ItemLabel = (props:ItemLabelProps):JSX.Element => {
  const {
    title = '', children, className, id = '', remarkText = '', remarkLink = ''
  } = props;

  return (
    <StyledItemLabel
      className={cx('StyledItemLabel', className)}
      aria-label={`${id} Item Label`}
    >
      <StyledTitleWrapper>
        <div className="title" aria-label={`${id} Item Label Title`}>
          {title}
        </div>
        <StyledMoreButton
          aria-label={`${id} Learn More`}
          onClick={() => {
            const windowReference = window.open();
            if (windowReference) windowReference.location = remarkLink;
          }}
        >
          {remarkText}
        </StyledMoreButton>
      </StyledTitleWrapper>
      {children}

    </StyledItemLabel>
  );
};
export default ItemLabel;
