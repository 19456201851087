import cx from 'classnames';
import { CommonProps } from 'type/component';
import React, { ChangeEvent, forwardRef } from 'react';
import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import styled from 'styled-components';
import {
  StyledRadioGroupWrap, StyledRadioGroup, StyleBtnLabel, StyledNormalRadio, StyledTitleWrapper
} from './style';

export const StyledRadioTooltip = styled(({ className, ...props }: MuiTooltipProps) => (
  <MuiTooltip
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -7]
            }
          }
        ]
      }
    }}
    {...props}
    classes={{ popper: className }}
  />
))(() => ({
  zIndex: 'inherit',
  '& .MuiTooltip-tooltip': {
    maxWidth: '80vw',
    display: 'flex',
    padding: 8,
    alignItems: 'center',
    gap: 16,
    borderRadius: 4,
    fontSize: 12,
    background: 'var(--Finlogix-Light-F400, #0282D0)'
  },
  '& .MuiTooltip-arrow': {
    color: 'var(--Finlogix-Light-F400, #0282D0)'
  }
}));

interface RadioGroupProps extends CommonProps {
  groupName: string;
  optionType?: string; // default | button
  options: OptionsProps[];
  disabled?: boolean;
  value?: string;
  label?: string;
  id?:string;
  labelButton?: React.ReactNode;
  onChange?: (event?: ChangeEvent<HTMLInputElement>) => void;
}

interface OptionsProps {
  value: string;
  label: string;
  icon?: string;
  tooltip?: React.ReactNode;
}

const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>((props, ref): JSX.Element => {
  const {
    groupName, options, className, optionType, value, label, id, labelButton, disabled = false, onChange, ...others
  } = props;
  const renderOptions = (): React.ReactNode => {
    if (optionType === 'button') {
      return (
        options.map((optionData, index) => (
          <StyledRadioTooltip
            key={`${optionData.value}-${groupName}-${index}`}
            id={`tooltip-${optionData.value}`}
            open={!!optionData.tooltip}
            arrow
            placement="bottom-end"
            title={optionData.tooltip}
          >
            <StyleBtnLabel
              htmlFor={`${groupName}-${optionData.value}`}
              disabled={disabled}
            >
              <input
                ref={ref}
                type="radio"
                id={`${groupName}-${optionData.value}`}
                value={optionData.value}
                name={groupName}
                disabled={disabled}
                checked={optionData.value === value}
                onChange={onChange}
                {...others}
              />
              <span
                aria-label={`${optionData.label} Option Button`}
                className={cx({ btnType: true })}
              >
                {optionData.icon && <img src={optionData.icon} alt={optionData.label} aria-label={`${optionData.label}-icon`} />}
                {optionData.label}
              </span>

            </StyleBtnLabel>
          </StyledRadioTooltip>
        ))
      );
    }
    return (
      options.map((optionData, index) => (
        <StyledNormalRadio key={`${optionData.value}-${groupName}-${index}`}>
          <input
            ref={ref}
            type="radio"
            id={`${groupName}-${optionData.value}`}
            value={optionData.value}
            name={groupName}
            disabled={disabled}
            checked={optionData.value === value}
            onChange={onChange}
            {...others}
          />
          <label htmlFor={`${groupName}-${optionData.value}`}>{optionData.label}</label>
        </StyledNormalRadio>
      ))
    );
  };

  return (
    <StyledRadioGroupWrap>
      <StyledTitleWrapper>
        {label && <label htmlFor={id} aria-label={`${id} label`}>{label}</label>}
        {labelButton}
      </StyledTitleWrapper>

      <StyledRadioGroup
        optionType={optionType}
        className={cx('StyledRadioGroup', 'lp-radio-group', className)}
      >
        {renderOptions()}
      </StyledRadioGroup>
    </StyledRadioGroupWrap>
  );
});
export default RadioGroup;
