import { Routes, Route, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store';
import Dashboard from 'pages/Dashboard/Dashboard';
import Support from 'pages/Support';
import LoginCallback from 'pages/LoginCallback';
import Register from 'pages/Register';
import Header from 'components/Header';
import SideMenu from 'containers/SideMenu';
import Footer from 'containers/Footer';
import IncompleteAccountRedirect from 'containers/Redirect/IncompleteAccountRedirect';
import SelectAccountType from 'pages/SelectAccountType';
import VerifyIdentity from 'pages/VerifyIdentity';
import CreateAccount from 'pages/CreateAccount';
import CreateCorporateAccount from 'pages/CreateCorporateAccount';
import CreateJointAccount from 'pages/CreateJointAccount';
import DemoAccount from 'pages/DemoAccount';
import ThankYou from 'pages/ThankYou';
import RG227ThankYou from 'pages/RG227ThankYou';
import AccountList from 'pages/AccountList';
import ExperienceQuestion from 'pages/ExperienceQuestion';
import CreateAccountContextProvider from 'store/context/createAccountContext';
import CreateCorporateAccountContextProvider from 'store/context/createCorporateAccountContext';
import CreateJointAccountContextProvider from 'store/context/createJointAccountContext';
import {
  Deposit, InternalTransfer, VerifyPaymentMethod, Withdrawal
} from 'pages/CashManagement/index';
import {
  ClientList, MarketingMaterials, Statistics, PartnerDashboard
} from 'pages/Partnerships/index';
import BackdropSpinner from 'components/BackdropSpinner';
import { AllVideos, OnlineTraining } from 'pages/Training';
import CategoryVideo from 'pages/Training/Videos/CategoryVideo';
import SingleVideoPage from 'pages/Training/Videos/SingleVideo';
import DownloadCenter from 'pages/DownloadCenter';
import LogoutRedirect from 'pages/LogoutRedirect';
import CorrectionWithoutLogin from 'pages/CorrectionWithoutLogin';
import CorrectionRedirect from 'pages/CorrectionRedirect';
import RG227PracticePage from 'pages/RG227PracticePage';
import { useAuth } from 'store/context/hooks';
import RG227Unsuccessful from 'pages/RG227Unsuccessful';
import { AccountRG227Provider } from 'store/context/accountRG227Context';
import ClientCloudhubRedirect from 'pages/ClientCloudhubRedirect';
import { StyledContent, StyledRightSideWrap } from './style';

const Router = (): JSX.Element => {
  const { accessToken } = useAuth();
  const isMenuOpen = useAppSelector((state) => state.sideMenuState.isOpen);
  const location = useLocation();
  const getBackgroundColor = (): string => {
    if (location.pathname.startsWith('/create-account')) {
      return '#F6F6F6';
    }
    switch (location.pathname) {
      case '/rg227-practice':
        return '#F6F6F6';
      default:
        return '';
    }
  };

  return (
    <Routes>
      {
        accessToken
          ? (

            <Route
              path="*"
              element={(
                <AccountRG227Provider>
                  <SideMenu />
                  <StyledRightSideWrap isMenuOpen={isMenuOpen}>
                    <Header />
                    <StyledContent background={getBackgroundColor()}>
                      <Routes>
                        <Route
                          path="/"
                          element={<Dashboard />}
                        />
                        <Route path="/demo-account" element={<DemoAccount />} />
                        <Route
                          path="agent-center/agent-activity"
                          element={<PartnerDashboard />}
                        />
                        <Route
                          path="/agent-center/statistics"
                          element={<Statistics />}
                        />
                        <Route
                          path="/agent-center/client-list"
                          element={<ClientList />}
                        />
                        <Route
                          path="/agent-center/marketing-materials"
                          element={<MarketingMaterials />}
                        />
                        <Route
                          path="/fund-manager/deposit"
                          element={<Deposit />}
                        />
                        <Route
                          path="/fund-manager/withdrawal"
                          element={<Withdrawal />}
                        />
                        <Route
                          path="/fund-manager/internal-transfer"
                          element={<InternalTransfer />}
                        />
                        <Route
                          path="/fund-manager/bank-account"
                          element={<VerifyPaymentMethod />}
                        />
                        <Route
                          path="/ticket/ticket-list"
                          element={<Support />}
                        />
                        <Route
                          path="/download-center"
                          element={<DownloadCenter />}
                        />
                        <Route path="training">
                          <Route path="online-training" element={<OnlineTraining />} />
                          <Route path="videos" element={<AllVideos />} />
                          <Route path="videos/category/:id" element={<CategoryVideo />} />
                          <Route path="videos/category/:id/:videoID" element={<SingleVideoPage />} />
                        </Route>
                        <Route path="/account-list" element={<AccountList />} />
                        <Route
                          path="/create-account/select-account-type"
                          element={<SelectAccountType />}
                        />
                        <Route
                          path="/create-account/verify-identity"
                          element={<VerifyIdentity />}
                        />
                        <Route
                          path="/create-account/personal-account"
                        >
                          <Route
                            index
                            element={(
                              <CreateAccountContextProvider>
                                <CreateAccount />
                              </CreateAccountContextProvider>
                            )}
                          />
                          <Route path="thank-you" element={<ThankYou />} />
                        </Route>
                        <Route path="/create-account/corporate-account">
                          <Route
                            index
                            element={(
                              <CreateCorporateAccountContextProvider>
                                <CreateCorporateAccount />
                              </CreateCorporateAccountContextProvider>
                            )}
                          />
                          <Route path="thank-you" element={<ThankYou />} />
                        </Route>
                        <Route path="/create-account/joint-account">
                          <Route
                            index
                            element={(
                              <CreateJointAccountContextProvider>
                                <CreateJointAccount />
                              </CreateJointAccountContextProvider>
                            )}
                          />
                          <Route path="thank-you" element={<ThankYou />} />
                        </Route>
                        <Route path="/rg227-practice" element={<RG227PracticePage />} />
                        <Route path="rg227">
                          <Route path="thank-you" element={<RG227ThankYou />} />
                          <Route path="unsuccessful" element={<RG227Unsuccessful />} />
                          <Route
                            path="*"
                            element={(
                              <CreateAccountContextProvider>
                                <ExperienceQuestion />
                              </CreateAccountContextProvider>
                            )}
                          />
                        </Route>
                        <Route path="/create-account/incomplete" element={<IncompleteAccountRedirect />} />
                      </Routes>
                      <Footer />
                    </StyledContent>
                  </StyledRightSideWrap>
                </AccountRG227Provider>
              )}
            />
          )
          : (
            <Route path="*" element={<BackdropSpinner open />} />
          )
      }

      <Route path="/open-live-account" element={<Register />} />
      <Route path="/correction/*" element={<CorrectionRedirect />} />
      <Route path="/correction-form" element={<CorrectionWithoutLogin />} />
      <Route path="/login/callback/*" element={<LoginCallback />} />
      <Route path="/logout-redirect" element={<LogoutRedirect />} />
      <Route path="/redirect/client-cloudhub/*" element={<ClientCloudhubRedirect />} />
    </Routes>
  );
};
export default Router;
