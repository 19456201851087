import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import {
  WithdrawalDT,
  WithdrawalMOB,
  WithdrawalTAB
} from 'assets/blurredPages';
import { StyledBlurredPageWrap } from 'pages/BlurredPage/style';

export const StyledWithdrawalWrap = styled(StyledBlurredPageWrap)`
  ::before {
    background-image: url(${WithdrawalDT});
    @media ${device.tablet} {
      background-image: url(${WithdrawalTAB});
    }
    @media ${device.mobileMaxWidth} {
      background-image: url(${WithdrawalMOB});
    }
  }
`;
