import * as React from "react";
import { SVGProps } from "react";
const SvgFinlogix = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path fill="url(#Finlogix_svg__a)" d="M0 0h13v13H0z" />
    <defs>
      <pattern
        id="Finlogix_svg__a"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use
          xlinkHref="#Finlogix_svg__b"
          transform="translate(.094) scale(.0625)"
        />
      </pattern>
      <image
        id="Finlogix_svg__b"
        width={13}
        height={16}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAQCAYAAADNo/U5AAAAAXNSR0IArs4c6QAAANxJREFUOE/dkjFqQkEQhv//7e5gQCQEC/ECgdQ5gjewF7yATbr0th7A9t3Aa2jSBtKlSoo0KgjuvvcmrNFCkX2vdsphvvnn/xl6X05AnQJoo0E5ax7oQ7FtCsSdzpr7CGkDgeMI38SZZ/oQPogsV8VvCtZMd2LMguSGqtolmQQulzGEMFDwFWAnpVTabHhHfsUZ7kPxTaBX56sqzWOrxc8D1DCItbOmT3J3DVqBeL9Q3aOqchFZnvrnSopcxI7qTr1VqIrR/5vXmTj3Uh9EUcyhGBP4sdY8kYxfn6w/eAJnK8XXpPwAAAAASUVORK5CYII="
      />
    </defs>
  </svg>
);
export default SvgFinlogix;
