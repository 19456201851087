import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledCreateAccountFrom = styled.div<{ showTip?: boolean }>`
  padding: ${(props) => (props.showTip ? '20px 64px' : '24px 64px')};
  position: relative;
  background: #f6f6f6;
  flex: 1;

  .custom-progress {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media ${device.tablet} {
    padding: ${(props) => (props.showTip ? '20px 24px' : '24px 24px')};
  }

  @media ${device.mobileMaxWidth} {
    padding: 24px 16px;
  }

`;

export const StyledLoadingContainer = styled.div`
  height: 480px;

  @media ${device.tablet} {
    height: 360px;
  }

  @media ${device.mobileMaxWidth} {
    height: 240px;
  }
`;
