import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledFooterWrapper = styled.div`
  background: inherit;
  color: #8c8c8c;
  font-size: 12px;
  a {
    color: #0282d0;
  }
`;

export const StyledFooter = styled.div`
margin: 0 64px 0;
padding: 24px 0 48px 0;
border-top: 1px solid #DDDDDD;

> p {
  margin: 0;
}

@media ${device.tablet} {
  margin: 0 16px 0;
  padding: 24px 0 40px 0;
}

@media ${device.mobileMaxWidth} {
  margin: 0 16px 0;
  padding: 24px 0 24px 0;
}
`;
