import { TradingPlatform } from 'type/common';
import MT4Icon from 'assets/MT4.svg';
import MT5Icon from 'assets/MT5.svg';
import LogixTraderIcon from 'assets/LogixTrader.svg';

export enum Options {
  TraditionASIC = 'TraditionASIC',
  NormalVFSC = 'NormalVFSC',
  AIASIC = 'AIASIC',
  AIASICPOA = 'AIASICPOA',
  AIVFSC = 'AIVFSC'
}

export enum CorporateAccountOptions {
  ASIC = 'ASIC',
  VFSC = 'VFSC',
}

export enum JointAccountOptions {
  ASIC = 'ASIC',
  VFSC = 'VFSC',
}

export enum Countries {
  AUS = 'AUS',
  CHN = 'CHN',
  TWN = 'TWN',
  HKG = 'HKG',
  IDN = 'IDN',
  VNM = 'VNM',
  MYS = 'MYS'
}

export type PersonalAccountStepsProps = {
  [key in Options]: string[]
}

export type CorporateAccountStepsProps = {
  [key in CorporateAccountOptions]: string[]
}

export type JointAccountStepsProps = {
  [key in JointAccountOptions]: string[]
}

export enum CreateAccountSteps {
  PersonalAccountCreated = 'v2_personal_account_created',
  VerifyYourIdentity = 'v2_personal_account_created',
  TradingAndEmployment = 'v2_trading_and_employment',
  Experience = 'v2_experience',
  VerifyIdentity = 'v2_verify_identity',
  PersonalInformation = 'v2_personal_information',
  TermsAndConditions = 'v2_terms_and_conditions',
  ConfirmID = 'v2_upload_documents',
}

export enum CreateCorporateAccountSteps {
  CorporateContactPersonDetails= 'corporate_contact_person_details',
  CorporateCompanyDetails= 'corporate_company_details_trading_preference',
  CorporateShareholdersAndDirectors= 'corporate_shareholders_directors',
  CorporatePreferenceAndExperience= 'corporate_experience',
  CorporateTermsAndConditions = 'corporate_terms_and_conditions',
  CorporateConfirmID = 'corporate_confirm_id'
}

export enum CreateJointAccountSteps {
  JointPrimaryAccountHolder= 'joint_primary_account_holder',
  JointSecondaryAccountHolder= 'joint_secondary_account_holder',
  JointTradingPreference= 'joint_trading_preference',
  JointExperience= 'joint_experience',
  JointTermsAndConditions = 'joint_terms_and_conditions',
  JointConfirmID = 'joint_confirm_id'
}

export const PERSONAL_ACCOUNT_STEPS: PersonalAccountStepsProps = {
  TraditionASIC: [
    CreateAccountSteps.TradingAndEmployment,
    CreateAccountSteps.Experience,
    CreateAccountSteps.PersonalInformation,
    CreateAccountSteps.TermsAndConditions,
    CreateAccountSteps.ConfirmID
  ],
  NormalVFSC: [
    CreateAccountSteps.TradingAndEmployment,
    CreateAccountSteps.PersonalInformation,
    CreateAccountSteps.TermsAndConditions,
    CreateAccountSteps.ConfirmID
  ],
  AIASIC: [
    CreateAccountSteps.TradingAndEmployment,
    CreateAccountSteps.Experience,
    CreateAccountSteps.PersonalInformation,
    CreateAccountSteps.TermsAndConditions
  ],
  AIASICPOA: [
    CreateAccountSteps.TradingAndEmployment,
    CreateAccountSteps.Experience,
    CreateAccountSteps.PersonalInformation,
    CreateAccountSteps.TermsAndConditions,
    CreateAccountSteps.ConfirmID
  ],
  AIVFSC: [
    CreateAccountSteps.TradingAndEmployment,
    CreateAccountSteps.PersonalInformation,
    CreateAccountSteps.TermsAndConditions
  ]
};

export const CORPORATE_ACCOUNT_STEPS: CorporateAccountStepsProps = {
  ASIC: [
    CreateCorporateAccountSteps.CorporateContactPersonDetails,
    CreateCorporateAccountSteps.CorporateCompanyDetails,
    CreateCorporateAccountSteps.CorporateShareholdersAndDirectors,
    CreateCorporateAccountSteps.CorporatePreferenceAndExperience,
    CreateCorporateAccountSteps.CorporateTermsAndConditions,
    CreateCorporateAccountSteps.CorporateConfirmID
  ],
  VFSC: [
    CreateCorporateAccountSteps.CorporateContactPersonDetails,
    CreateCorporateAccountSteps.CorporateCompanyDetails,
    CreateCorporateAccountSteps.CorporateShareholdersAndDirectors,
    CreateCorporateAccountSteps.CorporateTermsAndConditions,
    CreateCorporateAccountSteps.CorporateConfirmID
  ]
};

export const JOINT_ACCOUNT_STEPS: JointAccountStepsProps = {
  ASIC: [
    CreateJointAccountSteps.JointPrimaryAccountHolder,
    CreateJointAccountSteps.JointSecondaryAccountHolder,
    CreateJointAccountSteps.JointTradingPreference,
    CreateJointAccountSteps.JointExperience,
    CreateJointAccountSteps.JointTermsAndConditions,
    CreateJointAccountSteps.JointConfirmID
  ],
  VFSC: [
    CreateJointAccountSteps.JointPrimaryAccountHolder,
    CreateJointAccountSteps.JointSecondaryAccountHolder,
    CreateJointAccountSteps.JointTradingPreference,
    CreateJointAccountSteps.JointTermsAndConditions,
    CreateJointAccountSteps.JointConfirmID
  ]
};

export const TRADING_PLATFORM_ICON: Record<TradingPlatform, string> = {
  [TradingPlatform.MetaTrader4]: MT4Icon,
  [TradingPlatform.MetaTrader5]: MT5Icon,
  [TradingPlatform.LogixTrader]: LogixTraderIcon
} as const;
