import Input from 'components/Input';
import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledFormGroup = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;

  @media ${device.mobileMaxWidth} {
    flex-direction: column;
  }

  > * {
    flex: 1 1 50%;
  }
`;

export const StyledInput = styled(Input)`
  label {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    margin: 0 0 4px;
  }

  input {
    border-radius: 4px;
    padding: 8px;
  }
`;
