import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CreateAccountContextProvider from 'store/context/createAccountContext';
import CreateAccount from 'pages/CreateAccount';
import axios from 'api/axios';
import { getKeycloakTokenByLogixopanelToken } from 'api/v1/withoutLogin';
import BackdropSpinner from 'components/BackdropSpinner';
import { StyledCorrectionWrap } from './style';

const CorrectionWithoutLogin = ():JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const logixpanelToken = searchParams.get('correctionToken');
    if (searchParams.get('status') !== 'Reject') return;
    if (!logixpanelToken) return;
    getKeycloakTokenByLogixopanelToken(logixpanelToken).then((res) => {
      if (res && res.status === 200) {
        localStorage.setItem('accountID', res?.data?.account_id);
        axios.defaults.headers.common.Authorization = `Bearer ${res?.data?.access_token}`;
        setLoading(false);
      }
    }).catch(() => {
      window.location.href = '/';
    });
  }, []);

  return (
    <StyledCorrectionWrap>
      {
        loading
          ? (
            <BackdropSpinner open={loading} />
          )
          : (
            <CreateAccountContextProvider>
              <CreateAccount />
            </CreateAccountContextProvider>
          )
      }
    </StyledCorrectionWrap>
  );
};

export default CorrectionWithoutLogin;
