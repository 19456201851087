import styled from 'styled-components';

export const StyledBlurredPageWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  position: relative;
  ::before {
    background-repeat: no-repeat;
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    width: 100%;
    height: 100%;
    filter: blur(4.8px);
    background-size: cover; 
}
`;
