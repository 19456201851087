import { Menu, MenuItem } from '@mui/material';
import styled from 'styled-components';

export const StyledButton = styled.button`
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 1px 3px rgba(0, 0, 0, 0.12);
  }

  .MuiList-root {
    padding: 4px 0;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-family: 'Noto Sans';
  font-size: 14px;
  line-height: 24px;
  color: #3e3e3e;
  padding: 4px 16px;
  white-space: initial;

  svg {
    margin-top: 5px;
  }
`;
