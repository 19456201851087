import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import {
  DepositDT,
  DepositMOB,
  DepositTAB
} from 'assets/blurredPages';
import { StyledBlurredPageWrap } from 'pages/BlurredPage/style';

export const StyledDepositWrap = styled(StyledBlurredPageWrap)`
  ::before {
    background-image: url(${DepositDT});
    @media ${device.tablet} {
      background-image: url(${DepositTAB});
    }
    @media ${device.mobileMaxWidth} {
      background-image: url(${DepositMOB});
    }
  }
`;
