import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Lang from 'utils/language.util';
import { Languages } from 'constant/language';
// import language files
import en from './language/en';
import zhCn from './language/zhCn';
import zhTw from './language/zhTw';
import ja from './language/jp';
import ar from './language/ar';
import fr from './language/fr';
import ko from './language/ko';
import id from './language/id';
import es from './language/es';
import ms from './language/ms';
import pt from './language/pt';
import he from './language/he';
import vi from './language/vi';

const resources = {
  [Languages.EN]: en,
  [Languages.ZH_CN]: zhCn,
  [Languages.ZH_TW]: zhTw,
  [Languages.JA]: ja,
  [Languages.AR]: ar,
  [Languages.FR]: fr,
  [Languages.KO]: ko,
  [Languages.ID]: id,
  [Languages.ES]: es,
  [Languages.MS]: ms,
  [Languages.PT]: pt,
  [Languages.HE]: he,
  [Languages.VI]: vi
};

i18n
  .use(initReactI18next)
  .init({
    lng: Lang.getDefaultLanguage(),
    fallbackLng: Languages.EN,
    debug: false,
    ns: ['common'],
    defaultNS: 'common',
    resources,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
