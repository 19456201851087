export enum Languages {
  EN = 'en',
  ZH_TW = 'zh_TW',
  ZH_CN = 'zh',
  AR = 'ar',
  ID = 'id',
  VI = 'vi',
  ES = 'es',
  KO = 'ko',
  PT = 'pt',
  FR = 'fr',
  JA = 'ja',
  MS = 'ms',
  HE = 'he',
}

export const LANGUAGE_LIST: Record<Languages, string> = {
  [Languages.EN]: 'English',
  [Languages.ZH_CN]: '中文简体',
  [Languages.ZH_TW]: '中文繁體',
  [Languages.AR]: 'العربية',
  [Languages.ID]: 'Bahasa',
  [Languages.VI]: 'Tiếng Việt',
  [Languages.ES]: 'Española',
  [Languages.KO]: '한국인',
  [Languages.PT]: 'Português',
  [Languages.FR]: 'Français',
  [Languages.JA]: '日本語',
  [Languages.MS]: 'Melayu',
  [Languages.HE]: 'עִברִית'
} as const;
