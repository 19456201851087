import * as React from "react";
import { SVGProps } from "react";
const SvgArrowDropDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.487 4.944c-.565-.647-.106-1.659.753-1.659h5.52c.858 0 1.318 1.012.752 1.659l-2.76 3.153a1 1 0 0 1-1.505 0l-2.76-3.153Z"
      fill="#5C5C5C"
    />
  </svg>
);
export default SvgArrowDropDown;
