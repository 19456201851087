import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Banner from 'containers/Banner';
import ResponseMessagePaper from 'components/ResponseMessagePaper';
import { StyledActionButton } from 'components/ResponseMessagePaper/style';
import { StyledPageContainer } from './style';

const RG227ThankYou = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation('thankYouPage');

  return (
    <>
      <Banner name="AccountRegistrationBanner" />
      <StyledPageContainer>
        <ResponseMessagePaper
          severity="success"
          title={t('questionnaireCompleted')}
          contentTitle={t('thankYou')}
          contentDescription={<div style={{ maxWidth: '440px' }}>{t('thankYouCompleting')}</div>}
          actionButtonSlot={(
            <StyledActionButton onClick={() => navigate('/')}>
              {t('goToDashboard')}
            </StyledActionButton>
          )}
        />
      </StyledPageContainer>
    </>
  );
};

export default RG227ThankYou;
