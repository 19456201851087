import * as React from "react";
import { SVGProps } from "react";
const SvgAccount = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.933a1.317 1.317 0 0 0-1.317 1.317v.958a1.317 1.317 0 0 0 2.634 0V6.25A1.317 1.317 0 0 0 12 4.933Zm-1.78-.463a2.516 2.516 0 0 1 4.297 1.78v.358h4.191a2.517 2.517 0 0 1 2.517 2.517v8.625a2.517 2.517 0 0 1-2.517 2.517H5.292a2.516 2.516 0 0 1-2.517-2.517V9.125a2.517 2.517 0 0 1 2.517-2.517h4.191V6.25c0-.668.265-1.308.737-1.78Zm-.664 3.338H5.292a1.317 1.317 0 0 0-1.317 1.317v8.625a1.317 1.317 0 0 0 1.317 1.317h13.416a1.317 1.317 0 0 0 1.317-1.317V9.125a1.317 1.317 0 0 0-1.317-1.317h-4.264a2.517 2.517 0 0 1-4.888 0Zm-2.21 3.37a2.517 2.517 0 0 1 3.559 3.56l-.018.017a3.491 3.491 0 0 1 1.515 1.836.6.6 0 1 1-1.13.401 2.277 2.277 0 0 0-4.293 0 .6.6 0 1 1-1.132-.4 3.475 3.475 0 0 1 1.515-1.837 2.517 2.517 0 0 1-.017-3.576Zm1.779 3.097a1.317 1.317 0 1 1 0-2.634 1.317 1.317 0 0 1 0 2.634M14.275 12a.6.6 0 0 1 .6-.6h2.875a.6.6 0 0 1 0 1.2h-2.875a.6.6 0 0 1-.6-.6Zm0 3.833a.6.6 0 0 1 .6-.6h1.917a.6.6 0 0 1 0 1.2h-1.917a.6.6 0 0 1-.6-.6Z"
      fill="#fff"
    />
  </svg>
);
export default SvgAccount;
