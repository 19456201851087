import { Languages } from 'constant/language';
import { parseURL } from './url.util';

class Lang {
  /**
   * Type guard to check if a value is a valid Languages enum value.
   *
   * This function checks if the given value is one of the valid
   * language codes defined in the Languages enum.
   *
   * @param {unknown} value - The value to check
   * @returns {boolean} True if the value is a valid Languages enum value, false otherwise
   *
   * @example
   * isLanguage('en') // returns true
   * isLanguage('invalid') // returns false
  */
  static isLanguage = (value: unknown): value is Languages => typeof value === 'string' && Object.values(Languages).includes(value as Languages);

  /**
   * Converts the given language code to the appropriate locale for ACY URLs.
   *
   * This function is specifically used for generating href attributes in the format:
   * `https://acy.com/${getACYLocale(i18n.language)}/market-news/education/`
   *
   * @param {Languages} lang - The language code to convert
   * @returns {string} The converted locale string for use in ACY URLs
  */
  static getACYLocale = (lang: Languages): string => {
    switch (lang) {
      case Languages.ZH_TW:
        return 'zh-hant';
      case Languages.HE:
        return Languages.EN;
      default:
        return lang;
    }
  };

  /**
   * Determines if the current language is read from right-to-left (RTL).
   *
   * @returns {boolean} True if the language is RTL, false otherwise.
  */
  static isRTL = (): boolean => {
    const lang = Lang.getDefaultLanguage();
    return [Languages.AR, Languages.HE].includes(lang);
  };

  // [TODO]: refactor
  static transferLangIntoCmp = (locale: string | null): Languages => {
    if (!locale) return Languages.EN;
    if (['cn', 'zh-cn'].includes(locale)) return Languages.ZH_CN;
    if (['tw', 'zh-hant', 'zh-tw'].includes(locale)) return Languages.ZH_TW;
    if (Lang.isLanguage(locale)) return locale;
    return Languages.EN;
  };

  // [TODO]: refactor
  static transferLangIntoKeycloak = (lang: string | null): string => {
    if (!lang) return 'en';
    if (lang === Languages.ZH_CN) return 'cn';
    if (lang === Languages.ZH_TW) return 'tw';
    return lang;
  };

  // [TODO]: refactor
  static getBrowserLanguage = (): string => {
    const browserLanguages = window.navigator.languages;

    const supportedLanguages = [...Object.values(Languages), 'zh-cn', 'zh-tw'] as string[];

    let detectedLanguage = browserLanguages.find((lang) => supportedLanguages.includes(lang.toLocaleLowerCase()))?.toLocaleLowerCase() || '';

    if (detectedLanguage && !['zh-cn', 'zh-tw'].includes(detectedLanguage)) {
      [detectedLanguage] = detectedLanguage.split('-');
    }

    return detectedLanguage;
  };

  /**
   * Get the default language setting.
   *
   * Priority order:
   * 1. Access token or URL parameter (from Keycloak)
   * 2. User's language in localStorage
   * 3. Default language based on domain (for Chinese users)
   * 4. Browser language or global default language (English)
   *
   * @returns {Languages} The determined default language
  */
  static getDefaultLanguage = (): Languages => {
    // [TODO]: refactor, testing should be in multiple languages
    if (process.env.NODE_ENV === 'test') {
      return Languages.EN;
    }

    const url = parseURL();

    // Check URL parameters
    const locale = url.searchParams.get('locale');
    if (locale) {
      if (['cn', 'zh-cn'].includes(locale)) return Languages.ZH_CN;
      if (['tw', 'zh-hant', 'zh-tw'].includes(locale)) return Languages.ZH_TW;
      if (Lang.isLanguage(locale)) return locale;
    }

    // Check language in localStorage
    const localStorageLanguage = localStorage.getItem('language');
    if (localStorageLanguage && Lang.isLanguage(localStorageLanguage)) {
      return localStorageLanguage;
    }

    // Determine based on domain (for Chinese users)
    // [TODO]: replace with env variable
    if (url.domain !== 'acy.cloud') {
      return Languages.ZH_CN;
    }

    // Determine based on browser language & global default language is English
    const lowerCaseBrowserLang = Lang.getBrowserLanguage();
    return Lang.transferLangIntoCmp(lowerCaseBrowserLang);
  };
}

export default Lang;
