/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'api/axios';
import encBase64Url from 'crypto-js/enc-base64url';
import sha256 from 'crypto-js/sha256';

export const generateState = (): string => {
  const length = 30;
  let stateValue = '';
  const alphaNumericCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const l = alphaNumericCharacters.length;
  for (let i = 0; i < length; i += 1) {
    const randomByteArray = new Uint8Array(1);
    stateValue += alphaNumericCharacters.charAt(Math.floor((window.crypto.getRandomValues(randomByteArray)[0] * 2 ** -8) * l));
  }
  return stateValue;
};

const base64urlencode = (sourceValue: any): string => {
  const stringValue = String.fromCharCode.apply(null, sourceValue);
  const base64encoded = btoa(stringValue);
  const base64urlEncoded = base64encoded.replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
  return base64urlEncoded;
};

export const generateCodeVerifier = (): string => {
  let codeVerifier = '';
  const randomByteArray = new Uint8Array(32);
  window.crypto.getRandomValues(randomByteArray);
  codeVerifier = base64urlencode(randomByteArray);
  return codeVerifier;
};

/*
Generates a PKCE code challenge from the given code verifier.
@param codeVerifier The PKCE code verifier.
@returns The PKCE code challenge.
*/
export function generateCodeChallenge(codeVerifier: string): string {
  return sha256(codeVerifier).toString(encBase64Url);
}

export const setTokenInStorage = (returnObj: { [key: string | number]: string }): void => {
  localStorage.removeItem('codeVerifier');
  localStorage.removeItem('id_token');
  localStorage.setItem('accessToken', returnObj.access_token);
  localStorage.setItem('refreshToken', returnObj.refresh_token);
  localStorage.setItem('id_token', returnObj.id_token);
  axios.defaults.headers.common.Authorization = `Bearer ${returnObj.access_token}`;
};

export const jwtDecode = (token: string): { [key: string]: string } => {
  if (!token) return {};
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
};

export const clearStorageAndKeepNecessary = (): void => {
  const affiliateInStorage = localStorage.getItem('affiliateID');
  const languageInStorage = localStorage.getItem('language');
  localStorage.clear();
  if (affiliateInStorage) localStorage.setItem('affiliateID', affiliateInStorage);
  if (languageInStorage) localStorage.setItem('language', languageInStorage);
};
