/* eslint-disable @typescript-eslint/no-explicit-any */
import IconComponent from 'components/Icons';
import { formatBytes } from 'utils/common';
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadDocument, deleteDocument, getFileList } from 'api/v1/document';
import { useAppDispatch } from 'store';
import ErrorHandler from 'utils/ErrorHandler';
import { isArray, isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import {
  StyledUploadFile, StyledStatusImgWrap, StyledFileInfoWrap, StyledFileNameWrap,
  StyledSizeInfo, StyledCloseIcon, StyledStatusBlock, StyledStatusTxt, StyledFormatDesp,
  StyledFileDesp, StyledFileTitle, StyledWarningMsg, StyledWarningWrapper
} from './style';

interface FileInfoProps {
  name: string;
  size: number;
  type: string;
  documentID?: number;
  filePath?: string;
}

export enum FileStatus {
  UPLOADED = 'UPLOADED',
  ERROR = 'ERROR',
  INIT = 'INIT',
  DISABLED = 'DISABLED',
  PENDING = 'PENDING'
}

export interface ReturnFiles {
  info: {
    file?: {
      documentID?: number;
      filePath?: string;
      name: string;
      size: number;
    },
    status: FileStatus
  } | null
}
interface UploadFileProps {
  title: string;
  accept?: string;
  sizeLimit?: number;// MB
  fileDescription: string;
  formatText?: string;
  onChange: (filesInfo: ReturnFiles) => void;
  onClick?: () => void;
  onClose?: () => void;
  defaultFileStatus?: {
    fileInfo: FileInfoProps;
  }
  errorMsg?: string;
  disabled?: boolean;
  warningMsg?: string | string[];
  markWarning?: boolean;
  id?:string;
  files:FileList[];
  targetID: number;
  targetName: string;
  setFileList: (arg0:any) =>void;
}

interface FileList {
  shareholder_id?: number;
  director_id?: number;
  type: string;
  name: string;
  size: number;
  document_id?: number | undefined;
  documents?: File [];
  file_path: string;
}

interface File {
  file_path: string;
  type: string;
  name: string;
  size: number;
  document_id: number | undefined;
}

const UploadFile = (props: UploadFileProps): JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const {
    title, fileDescription, onClick, accept = '*',
    onChange, sizeLimit, onClose, formatText, files, targetID, targetName,
    errorMsg, warningMsg, markWarning, disabled, id = '',
    defaultFileStatus, setFileList, ...others
  } = props;
  const { setValue, getValues } = useFormContext();
  const [fileStatus, setFileStatus] = useState<FileStatus>(FileStatus.INIT);
  const [fileInfo, setFileInfo] = useState<FileInfoProps | null>(null);
  const dispatch = useAppDispatch();
  const accountID = String(localStorage.getItem('accountID'));

  const clearFile = (): void => {
    setFileInfo(null);
    onChange({ info: null });
  };

  const handleInput = async (e: any): Promise<any> => {
    setFileStatus(FileStatus.INIT);
    const file = e.target.files[0];
    const sizeLimitInByte = sizeLimit ? sizeLimit * 1024 * 1024 : null;
    const fileSize = file.size; // byte
    try {
      if (!file) return;
      if (sizeLimitInByte && fileSize > sizeLimitInByte) {
        setFileStatus(FileStatus.ERROR);
        console.error('FILE Size is too BIG');
        return;
      }
      let documentID = fileInfo?.documentID || undefined;
      let filePath = '';
      setFileStatus(FileStatus.PENDING);
      ErrorHandler(
        uploadDocument(accountID, id, file, targetID, documentID),
        dispatch
      )
        .then((res) => {
          if (res && res.status === 200) {
            const {
              company,
              directors,
              shareholders,
              documents,
              secondary_account_holder: secondaryAccountHolder
            } = res.data;
            ErrorHandler(
              getFileList(accountID),
              dispatch
            )
              .then((filesRes) => {
                if (filesRes && filesRes.status === 200) {
                  setFileList(filesRes.data);
                  const {
                    company: listCompany,
                    directors: listDirectors,
                    shareholders: listShareholders,
                    documents: listDocuments,
                    secondary_account_holder: listSecondaryAccountHolder
                  } = filesRes.data;
                  if (documents && documents.length > 0) {
                    documentID = documents[0].document_id;
                    filePath = documents[0].file_path;
                    setValue('documents', listDocuments);
                  } else if (shareholders && shareholders.length > 0) {
                    if (!isEmpty(getValues('shareholders'))) {
                      documentID = shareholders[0].documents[0].document_id;
                      filePath = shareholders[0].documents[0].file_path;
                      listShareholders.forEach((shareholder: FileList, shareholdersIndex: number) => {
                        if (shareholders[0].shareholder_id === shareholder.shareholder_id) {
                          setValue(`shareholders[${shareholdersIndex}][documents]`, shareholder?.documents);
                        }
                      });
                    }
                  } else if (directors && directors.length > 0) {
                    if (!isEmpty(getValues('directors'))) {
                      documentID = directors[0].documents[0].document_id;
                      filePath = directors[0].documents[0].file_path;
                      listDirectors.forEach((director: FileList, directorsIndex: number) => {
                        if (directors[0].director_id === director.director_id) {
                          setValue(`directors[${directorsIndex}][documents]`, director?.documents);
                        }
                      });
                    }
                  } else if (secondaryAccountHolder) {
                    documentID = secondaryAccountHolder.documents[0].document_id;
                    filePath = secondaryAccountHolder.documents[0].file_path;
                    setValue('secondary_account_holder[documents]', listSecondaryAccountHolder.documents);
                  } else {
                    documentID = company.documents[0].document_id;
                    filePath = company.documents[0].file_path;
                    setValue('company[documents]', listCompany.documents);
                  }
                }
                setFileInfo(
                  {
                    name: file.name, size: file.size, documentID, filePath, type: file.type
                  }
                );
                setFileStatus(FileStatus.UPLOADED);
                onChange({
                  info: {
                    file: {
                      documentID,
                      filePath,
                      name: file.name,
                      size: file.size
                    },
                    status: fileStatus
                  }
                });
              });
          }
        });
    } catch (error) {
      onChange({
        info: {
          status: fileStatus
        }
      });
    } finally {
      e.target.value = '';
    }
  };

  const handleUploadStatus = (uploadStatus: FileStatus): React.ReactNode => {
    switch (uploadStatus) {
      case FileStatus.UPLOADED:
        return (
          <StyledStatusImgWrap aria-label={`${id} uploaded wrap`}>
            <StyledStatusBlock status="UPLOADED">
              <IconComponent name="CloudUploaded" aria-label={`${id} uploaded icon`} />
              <StyledStatusTxt status="UPLOADED" aria-label={`${id} file status`}>{t('fileUploaded')}</StyledStatusTxt>
            </StyledStatusBlock>
            {fileInfo?.size
              && (
                <StyledSizeInfo aria-label={`${id} file size`}>
                  {t('fileSize')}
                  {' '}
                  {formatBytes(Number(fileInfo.size))}
                </StyledSizeInfo>
              )}
          </StyledStatusImgWrap>
        );
      case FileStatus.ERROR:
        return (
          <StyledStatusImgWrap aria-label={`${id} error wrap`}>
            <StyledStatusBlock status="ERROR">
              <IconComponent name="CloudToCheck" aria-label={`${id} error icon`} />
              <StyledStatusTxt status="ERROR" aria-label={`${id} file status`}>
                {t('uploadFile')}
              </StyledStatusTxt>
            </StyledStatusBlock>
            <StyledSizeInfo aria-label={`${id} file size`} status="error">
              {t('maxLimit')}
              {/* {' '}
              {sizeLimit}
              {' '}
              MB */}
            </StyledSizeInfo>
          </StyledStatusImgWrap>
        );
      case FileStatus.DISABLED:
        return (
          <StyledStatusImgWrap aria-label={`${id} disabled wrap`}>
            <StyledStatusBlock status="DISABLED">
              <IconComponent name="CloudToCheck" aria-label={`${id} disabled icon`} />
              <StyledStatusTxt status="DISABLED" aria-label={`${id} file status`}>
                {t('uploadFile')}
              </StyledStatusTxt>
            </StyledStatusBlock>
            <StyledSizeInfo aria-label={`${id} file size`}>
              {t('maxLimit')}
              {/* {' '}
              {sizeLimit}
              {' '}
              MB */}
            </StyledSizeInfo>
          </StyledStatusImgWrap>
        );

      default: // INIT
        return (
          <StyledStatusImgWrap aria-label={`${id} init wrap`}>
            <StyledStatusBlock status="INIT">
              <IconComponent name="CloudToCheck" aria-label={`${id} init icon`} />
              <StyledStatusTxt status="INIT" aria-label={`${id} file status`}>{t('uploadFile')}</StyledStatusTxt>
            </StyledStatusBlock>
            {sizeLimit && (
              <StyledSizeInfo aria-label={`${id} file size`}>
                {t('maxLimit')}
                {/* {' '}
                {sizeLimit}
                {' '}
                MB */}
              </StyledSizeInfo>
            )}
          </StyledStatusImgWrap>
        );
    }
  };

  const handleOnClose = (): void => {
    if (fileStatus === FileStatus.DISABLED || fileStatus === FileStatus.PENDING) return;
    setFileStatus(FileStatus.PENDING);
    ErrorHandler(
      deleteDocument(accountID, fileInfo?.documentID),
      dispatch
    )
      .then((res) => {
        if (res && res.status === 204) {
          clearFile();
          setFileStatus(FileStatus.INIT);
          ErrorHandler(
            getFileList(accountID),
            dispatch
          )
            .then((listRes) => {
              if (listRes && listRes.status === 200) {
                setFileList(listRes.data);
              }
            });
        }
      });
    if (onClose) onClose();
  };

  useEffect(() => {
    if (isArray(files) && !isEmpty(files)) {
      files.forEach((file) => {
        if (targetName === 'company'
        || targetName === 'contactPerson'
        || targetName === 'secondaryAccountHolder'
        || targetName === 'primaryAccountHolder') {
          if (file?.type === id) {
            setFileInfo(
              {
                name: file.name, size: file.size, type: file.type, documentID: file.document_id, filePath: file.file_path
              }
            );
            onChange({
              info: {
                file: {
                  documentID: file.document_id,
                  filePath: file.file_path,
                  name: file.name,
                  size: file.size
                },
                status: fileStatus
              }
            });
            if (fileStatus === FileStatus.INIT) {
              setFileStatus(FileStatus.UPLOADED);
            }
          }
        } else if (targetID === file.shareholder_id || targetID === file.director_id) {
          file?.documents?.forEach((element) => {
            if (element?.type === id) {
              setFileInfo(
                {
                  name: element.name, size: element.size, type: element.type, documentID: element.document_id, filePath: element.file_path
                }
              );
              onChange({
                info: {
                  file: {
                    documentID: element.document_id,
                    filePath: element.file_path,
                    name: element.name,
                    size: element.size
                  },
                  status: fileStatus
                }
              });
              if (fileStatus === FileStatus.INIT) {
                setFileStatus(FileStatus.UPLOADED);
              }
            }
          });
        }
      });
    }
  }, [files]);

  useEffect(() => {
    if (errorMsg) {
      setFileStatus(FileStatus.ERROR);
    }
  }, [errorMsg, defaultFileStatus, warningMsg, markWarning]);

  useEffect(() => {
    if (!isEmpty(warningMsg) && !markWarning) {
      clearFile();
      setFileStatus(FileStatus.INIT);
    }
  }, [markWarning, files]);

  useEffect(() => {
    if (disabled) setFileStatus(FileStatus.DISABLED);
  }, [warningMsg, disabled]);

  useEffect(() => {
    if (fileStatus === FileStatus.ERROR) {
      clearFile();
    }
  }, [fileStatus]);

  return (
    <div>
      <StyledUploadFile className="lp-upload-file">
        {handleUploadStatus(fileStatus)}
        <StyledFileInfoWrap>
          <StyledFileTitle aria-label={`${id} file title`}>
            {title}
          </StyledFileTitle>
          <StyledFileDesp aria-label={`${id} file description`}>
            {fileDescription}
          </StyledFileDesp>
          <div>
            <div>
              <Button
                aria-label={`${id} upload button`}
                className="upload-btn"
                onClick={onClick}
                variant="contained"
                component="label"
                disabled={fileStatus === FileStatus.DISABLED || fileStatus === FileStatus.PENDING}
              >
                {fileStatus !== FileStatus.PENDING ? t('chooseFile')
                  : <CircularProgress className="custom-circular-progress" />}
                <input
                  accept={accept}
                  type="file"
                  hidden
                  onChange={(e) => {
                    const target = e.target.value.split('.');

                    if (['jpg', 'jpeg', 'png', 'pdf', 'heic'].some((el: string) => el === target[target.length - 1]?.toLowerCase())) {
                      handleInput(e);
                    }
                  }}
                  {...others}
                />
              </Button>
            </div>
            {
              fileInfo ? (
                <StyledFileNameWrap aria-label={`${id} file name`}>
                  <span>
                    {t('fileName')}
                    {' '}
                    {fileInfo?.name}
                  </span>
                  <StyledCloseIcon
                    onClick={handleOnClose}
                    aria-label={`${id} close icon`}
                  >
                    <IconComponent name="Close" />
                  </StyledCloseIcon>
                  {errorMsg
                    && (
                      <StyledSizeInfo aria-label={`${id} file size`} status="error">
                        {errorMsg}
                      </StyledSizeInfo>
                    )}
                </StyledFileNameWrap>
              )
                : (
                  <StyledFormatDesp>
                    {formatText}
                  </StyledFormatDesp>
                )
            }
          </div>
        </StyledFileInfoWrap>
      </StyledUploadFile>
      {warningMsg && (
        <StyledWarningWrapper markWarning={markWarning}>
          {Array.isArray(warningMsg) ? warningMsg.map((el, index) => (
            <StyledWarningMsg key={el} markWarning={markWarning} aria-label={`${id} upload error ${index}`}>
              {el}
            </StyledWarningMsg>
          )) : <StyledWarningMsg markWarning={markWarning} aria-label={`${id} upload error`}>{warningMsg}</StyledWarningMsg>}
        </StyledWarningWrapper>
      )}
    </div>
  );
};

export default UploadFile;
