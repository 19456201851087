import Banner from 'containers/Banner';
import SelectAccountTypePanel from 'containers/SelectAccountTypePanel';
import { SelectAccountWrapper } from './style';

const SelectAccountType = ():JSX.Element => (
  <SelectAccountWrapper>
    <Banner name="AccountRegistrationBanner" />
    <SelectAccountTypePanel />
  </SelectAccountWrapper>
);

export default SelectAccountType;
