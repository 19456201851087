import * as React from "react";
import { SVGProps } from "react";
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.62.752a.766.766 0 0 1 1.142 0 .9.9 0 0 1 .012 1.205l-6.45 8.018a.802.802 0 0 1-.262.2.768.768 0 0 1-.9-.177L.235 5.816A.892.892 0 0 1 0 5.208c0-.228.085-.446.236-.608a.8.8 0 0 1 .262-.187.766.766 0 0 1 .88.187l3.33 3.548 5.89-7.37a.274.274 0 0 1 .023-.026Z"
      fill="#5C5C5C"
    />
  </svg>
);
export default SvgCheck;
