import { createSlice } from '@reduxjs/toolkit';

interface CreateAccountState {
  step: number
}

const initialState: CreateAccountState = {
  step: 0
};

export const createAccountSlice = createSlice({
  name: 'createAccount',
  initialState,
  reducers: {
    assignStep: (state, action) => {
      state.step = action.payload.step;
    },
    nextStep: (state) => {
      state.step += 1;
    },
    backStep: (state) => {
      state.step -= 1;
    },
    backTwoSteps: (state) => {
      state.step -= 2;
    }
  }
});

export const {
  nextStep, backStep, backTwoSteps, assignStep
} = createAccountSlice.actions;
export default createAccountSlice.reducer;
