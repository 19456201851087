/* eslint-disable @typescript-eslint/no-explicit-any */
import IconComponent from 'components/Icons';
import { formatBytes } from 'utils/common';
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledUploadFile, StyledStatusImgWrap, StyledFileInfoWrap, StyledFileNameWrap,
  StyledSizeInfo, StyledCloseIcon, StyledStatusBlock, StyledStatusTxt, StyledFormatDesp,
  StyledFileDesp, StyledFileTitle, StyledWarningMsg, StyledWarningWrapper
} from './style';

interface FileInfoProps {
  [key: string]: string | number
}

export enum FileStatus {
  UPLOADED = 'UPLOADED',
  ERROR = 'ERROR',
  INIT = 'INIT',
  DISABLED = 'DISABLED'
}

export interface ReturnFiles {
  info: {
    file?: File,
    status: FileStatus
  } | null
}
interface UploadFileProps {
  title: string;
  accept?: string;
  sizeLimit?: number;// MB
  fileDescription: string;
  formatText?: string;
  onChange: (filesInfo: ReturnFiles, isCancelRejectUpload?: boolean) => void;
  onClick?: () => void;
  onClose?: () => void;
  defaultFileStatus?: {
    fileInfo: FileInfoProps;
  }
  errorMsg?: string;
  disabled?: boolean;
  warningMsg?: string | string[];
  markWarning?: boolean;
  id?:string;
}

// INPUT TYPE TO BE CORRECT
// interface HTMLInputEvent extends Event {
//     target: HTMLInputElement & EventTarget;
// }

const UploadFile = (props: UploadFileProps): JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const {
    title, fileDescription, onClick, accept = '*',
    onChange, sizeLimit, onClose, formatText,
    errorMsg, warningMsg, markWarning, disabled, id = '',
    defaultFileStatus, ...others
  } = props;

  const [fileStatus, setFileStatus] = useState<FileStatus>(FileStatus.INIT);
  const [fileInfo, setFileInfo] = useState<FileInfoProps | null>(null);

  const clearFile = (): void => {
    setFileInfo(null);
    onChange({ info: null }, true);
  };

  const handleInput = async (e: any): Promise<any> => {
    setFileStatus(FileStatus.INIT);

    const file = e.target.files[0];
    const sizeLimitInByte = sizeLimit ? sizeLimit * 1024 * 1024 : null;
    const fileSize = file.size; // byte
    try {
      if (!file) return;
      setFileInfo(file);
      setFileStatus(FileStatus.UPLOADED);

      if (sizeLimitInByte && fileSize > sizeLimitInByte) {
        setFileStatus(FileStatus.ERROR);
        console.error('FILE Size is too BIG');
        return;
      }
      onChange({
        info: {
          file,
          status: fileStatus
        }
      });
    } catch (error) {
      onChange({
        info: {
          status: fileStatus
        }
      });
    } finally {
      e.target.value = '';
    }
  };

  const handleUploadStatus = (uploadStatus: FileStatus): React.ReactNode => {
    switch (uploadStatus) {
      case FileStatus.UPLOADED:
        return (
          <StyledStatusImgWrap aria-label={`${id} uploaded wrap`}>
            <StyledStatusBlock status="UPLOADED">
              <IconComponent name="CloudUploaded" aria-label={`${id} uploaded icon`} />
              <StyledStatusTxt status="UPLOADED" aria-label={`${id} file status`}>{t('fileUploaded')}</StyledStatusTxt>
            </StyledStatusBlock>
            {fileInfo?.size
              && (
                <StyledSizeInfo aria-label={`${id} file size`}>
                  {t('fileSize')}
                  {' '}
                  {formatBytes(Number(fileInfo.size))}
                </StyledSizeInfo>
              )}
          </StyledStatusImgWrap>
        );
      case FileStatus.ERROR:
        return (
          <StyledStatusImgWrap aria-label={`${id} error wrap`}>
            <StyledStatusBlock status="ERROR">
              <IconComponent name="CloudToCheck" aria-label={`${id} error icon`} />
              <StyledStatusTxt status="ERROR" aria-label={`${id} file status`}>
                {t('uploadFile')}
              </StyledStatusTxt>
            </StyledStatusBlock>
            <StyledSizeInfo aria-label={`${id} file size`} status="error">
              {t('maxLimit')}
              {/* {' '}
              {sizeLimit}
              {' '}
              MB */}
            </StyledSizeInfo>
          </StyledStatusImgWrap>
        );
      case FileStatus.DISABLED:
        return (
          <StyledStatusImgWrap aria-label={`${id} disabled wrap`}>
            <StyledStatusBlock status="DISABLED">
              <IconComponent name="CloudToCheck" aria-label={`${id} disabled icon`} />
              <StyledStatusTxt status="DISABLED" aria-label={`${id} file status`}>
                {t('uploadFile')}
              </StyledStatusTxt>
            </StyledStatusBlock>
            <StyledSizeInfo aria-label={`${id} file size`}>
              {t('maxLimit')}
              {/* {' '}
              {sizeLimit}
              {' '}
              MB */}
            </StyledSizeInfo>
          </StyledStatusImgWrap>
        );

      default: // INIT
        return (
          <StyledStatusImgWrap aria-label={`${id} init wrap`}>
            <StyledStatusBlock status="INIT">
              <IconComponent name="CloudToCheck" aria-label={`${id} init icon`} />
              <StyledStatusTxt status="INIT" aria-label={`${id} file status`}>{t('uploadFile')}</StyledStatusTxt>
            </StyledStatusBlock>
            {sizeLimit && (
              <StyledSizeInfo aria-label={`${id} file size`}>
                {t('maxLimit')}
                {/* {' '}
                {sizeLimit}
                {' '}
                MB */}
              </StyledSizeInfo>
            )}
          </StyledStatusImgWrap>
        );
    }
  };

  const handleOnClose = (): void => {
    setFileStatus(FileStatus.INIT);
    clearFile();
    if (onClose) onClose();
  };

  useEffect(() => {
    if (errorMsg) {
      setFileStatus(FileStatus.ERROR);
    } else if (defaultFileStatus?.fileInfo) {
      if (warningMsg && !markWarning) return;
      setFileInfo(defaultFileStatus.fileInfo);
      setFileStatus(FileStatus.UPLOADED);
    }
  }, [errorMsg, defaultFileStatus, warningMsg, markWarning]);

  useEffect(() => {
    if (disabled) setFileStatus(FileStatus.DISABLED);
  }, [warningMsg, disabled]);

  useEffect(() => {
    if (fileStatus === FileStatus.ERROR) {
      clearFile();
    }
  }, [fileStatus]);

  return (
    <div>
      <StyledUploadFile className="lp-upload-file">
        {handleUploadStatus(fileStatus)}
        <StyledFileInfoWrap>
          <StyledFileTitle aria-label={`${id} file title`}>
            {title}
          </StyledFileTitle>
          <StyledFileDesp aria-label={`${id} file description`}>
            {fileDescription}
          </StyledFileDesp>
          <div>
            <div>
              <Button
                aria-label={`${id} upload button`}
                className="upload-btn"
                onClick={onClick}
                variant="contained"
                component="label"
                disabled={fileStatus === FileStatus.DISABLED}
              >
                {t('chooseFile')}
                <input
                  accept={accept}
                  type="file"
                  hidden
                  onChange={(e) => {
                    const target = e.target.value.split('.');

                    if (['jpg', 'jpeg', 'png', 'pdf', 'heic'].some((el: string) => el === target[target.length - 1]?.toLowerCase())) {
                      handleInput(e);
                    }
                  }}
                  {...others}
                />
              </Button>
            </div>
            {
              fileInfo ? (
                <StyledFileNameWrap aria-label={`${id} file name`}>
                  <span>
                    {t('fileName')}
                    {' '}
                    {fileInfo?.name}
                  </span>
                  <StyledCloseIcon
                    onClick={handleOnClose}
                    aria-label={`${id} close icon`}
                  >
                    <IconComponent name="Close" />
                  </StyledCloseIcon>
                  {errorMsg
                    && (
                      <StyledSizeInfo aria-label={`${id} file size`} status="error">
                        {errorMsg}
                      </StyledSizeInfo>
                    )}
                </StyledFileNameWrap>
              )
                : (
                  <StyledFormatDesp>
                    {formatText}
                  </StyledFormatDesp>
                )
            }
          </div>
        </StyledFileInfoWrap>
      </StyledUploadFile>
      {warningMsg && (
        <StyledWarningWrapper markWarning={markWarning}>
          {Array.isArray(warningMsg) ? warningMsg.map((el, index) => (
            <StyledWarningMsg key={el} markWarning={markWarning} aria-label={`${id} upload error ${index}`}>
              {el}
            </StyledWarningMsg>
          )) : <StyledWarningMsg markWarning={markWarning} aria-label={`${id} upload error`}>{warningMsg}</StyledWarningMsg>}
        </StyledWarningWrapper>
      )}
    </div>
  );
};

export default UploadFile;
