import { useState } from 'react';
import Button from '@mui/material/Button';
import { useCreateAccountFlow } from 'store/context/hooks';
import { useFormContext } from 'react-hook-form';
import { useAppSelector, useAppDispatch } from 'store';
import { useNavigate, useLocation } from 'react-router-dom';
import { resubmitAccount } from 'api/v1/account';
import ErrorHandler from 'utils/ErrorHandler';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import BackdropSpinner from 'components/BackdropSpinner';
import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { StyledTitle, StyledResumitBtnWrap } from './style';

const ResubmitFooter = ():JSX.Element => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const {
    rejectStepStatus,
    steps
  } = useCreateAccountFlow();
  const dispatch = useAppDispatch();
  const { getValues } = useFormContext();
  const currentStep = useAppSelector((state) => state.createAccountState.step);
  const navigator = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('createAccount');

  const checkInValid = ():boolean => {
    let disableFlag = false;
    if (!rejectStepStatus) return disableFlag;
    Object.keys(rejectStepStatus).forEach((key) => {
      if (!rejectStepStatus[key].valid || rejectStepStatus[key].isWarning) disableFlag = true;
    });
    return disableFlag;
  };

  const clickHandler = ():void => {
    setSubmitLoading(true);
    const data = getValues();
    const submitData = {
      ...data,
      entry_url: `${DOMAIN_CONFIG.origin}/rg227/${localStorage.getItem('accountID') || ''}`
    };

    const accountID = localStorage.getItem('accountID');
    const theNextStep = currentStep === steps.length - 1
      ? steps[currentStep] : steps[currentStep + 1];

    if (accountID) {
      ErrorHandler(resubmitAccount(
        submitData,
        accountID,
        theNextStep,
        steps[currentStep]
      ), dispatch).then((res) => {
        if (res && res.status === 200) {
          if (location.pathname === '/correction-form') {
            window.location.href = '/';
          } else {
            navigator('/create-account/personal-account/thank-you');
          }

          localStorage.removeItem('accountID');
        }
      }).finally(() => {
        setSubmitLoading(false);
      });
    }
  };

  const titleNode = (
    <StyledTitle>
      { t('resubmitToolTip') }
    </StyledTitle>
  );

  return (
    <StyledResumitBtnWrap>
      <Tooltip
        arrow
        placement="top"
        enterTouchDelay={0}
        componentsProps={{
          tooltip: {
            sx: {
              bottom: '-5px',
              fontFamily: 'Noto Sans',
              backgroundColor: '#3E3E3E',
              borderRadius: 1,
              padding: '6px 7px',
              fontSize: 12
            }
          }
        }}
        title={titleNode}
        className="tool-tip"
      >
        <div>
          <Button
            aria-label="Submit Reject Fields"
            className="resubmit-button"
            onClick={clickHandler}
            disabled={checkInValid()}
          >
            <CheckIcon />
            { t('submit') }
          </Button>
        </div>
      </Tooltip>
      <BackdropSpinner open={submitLoading} />
    </StyledResumitBtnWrap>
  );
};

export default ResubmitFooter;
