/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-danger */
import FormPanel from 'components/FormPanel';
import { forwardRef, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getTermsAndConditionsForm } from 'api/v1/account';
import CircularProgress from '@mui/material/CircularProgress';
import { useCreateAccountFlow } from 'store/context/hooks';
import { getFieldWarningMessage, checkFieldIsWarning } from 'containers/CreateAccountForm/utils';
import { CreateAccountSteps } from 'constant/createAccount';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledBannerDesp } from '../style';
import {
  StyledTermsAndConditions,
  StyledCheckboxSect,
  StyledCheckboxLabelWrap,
  StyledLoadingWrap,
  StyledScrollWrap,
  StyledTermsAndConditionsContentWrap
} from './style';

interface CheckBoxProps {
  label:string | React.ReactNode;
  id?:string;
  disabled?: boolean
}

export interface ResponseProps {
  id: string;
  panelTitle: string;
  bannerComment: string;
  termsContent: string;
  checkBoxTitle: string;
  data: {
    id: string;
    label: string;
  }[];
}

const Checkbox = forwardRef<HTMLInputElement, CheckBoxProps>((props, ref):JSX.Element => {
  const {
    label, id = '', disabled, ...others
  } = props;
  return (
    <div className="checkbox" aria-label={`${id} checkbox item`}>
      <label className="checkbox-label">
        <div>
          <input
            aria-label={`${id} input`}
            ref={ref}
            type="checkbox"
            disabled={disabled}
            {...others}
          />
          <span className="check-mark" aria-label={`${id} check mark`} />
        </div>
        { label }
      </label>
    </div>
  );
});

const TermsAndConditions = ():JSX.Element => {
  const { register, getValues } = useFormContext();
  const { rejectWarningFields } = useCreateAccountFlow();
  const [searchParams] = useSearchParams();

  const [termsAndConditions, setTermsAndConditions] = useState<ResponseProps | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { i18n } = useTranslation();

  useEffect(() => {
    const country = getValues('company.country_of_company');
    getTermsAndConditionsForm(country).then((res) => {
      if (res?.data) {
        setTermsAndConditions(res.data);
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <StyledTermsAndConditions style={{ direction: i18n.language === 'he' ? 'ltr' : 'inherit' }}>
      {!isLoading && termsAndConditions && (
        <>
          <FormPanel id={termsAndConditions.id} title={termsAndConditions.panelTitle}>
            <StyledBannerDesp aria-label="Terms And Conditions Banner Comment">
              {termsAndConditions.bannerComment}
            </StyledBannerDesp>

            <StyledTermsAndConditionsContentWrap className="StyledContentWrap" aria-label="Terms And Conditions Content">
              <StyledScrollWrap
                dangerouslySetInnerHTML={{ __html: termsAndConditions.termsContent }}
              />

            </StyledTermsAndConditionsContentWrap>

          </FormPanel>
          <StyledCheckboxSect>
            <div className="title">
              {termsAndConditions.checkBoxTitle}
            </div>
            {termsAndConditions.data.map((eachTerm:{ id:string; label:string; }) => (
              <Checkbox
                key={eachTerm.id}
                id={eachTerm.id}
                label={(
                  <StyledCheckboxLabelWrap
                    dangerouslySetInnerHTML={{ __html: eachTerm.label }}
                    aria-label={`Terms And Conditions Checkbox Label ${eachTerm.id}`}
                  />
                )}
                {...register(eachTerm.id, {
                  required: searchParams.get('status') === 'Reject'
                    ? !!getFieldWarningMessage(rejectWarningFields, CreateAccountSteps.TermsAndConditions, eachTerm.id, 0)
                    : true
                })}
                disabled={checkFieldIsWarning(rejectWarningFields, CreateAccountSteps.TermsAndConditions, eachTerm.id, 0)}
              />
            ))}
          </StyledCheckboxSect>
        </>
      )}
      {
        isLoading && (
          <StyledLoadingWrap>
            <CircularProgress />
          </StyledLoadingWrap>
        )
      }
    </StyledTermsAndConditions>

  );
};

export default TermsAndConditions;
