import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledRG227PracticeFormWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px 0px 80px 0px;
  background: inherit;
`;

export const StyledRG227PracticeForm = styled.div`
  box-shadow: 0px 1px 4px rgba(90, 114, 143, 0.1), 0px 1px 3px rgba(90, 114, 143, 0.16);
  border-radius: 12px;
  width: 100%;
  margin: 0px 108px;
  background: #FFFFFF;
  @media ${device.tabletMaxWidth} {
    margin: 0px 24px;
  }
  @media ${device.mobileMaxWidth} {
    margin: 0px 16px;
  }
`;

export const StyledHead = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid #DDDDDD;
`;

export const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #013B81;
`;

export const StyledSubtitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5C5C5C;
`;

export const StyledContent = styled.div`
  min-height: 200px;
  padding: 40px;
`;

export const StyledButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-top: 4px;
  .submit-button {
    padding: 8px 24px;
    font-family: 'Noto Sans';
    background: #145DB5;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: none;

    &:hover, &:active {
      background: #145DB5;
    }

    &:disabled {
      color: #fff;
      opacity: 0.6;
    }
  }
`;

export const StyledGetNewQuestionWrap = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #013B81;
  cursor: pointer;
  user-select: none;
`;

export const StyledLoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledPopupContentWrap = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1C1C1C;
  gap: 4px;
  @media ${device.mobileMaxWidth} {
    font-size: 14px;
  }
`;

export const StyledClickHere = styled(StyledGetNewQuestionWrap)`
  @media ${device.mobileMaxWidth} {
    font-size: 14px;
  }
`;
