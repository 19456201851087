import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const sideMenuOpenWidth = 280;
export const sideMenuCloseWidth = 88;

const toggleMenuWidth = (isMenuOpen: boolean): string => `
  width: ${isMenuOpen ? sideMenuOpenWidth : sideMenuCloseWidth}px;
  @media ${device.tablet} {
    width: 460px;
  }
  @media ${device.mobileMaxWidth} {
    width: 100%;
  }
`;

export const StyledMenuHeader = styled.div<{ open: boolean }>`
  position: fixed;
  ${(props) => toggleMenuWidth(props.open)}
  height: 72px;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.12), 0px 0px 16px rgba(0, 0, 0, 0.2);
  background: #01254f;
  transition: width 0.5s;
  border-top-right-radius: ${(props) => (props.theme.isRTL ? 0 : '32px')};
  border-top-left-radius: ${(props) => (props.theme.isRTL ? '32px' : 0)};
`;

export const StyledMenuContainer = styled.div<{
  open: boolean;
}>`
  > .MuiDrawer-paper {
    ${(props) => toggleMenuWidth(props.open)}
    border-radius: ${(props) => (props.theme.isRTL ? '32px 0px 0px 32px' : '0px 32px 32px 0px')};
  }
  section {
    ${(props) => toggleMenuWidth(props.open)}
    position: fixed;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.sideMenu.background};
    border-radius: ${(props) => (props.theme.isRTL ? '32px 0px 0px 32px' : '0px 32px 32px 0px')};
    z-index: 3;
    transition: width 0.5s;
    @media ${device.mobileMaxWidth} {
      border-radius: 0;
    }
  }
`;

export const StyledBorderWrap = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.common.lightGrey};
    border-radius: 4px;
    -webkit-border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.common.lightGrey};
  }

  ::-webkit-scrollbar-track-piece:end {
    margin-bottom: 72px;
  }

  ::-webkit-scrollbar-track-piece:start {
    margin-top: 72px;
  }
`;

export const StyledMenuIconWrap = styled.div<{ isMenuOpen: boolean }>`
  ${(props) => toggleMenuWidth(props.isMenuOpen)}
  background-color: ${(props) => props.theme.sideMenu.background};
  color: #f4f4f4;
  transition: width 0.5s;
  box-shadow: 0px 0px 18px rgb(0 0 0 / 12%), 0px 0px 16px rgb(0 0 0 / 20%);
  z-index: 2;
  > div {
    position: absolute;
    top: 16px;
    left: ${(props) => (props.theme.isRTL ? '0px' : '24px')};
    right: ${(props) => (props.theme.isRTL ? '24px' : '0px')};
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #003566;
      border-radius: 50%;
    }
  }
  @media ${device.desktopMinWidth} {
    > div {
      > svg {
        width: 19px;
        height: 17px;
      }
    }
  }
`;

export const StyledContent = styled.div`
  margin: 112px 0 72px 0;
`;

export const StyledAvatarContainer = styled.div<{
  isMenuOpen: boolean;
  isListOpen: boolean;
}>`
  display: flex;
  align-items: center;
  height: 72px;
  z-index: 1;
  position: fixed;
  bottom: 0;
  background: ${(props) => props.theme.sideMenu.background};
  box-shadow: 0px 0px 18px rgb(0 0 0 / 12%), 0px 0px 16px rgb(0 0 0 / 20%);
  backdrop-filter: blur(12px);
  border-radius: ${(props) => (props.theme.isRTL ? '0px 0px 0px 32px' : '0px 0px 32px 0px')};
  transition: width 0.5s;
  ${(props) => toggleMenuWidth(props.isMenuOpen)}

  .dropDown {
    margin: ${(props) => (props.isMenuOpen ? '0 32' : '0 32')}px;
  }

  @media ${device.mobileMaxWidth} {
    border-bottom-right-radius: 0;
  }

  .dropDown {
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    display: flex;
    transition: all 0.3s;
    > div {
      svg {
        ${(props) => props.isListOpen && 'transform: rotate(180deg);'};
      }
    }

    .title {
      cursor: pointer;
      display: flex;
      flex: 1;
      align-items: center;
      color: #fff;
    }

    .menu-list {
      margin: 0;
      top: -104px;
      right: ${(props) => (props.theme.isRTL ? 0 : 'unset')};
      width: ${(props) => (props.isMenuOpen ? '100%' : '216px')};
      background-color: #003566;
      padding: 8px;
      box-sizing: border-box;
      border-radius: 4px;

      .item {
        padding: 0;
        border-radius: 4px;
        &:hover {
          background: ${(props) => props.theme.common.lightGray};
        }

        > button {
          display: flex;
          flex-direction: row;
          cursor: pointer;
          border: none;
          background-color: ${(props) => props.theme.sideMenu.avatarMenuBg};
          color: #ffffff;
          width: 100%;
          height: 100%;
          padding: 8px 16px;
          border-radius: 4px;

          &:hover {
            background: ${(props) => props.theme.sideMenu.avatarActive};
          }

          > svg {
            font-size: 24px;
          }

          > span {
            padding: ${(props) => (props.theme.isRTL ? '0 24px 0 0' : '0 0 0 24px')};
            line-height: 24px;
            font-weight: 400;
            font-size: 14px;
          }
        }

        > a {
          color: ${(props) => props.theme.sideMenu.secondary};
        }
      }
    }
  }
`;

export const StyledAvatarNameWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  > span {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
  }

  > svg {
    width: 10px;
    height: 9px;
  }
`;

export const StyledDropDownContainer = styled.div<{ isMenuOpen: boolean }>`
  display: ${(props) => (props.isMenuOpen ? 'flex' : 'none')};
  align-items: center;
  padding: 8px 16px;
  border-radius: 10px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
  }

  .menu-list {
    top: -110px;
    left: -90px;
    width: 240px;
    background-color: ${(props) => props.theme.common.lightGrey};
    padding: 8px;
    box-sizing: border-box;
    border-radius: 4px;

    .item {
      border-radius: 4px;
      &:hover {
        background: ${(props) => props.theme.sideMenu.avatarActive};
      }
      > a {
        color: ${(props) => props.theme.sideMenu.secondary};
      }
    }
  }
`;

export const StyledMenuBlock = styled.div`
  margin-bottom: 8px;

  &.lang-menu {
    > div > div {
      ${(props) => !props.theme.isRTL && 'padding-right: 0'};
    }
    span {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      > svg {
        transform: rotate(-90deg);
        width: 10px;
      }
    }
  }
`;

export const StyleMobileLangWrap = styled.div`
  display: flex;
  margin-left: 10px;
  ${(props) => props.theme.isRTL && 'padding-right: 5px'};
`;

export const StyledAvatar = styled.div<{ isMenuShow: boolean }>`
  display: flex;
  align-items: center;
  margin: ${(props) => {
    if (!props.isMenuShow) return '0';
    return props.theme.isRTL ? '0 0 0 16px' : '0 16px 0 0';
  }};

  svg {
    transform: rotate(0) !important;
    width: 30px;
    height: 30px;
  }
`;
