import * as React from "react";
import { SVGProps } from "react";
const SvgMenuShrink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m5.136 7.273-3.03 3.03 3.03 3.03"
      stroke="#fff"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 18.363h19M9.682 12.909H20.5M9.682 7.454H20.5M1.5 1.636h19"
      stroke="#fff"
      strokeWidth={1.4}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgMenuShrink;
