const size = {
  mobile: 375,
  tablet: 768,
  desktop: 1024,
  bigDesktop: 1920
};

export const device = {
  mobileMaxWidth: `only screen and (max-width: ${size.tablet - 0.1}px)`,
  tablet: `only screen and (min-width: ${size.tablet}px) and (max-width: ${size.desktop - 0.1}px)`,
  tabletMaxWidth: `only screen and (max-width: ${size.desktop - 0.1}px)`,
  desktopMinWidth: `only screen and (min-width: ${size.desktop}px)`,
  bigDesktopMinWidth: `only screen and (min-width: ${size.bigDesktop}px)`
};
