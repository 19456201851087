import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledSectionContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 52px;
  padding: 0 64px;
  @media ${device.tablet} {
    margin: 16px auto 64px auto;
    width: 100%;
    padding: 0 16px;
  }
  @media ${device.mobileMaxWidth} {
    margin: 16px auto 16px auto;
    padding: 0 16px;
  }
`;

export const StyledHeader = styled.div`
  height: 72px;
  border-bottom: 1px solid #DDDDDD; 
  width: 100%;
  @media ${device.tablet} {
    height: 72px;
  }
  @media ${device.mobileMaxWidth} {
    height: 60px;
  }
`;

export const StyledHeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: max-content;
  height: 100%;
  border-bottom: 1px solid #145DB5;
  h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    text-transform: capitalize;
    color: #1C1C1C;
    margin: 16px 0;
    @media ${device.mobileMaxWidth} {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-top: 40px;
  width: 100%;
  @media ${device.mobileMaxWidth} {
    margin-top: 32px;
  }
`;

export const StyledMetaTraderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  margin-bottom: 40px;
  @media ${device.mobileMaxWidth} {
    margin-bottom: 32px;
  }
`;

export const StyledMetaTraderHeader = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px; 
  text-transform: capitalize;
  color: #1C1C1C;
  width: 100%;
`;

export const StyledPlatformContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledPlatformTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #1C1C1C;
  margin-bottom: 16px;
`;

export const StyledPlatformMain = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;
