import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledPageContainer = styled.div`
  padding: 32px 64px;

  @media ${device.tablet} {
    padding: 30px 34px; 
  }

  @media ${device.mobileMaxWidth} {
    padding: 30px 20px; 
  }
`;
