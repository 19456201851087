import * as React from "react";
import { SVGProps } from "react";
const SvgWebAsset = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.167 13.334c-.367 0-.68-.13-.942-.392A1.284 1.284 0 0 1 1.833 12V4c0-.366.131-.68.392-.941.261-.261.575-.392.942-.392h10.666c.367 0 .681.13.942.392.261.26.392.575.392.941v8c0 .367-.13.681-.392.942-.26.261-.575.392-.941.392H3.167Zm0-1.334h10.666V5.334H3.168V12Z"
      fill="#00B875"
    />
  </svg>
);
export default SvgWebAsset;
