import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import { device } from 'styles/deviceStyle';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 24px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.26), 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    width: 800px;
    max-width: unset;
  }
`;

export const StyledDialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #dddddd;

  h5 {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #1c1c1c;
  }
`;

export const StyledDialogCloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #5c5c5c;
`;

export const StyledDialogContent = styled.div`
  padding: 24px 0;
  color: #5c5c5c;
  font-size: 14px;
  line-height: 24px;
`;

export const StyledDialogActions = styled.div`
  display: flex;
  gap: 8px;

  @media ${device.mobileMaxWidth} {
    flex-direction: column;
  }
`;

type StyledDialogActionButtonProps = {
  variant: 'filled' | 'text';
};

export const StyledDialogActionButton = styled.button<StyledDialogActionButtonProps>`
  border: none;
  background-color: ${({ variant }) => (variant === 'filled' ? '#145DB5' : 'transparent')};
  color: ${({ variant }) => (variant === 'filled' ? '#ffffff' : '#145DB5')};
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  border-radius: 4px;
  padding: 8px 24px;
  cursor: pointer;
  white-space: nowrap;
`;
