import styled from 'styled-components';

interface StyledPaperProps {
  elevation: number;
}

const getBoxShadow = (elevation: number): string => {
  switch (elevation) {
    case 0:
      return '0px 4px 4px rgba(0, 0, 0, 0.25)';
    case 1:
      return '0px 0px 5px rgba(0, 0, 0, 0.12), 0px 0px 7px rgba(0, 0, 0, 0.2)';
    case 2:
      return '0px 0px 9px rgba(0, 0, 0, 0.12), 0px 0px 13px rgba(0, 0, 0, 0.2)';
    case 3:
      return '0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2)';
    case 4:
      return '0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)';
    case 6:
      return '0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)';
    case 8:
      return '0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)';
    case 9:
      return '0px 3px 16px rgba(0, 0, 0, 0.12), 0px 5px 6px rgba(0, 0, 0, 0.2)';
    case 12:
      return '0px 0px 18px rgba(0, 0, 0, 0.12), 0px 0px 16px rgba(0, 0, 0, 0.2)';
    case 16:
      return '0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)';
    case 24:
      return '0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)';
    default:
      return '0px 4px 4px rgba(0, 0, 0, 0.25)';
  }
};

export const StyledPaper = styled.div<StyledPaperProps>`
  padding: 20px;
  background: ${(props) => props.color};
  box-shadow: ${(props) => getBoxShadow(props.elevation)};
  border-radius: 16px;
`;
