import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import {
  DashboardDT,
  DashboardTAB,
  DashboardMOB
} from 'assets/blurredPages';
import { StyledBlurredPageWrap } from 'pages/BlurredPage/style';

export const StyledDashboardWrap = styled(StyledBlurredPageWrap)`
  ::before {
    background-image: url(${DashboardDT});
    @media ${device.tablet} {
      background-image: url(${DashboardTAB});
    }
    @media ${device.mobileMaxWidth} {
      background-image: url(${DashboardMOB});
    }
  }
`;
