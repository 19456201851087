import * as React from "react";
import { SVGProps } from "react";
const SvgTradingCup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path fill="url(#TradingCup_svg__a)" d="M0 0h16v19H0z" />
    <defs>
      <pattern
        id="TradingCup_svg__a"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use
          xlinkHref="#TradingCup_svg__b"
          transform="matrix(.04348 0 0 .03661 0 .207)"
        />
      </pattern>
      <image
        id="TradingCup_svg__b"
        width={23}
        height={16}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAQCAYAAAD9L+QYAAAAAXNSR0IArs4c6QAAAd9JREFUOE+tkzFoFEEUhr9/72ZnL0QUhGiRK+wEtbBQKw1aiCYWFikF7SyikEJsFQsLQbCJhUQEC4uIhWCliNgISoogiIiNFkJAUORk73Z2M0/2JGChl5PcK2eGb/73/v+JEVfeC/Old/e3St80Yjbdspxiza5l3h0dOdzMmr1Q/jTsXB+eh3BwLE3fjKqLbhHeAsvqdDoTLs1uZ97NjhD+UeKTekVxw2BPy/uZQfBahPd+0jm3kuf5zqTZvCd0wOC7oXeJ2bOqcktJWu6S8QrsqbohrGJsA12VsWwNgsW4PTG1kbUN9iLtx9hRfy5LplG8XguqWwcOAUk9XcEHQRFhAnitvCgfC5sGmn9RngNjf5xXiPMYdzFeIqb+0W2U6WTf0NrhPM8nGo1GFmMMrVarI+lH3+w8b6vRWAQdF3pk2CS/VX+O4uyYc++LojoSFeeETgBfEuyy935pqCj2etWMKT6RMW/iFuL5GlwaT9OVQT4NCQ9zllCnqcKYkriQpemdjdI1FLwbwgvBQzMWgAdZ6s5Isk3Di6LYHdEVYB8Qs9QdXvdj0/BeCDcxWwOdqkp3bHxcqxtB1+8HjsXM6j04LdN85d3sFunrsOD+Tgx6bGZZt6gutnxzQVKd+f+qX96Fx+Fbzdk8AAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
);
export default SvgTradingCup;
