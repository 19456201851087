import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState
} from 'react';
import { AlertToastProps } from 'components/AlertToast';

type ResponseMessage = {
  type: AlertToastProps['severity'];
  title: AlertToastProps['title'];
  content: AlertToastProps['content'];
};

type Context = {
  isBackdropSpinnerOpen: boolean;
  setIsBackdropSpinnerOpen: Dispatch<SetStateAction<boolean>>;
  isRemoveMemberDialogOpen: boolean;
  setIsRemoveMemberDialogOpen: Dispatch<SetStateAction<boolean>>;
  isInviteMemberDialogOpen: boolean;
  setIsInviteMemberDialogOpen: Dispatch<SetStateAction<boolean>>;
  isTradingExperienceDialogOpen: boolean;
  setIsTradingExperienceDialogOpen: Dispatch<SetStateAction<boolean>>;
  isResponseMessageToastOpen: boolean;
  setIsResponseMessageToastOpen: Dispatch<SetStateAction<boolean>>;
  responseMessage: ResponseMessage;
  setResponseMessage: Dispatch<SetStateAction<ResponseMessage>>;
};

const LayoutControlContext = createContext<Context>({
  isBackdropSpinnerOpen: false,
  isRemoveMemberDialogOpen: false,
  isInviteMemberDialogOpen: false,
  isTradingExperienceDialogOpen: false,
  isResponseMessageToastOpen: false
} as Context);

export const LayoutControlProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [isBackdropSpinnerOpen, setIsBackdropSpinnerOpen] = useState(false);
  const [isRemoveMemberDialogOpen, setIsRemoveMemberDialogOpen] = useState(false);
  const [isInviteMemberDialogOpen, setIsInviteMemberDialogOpen] = useState(false);
  const [isTradingExperienceDialogOpen, setIsTradingExperienceDialogOpen] = useState(false);
  const [isResponseMessageToastOpen, setIsResponseMessageToastOpen] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<ResponseMessage>({
    type: 'success',
    title: '',
    content: ''
  });

  const value = useMemo(
    () => ({
      isBackdropSpinnerOpen,
      setIsBackdropSpinnerOpen,
      isRemoveMemberDialogOpen,
      setIsRemoveMemberDialogOpen,
      isInviteMemberDialogOpen,
      setIsInviteMemberDialogOpen,
      isTradingExperienceDialogOpen,
      setIsTradingExperienceDialogOpen,
      isResponseMessageToastOpen,
      setIsResponseMessageToastOpen,
      responseMessage,
      setResponseMessage
    }),
    [
      isBackdropSpinnerOpen,
      isRemoveMemberDialogOpen,
      isInviteMemberDialogOpen,
      isTradingExperienceDialogOpen,
      isResponseMessageToastOpen,
      responseMessage
    ]
  );

  return (
    <LayoutControlContext.Provider value={value}>
      {children}
    </LayoutControlContext.Provider>
  );
};

export const useLayoutControlState = (): Context => useContext(LayoutControlContext);
