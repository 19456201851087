import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import PopUp from '../PopUp';

export const PopUpWrapper = styled(PopUp)`
    max-width: 482px;
    width: 80%;
  > div:first-child {
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 0;
  }
`;

export const StyledItemTitle = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #5C5C5C;
  + div {
    margin-top: 16px;
  }
`;
export const StyledHeader = styled.div`
  display: flex;
  align-items: center;

  span {
    line-height: 36px;
    color: #1C1C1C;
    font-size: 24px;
    font-weight: 600;
  }
`;

export const StyledAccountData = styled.div`
  margin-bottom: 16px;
  p {
    margin: 0;
    color: #1C1C1C;
    font-size: 14px;
  }
`;

export const StyledInputContainer = styled.div`
 display: flex;
 flex-direction: column;
 margin-bottom: 16px;
 p {
    margin: 0 0 8px 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: var(--neutral-n-75, #1C1C1C);
  }
`;

export const StyledChooseAmountContainer = styled(StyledInputContainer)`
  width: 100%;
`;

export const StyledButtonContainer = styled.div`
 width: 100%;
 display: flex;
 flex-wrap: wrap;
 gap: 8px;
`;
export const StyledButton = styled.button<{isSelected: boolean}>`
  width: calc((100% - 16px) / 3);
  background-color: #ffffff;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--neutral-n-500, #DDD);
  background-color: #FFFFFF;
  color: #5C5C5C;
  cursor: pointer;
  ${(props) => props.isSelected
    && `
      border-color: var(--neutral-n-500, #145DB5);
      background-color: rgba(20, 93, 181, 0.10);
      color: #145DB5;
    `}
`;
export const StyledInput = styled.input<{showError: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  background: #ffffff;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3e3e3e;
  border: ${(props) => (props.showError ? '1px solid #F6ABAB' : '1px solid #bbbbbb')};
`;

export const StyledItemWrapper = styled.div`
  margin-bottom: 32px;

  > .StyledRadioGroup {
    .btnType {
      padding: 4px 43.5px;
    }
  }

  .select-custom {
    width: 632px;

    @media ${device.mobileMaxWidth} {
      width: 100%;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: end;
  .loadingIcon {
      height: 24px !important;
    }

  button {
    width: 100%;
    padding: 8px 16px;
    text-transform: none;
    border-radius: 4px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #145DB5;
    height: 40px !important;
    color: #ffffff;
    background-color: #145DB5;
    &:hover, &:focus {
      color: #ffffff;
      background-color: ${(props) => props.theme.common.primary};
      border-color: ${(props) => props.theme.common.primary};
    };
    &:disabled {
      color: #ffffff;
      opacity: 0.6;
    }
    .custom-progress {
      height: 24px !important;
      width: 24px !important;
      color: #bbbbbb !important;
    }
  }
`;

export const StyledError = styled.div`
  margin-top: 4px;
  color: #E92C2C;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
