import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IconComponent from 'components/Icons';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import SideMenuItem from 'containers/SideMenu/components/SideMenuItem';
import { useAppSelector, useAppDispatch } from 'store';
import { useMediaQuery } from 'react-responsive';
import { device } from 'styles/deviceStyle';
import { toggleSideMenu } from 'containers/SideMenu/slices';

import {
  StyledIconWrap,
  StyledTriangleWrap,
  StyledTitleWrap,
  StyledSideMenuRow,
  StyledSideMenuSubheader,
  StyledCollapseContainer
} from '../styled';

type Props = {
  groupInfo: {
    id: string
    type: string
    icon: string
    value: string
    items?: {
      id: string
      type: string
      url: string,
      value: string
    }[]
  },
  handleCloseMenu: () => void
}

const SideMenuGroup = ({ groupInfo, handleCloseMenu }: Props): JSX.Element => {
  const { t } = useTranslation('sidebar');
  const [open, setOpen] = useState(false);
  const navigator = useNavigate();
  const location = useLocation();
  const isMenuOpen = useAppSelector((state) => state.sideMenuState.isOpen);
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery({ query: device.desktopMinWidth });

  const clickHandler = (): void => {
    // Clicking on icon should open sidebar and give below sub items
    if (!isMenuOpen) {
      handleCloseMenu();
      setOpen(true);
    } else {
      setOpen((prev) => !prev);
    }
  };

  return (
    <>
      <StyledSideMenuRow onClick={clickHandler} isMenuOpen={isMenuOpen}>
        <StyledSideMenuSubheader isMenuOpen={isMenuOpen}>
          {groupInfo.icon && (
            <StyledIconWrap isMenuOpen={isMenuOpen}>
              <IconComponent name={groupInfo.icon} aria-label={`${groupInfo.id} icon`} />
            </StyledIconWrap>
          )}

          <StyledTitleWrap isMenuOpen={isMenuOpen}>
            <span aria-label={groupInfo.id}>{t(groupInfo.id)}</span>
            <StyledTriangleWrap isListOpen={open}>
              <IconComponent name="TriangleDown" aria-label={`${groupInfo.id} arrrow down`} />
            </StyledTriangleWrap>
          </StyledTitleWrap>
        </StyledSideMenuSubheader>
      </StyledSideMenuRow>
      <StyledCollapseContainer>
        <Collapse in={open && isMenuOpen} timeout="auto">
          {groupInfo.items
          && groupInfo.items.map((item) => (
            <SideMenuItem
              key={item.id}
              titleAriaLabel={item.id}
              handleClick={() => {
                if (!isDesktop) dispatch(toggleSideMenu());
                navigator(item.url || '');
              }}
              active={location.pathname === item.url}
              icon=""
            >
              {t(item.id)}
            </SideMenuItem>
          ))}
        </Collapse>
      </StyledCollapseContainer>
    </>
  );
};

export default SideMenuGroup;
