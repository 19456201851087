import * as React from "react";
import { SVGProps } from "react";
const SvgCashManagement = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 9V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2m2 4h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2Z"
      stroke="#fff"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 11.6c-1.105 0-2 .537-2 1.2 0 .663.895 1.2 2 1.2s2 .537 2 1.2c0 .663-.895 1.2-2 1.2m0-4.8v4.8m0-4.8c.74 0 1.387.241 1.733.6M14 11.6V11m0 5.4v.6m0-.6c-.74 0-1.387-.241-1.733-.6"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCashManagement;
