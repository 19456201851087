import axios from 'api/axios';
import { AxiosPromise } from 'axios';

interface VideoParamsType {
    page: number;
    pageSize: number;
    category: number;
}

export const getVideos = (): AxiosPromise => axios({
  method: 'GET',
  url: '/videos'
});

export const getSingleVideo = (videoID: string| undefined): AxiosPromise => axios({
  method: 'GET',
  url: `/videos/${videoID}`
});

export const getCategoryVideo = (data: VideoParamsType): AxiosPromise => axios({
  method: 'GET',
  url: '/videos',
  params: data
});
